import { Tooltip, Zoom } from "@mui/material";
import InfoIcon from "../../images/icons/infoIcon.svg";
import { useState } from "react";

const BroadcastTablestatus = ({ statusClass, statusTitle, error }) => {
  const [showError, setShowError] = useState(false);
  const cleanedString = error?.replace(/Error:\s*\([^)]*\)\s*/, "");
  const statusColorMap = {
    Completed: "#2BD02E", // Green for completed
    Pending: "#FEC931", // Yellow for pending (example color, adjust as needed)
    Failed: "#F64F43",
    Read: "#2BD02E",
    Delivered: "#669CEC",
  };

  // Function to get the background color based on the statusTitle
  const getStatusColor = (statusTitle) => {
    return statusColorMap[statusTitle] || "#CCCCCC"; // Default color if statusTitle is unknown
  };

  const statusStyle = {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    backgroundColor: getStatusColor(statusTitle),
  };
  console.log("errrrrrrrrrr", error);
  return (
    <>
      <ul className="nav align-items-center">
        <li className="nav-item pe-2">
          <div
            style={statusStyle}
            className={`status-circle ${statusClass}`}
          ></div>
        </li>
        <li className="nav-item">
          <div className="status-title">{statusTitle}</div>
        </li>
        {error != undefined && (
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={
              <div>
                <h2 style={{ fontWeight: "600", fontSize: "16px" }}>
                  Failure Detail:
                </h2>{" "}
                <p style={{ fontSize: "14px" }}>{cleanedString}</p>
              </div>
            }
            placement={"top"}
            sx={{ cursor: "pointer" }}
            open={showError}
            onMouseEnter={() => setShowError(true)}
            onMouseLeave={() => setShowError(false)}
          >
            <img
              src={InfoIcon}
              style={{ cursor: "pointer" }}
              className="mt-1"
              width={16}
              height={16}
            />
          </Tooltip>
        )}
      </ul>
    </>
  );
};

export default BroadcastTablestatus;
