import { Link } from "react-router-dom";

const BroadcastAction = ({ Link_add }) => {
  return (
    <>
      <Link to={Link_add} className="ded-btn signal-btn">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.6665 18.3346V11.0013H7.33317V18.3346H3.6665ZM9.1665 18.3346V3.66797H12.8332V18.3346H9.1665ZM14.6665 18.3346V8.2513H18.3332V18.3346H14.6665Z"
            fill="#4B4A4A"
          />
        </svg>
      </Link>
    </>
  );
};

export default BroadcastAction;
