import React , {ChangeEvent } from 'react'
import {Box} from '@mui/material';
import "./styles.css"

interface propsTypes {
    width?: string,
    height?: string,
    backgroundColor?: string,
    placeholder?: string,
    value?: string,
    disabled?:boolean,
    isTextBold?: Boolean,
    flag:Boolean,
    handleOnChange?: (val : string) => void
}

const InputField: React.FC<propsTypes> = (props) => {
    const handleChangeText = (event: ChangeEvent<HTMLInputElement>) => {
        console.log("(event.target.value", event.target.value)
        if(props?.handleOnChange != undefined){ // call function only if it is not undefined
            props?.handleOnChange(event.target.value);
        }
    };
  return (
    <>
       <Box
            sx={{
                height: props?.height ?? "38px",
                display : "flex",
                justifyContent : "center",
                alignItems : "center",
                borderRadius : "15px",
                width : props?.width ?? "300px",
                padding : 0,
                margin: 0,
                position:props?.flag && 'relative',
                backgroundColor:props?.flag && (props?.backgroundColor ?? '#ffffff'),
            }}
            className="custom-input-container"
        >
            {
                props.flag &&   <span
                style={{
                  position: 'absolute',
                  left: '10px',
                  fontWeight: props?.isTextBold ? 600 : 'normal',
                  color: 'black',
                  pointerEvents: 'none', // Make the span unselectable
                }}
              >
                #
              </span>
        
            }
            <input
                placeholder={props?.placeholder ?? "Placeholder ...."}
                value={props?.value?.startsWith('#')?props?.value?.slice(1):props?.value}
                onChange={handleChangeText}
                className="custom-input-field"
                disabled={props?.disabled}
                style={{
                    paddingLeft: props.flag &&  '20px', 
                    backgroundColor : props?.backgroundColor ?? "#ffffff",
                    fontWeight : props?.isTextBold ? 600 : "",
                    width:  props.flag && '100%',
                }}
            />
        </Box>
    </>
  )
}

export default InputField
