import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";

import "./css/template-action.css";

const TemplateAction = ({
  onChildC,
  template,
  onClickDelete,
  selIndex,
  index,
  setSelIndex,
  // showDeleteModal,
  // setShowDeletModal,
  isDeleting,
  templates,
  editTemplate,
  activeTempIcon,
  templateData,
}) => {
  const handleButtonClick = () => {
    onClickDelete(template?.name);
  };
  console.log("template :::", template, templateData);
  const [showDeleteModal, setShowDeletModal] = useState(false);
  const isDesktop = useMediaQuery("(min-width:600px)");
  return (
    <>
      <div className="action">
        <ul className="nav flex-nowrap">
          <li className="nav-item">
            <button
              className="action-b"
              style={{
                backgroundColor:
                  templates[index]?.name == templateData?.name ? "#E9F2FF" : "",
              }}
              onClick={onChildC}
            >
              {templates[index]?.name == templateData?.name ? (
                <img
                  src={activeTempIcon}
                  style={{ width: "16px", height: "10px" }}
                />
              ) : (
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99984 1.33333C9.22566 1.32926 10.4278 1.67119 11.4679 2.31982C12.5081 2.96845 13.3441 3.89744 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C2.65558 3.89744 3.49159 2.96845 4.53176 2.31982C5.57192 1.67119 6.77401 1.32926 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.44186 3.33333 8.86579 3.50893 9.17835 3.82149C9.49091 4.13405 9.6665 4.55797 9.6665 5C9.6665 5.44203 9.49091 5.86595 9.17835 6.17851C8.86579 6.49107 8.44186 6.66667 7.99984 6.66667C7.55781 6.66667 7.13389 6.49107 6.82133 6.17851C6.50877 5.86595 6.33317 5.44203 6.33317 5C6.33317 4.55797 6.50877 4.13405 6.82133 3.82149C7.13389 3.50893 7.55781 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              )}
            </button>
          </li>
          <li className="nav-item" onClick={editTemplate}>
            <Link to="/createTemplate" className="action-b">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </Link>
          </li>
          <li className="nav-item">
            <button
              className="action-b"
              onClick={() => {
                setShowDeletModal(true);
                setSelIndex(index);
              }}
            >
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </button>
          </li>
        </ul>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeletModal(false)}
        // className="whatsapp-modal confirm-modal modal-contact delete-modal"
        className="whatsapp-modal confirm-modal modal-contact "
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content" style={{ wordWrap: "break-word" }}>
            You are about to delete{" "}
            <span className="u-name">
              “<b>{template?.name}</b>“
            </span>{" "}
            from templates. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {isDeleting ? (
            <Button
              variant="danger-light"
              style={{ width: isDesktop ? "161px" : "142.7px" }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red", marginRight: "10px" }}
              />
            </Button>
          ) : (
            <Button
              variant="danger-light"
              onClick={() => (!isDeleting ? handleButtonClick() : null)}
            >
              Yes, Delete it
            </Button>
          )}
          <Button variant="danger" onClick={() => setShowDeletModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateAction;
