import BroadcastCardSkeleton from "./broadcast-card-sekelton";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import "./broadcast.css";

const Broadcastcard = ({
  title,
  imageicon,
  number,
  detailLoader,
  id,
  iconClass,
}) => {
  // return (
  //   <>
  //     <div className="broadcast-card">
  //       <div className="card-inner">
  //         <div className="broadcast-header">
  //           {title}
  //         </div>
  //         {!loading ? (
  //           <div className="broadcast-body">
  //             <div className="card-content d-flex align-items-center justify-content-center">
  //               <div className="icon-s">
  //                 {id ? (
  //                   <img src={imageicon} alt="b-icon" id={id} />
  //                 ) : (
  //                   <img src={imageicon} alt="b-icon" />
  //                 )}
  //               </div>
  //               <div className="number-s">{number}</div>
  //             </div>
  //           </div>
  //         ) : (
  //           <div className="broadcast-body placeholder-glow">
  //             <div
  //               className="placeholder w-75 py-2 mx-auto d-block"
  //               style={{ height: "61.6px", maxWidth: "115px" }}
  //             ></div>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </>
  // );
  const width = useDeviceWidthChecker();

  return (
    <>
      {detailLoader ? (
        <BroadcastCardSkeleton />
      ) : (
        <div className="broadcast-card mt-2">
          <div className="card-inner ">
            <div className="broadcast-header">
              <div className="c-title">{title}</div>
              <div className="total-number">{number}</div>
            </div>
            <div
              className={`card-icon d-flex align-items-center justify-content-center ${iconClass}`}
            >
              <div className="icon-s">
                <img
                  src={imageicon}
                  alt="b-icon"
                  style={{ width: width >= 768 ? "27px" : "18px" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Broadcastcard;
