import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { toast, ToastContainer } from "react-toastify";
import PageHeader from "../components/page-header/pageHeader";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "../components/broadcast/broadcast.css";
import { getAllGroups } from "../services/Group";
import { getAllTemplates } from "../services/WhatsappAPI";
import { AuthTemplate } from "../components/template-preview/AutheTemplate";
import { UrlPhoneNumber } from "../components/template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../components/template-preview/QuickReplyPreview";
import {
  getSingleBroadcasts,
  sendBroadcast,
  updateBroadcast,
} from "../services/Broadcast";
import "./broadcast.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setBroadcastDetail,
  setDetailLoader,
  setFilteredBroadcast,
  setEditLoading,
  setIsListingOpen,
} from "../reduxSlice/broadcast";
import EditBroadcastSkeleton from "../components/broadcast/EditBroadcastSkeleton";
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector";

const NewBroadcast = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const width = useDeviceWidthChecker();

  const { broadcastDetail, detailLoader, filteredBroadcast, editLoading } =
    useSelector((state) => state.BroadCastsReducer);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(null);
  const [titleErrorContent, setTitleErrorContent] = useState("");
  const [selectedgroups, setSelectedGroups] = useState([]);
  const [groupError, setGroupError] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [templates, setTemplates] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [sending, setSending] = useState(false);
  const [TemplateError, setTemplateError] = useState(false);
  const [broadcastAll, setBroadcastAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templateshow, setTemplateShow] = useState(false);
  const [templatetoShow, setTemplatetoShow] = useState(undefined);
  const [bodyVarCount, setbodyVarCount] = useState(0);
  const [headerVarCount, setHeaderVarCount] = useState(0);
  const [headerSample, setHeaderSample] = useState("");
  const [bodySample, setBodySample] = useState([]);
  const [isNowTimeSelected, setIsNowTimeSelected] = useState(true);
  const [selDateForSchedule, setSelDateForSchedule] = useState(null);
  const [selTimeForSchedule, setselTimeForSchedule] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(setEditLoading(true));
      // Fetch broadcast data using id from params
      if (templateList?.length > 0) {
        const fetchBroadcastData = async () => {
          try {
            const resp = await getSingleBroadcasts(id); // Adjust API function as needed
            if (resp.success) {
              // Populate the form with the fetched data
              const broadcast = resp.data;
              setTitle(broadcast[0].broadcastName);
              const selectedTemplate = templateList?.filter(
                (template) => template?.id == broadcast[0]?.templateId
              );
              console.log("template selecteddd1", selectedTemplate[0]);

              handleSelectTemplateChange({
                ...selectedTemplate[0],
                value: broadcast[0].templateId,
                label: selectedTemplate[0]?.label,
              });
              setSelectedGroups(
                broadcast[0].groups.map((group) => ({
                  value: group._id,
                  label: group.groupName,
                }))
              );
              // Set date and time if broadcast is scheduled
              if (broadcast[0].scheduleDate) {
                const scheduleDate = dayjs(broadcast[0].scheduleDate); // Convert to dayjs
                setSelDateForSchedule(scheduleDate);
                setselTimeForSchedule(scheduleDate);
                // setIsNowTimeSelected(false);
                // setSelDateForSchedule(moment(broadcast.scheduleDate));
                // setselTimeForSchedule(moment(broadcast.scheduleDate));
                setIsNowTimeSelected(false);
              }
            }
          } catch (error) {
            console.log("Failed to fetch broadcast data", error);
          } finally {
            dispatch(setEditLoading(false));
          }
        };

        fetchBroadcastData();
      }
    }
  }, [id, templateList]);
  console.log("selectedTemplate", selectedTemplate);
  const handleInputChange = (e) => {
    const input = e.target.value;
    setTitle(input);
    if (input.length > 40) {
      setTitleError(true);
      setTitleErrorContent("Broadcast name cannot exceed 40 characters *");
    } else if (input.length > 0 && !/^\ /.test(input)) {
      setTitleError(false);
    } else {
      setTitleError(true);
      setTitleErrorContent("Broadcast name cannot be empty *");
    }
  };

  const handleSelectChange = (selected) => {
    setSelectedGroups(selected);
    if (selected.length > 0) {
      setGroupError(false);
    }
  };

  const handleSelectTemplateChange = (selectedOption) => {
    console.log("template selecteddd", selectedOption);
    setSelectedTemplate(selectedOption);
    if (selectedOption) {
      setTemplateError(false);
    }
    let hLen = 0;
    let bLen = 0;
    if (
      selectedOption.components[0].type === "HEADER" &&
      selectedOption?.category == "AUTHENTICATION"
    ) {
      console.log("coming1");
      if (selectedOption.components[0].format == "text") {
        if (selectedOption.components[0].text.includes("{{1}}")) {
          hLen = 1;
          setHeaderVarCount(1);
        }
        bLen = (selectedOption.components[1].text.match(/{{[1-9]}}/g) || [])
          .length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        setBodySample(newBodySample);
      }
    } else {
      console.log("coming2");

      if (selectedOption?.category == "AUTHENTICATION") {
        console.log("coming3");

        bLen = (selectedOption.components[0].text.match(/{{[1-9]}}/g) || [])
          .length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        console.log("new body samplelelele", newBodySample);
        setBodySample(newBodySample);
      }
    }
    console.log("headerVarCount", bLen, "::", hLen);
    if (
      (bLen > 0 || hLen > 0) &&
      selectedOption?.category == "AUTHENTICATION"
    ) {
      setTemplatetoShow(selectedOption);
      setTemplateShow(true);
    } else {
      setTemplatetoShow(selectedOption);
    }

    console.log("bodyVarCount", bodyVarCount, ":::", bodySample);
  };
  console.log("templateToShow", templatetoShow);
  const fetchData = async () => {
    try {
      // setLoading(true)
      const resp = await getAllGroups(1, 1000);
      const groupData = [];
      if (resp.success) {
        let nonEmptyGroups = resp.data.filter(
          (item) => item?.contacts?.length > 0
        );
        nonEmptyGroups.map((data) => {
          groupData.push({ value: data._id, label: data.groupName });
        });
      }
      setGroups(groupData);
    } catch (error) {
      console.log("Failed to fetch Groups!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let nonEmptyGroups = groups.filter((item) => item?.contacts?.length > 0);
    console.log("nonEmptyGroups", nonEmptyGroups);
    const newOptions = [
      { label: "All contacts", value: "all" },
      ...nonEmptyGroups.map((group) => ({
        value: group._id,
        label: group.groupName,
      })),
    ];

    setGroupOptions(newOptions);
  }, [groups]);

  useEffect(() => {
    const fetchTemplatesData = async () => {
      try {
        const resp = await getAllTemplates();
        if (!resp.success) throw new Error(resp.error);
        setTemplates(resp.data);
        const templateList = [];
        const approvedTemplates = resp?.data?.filter(
          (item) => item?.status == "APPROVED"
        );
        console.log("templatesDataaa", approvedTemplates);
        approvedTemplates?.map((data) => {
          const singleTemplate = {};
          singleTemplate.value = data?.id;
          singleTemplate.label = data?.name;
          templateList.push({ ...singleTemplate, ...data });
        });

        setTemplateList(templateList);
      } catch (error) {
        console.log("Failed to fetch templates!");
      }
    };
    fetchTemplatesData();
  }, []);

  // diable previouse dates
  const disabledDate = (current) => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    const newDate = new Date(date);

    return current && current.valueOf() <= newDate;
  };

  // const currentTime = moment();
  const currentTime = dayjs();
  const disabledHours = () => {
    const hours = [];
    if (selDateForSchedule == moment().format("YYYY-MM-DD")) {
      for (let i = 0; i < 24; i++) {
        if (i < currentTime.hour()) {
          hours.push(i);
        }
      }
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if (selDateForSchedule == moment().format("YYYY-MM-DD")) {
      if (selectedHour === currentTime.hour()) {
        for (let i = 0; i < 60; i++) {
          if (i < currentTime.minute()) {
            minutes.push(i);
          }
        }
      }
    }
    return minutes;
  };

  // const currentTime = dayjs();

  const disabledTime = () => {
    if (selDateForSchedule && selDateForSchedule.isSame(currentTime, "day")) {
      return {
        disabledHours: () =>
          Array.from({ length: currentTime.hour() }, (_, i) => i),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === currentTime.hour()) {
            return Array.from({ length: currentTime.minute() }, (_, i) => i);
          }
          return [];
        },
      };
    }
    // If the selected date is not today, no times are disabled
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  const onChangeDateForAddBroadcast = (date, dateString) => {
    console.log(dateString);
    setSelDateForSchedule(date ? dayjs(date) : null);
    setselTimeForSchedule(null);
  };

  const onChangeTimeForAddBroadcast = (time, timeString) => {
    console.log(timeString);
    setselTimeForSchedule(time ? dayjs(time) : null);
  };

  const HandleSendBroadcast = async () => {
    let dateObj = new Date();
    let scheduleTimeTemp = Date.UTC(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours(),
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds()
    );
    let scheduleTime = new Date(scheduleTimeTemp);

    if (!isNowTimeSelected) {
      if (selDateForSchedule == null) {
        toast.error("Date is required for schedule broadcast");
        return;
      }
      if (selTimeForSchedule == null) {
        toast.error("Time is required for schedule broadcast");
        return;
      }

      let scheduleDate = new Date(selDateForSchedule.toDate());
      const hours = selTimeForSchedule.hour(); // Get hours
      const minutes = selTimeForSchedule.minute(); // Get minutes
      const seconds = selTimeForSchedule.second(); // Get seconds
      console.log("new Date : ", selTimeForSchedule);
      // const splittedDate = selTimeForSchedule?.split(":");
      scheduleDate.setHours(hours, minutes, seconds, 0);
      console.log("new Date two : ", scheduleDate);

      let scheduleTimeTemp = Date.UTC(
        scheduleDate.getUTCFullYear(),
        scheduleDate.getUTCMonth(),
        scheduleDate.getUTCDate(),
        scheduleDate.getUTCHours(),
        scheduleDate.getUTCMinutes(),
        scheduleDate.getUTCSeconds()
      );
      scheduleTime = new Date(scheduleTimeTemp);
    }
    if (titleError) {
      setTitleError(true);

      return;
    }

    if (title === "") {
      setTitleError(true);
      setTitleErrorContent("Broadcast name cannot be empty *");

      return;
    }
    if (selectedTemplate == undefined) {
      setTemplateError(true);
      return;
    }
    console.log("inside length1", selectedgroups);

    if (selectedgroups.length == 0) {
      console.log("inside length");
      setGroupError(true);
      return;
    }

    let broadcastall = false;

    selectedgroups.map((group) => {
      if (group.value == "all") {
        setBroadcastAll(true);
        broadcastall = true;
      }
    });

    let data = {
      broadcastName: title,
      ...(broadcastall
        ? { broadcastAll: true }
        : { groups: selectedgroups.map((group) => group.value) }),
      templateId: selectedTemplate.id,
      variables: [
        { type: "header", parameters: [] },
        { type: "body", parameters: [] },
      ],

      scheduleDate: !isNowTimeSelected ? scheduleTime.toISOString() : null,
    };

    // return
    try {
      let res;
      setSending(true);
      if (!id) {
        res = await sendBroadcast(data);
      } else {
        let updatedData = { ...data };
        res = await updateBroadcast({ broadcastId: id, ...updatedData });
      }
      if (res.success) {
        toast.success("Broadcast Successful");
        if (!id) {
          if (!isNowTimeSelected) {
            dispatch(setIsListingOpen(false));
          }
          navigate("/broadcast");
        } else {
          dispatch(setIsListingOpen(false));
          navigate("/broadcast");
        }
        setTitle("");
        setTemplatetoShow(null);
        setSelectedGroups(null);
        setSelDateForSchedule(null);
        setselTimeForSchedule(null);
        setIsNowTimeSelected(true);
        setSelectedTemplate(null);
        // setUpdateUi((prevData) => {
        //   return !prevData;
        // });
        // console.log("resp of new broadcast : ", res);

        // let allBoradCasts = Object.values(allBroadCastsData);
        // setAllBroadCastData([res?.data, ...allBoradCasts]);
      } else if (res.error) {
        toast.error(res.error);
      } else throw new Error(res.error);
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
    setSending(false);
  };

  const linkStyle = {
    color: "#165E5B",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "20px",
    textDecoration: "none",
  };

  useEffect(() => {
    if (!templateshow) {
      setBodySample([]);
      setbodyVarCount(0);
      setHeaderVarCount(0);
      setHeaderSample("");
    }
  }, [templateshow]);

  console.log("bodyVarCount ====", bodyVarCount);

  console.log("templates", templates);

  console.log("templatetoShow", templatetoShow);
  console.log("selected groups", groupError);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px 4px", // Adjust the padding here
      Border: state.isFocused && "0.5px solid #B3B3B3",
      backgroundColor: "#F3F7F7",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      whiteSpace: "normal", // Allow text to wrap to the next line
      wordWrap: "break-word",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),

    menu: (provided) => ({
      ...provided,
      zIndex: 2, // Ensures menu appears above other elements
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "240px", // Apply max height here
      overflowY: "auto",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#165E5B", // Change this to your desired background color for selected items
      color: "white", // Text color for selected items
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white", // Text color of the label in selected items
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white", // Color for the remove icon
      ":hover": {
        backgroundColor: "#5FD49557", // Change background on hover of remove button
        color: "white", // Change text color on hover of remove button
      },
    }),
  };

  return (
    <>
      <PageHeader
        Pagename={id ? "Edit Broadcast" : "Send New Braodcast"}
        Backpage={"Broadcast"}
        Currentpage={id ? "Edit Broadcast" : "Send New Braodcast"}
        Linkpage={"/broadcast"}
      />
      {editLoading ? (
        <EditBroadcastSkeleton />
      ) : (
        <section className="main inner-main py-3 pb-lg-5">
          <Container>
            <Row>
              <Col md={12} lg={8} xxxl={9} className="mb-3 mb-lg-0">
                <div className="create-template-form mb-3">
                  <Row>
                    <Col sm={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Broadcast Name</Form.Label>
                        <div className="label-bottom">
                          Give a name to your broadcast.
                        </div>
                        <div className="input-body">
                          <Form.Control
                            type="text"
                            placeholder="Set broadcast Name i.e Offer Alert"
                            name="name"
                            value={title}
                            onChange={handleInputChange}
                            style={{
                              marginBottom: !titleError ? "20px" : "0px",
                            }}
                          />
                          {titleError && (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}
                            >
                              {titleErrorContent}
                            </span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Select Message Template </Form.Label>
                        <Select
                          className="c-select mw-100"
                          options={templateList}
                          styles={customStyles}
                          placeholder={"Select Template"}
                          classNamePrefix="select"
                          value={selectedTemplate}
                          onChange={handleSelectTemplateChange}
                        />
                      </Form.Group>
                      {TemplateError && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          Please select a Template *
                        </span>
                      )}
                    </Col>
                    <Col sm={12} className="text-end mt-2">
                      <Link style={linkStyle} to="/createTemplate">
                        Create New Template
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="create-template-form mb-3">
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label> Who do you want to send it to?</Form.Label>
                        <div
                          className="label-bottom pt-3"
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Select Group
                        </div>

                        <Select
                          className="c-select mw-100"
                          options={groups}
                          styles={customStyles}
                          isMulti // allow multiple selection
                          value={selectedgroups} // set selected options
                          onChange={handleSelectChange} // update selected options
                          placeholder="Select group(s)"
                          classNamePrefix="select"
                        />
                      </Form.Group>
                      {groupError && (
                        <span
                          style={{
                            color: "red",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          Please select atleast 1 group *
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="create-template-form mb-3">
                  <Row>
                    <Col sm={12}>
                      <Form.Group>
                        <Form.Label>Schedule Broadcast</Form.Label>
                        <div className="label-bottom">
                          Choose a broadcast type below.
                        </div>
                        <div className="button-select">
                          <div className="form-check form-check-inline">
                            <Form.Check
                              checked={isNowTimeSelected == true}
                              type={"radio"}
                              label={`Now`}
                              id={`1`}
                              onChange={() => setIsNowTimeSelected(true)}
                            />
                          </div>
                          <div className="form-check form-check-inline">
                            <Form.Check
                              checked={isNowTimeSelected == false}
                              type={"radio"}
                              label={`Later`}
                              id={`2`}
                              onChange={() => setIsNowTimeSelected(false)}
                            />
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  {!isNowTimeSelected ? (
                    <Row
                      className="justify-content-start"
                      style={{ width: "300px" }}
                    >
                      <Col
                        xs={12}
                        sm={6}
                        className=" d-flex justify-content-start p-0"
                      >
                        <Form.Group
                          controlId="timePicker"
                          className="date-time-picker date-class-new"
                        >
                          <DatePicker
                            onChange={onChangeDateForAddBroadcast}
                            disabledDate={disabledDate}
                            showNow={false}
                            value={selDateForSchedule} // Pass the date state as value
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        className=" d-flex justify-content-start p-0"
                      >
                        <Form.Group
                          controlId="timePicker"
                          className="date-time-picker date-class-new"
                        >
                          <TimePicker
                            showNow={false}
                            disabled={selDateForSchedule == null}
                            onChange={onChangeTimeForAddBroadcast}
                            value={selTimeForSchedule} // Pass the time state as value
                            defaultOpenValue={dayjs("00:00:00", "HH:mm:ss")}
                            // disabledHours={disabledHours}
                            // disabledMinutes={disabledMinutes}
                            disabledTime={disabledTime}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                <Row>
                  <Col sm={12}>
                    <Button
                      className="btn-main w-100"
                      onClick={() => HandleSendBroadcast()}
                    >
                      {sending ? (
                        <div>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      ) : !isNowTimeSelected ? (
                        id ? (
                          "Edit Broadcast"
                        ) : (
                          "Schedule Broadcast "
                        )
                      ) : (
                        "Send Broadcast"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={4} xxxl={3}>
                <div className="message-preview-sec">
                  <div className="message-preview-title">
                    Message preview on WhatsApp
                  </div>
                  <div className="">
                    <div className="preview-sec preview-sec pt-0">
                      <div className="preview-body">
                        <div className="preview-template-inbox">
                          {templatetoShow?.category == "AUTHENTICATION" ? (
                            <AuthTemplate
                              components={templatetoShow?.components}
                              isInbox={true}
                            />
                          ) : (
                            <UrlPhoneNumber
                              components={templatetoShow?.components}
                              isInbox={true}
                              template={templatetoShow}
                              id={templatetoShow?.id}
                            />
                          )}
                        </div>
                        <QuickReplyPreview
                          components={templatetoShow?.components}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default NewBroadcast;
