import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
//import { Link } from 'react-router-dom';
import Select from "react-select";

import messagesend from "../images/icons/b-tick.svg";
import delivered from "../images/icons/delivery.svg";
import readmessage from "../images/icons/read.svg";
import failed from "../images/icons/failed.svg";
import pending from "../images/icons/pending.svg";
import Replied from "../images/icons/replied.svg";

import Broadcastcard from "../components/broadcast/broadcast-card";
import Broadcasttable from "../components/broadcast/broadcast-table";
import BroadcastcardLoading from "../components/broadcast/broadcast-loading";

const Broadcast = () => {
  const [ctshow, setCtShow] = useState(false);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };
  return (
    <>
      <section className="main inner-main broadcast-sec">
        <section className="contact-group-top">
          <Container fluid>
            <Row className="pb-3 pb-lg-4">
              <Col sm={12} className="text-end">
                <div className="btn-sec text-sm-end">
                  <Button className="btn-main" onClick={() => setCtShow(true)}>
                    <i className="bi bi-plus"></i> Send Broadcast
                  </Button>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="overview-heading">Overview</div>
              </Col>
            </Row>

            <Row className="pb-3">
              <Col md="4" lg={3} xl={2}>
                <div className="message-send">
                  <Broadcastcard
                    title={"Messages Sent"}
                    imageicon={messagesend}
                    number={"10"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="delivered-message">
                  <Broadcastcard
                    title={"Delivered Messages"}
                    imageicon={delivered}
                    number={"10"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="read-message">
                  <Broadcastcard
                    title={"Read Messages"}
                    imageicon={readmessage}
                    number={"10"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="replied">
                  <Broadcastcard
                    title={"Replied"}
                    imageicon={Replied}
                    number={"10"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="failed">
                  <Broadcastcard
                    title={"Failed Messages"}
                    imageicon={failed}
                    number={"10"}
                  />
                </div>
              </Col>
              <Col md="4" lg={3} xl={2}>
                <div className="pending">
                  <Broadcastcard
                    title={"Pending Messages"}
                    imageicon={pending}
                    number={"10"}
                  />
                </div>
              </Col>
            </Row>

            <Row className="pb-3">
              <Col md="4" lg={3} xl={2}>
                <div className="message-send">
                  <BroadcastcardLoading title={"Messages Sent"} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="contact-group-table">
          <Container fluid>
            <Row className="py-3">
              <Col sm={12}>
                <div className="overview-heading">Broadcast Summary</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Broadcasttable />
              </Col>
            </Row>
          </Container>
        </section>
      </section>

      <Modal
        show={ctshow}
        onHide={() => setCtShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Send Broadcast</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Enter Broadcast Name</Form.Label>
                <Form.Control type="text" placeholder="Mwan Mobile" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Select Template</Form.Label>
                <Select
                  styles={colourStyles}
                  className="c-select"
                  options={options}
                  placeholder={"Template name"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Select Group</Form.Label>
                <Select
                  styles={colourStyles}
                  className="c-select"
                  options={options}
                  placeholder={"Group name"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button
                className="btn-main w-100"
                onClick={() => setCtShow(false)}
              >
                Send Broadcast Message
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Broadcast;
