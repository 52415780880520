import React from "react";
import Wtable from "../table/table";
import "../table/table.css";

import BroadcastTablestatus from "./broadcast-status";
import Broadcastname from "./broadcast-name";
import BroadcastType from "./broadcast-type";
import DeliveryStatus from "./delivery-status";
import BroadcastAction from "./broadcast-action";
import TableSkeleton from "../skeletons/TableLoader";
import { Margin, Padding } from "@mui/icons-material";

const BroadcastDetailTable = ({
  broadcastDetail,
  detailLoader,
  filterLoader,
}) => {
  console.log("broadcastDetail", broadcastDetail);

  const groupStyles = {
    backgroundColor: "#BCF1D54D",
    padding: "7px",
    borderRadius: "6px",
  };
  const groupContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  };

  const theadData = [
    "Name",
    "Phone Number",
    "Member Of Group",
    "Status",
    "Delivered At",
  ];

  const determineStatus = (message) => {
    if (!message) return { status: "Pending", deliveredDate: "" };

    const { success, read, delivered, sent, failed } = message;
    if (failed) return { status: "Failed", deliveredDate: "" };

    if (read) return { status: "Read", deliveredDate: read };
    if (delivered) return { status: "Delivered", deliveredDate: delivered };
    if (sent) return { status: "Sent", deliveredDate: sent };

    return { status: "Pending", deliveredDate: "" };
  };

  let tbodyData = null;

  tbodyData =
    broadcastDetail &&
    broadcastDetail[0]?.messages?.map((data) => {
      const { status, deliveredDate } = determineStatus(data.message);
      // let status = "";
      // let deliveredDate = "";
      // if (!data?.message) {
      //   status = "Pending";
      //   deliveredDate = "-";
      //   return;
      // }
      // if (data?.message?.success) {
      //   if (data?.message?.read != "") {
      //     status = "Read";
      //     deliveredDate = data?.message?.read;
      //   } else if (data?.message?.delivered != "") {
      //     status = "delivered";
      //     deliveredDate = data?.message?.delivered;
      //   } else if (data?.message?.sent != "") {
      //     status = "Sent";
      //     deliveredDate = data?.message?.sent;
      //   }
      // } else {
      //   status = "Failed";
      //   deliveredDate = "-";
      // }

      return {
        id: data?.message?._id,
        items: [
          data?.contact?.contactName || "-", // Fallback for missing names
          data?.contact?.number || "-", // Fallback for missing numbers
          <div style={groupContainerStyles}>
            {data?.groups?.map((group) => {
              return <span style={groupStyles}>{group}</span>;
            })}
          </div>,
          <BroadcastTablestatus
            statusClass="status-title"
            statusTitle={status}
            error={data?.error}
          />,
          !deliveredDate ? "-" : formatDate(deliveredDate, true),
        ],
      };
    });

  function formatDate(isoDateString, includeTime = false) {
    if (isoDateString == "" || isoDateString == "-") {
      return;
    }
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }
  if (detailLoader || filterLoader) {
    return <TableSkeleton width="100%" />;
  }
  return (
    <>
      <div className="cg-custom-table">
        <Wtable theadData={theadData} tbodyData={tbodyData} />
      </div>
    </>
  );
};

export default BroadcastDetailTable;
