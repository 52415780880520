import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Tooltip from "@mui/material/Tooltip";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Allcontacts from "../group-table/all-contacts";
import WindowWidthDetector from "../customHooks/DeviceWidthDetector";
import { exportToExcel } from "../../utils/exportToCsv";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const Actionbutton = ({
  handleDelete,
  group,
  Contactlist,
  handleSelected,
  selectedContacts,
  editGroup,
  onClick,
  setCsvShow,
  setIsGroupImport,
}) => {
  const width = WindowWidthDetector();
  const [dtshow, setDtShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [cgtshow, setCgtShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupName, setGroupName] = useState("");
  const [showError1, setShowError1] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  console.log("group in edit", group);

  const handleInputChange = (e) => {
    setGroupName(e.target.value);
    if (e.target.value.length > 0) {
      setShowError1(false);
    }
  };
  const exportContact = async () => {
    const result = group?.contacts.map((contact) => ({
      name: contact.contactName,
      number: contact.number,
    }));
    exportToExcel(result);
    toast.success("Contacts Exported Sucessfully");
  };
  useEffect(() => {
    if (selectedContacts.length > 0) {
      setShowError(false);
    }
  }, [selectedContacts]);

  return (
    <>
      <div className="d-flex align-items-center flex-row">
        <button
          className="ded-btn"
          onClick={() => {
            setEditShow(true);
            handleSelected(group.contacts.map((contact) => contact._id));
            setGroupName(group.groupName);
            onClick();
          }}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
              fill="black"
              fillOpacity={"0.6"}
            />
          </svg>
        </button>
        <button className="ded-btn" onClick={() => setDtShow(true)}>
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
              fill="black"
              fillOpacity={"0.6"}
            />
          </svg>
        </button>
        {/* <Tooltip title={"Import Contacts"} arrow placement="top">
          <button
            className="ded-btn"
            onClick={() => {
              setCsvShow(true);
              setIsGroupImport(true);
            }}
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_8814_29655)">
                <path
                  d="M11.0293 4.88759L7.51928 1.33203L3.97046 4.88759"
                  stroke="black"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.51929 1.34766V11.9957"
                  stroke="black"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.32349 8.88672V12.8867C1.32349 13.8686 2.11357 14.6645 3.08819 14.6645H11.9117C12.8863 14.6645 13.6764 13.8686 13.6764 12.8867V8.88672"
                  stroke="black"
                  stroke-width="1.7"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_8814_29655">
                  <rect width="15" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </Tooltip> */}
        <Tooltip title={"Export Contacts"} arrow placement="top">
          <button className="ded-btn" onClick={exportContact}>
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8811 10.5L9.50015 14.329L13.1192 10.5371"
                stroke="#666666"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.5 4.16797V14.1203"
                stroke="#666666"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.07153 16.8359H14.9287"
                stroke="#666666"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </Tooltip>
      </div>

      <Modal
        show={dtshow}
        onHide={() => setDtShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete{" "}
            <span className="u-name">
              <b>{` “${group.groupName.trim()}”`}</b>
            </span>{" "}
            from your Groups. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            variant="danger-light"
            onClick={() => {
              setDtShow(false);
              handleDelete(group._id);
            }}
          >
            Yes, Delete it
          </Button>
          <Button variant="danger" onClick={() => setDtShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={editshow}
        onHide={() => setEditShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Set Group Name i.e Coworkers, Employees"
                  onChange={handleInputChange}
                  value={groupName}
                  required
                />
                {showError1 && (
                  <span style={{ color: "red", fontWeight: "500" }}>
                    Group name cannot be empty *
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>
                  Participants: {selectedContacts.length} of{" "}
                  {Contactlist.length}{" "}
                  <span
                    className="ms-3 edit-group"
                    style={{ cursor: "pointer", color: "#165E5B" }}
                    onClick={() => {
                      if (groupName.length === 0) {
                        setShowError1(true);
                      } else {
                        setCgtShow(true);
                        setEditShow(false);
                      }
                    }}
                  >
                    Edit
                  </span>
                </Form.Label>
              </Form.Group>
            </Col>
          </Row> */}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => setEditShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn-main"
            onClick={async () => {
              if (groupName.length === 0) {
                setShowError1(true);
              } else {
                setIsEditing(true);
                await editGroup(group._id, groupName, selectedContacts);
                setIsEditing(false);

                setEditShow(false);
              }
            }}
          >
            {isEditing ? (
              <div
                style={{
                  display: "flex",
                  minWidth: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ color: "rgba(22, 94, 91, 1)" }}
                />
              </div>
            ) : (
              "Update Group"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cgtshow}
        onHide={() => setCgtShow(false)}
        className="whatsapp-modal modal-g-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="mb-1 w-100">
            Edit Group Member
            <span
              className="finish-btn"
              onClick={() => {
                // if (selectedContacts.length===0){
                //     setShowError(true)
                // }
                // else{
                setCgtShow(false);
                editGroup(group._id, groupName, selectedContacts);
                setSearchQuery("");
                //}
              }}
            >
              Done
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}></Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  onChange={(e) => setSearchQuery(e.target.value.trimStart())}
                />
              </Form.Group>
            </Col>
          </Row>
          {showError && (
            <span style={{ color: "red", fontWeight: "500" }}>
              Select atleast 1 contact *
            </span>
          )}
          <Row>
            <div
              className="col-12"
              style={{
                maxHeight: width < 600 ? "300px" : "",
                height: width < 600 ? "100%" : "",
                overflow: width < 600 ? "auto" : "",
              }}
            >
              <Allcontacts
                Contactlist={Contactlist}
                handleSelected={handleSelected}
                selectedContacts={selectedContacts}
                searchQuery={searchQuery}
              />
              {showError1 && (
                <span style={{ color: "red", fontWeight: "500" }}>
                  Group name cannot be empty *
                </span>
              )}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Actionbutton;
