import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

const BlockedUserMobileListing = ({ handleOpenDeletePopup, data }) => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="template-listing-collapse blocked-listing">
            <div className="tlc-header">Name</div>
            <div className="tlc-body">
              {data &&
                data?.map((d) => {
                  return (
                    <Accordion defaultActiveKey="1">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="custom-dropdown-btn">
                          <div
                            className="username-broadcastmob"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "18.62px",
                            }}
                          >
                            {/* <Username name={data?.widgetName || "-"} /> */}
                            {d?.name || "-"}
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div
                              className="ub-title"
                              style={{ fontWeight: "400", marginLeft: "5px" }}
                            >
                              Phone Number
                            </div>
                            <div className="cb-date">{d?.phone}</div>
                          </div>

                          <div className=" d-flex align-items-center justify-content-end mb-3 ">
                            <button
                              className="unblock-btn w-100"
                              onClick={() => handleOpenDeletePopup(d)}
                            >
                              Unblock
                            </button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BlockedUserMobileListing;
