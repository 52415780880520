import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { Container } from "react-bootstrap";
import NotFoundImage from "../images/not-found.png";
import UtterLogo from "../images/logos/utter_logo.svg";

function NotFound() {
  const navigate = useNavigate();

  // useEffect(() => {
  //         navigate('/');
  // }, []);

  const backBtnHandler = () => {
    navigate("/");
  };

  // return (<></>)

  return (
    <div className="not-found-container">
      <div className="not-found-top-bar">
        <img src={UtterLogo} alt="" />
      </div>
      <div className="not-found-content">
        <img src={NotFoundImage} className="not-found-img" />
        <h1 className="not-found-heading" style={{ fontWeight: "700" }}>
          Oops, This Page Could Not Be Found.
        </h1>
        <p className="not-found-typography">
          The page you are looking for might have been removed, its name
          changed, or it is temporarily unavailable.
        </p>
        <div className="not-found-btn-container">
          <Button className="btn-main" onClick={backBtnHandler}>
            Back To Home
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
