import moment from "moment";
import md5 from "md5";

/**
 * Function to check if a string contains HTML tags.
 * @param {string} text - The string to check.
 * @returns {boolean} - Returns true if the string contains HTML tags, false otherwise.
 */
export function containsHTMLTags(text) {
  // Regular expression to match HTML tags
  const regex = /<\/?[a-z][\s\S]*>/i;
  return regex.test(text);
}

const letterToColorMap = {
  a: "#FF9A7F",
  b: "#7FFF9A",
  c: "#7F9AFF",
  d: "#FF7FD1",
  e: "#9AFF7F",
  f: "#9A7FFF",
  g: "#FF7F9A",
  h: "#7FD1FF",
  i: "#FF9A7F",
  j: "#7FFF9A",
  k: "#7F9AFF",
  l: "#FF7FD1",
  m: "#9AFF7F",
  n: "#9A7FFF",
  o: "#FF7F9A",
  p: "#7FD1FF",
  q: "#FF9A7F",
  r: "#7FFF9A",
  s: "#7F9AFF",
  t: "#FF7FD1",
  u: "#9AFF7F",
  v: "#9A7FFF",
  w: "#FF7F9A",
  x: "#7FD1FF",
  y: "#FF9A7F",
  z: "#7FFF9A",
};

export function getColorForLetter(letter) {
  // Convert the letter to lowercase to match the map keys
  const lowerCaseLetter = letter.toLowerCase();

  // Get the base color from the map
  const baseColor = letterToColorMap[lowerCaseLetter] || "#000000"; // default to black if letter not found

  // Set light opacity (0.2 for 20% opacity)
  const opacity = 0.2;

  // Convert the hex color to RGBA
  const rgbColor = hexToRgb(baseColor);
  const rgbaColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${opacity})`;

  return rgbaColor;
}

// Helper function to convert hex color to RGB
function hexToRgb(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return { r, g, b };
}

export function getRandomLetter(str) {
  // Ensure the string is not empty
  if (str.length === 0) {
    throw new Error("The input string must not be empty.");
  }

  // Get a random index within the string length
  const randomIndex = Math.floor(Math.random() * str.length);

  // Return the character at the random index
  return str.charAt(randomIndex);
}

export function isDateTimeGreaterThanCurrent(milliseconds) {
  // Parse the input date-time in milliseconds and convert it to UTC
  const inputDateTimeUtc = moment.utc(milliseconds);

  // Get the current date-time in UTC
  const currentDateTimeUtc = moment.utc();

  // Compare the input date-time with the current date-time
  return inputDateTimeUtc.isAfter(currentDateTimeUtc);
}

const generateLighterColor = (r, g, b) => {
  // Adjust RGB values to make the color lighter
  const lightnessFactor = 0.5; // Factor to adjust brightness (1.0 is no change, >1 is lighter)
  r = Math.round(r + (255 - r) * lightnessFactor);
  g = Math.round(g + (255 - g) * lightnessFactor);
  b = Math.round(b + (255 - b) * lightnessFactor);
  return { r, g, b };
};

export const generateColorHash = (...strings) => {
  const combinedString = strings.join("");
  const hash = md5(combinedString);
  const hexColor = `#${hash.substring(0, 6)}`;

  // Convert hex to RGB
  let r = parseInt(hexColor.slice(1, 3), 16);
  let g = parseInt(hexColor.slice(3, 5), 16);
  let b = parseInt(hexColor.slice(5, 7), 16);

  // Generate a lighter color
  const lighterColor = generateLighterColor(r, g, b);

  // Convert the lighter RGB back to rgba with opacity
  return `rgba(${lighterColor.r}, ${lighterColor.g}, ${lighterColor.b}, 0.5)`;
};

// Generate random background color
export const getRandomColor = (str) => {
  // const letters = '0123456789ABCDEF';
  // let color = '#';
  // for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  // }
  // return color;

  // Create a hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to a 6 digit hex color
  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }

  return color;
};

// Function to strip the time component from a date
function stripTime(date) {
  const strippedDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  return strippedDate;
}

// Function to compare two dates without considering time
export function isDateGreaterThanOrEqual(date1, date2) {
  // Strip the time component from both dates
  const strippedDate1 = stripTime(new Date(date1));
  const strippedDate2 = stripTime(new Date(date2));

  // Compare the dates
  return strippedDate2 >= strippedDate1;
}

// check if current date is less tha n or equal to previouse message date
export function isDateLessThanOrEqual(date1, date2) {
  // Strip the time component from both dates
  const strippedDate1 = stripTime(new Date(date1));
  const strippedDate2 = stripTime(new Date(date2));

  // Compare the dates
  return strippedDate1 >= strippedDate2;
}

// sort and group messages for showing in chat
export const sortAndGroupMessages = (allMessages) => {
  console.log("allMessages", allMessages);
  const uniqueMessages = (arr) => {
    const seen = {};
    const uniqueItems = [];

    if (arr?.length > 0) {
      arr?.forEach((item) => {
        if (item?.flow) {
          seen[item?.whatsappMessageId] = true;
          uniqueItems.push(item);
        } else if (!seen[item?.whatsappMessageId]) {
          seen[item?.whatsappMessageId] = true;
          uniqueItems.push(item);
        }
      });
    }

    return uniqueItems;
  };

  const filteredMsgs = uniqueMessages(allMessages);

  filteredMsgs.sort(
    (a, b) => moment(a.timestamp).toDate() - moment(b.timestamp).toDate()
  );
  // Prepare the result array
  const result = [];
  for (let i = 0; i < filteredMsgs.length; i++) {
    const currentItem = filteredMsgs[i];
    const nextItem = filteredMsgs[i + 1];
    const prevItem = filteredMsgs[i - 1];
    const today = moment().startOf("day");
    const isTodayDate = moment(currentItem.timestamp)
      .startOf("day")
      .isSame(today);

    // Check if the next item exists and has the same date
    if (nextItem) {
      const differenceInSec = moment(nextItem.timestamp).diff(
        currentItem.timestamp,
        "seconds"
      );
      // const isSameSender =
      //   (prevItem?.from && currentItem?.from) ||
      //   (!prevItem?.from && !currentItem?.from)
      //     ? true
      //     : false;
      if (differenceInSec < 60) {
        result.push({
          ...currentItem,
          diff: differenceInSec,
          dateToDisplay: isTodayDate
            ? moment(currentItem.timestamp)?.format("hh:mm a")
            : moment(currentItem.timestamp)?.format("DD/MM/YY hh:mm a"),
          isSameSender: false,
          displayDate: true,
        });
      } else {
        result.push({
          ...currentItem,
          dateToDisplay: isTodayDate
            ? moment(currentItem.timestamp)?.format("hh:mm a")
            : moment(currentItem.timestamp)?.format("DD/MM/YY hh:mm a"),
          diff: differenceInSec,
          isSameSender: false,
          displayDate: true,
        });
      }
    } else {
      // const isSameSender =
      //   (prevItem?.from && currentItem?.from) ||
      //   (!prevItem?.from && !currentItem?.from)
      //     ? true
      //     : false;
      result.push({
        ...currentItem,
        isSameSender: false,
        dateToDisplay: isTodayDate
          ? moment(currentItem.timestamp)?.format("hh:mm a")
          : moment(currentItem.timestamp)?.format("DD/MM/YY hh:mm a"),
        displayDate: true,
      });
    }
  }
  console.log("result", result);
  return result;
};

// get file type
export const getFileType = (file) => {
  const files = file;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    console.log(`File type: ${file.type}`);

    // Example to check specific types
    if (file.type === "application/pdf") {
      console.log("This is a PDF file.");
    } else if (
      file.type === "application/vnd.ms-powerpoint" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      console.log("This is a PowerPoint file.");
    } else if (
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      console.log("This is a Word document.");
    } else if (
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      console.log("This is an Excel file.");
    } else {
      console.log("Unknown file type.");
    }
  }
};

export const getFileTypeFromUrl = (url) => {
  // Extract the file extension
  console.log("url got : ", url);
  if (!url || url == "") {
    return "csv";
  }
  const extension = url?.split(".")?.pop()?.toLowerCase();

  // Map common file extensions to MIME types
  const mimeTypes = {
    pdf: "pdf",
    jpg: "jpeg",
    jpeg: "jpeg",
    png: "png",
    gif: "gif",
    txt: "plain",
    html: "html",
    css: "css",
    js: "javascript",
    json: "json",
    mp4: "mp4",
    mp3: "mp3",
    doc: "doc",
    docx: "doc",
    xls: "xls",
    xlsx: "xlsx",
    csv: "csv",
    txt: "txt",
    ppt: "ppt",
    pptx: "pptx",
    // Add more mappings as needed
  };

  // Return the corresponding MIME type, or 'unknown' if not found
  return mimeTypes[extension] || "";
};

export function getFileSize(bytes) {
  const KB = 1024; // 1 KB = 1024 bytes
  const MB = KB * 1024; // 1 MB = 1024 KB

  if (bytes < MB) {
    return `${(bytes / KB).toFixed(2)} KB`;
  } else {
    return `${(bytes / MB).toFixed(2)} MB`;
  }
}

export function isGreaterThanOrEqualToOneMinute(givenDateInMillis) {
  // const oneMinuteInMillis = 6 * 1000; // 1 minute = 60,000 milliseconds
  // const currentTimeInMillis = Date.now(); // Get current time in milliseconds

  // // Calculate the difference between the given time and the current time
  // const differenceInMillis = currentTimeInMillis - givenDateInMillis;

  // // Check if the difference is greater than or equal to 1 minute
  // console.log(
  //   "dates got : ",
  //   givenDateInMillis,
  //   differenceInMillis,
  //   differenceInMillis >= oneMinuteInMillis
  // );
  // return differenceInMillis >= oneMinuteInMillis;

  const currentTime = Date.now(); // Get the current time in milliseconds
  console.log(
    "givenDateInMillis",
    givenDateInMillis,
    "::",
    currentTime,
    "::",
    givenDateInMillis > currentTime
  );
  return givenDateInMillis > currentTime; // Compare the given time with the current time
}

export function addHoursToCurrentDate(hours) {
  const currentDate = new Date();
  const millisecondsToAdd = hours * 60 * 60 * 1000;
  const updatedDate = new Date(currentDate.getTime() + millisecondsToAdd);

  return updatedDate.getTime();
}

export function isCSV(file) {
  // Check MIME type and extension
  const mimeType = file.type; // MIME type of the file
  const fileName = file.name.toLowerCase(); // File name in lowercase

  // Check if the MIME type is 'text/csv' or if the file ends with '.csv'
  return mimeType === "text/csv" || fileName.endsWith(".csv");
}
