import React, { useState } from "react";
import { Tooltip, Zoom } from "@mui/material";
import { Spinner } from "react-bootstrap";

const UserRestoreAction = (props) => {
  const [restore, setRestore] = useState(false);
  return (
    <>
      <div className="d-flex align-items-center flex-row">
        <button className="ded-btn" onClick={props?.handleClickRestoreUser}>
          {props.isFetching ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              style={{ color: "#165E5B" }}
            />
          ) : (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 15.75C7.2625 15.75 5.75625 15.1781 4.48125 14.0344C3.20625 12.8906 2.475 11.4625 2.2875 9.75H3.825C4 11.05 4.57812 12.125 5.55937 12.975C6.54062 13.825 7.6875 14.25 9 14.25C10.4625 14.25 11.7031 13.7406 12.7219 12.7219C13.7406 11.7031 14.25 10.4625 14.25 9C14.25 7.5375 13.7406 6.29688 12.7219 5.27812C11.7031 4.25937 10.4625 3.75 9 3.75C8.1375 3.75 7.33125 3.95 6.58125 4.35C5.83125 4.75 5.2 5.3 4.6875 6H6.75V7.5H2.25V3H3.75V4.7625C4.3875 3.9625 5.16562 3.34375 6.08437 2.90625C7.00312 2.46875 7.975 2.25 9 2.25C9.9375 2.25 10.8156 2.42812 11.6344 2.78437C12.4531 3.14062 13.1656 3.62188 13.7719 4.22812C14.3781 4.83437 14.8594 5.54688 15.2156 6.36562C15.5719 7.18438 15.75 8.0625 15.75 9C15.75 9.9375 15.5719 10.8156 15.2156 11.6344C14.8594 12.4531 14.3781 13.1656 13.7719 13.7719C13.1656 14.3781 12.4531 14.8594 11.6344 15.2156C10.8156 15.5719 9.9375 15.75 9 15.75Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          )}
        </button>
      </div>
    </>
  );
};

export default UserRestoreAction;
