import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTemplates: [],
    totalRecords: 0
};

export const TemplatesSlices = createSlice({
    name: "Templates",
    initialState,
    reducers: {
        // set all templates
        setTemplatesDataInRedux: (state, action) => {
            state.allTemplates = Object.values(action.payload.data);
            state.totalRecords = action.payload.totalRecords;
        },
        // empty all templates
        removeAllTemplatesData: (state, action) => {
            state.allTemplates = [];
            state.totalRecords = 0;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setTemplatesDataInRedux,
    removeAllTemplatesData,
} = TemplatesSlices.actions;

export default TemplatesSlices.reducer;
