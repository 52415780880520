import "./broadcast.css";

const DeliveryStatus = ({ CompletedValue, PendingValue, FailedValue }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Success":
        return "#5FD495"; // Green for completed
      case "inQueue":
        return "#FEC931"; // Yellow for pending
      case "Failed":
        return "#FFB7B2"; // Red for failed
      default:
        return "#CCCCCC"; // Default color
    }
  };

  const getInnerStyle = (value, status) => ({
    width: `${value}%`,
    backgroundColor: getStatusColor(status),
  });

  return (
    <>
      <div className="delivery-status">
        <div className="status-span">
          <div className="status-title">Success</div>
          <div className="status-progress completed">
            <div
              className="inner"
              style={getInnerStyle(CompletedValue, "Success")}
            ></div>
          </div>
          <div className="progress-value">{CompletedValue}%</div>
        </div>
        <div className="status-span">
          <div className="status-title">In Queue</div>
          <div className="status-progress pending">
            <div
              className="inner"
              style={getInnerStyle(PendingValue, "inQueue")}
            ></div>
          </div>
          <div className="progress-value">{PendingValue}%</div>
        </div>
        <div className="status-span">
          <div className="status-title">Failed</div>
          <div className="status-progress failed">
            <div
              className="inner"
              style={getInnerStyle(FailedValue, "Failed")}
            ></div>
          </div>
          <div className="progress-value">{FailedValue}%</div>
        </div>
      </div>
    </>
  );
};

export default DeliveryStatus;
