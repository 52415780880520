import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import WidgetListingIcon from "../../images/widget-listing-icon.svg";
import { Tooltip } from "@mui/material";

const WidgetHeader = ({ screenWidth, isSimpleUser }) => {
  const navigate = useNavigate();

  const breadcrumbLinkstyle = {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#4B4A4A",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  };
  const buttonStyle = {
    maxHeight: "38px",
    display: "flex",
    alignItems: "center",
    minWidth: screenWidth > 1024 && "157px",
  };

  const breadcrumbSpanstyle = {
    fontWeight: "600",
    color: "#4B4A4A",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const linkstyle = {
    textDecoration: "none",
    marginRight: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const svg_iconstyle = {
    position: "relative",
  };
  return (
    <>
      {screenWidth >= 1024 ? (
        <section className="contact-group-top">
          <Container>
            <Row className="flex-column-reverse flex-md-row py-2">
              <Col xs={6} className="">
                <h2
                  className="mt-1 flex gap-2"
                  style={{
                    fontWeight: "600",
                    fontSize: "22px",
                    fontFamily: "Segoe UI",
                  }}
                >
                  <img src={WidgetListingIcon} />
                  WhatsApp Website Widget
                </h2>
              </Col>
              <Col xs={6} className="flex justify-end gap-4">
                <Link style={linkstyle} to={`/widgets`}>
                  <span style={breadcrumbSpanstyle}>
                    <svg
                      style={svg_iconstyle}
                      className="me-2"
                      width="7"
                      height="11"
                      viewBox="0 0 7 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10L1 5.5L6 1"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {`Integrations `}
                  </span>{" "}
                  <span style={{ width: "5px" }}></span>
                  <span style={{ textDecoration: "none", color: "#4B4A4A" }}>
                    /{" "}
                    {`${
                      screenWidth >= 1024 && screenWidth < 1440
                        ? " WhatsApp..."
                        : "WhatsApp Website Widget"
                    }`}
                  </span>
                </Link>
                {isSimpleUser ? (
                  <Tooltip title={"You can't perform this action"} arrow>
                    <Button className="btn-main" style={buttonStyle}>
                      <i className="bi bi-plus"></i> Add Widget
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="btn-main"
                    style={buttonStyle}
                    onClick={() => navigate("/widget-creation")}
                  >
                    <i className="bi bi-plus"></i> Add Widget
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="contact-group-top flex justify-start align-middle">
          <Container className={`${screenWidth < 768 && "mb-3"}`}>
            <Row className="  py-2">
              <Col xs={9} className="flex ">
                <Link style={linkstyle} to={`/widgets`}>
                  <span style={breadcrumbSpanstyle}>
                    <svg
                      style={svg_iconstyle}
                      className="me-2"
                      width="7"
                      height="11"
                      viewBox="0 0 7 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 10L1 5.5L6 1"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {`Integrations`}
                  </span>
                  <span style={{ textDecoration: "none", color: "#4B4A4A" }}>
                    /
                    {`${
                      screenWidth >= 768
                        ? " WhatsApp Website Widget"
                        : screenWidth >= 375
                        ? " WhatsApp W..."
                        : "WhatsApp..."
                    }`}
                  </span>
                </Link>
              </Col>
              <Col xs={3} className="flex justify-end">
                {" "}
                {isSimpleUser ? (
                  <Tooltip title={"You can't perform this action"} arrow>
                    <Button className="btn-main" style={buttonStyle}>
                      <i className="bi bi-plus"></i> Add Widget
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    className="btn-main "
                    style={buttonStyle}
                    onClick={() => navigate("/widget-creation")}
                  >
                    <i className="bi bi-plus"></i>
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default WidgetHeader;
