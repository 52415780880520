import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BlockedUserHeaderIcon from "../../images/blocked-user-header-icon.svg";

const BlockedUsersHeader = ({ screenWidth }) => {
  return (
    <>
      <section className="contact-group-top">
        <Container>
          <Row className="flex-column-reverse flex-md-row py-2 ">
            <Col xs={12} className="">
              <h2
                className=" flex gap-2 align-middle "
                style={{
                  fontWeight: "600",
                  fontSize: "22px",
                  fontFamily: "Segoe UI",
                  marginBottom: screenWidth < 500 && "15px",
                }}
              >
                <img src={BlockedUserHeaderIcon} className="mt-1" />
                Blocked Contacts
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlockedUsersHeader;
