import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allContacts: [],
  totalRecords: 0,
  allInboxContacts: [],
};

export const ContactsSlices = createSlice({
  name: "Contacts",
  initialState,
  reducers: {
    // set all contacts
    setContactsData: (state, action) => {
      state.allContacts = Object.values(action.payload.data);
      state.totalRecords = action.payload.totalRecords;
    },
    // empty all contacts
    removeAllContactsData: (state, action) => {
      state.allContacts = [];
      state.totalRecords = 0;
    },
    setAllInboxContacts: (state, action) => {
      state.allInboxContacts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setContactsData, removeAllContactsData, setAllInboxContacts } =
  ContactsSlices.actions;

export default ContactsSlices.reducer;
