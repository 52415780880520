import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allTagsStored: [],
    totalRecords: 0
};

export const TagsSlices = createSlice({
    name: "Groups",
    initialState,
    reducers: {
        // set all Tags
        setTagsData: (state, action) => {
            state.allTagsStored = Object.values(action.payload.data);
            state.totalRecords = action.payload.totalRecords;
        },
        // empty all tags
        removeAllTagsData: (state, action) => {
            state.allTagsStored = [];
            state.totalRecords = 0
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setTagsData,
    removeAllTagsData,
} = TagsSlices.actions;

export default TagsSlices.reducer;
