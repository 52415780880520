import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Username from "../userName/username";

import "../broadcast/usermob.css";

const WidgetMobileListing = ({ data }) => {
  const groupStyles = {
    backgroundColor: "#BCF1D54D",
    padding: "7px",
    borderRadius: "6px",
  };
  const groupContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  };

  function formatDate(isoDateString, includeTime = false) {
    if (isoDateString == "") {
      return;
    }
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getUTCDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getUTCFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getUTCHours();
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="template-listing-collapse">
              <div className="tlc-header">Widget Name</div>
              <div className="tlc-body">
                {data &&
                  data?.map((data) => {
                    console.log("widgetDDDD", data);
                    return (
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div
                              className="username-broadcastmob"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18.62px",
                              }}
                            >
                              {/* <Username name={data?.widgetName || "-"} /> */}
                              {data?.widgetName || "-"}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                              <div
                                className="ub-title"
                                style={{ fontWeight: "400" }}
                              >
                                Created At
                              </div>
                              <div className="cb-date">
                                {formatDate(data?.createdAt, false)}
                              </div>
                            </div>
                            <div className="contact-list d-flex align-items-center justify-content-end mb-3">
                              <div className="cb-date">{data?.action}</div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WidgetMobileListing;
