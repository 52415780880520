function getConfig() {
    // const backendUrl = 'http://localhost:5002/';
    // const backendUrl = 'https://whatsappapi.mwancloud.com/';
    const backendUrl='https://utter-api.dev.mwancloud.com/';
  
    return {
      backend: backendUrl
    };
  }
  
  export default getConfig();