import "./broadcast.css";

const Broadcastname = ({ EventName, CreatedName, isUpdated }) => {
  const truncatedName =
    EventName?.length <= 40 ? EventName : EventName?.slice(0, 40) + "...";
  console.log("dattaaa", EventName);
  return (
    <>
      <div className="broadcast-name">
        <div class="event-name">{EventName}</div>
        <div class="createdby">
          {(isUpdated ? "Updated By" : "Created By") + " " + CreatedName}
        </div>
      </div>
    </>
  );
};

export default Broadcastname;
