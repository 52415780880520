import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../broadcast/emptyBroadcast.css";

import WidgetListingIcon from "../../images/widget-listing-icon.svg";

import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const EmptyWidget = ({ isSimpleUser }) => {
  return (
    <>
      <section className=" templates-body-empty" style={{ width: "100%" }}>
        <Container className="mt-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="tb-empty-content text-center groups">
                <div className="w-100 flex justify-center">
                  <img
                    class="groups-icons"
                    src={WidgetListingIcon}
                    alt="icon"
                  />
                </div>
                <div className="tb-page-title">No Widgets Found</div>
                <p className="mt-2">
                  You can create a new widget by clicking the button below
                </p>
                {isSimpleUser ? (
                  <Tooltip title={"You can't perform this action"} arrow>
                    <Link to={`/widget-listing`} className="btn btn-main mt-4">
                      <i className="bi bi-plus"></i> Add Widget
                    </Link>
                  </Tooltip>
                ) : (
                  <Link to={`/widget-creation`} className="btn btn-main mt-4">
                    <i className="bi bi-plus"></i> Add Widget
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EmptyWidget;
