import React, { useState, useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { IconButton, Box, Menu, MenuItem, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NoNoteIcon from "../../images/no-notes-icon.svg";
import moment from "moment";

const UserNotes = (props) => {
  const [noteMsg, setNoteMsg] = useState("");
  const [editNoteData, setEditNoteData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleClickDropdown = (event) => {
    props?.setAnchorEl(event.currentTarget);
    props?.setOpenedMenuId(event.currentTarget?.id);
  };
  const handleCloseDropdown = () => {
    props?.setOpenedMenuId(null);
    props?.setAnchorEl(null);
  };

  const handleCancelAddNoteClick = () => {
    props?.setIsAddNewNote(false);
    props?.setIsNoteAdding(false);
    props?.setIsNoteUpdate(false);
    props?.setIsNoteEditing(false);
    setEditNoteData(null);
    props?.setSelectedNote(null);
    setNoteMsg("");
  };

  const handleAddNoteClick = () => {
    props?.setIsAddNewNote(true);
    setEditNoteData(null);
    props?.setSelectedNote(null);
    setNoteMsg("");
  };
  const noteContainerStyle = {
    overflowY: props?.notes?.length > 3 ? "auto" : "hidden", // Adjust '3' as per your requirement
    maxHeight: props?.isAddNewNote ? "200px" : "600px", // Adjust this value based on your layout
  };

  // if (props?.isNotesFetching) {
  //     return (<>
  //         <p>Fetching Notes ...</p>
  //     </>)
  // }

  return (
    <>
      <div className="note-body">
        <div className="note-header pb-2 mb-3 mb-md-0 text-center text-md-end">
          <Box
            className={`flex ${
              screenWidth < 767 ? "justify-center" : "justify-end"
            } gap-2 align-items-center w-100 pb-2`}
          >
            <IconButton
              sx={{
                padding: 0,
                width: "18px",
                height: "18px",
                display: "flex", // Ensure the button displays as a flex container
                alignItems: "center", // Center content vertically
                justifyContent: "center",
              }}
            >
              {props?.isAddNewNote || props?.isUpdateNote ? (
                <CancelIcon
                  sx={{
                    color: "rgba(22, 94, 91, 1)",
                    fontSize: "21.5px", // Set icon size to 18px
                  }}
                  onClick={handleCancelAddNoteClick}
                />
              ) : (
                <AddCircleIcon
                  sx={{
                    color: "rgba(22, 94, 91, 1)",
                    fontSize: "21.5px", // Set icon size to 18px
                  }}
                  onClick={handleAddNoteClick}
                />
              )}
            </IconButton>
            <p
              style={{
                fontFamily: "Segoe UI",
                color: "#165E5B",
                fontSize: "14px",
              }}
              className=" font-semibold "
            >
              Write a Note
            </p>
          </Box>
          {props?.isNotesFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : props?.isAddNewNote || props?.isUpdateNote ? (
            <Box className="w-100 mb-2 flex flex-column ">
              <textarea
                placeholder="Write a note..."
                value={noteMsg}
                onChange={(e) => setNoteMsg(e.target.value)}
                style={{
                  width: "100%",
                  border: "1px solid lightGrey",
                  borderRadius: "7px",
                  padding: "10px",
                  resize: "none",
                }}
              />
              <Box className="w-100 mt-2 flex justify-end align-items-center">
                <Button
                  sx={{ fontFamily: "SEGOE UI" }}
                  onClick={() =>
                    props?.isNoteAdding || props?.isNoteEditing
                      ? null
                      : props?.isUpdateNote
                      ? props?.handleUpdateNote(noteMsg)
                      : props?.handleSaveNote(noteMsg)
                  }
                  variant="contained"
                  className="bg-[#165E5B] text-[14px] font-medium font-['Segoe UI'] text-[#FFFFFF] rounded-md"
                >
                  {props?.isNoteAdding || props?.isNoteEditing ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      style={{ marginRight: "5px" }}
                      aria-hidden="true"
                    />
                  ) : null}
                  {props?.isUpdateNote ? "Update Note" : "Save Note"}
                </Button>
              </Box>
            </Box>
          ) : null}
        </div>
        {props?.notes?.length <= 0 && !props?.isNotesFetching ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            <img src={NoNoteIcon} alt="not-notes" />
            <p className="mt-3">No notes to display</p>
          </div>
        ) : (
          <div className="note-body-inner" style={noteContainerStyle}>
            {props?.notes?.map((item, ind) => (
              <div className="note-panel">
                <div className="note-user">
                  {item?.user?.firstname + " " + item?.user?.lastname}
                </div>
                <div className="note-date">
                  {moment(item?.updatedAt).format("DD/MM/YYYY hh:mm a")}
                </div>
                <div
                  className="note-content"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {item?.text}
                </div>
                <div className="note-option">
                  {props?.userId == item?.user?._id ? (
                    <>
                      <IconButton
                        aria-label="more"
                        aria-controls="note-menu"
                        aria-haspopup="true"
                        onClick={handleClickDropdown}
                        sx={{
                          backgroundColor:
                            props?.openedMenuId == ind ? "#FFFFFF" : "",
                        }}
                        id={ind}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="note-menu"
                        anchorEl={props?.anchorEl}
                        open={props?.open}
                        onClose={handleCloseDropdown}
                        sx={{ boxShadow: "none" }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          style={{ width: "120px", height: "40px" }}
                          onClick={() => {
                            props?.setIsNoteUpdate(true);
                            props?.setSelectedNote(
                              props?.notes[props?.openedMenuId]?._id
                            );
                            handleCloseDropdown();
                            setNoteMsg(props?.notes[props?.openedMenuId]?.text);
                            setEditNoteData(props?.notes[props?.openedMenuId]);
                          }}
                        >
                          Edit Note
                        </MenuItem>
                        <MenuItem
                          style={{
                            width: "120px",
                            height: "40px",
                            color: "#E94B42",
                          }}
                          onClick={() => {
                            props?.setSelectedNote(
                              props?.notes[props?.openedMenuId]?._id
                            );
                            props?.setIsShowDeleteNote(true);
                            handleCloseDropdown();
                          }}
                        >
                          Delete Note
                        </MenuItem>
                      </Menu>
                    </>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default UserNotes;
