import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "./form.css";
import SideSection from "./side-section";

const Reset_form = ({ heading, tokenStatus, reset, updated }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/resetpassword");
  };

  const [password, setPassword] = useState({ pass1: "", pass2: "" });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password.pass1 !== password.pass2) {
      alert("Passwords do not match");
      return;
    }
    if (password.pass1 == "") {
      alert("Passwords cannot be empty");
      return;
    }
    if (password.pass1.length < 8) {
      alert("Passwords cannot be less than 8 digit");
      return;
    }
    reset(password.pass1);
  };

  return (
    // <div className="form-sec">
    // <Form>
    //     <div className="form-heading text-center">
    //         {heading}
    //     </div>
    //     {tokenStatus == "valid" && (<><Form.Group className="form-group">
    //         <Form.Label>Enter password*</Form.Label>
    //         <Form.Control type="password" placeholder="" value={password.pass1} onChange={(e) => setPassword({ ...password, pass1: e.target.value })} />
    //     </Form.Group>
    //         <Form.Group className="form-group">
    //             <Form.Label>Confirm password*</Form.Label>
    //             <Form.Control type="password" placeholder="" value={password.pass2} onChange={(e) => setPassword({ ...password, pass2: e.target.value })} />
    //         </Form.Group>
    //         {updated === true && <Form.Group className="text-center last-pra" style={{ marginBottom: '10px', color: 'green' }}>
    //             Password updated Successfully ✔
    //         </Form.Group>}
    //         <Form.Group className="form-group">
    //             {updated === false ? <Button className="btn btn-main" onClick={handleSubmit}>
    //
    //             </Button> : <></>}
    //         </Form.Group></>)}
    //     {tokenStatus == "expired" && (
    //         <>
    //             <Form.Group className="text-center last-pra" style={{ marginBottom: '30px', color: 'orange' }}>
    //                 Your Link is expired, Click on Reset Password to generate a new link.
    //             </Form.Group>
    //             <Button className="btn btn-main" onClick={handleClick}>
    //                 Reset Password
    //             </Button>
    //         </>
    //     )}
    //     {tokenStatus == "invalid" && (
    //         <>
    //             <Form.Group className="text-center last-pra" style={{ marginBottom: '30px', color: 'red' }}>
    //                 Your Link is invalid, Click on Reset Password to generate a valid password reset link.
    //             </Form.Group>
    //             <Button className="btn btn-main" onClick={handleClick}>
    //                 Reset Password
    //             </Button>
    //         </>
    //     )}

    //     <Form.Group className="text-center last-pra">
    //         <Link to="/login">Back to login</Link>
    //     </Form.Group>
    // </Form>
    // </div>

    <section className="login-main">
      <SideSection />

      <div className="login-form">
        <div className="loginForm-body">
          <div className="login-logo text-center">
            <a href="index.php">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="95"
                height="24"
                viewBox="0 0 95 24"
                fill="none"
              >
                <path
                  d="M0.0280578 0.0414574C2.85899 0.0414574 5.64857 0.0414574 8.50779 0.0414574C8.50779 0.281336 8.50779 0.490195 8.50779 0.699055C8.50779 4.33652 8.50126 7.97605 8.50996 11.6135C8.51431 13.181 9.62623 14.5665 11.1733 14.976C12.7074 15.3813 14.3437 14.7836 15.2228 13.4974C15.6972 12.8026 15.8147 12.0292 15.8147 11.2227C15.8125 7.68448 15.8212 4.1442 15.7994 0.605999C15.7994 0.120038 15.9539 -0.00403673 16.4501 9.9101e-05C18.8871 0.0228462 21.322 0.0145745 23.7591 0.00630285C24.0964 0.00630285 24.3053 0.0331858 24.3031 0.448837C24.2813 4.38408 24.3009 8.3214 24.2639 12.2566C24.2509 13.7621 23.7831 15.1827 23.1259 16.5517C23.0215 16.7709 22.9823 17.0645 23.0367 17.2982C23.4784 19.2338 23.9506 21.1652 24.4141 23.0967C24.4337 23.1773 24.4598 23.2559 24.4772 23.3365C24.6099 23.9652 24.4511 24.1079 23.8113 23.928C22.1924 23.4792 20.5604 23.0698 18.9655 22.5549C17.8557 22.1971 16.87 22.2095 15.7189 22.559C8.57959 24.7303 1.03553 20.0982 0.110744 13.0549C0.0454655 12.5627 0.00847413 12.0664 0.00629817 11.5722C-0.00240569 7.89954 -0.000229722 4.22899 0.00194624 0.556369C0.00194624 0.395071 0.017178 0.233774 0.0280578 0.0414574Z"
                  fill="#5FD495"
                />
                <path
                  d="M90.1413 10.1602C89.3342 9.73828 88.4893 9.52734 87.6066 9.52734C86.497 9.52734 85.5764 9.86719 84.8451 10.5469C84.1137 11.2031 83.4706 12.0234 82.9157 13.0078V22.9219H78.1113V5.16797H82.9157V8.50781C83.5715 7.45312 84.3407 6.57422 85.2234 5.87109C86.1313 5.16797 87.1527 4.81641 88.2876 4.81641C88.9685 4.81641 89.5864 4.92188 90.1413 5.13281V10.1602Z"
                  fill="#165E5B"
                />
                <path
                  d="M65.6288 4.71094C67.4951 4.71094 69.0965 5.08594 70.4332 5.83594C71.7951 6.58594 72.8291 7.62891 73.5353 8.96484C74.2666 10.2773 74.6323 11.8008 74.6323 13.5352V15.4336H60.7866C61.0388 16.6289 61.6693 17.5898 62.6781 18.3164C63.6869 19.043 64.9226 19.4062 66.3854 19.4062C67.7725 19.4062 69.0335 19.1484 70.1684 18.6328C71.3033 18.1172 72.2112 17.5898 72.8922 17.0508L72.8543 21.1992C72.249 21.6914 71.3411 22.1953 70.1306 22.7109C68.9452 23.2031 67.6338 23.4492 66.1963 23.4492C64.3048 23.4492 62.5772 23.0508 61.0135 22.2539C59.4751 21.457 58.2394 20.3672 57.3062 18.9844C56.3983 17.6016 55.9443 16.0195 55.9443 14.2383C55.9443 12.5039 56.3605 10.9219 57.1927 9.49219C58.025 8.0625 59.1725 6.91406 60.6353 6.04688C62.098 5.15625 63.7625 4.71094 65.6288 4.71094ZM65.5153 8.61328C64.33 8.61328 63.3338 8.95312 62.5267 9.63281C61.7197 10.3125 61.1649 11.2266 60.8622 12.375H70.0171C69.9162 11.1094 69.4118 10.1719 68.5039 9.5625C67.596 8.92969 66.5998 8.61328 65.5153 8.61328Z"
                  fill="#165E5B"
                />
                <path
                  d="M34.6356 0V5.16797H41.1045C42.6177 5.16797 43.7652 4.76953 44.547 3.97266C45.3541 3.15234 46.0602 1.88672 46.6655 0.175781H48.7462V5.16797H54.0424V8.89453H48.7462V16.5586C48.7462 17.3555 48.9353 17.9883 49.3136 18.457C49.7171 18.9023 50.3098 19.125 51.0916 19.125C51.7726 19.125 52.3526 19.0547 52.8318 18.9141C53.3362 18.7734 53.8028 18.5742 54.2315 18.3164V21.8672C53.7523 22.3125 53.1471 22.6875 52.4157 22.9922C51.7095 23.2969 50.8142 23.4492 49.7297 23.4492C48.0148 23.4492 46.6151 22.9453 45.5306 21.9375C44.4714 20.9062 43.9418 19.5 43.9418 17.7188V8.89453H34.6356V16.5586C34.6356 17.3555 34.8247 17.9883 35.203 18.457C35.6066 18.9023 36.1992 19.125 36.9811 19.125C37.662 19.125 38.2421 19.0547 38.7212 18.9141C39.2256 18.7734 39.6922 18.5742 40.1209 18.3164V21.8672C39.6418 22.3125 39.0365 22.6875 38.3051 22.9922C37.5989 23.2969 36.7036 23.4492 35.6192 23.4492C33.9042 23.4492 32.5045 22.9453 31.4201 21.9375C30.3608 20.9062 29.8312 19.5 29.8312 17.7188V8.89453H26.3887V6.60938C27.5992 6.21094 28.7089 5.46094 29.7177 4.35938C30.7517 3.25781 31.5714 1.80469 32.1767 0H34.6356Z"
                  fill="#165E5B"
                />
              </svg>
            </a>
          </div>
          <div className="loginForm-title text-center">{heading}</div>
          <div className="loginForm-inner">
            <div className="form-group">
              <form>
                {/* <div className="form-heading text-center">{heading}</div> */}
                {tokenStatus == "valid" && (
                  <>
                    <div className="form-group">
                      <label cla>Enter password*</label>
                      <input
                        type="text"
                        placeholder=""
                        value={password.pass1}
                        onChange={(e) =>
                          setPassword({ ...password, pass1: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm password*</label>
                      <input
                        type="text"
                        placeholder=""
                        value={password.pass2}
                        onChange={(e) =>
                          setPassword({ ...password, pass2: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                    {updated === true && (
                      <div
                        className="text-center last-pra"
                        style={{ marginBottom: "10px", color: "green" }}
                      >
                        Password updated Successfully ✔
                      </div>
                    )}
                    <div className="form-group">
                      {updated === false ? (
                        <button
                          className="btn utter-btn secondary w-100"
                          onClick={handleSubmit}
                        >
                          Reset Password
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                {tokenStatus == "expired" && (
                  <>
                    <div
                      className="text-center last-pra"
                      style={{ marginBottom: "30px", color: "orange" }}
                    >
                      Your Link is expired, Click on Reset Password to generate
                      a new link.
                    </div>
                    <button
                      className="btn utter-btn secondary w-100"
                      onClick={handleClick}
                    >
                      Reset Password
                    </button>
                  </>
                )}
                {tokenStatus == "invalid" && (
                  <>
                    <div
                      className="text-center last-pra"
                      style={{ marginBottom: "30px", color: "red" }}
                    >
                      Your Link is invalid, Click on Reset Password to generate
                      a valid password reset link.
                    </div>
                    <button
                      className="btn utter-btn secondary w-100"
                      onClick={handleClick}
                    >
                      Reset Password
                    </button>
                  </>
                )}

                <div className="text-center last-pra">
                  <Link to="/login">Back to login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reset_form;
