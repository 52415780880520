import { useCallback, useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ReactFlow,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  useReactFlow,
  ReactFlowProvider,
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";

import "../flows.css";
import SendMessage from "./send-message/SendMessage";
import AskAQuestion from "./Ask-a-question/AskAQuestion";
import Answer from "./Ask-a-question/Answer";
import Template from "./Template/Template";
import TemplatePreview from "./Template/TemplatePreview";
import Form from "./Form/Form";
import SubmitForm from "./Form/SubmitForm";
import { useDispatch, useSelector } from "react-redux";
// import { setGLobalNodes, setIsData } from "../../../reduxSlice/workflow";
import CustomEdge from "./CustomEdge";
import {
  getWorkflowById,
  publishFlow,
  updateFlow,
} from "../../../services/workflow";
import { toast } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  setGLobalNodes,
  triggerDeleteNodeFromNodes,
  clearTrigger,
  clearGlobalNodes,
  setIsData,
  setIsDeleteNode,
} from "../../../reduxSlice/workflow";

const initialEdges = [];
const initialNodes = [];

const nodeTypes = {
  sendMessage: SendMessage,
  askAQuestion: AskAQuestion,
  answer: Answer,
  template: Template,
  // templatePreview: TemplatePreview,
  form: Form,
  submitForm: SubmitForm,
  // sendMessage: (props) => <SendMessage {...props} data={requiredProps} />,
  // askAQuestion: (props) => <AskAQuestion {...props} data={requiredProps} />,
  // answer: Answer,
  // template: (props) => <Template {...props} data={requiredProps} />,
  // form: (props) => <Form {...props} data={requiredProps} />,
  // submitForm: SubmitForm,
};

const FlowCanvas = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [apiNodes, setApiNodes] = useState([]);
  const [draggedNodeData, setDraggedData] = useState(null);
  const { screenToFlowPosition } = useReactFlow();
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const flowRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [flowName, setFlowName] = useState("");

  const [isOpenPublish, setIsOpenPublish] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const edgeTypes = {
    bidirectional: (props) => <CustomEdge {...props} setEdges={setEdges} />,
  };

  const {
    triggerDeleteNode,
    triggerAddChild,
    triggerupdateNode,
    triggerAddSingleChild,
    isDeleteNode,
  } = useSelector((state) => state.WorkflowReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const minimapDesc = document.getElementById("react-flow__minimap-desc-1");
    if (minimapDesc) {
      minimapDesc.textContent = "Flow Minimap";
    }
  }, []);
  // Handle node updates
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes, dispatch]
  );

  const onEdgesChange = useCallback(
    (changes) =>
      setEdges((eds) =>
        applyEdgeChanges(changes, eds).map((edge) => ({
          ...edge,
          type: edge.type || "bidirectional", // Ensure the type persists
        }))
      ),
    [setEdges]
  );
  const getFlowData = async () => {
    const resp = await getWorkflowById(id);
    if (resp?.success) {
      const newNodes = resp?.data[0]?.payLoad?.Nodes;
      const newEdges = resp?.data[0]?.payLoad?.Edges;
      console.log("newNodes:", newNodes, "::", "newEdges", newEdges);
      setFlowName(resp?.data[0]?.name);
      // dispatch(setIsData(true));
      const updatedNodes = newNodes?.map((node) => ({
        ...structuredClone(node), // Ensures deep copy
      }));

      setNodes(updatedNodes);

      setEdges([...newEdges]);
      // setApiNodes(newNodes);
      dispatch(setIsData(true));
      dispatch(setGLobalNodes([...newNodes])); // Ensure a new array reference
    }
  };
  useEffect(() => {
    getFlowData();
  }, [id]);

  const onConnect = useCallback((connection) => {
    const edge = { ...connection, type: "bidirectional", animated: true };
    setEdges((eds) => addEdge(edge, eds));
  }, []);

  const handleMove = (event, viewport) => {
    setPanPosition({ x: viewport.x, y: viewport.y });
  };
  const updateNodeData = (nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, ...newData } }
          : node
      )
    );
  };
  const addChildToNodes = (children) => {
    console.log("nodes:data", children);
    setNodes((prevNodes) => [
      ...prevNodes,
      ...children.map((child) => ({ ...child })),
    ]);
  };
  const addSingleChildToNode = (child) => {
    console.log("child", child);
    setNodes((prevNodes) => [...prevNodes, child]);
  };

  const deleteNode = (parentId) => {
    setNodes((prevNodes) =>
      prevNodes.filter((node) => node.parentId !== parentId)
    );
  };

  // const handleDeleteNode = (nodeId) => {
  //   setNodes((prevNodes) => {
  //     const getAllChildNodes = (id) => {
  //       const children = prevNodes.filter((node) => node.parentId === id);
  //       return children.reduce((acc, child) => {
  //         return acc.concat(child.id, getAllChildNodes(child.id));
  //       }, []);
  //     };

  //     const allNodesToDelete = [nodeId, ...getAllChildNodes(nodeId)];
  //     return prevNodes.filter((node) => !allNodesToDelete.includes(node.id));
  //   });
  // };

  const handleDeleteNode = (nodeId) => {
    setNodes((prevNodes) => {
      const getAllChildNodes = (id, nodes) => {
        const children = nodes.filter((node) => node.parentId === id);
        return children.reduce((acc, child) => {
          return acc.concat(child.id, getAllChildNodes(child.id, nodes));
        }, []);
      };

      // Compute all nodes to delete before updating state
      const allNodesToDelete = [nodeId, ...getAllChildNodes(nodeId, prevNodes)];

      // Remove nodes
      const updatedNodes = prevNodes.filter(
        (node) => !allNodesToDelete.includes(node.id)
      );

      // Update edges inside setNodes to ensure correct state update
      setEdges((prevEdges) =>
        prevEdges.filter(
          (edge) =>
            !allNodesToDelete.includes(edge.source) &&
            !allNodesToDelete.includes(edge.target)
        )
      );

      return updatedNodes;
    });
  };

  // Listen for Redux trigger and update local state
  useEffect(() => {
    if (triggerDeleteNode) {
      deleteNode(triggerDeleteNode);
      // Reset trigger after execution
    }
    if (triggerAddChild) {
      addChildToNodes(triggerAddChild);
    }
    if (triggerupdateNode) {
      const { id, ...rest } = triggerupdateNode;
      updateNodeData(id, { ...rest });
    }
    if (triggerAddSingleChild) {
      addSingleChildToNode(triggerAddSingleChild);
    }
    // if (isDeleteNode?.isDelete) {
    //   handleDeleteNode(isDeleteNode?.nodeId);
    // }

    dispatch(clearTrigger());
  }, [
    triggerDeleteNode,
    triggerAddChild,
    triggerupdateNode,
    triggerAddSingleChild,
    // isDeleteNode,
    dispatch,
  ]);

  // Handle drop event
  const onDrop = (event) => {
    event.preventDefault();
    dispatch(clearGlobalNodes());
    // dispatch(setIsData(false));

    // Get the mouse position on the screen
    const mousePosition = {
      x: event.clientX,
      y: event.clientY,
    };
    const flowPosition = screenToFlowPosition(mousePosition);
    const nodeType = event.dataTransfer.getData("nodeType");
    console.log("event");
    const nodeData = event.dataTransfer.getData("nodeData");
    const parsedData = JSON.parse(nodeData);
    setDraggedData(parsedData);

    console.log("parsedData", parsedData);
    if (nodeType === "group" && parsedData.id == "sendMessage") {
      // Create a group node
      const newGroupNode = {
        id: `${+new Date()}`,
        type: "sendMessage",
        data: {
          handleAddChild,
          draggedNodeData,

          text: "",
          updateNodeData,
          url: "",
          type: "",
        },
        position: flowPosition,
        // style: {
        //   minWidth: "360px",
        //   minHeight: "150px",
        //   background: "white",
        //   border: "none",
        //   outline: "0",
        // },
      };

      setNodes((prevNodes) => [...(prevNodes || []), newGroupNode]);
    } else if (nodeType === "group" && parsedData.id == "askAQuestion") {
      const newGroupNode = {
        id: `${+new Date()}`,
        type: "askAQuestion",

        data: {
          updateNodeData,
          question: "",
          answers: [],
          addChildToNodes,
          deleteNode,
          isNew: true,
        },
        position: flowPosition,

        // position,
        // style: {
        //   // minWidth: "360px",
        //   // minHeight: "150px",
        //   // background: "white",
        //   border: "none",
        //   outline: "0",
        // },
      };

      setNodes((prevNodes) => [...(prevNodes || []), newGroupNode]);
    } else if (nodeType === "group" && parsedData.id == "template") {
      const newGroupNode = {
        id: `${+new Date()}`,
        type: "template",
        data: { addSingleChildToNode, templatetoShow: {}, updateNodeData },
        position: flowPosition,
      };

      setNodes((prevNodes) => [...(prevNodes || []), newGroupNode]);
    } else if (nodeType === "group" && parsedData.id == "form") {
      const newGroupNode = {
        id: `${+new Date()}`,
        type: "form",
        data: {
          addSingleChildToNode,
          updateNodeData,
          deleteNode,
        },
        position: flowPosition,
        style: { zIndex: "100" },
      };

      setNodes((prevNodes) => [...(prevNodes || []), newGroupNode]);
    } else {
      const newNode = {
        id: `${+new Date()}`,
        type: nodeType,
        position: flowPosition,
        data: { label: nodeType },
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
    }
  };

  const handleAddChild = (parentId) => {
    setNodes((prevNodes) => {
      const parentNode = prevNodes.find((node) => node.id === parentId);
      const existingChildren = prevNodes.filter(
        (node) => node.parentId === parentId
      );

      const childHeight = 60; // Fixed height for each child node
      const childSpacing = 10; // Spacing between child nodes

      const initialYOffset = 250; // Offset for where "Add Child" button ends

      const newChildPosition = {
        x: 10, // Offset slightly to the right (relative to parent)
        y:
          initialYOffset +
          existingChildren.length * (childHeight + childSpacing), // Start below button
      };
      const childPosition = { x: 0, y: 100 };

      const newChildNode = {
        id: `${+new Date()}`,
        type: "child",
        position: childPosition,
        data: { label: `Child Node ${existingChildren.length + 1}` },
        parentId: parentId,
        extent: "parent", // Keep children inside parent
        style: {
          minWidth: "100px",
          minHeight: "50px",
          padding: "0",
        },
      };

      return [...prevNodes, newChildNode];
    });
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  console.log("nodes: ", nodes, ":: ", "edges: ", edges);

  function validateNodes(nodes) {
    let hasDuplicateTitle = false;
    let hasMissingTitle = false;
    const seenTitles = new Set();
    let startNodeCount = 0;
    let endNodeCount = 0;
    let isEmptyNode = false;
    let isChildNodeNotExists = false;

    for (const node of nodes || []) {
      const screenTitle = node?.data?.screenTitle?.trim();
      const startNode = node?.data?.isStartNode;
      const endNode = node?.data?.isEndNode;
      const nodeType = node?.type;

      const isNotChildNode = nodes?.every((subNode) => {
        if (nodeType == "askAQuestion" || nodeType == "form") {
          if (node?.id != subNode?.parentId) {
            return true;
          }
          return false;
        }
      });
      if (isNotChildNode) {
        isChildNodeNotExists = true;
        break;
      }

      if (nodeType == "sendMessage" && !node?.data?.text) {
        isEmptyNode = true;
        break;
      }
      if (
        nodeType == "askAQuestion" &&
        (!node?.data?.question ||
          node?.data?.answers?.length <= 0 ||
          !node?.data?.answers)
      ) {
        isEmptyNode = true;
        break;
      }

      if (
        nodeType == "template" &&
        (node?.data?.templatetoShow == null ||
          node?.data?.templatetoShow == {} ||
          !node?.data.hasOwnProperty("templatetoShow"))
      ) {
        // isEmptyNode = true;
        isChildNodeNotExists = true;
        break;
      }

      if (startNode) {
        startNodeCount += 1;
      }
      if (endNode) {
        endNodeCount += 1;
      }

      if (node?.parentId) continue; // Skip nodes with parentId

      if (!screenTitle) {
        hasMissingTitle = true;
        hasDuplicateTitle = false;
        break; // No need to check further if any title is missing
      }

      if (screenTitle && seenTitles.has(screenTitle)) {
        hasDuplicateTitle = true;
        hasMissingTitle = false;
        break; // Stop early when a duplicate is found
      }

      seenTitles.add(screenTitle);
    }

    return {
      hasDuplicateTitle,
      hasMissingTitle,
      startNodeCount,
      endNodeCount,
      isEmptyNode,
      isChildNodeNotExists,
    };
  }

  function validateNodeLinking(nodes, edges) {
    let isNotLinked = false;
    // if (
    //   edges?.length <= 0 &&
    //   (node?.type == "form" ||
    //     node?.type == "askAQuestion" ||
    //     node?.type == "submitForm")
    // ) {
    //   isNotLinked = true;
    //   return { isNotLinked };

    // }
    for (const node of nodes || []) {
      if (node?.type == "askAQuestion" || node?.type == "answer") {
        if (edges?.length <= 0) {
          isNotLinked = true;
          return { isNotLinked };
        }
      }
      for (const edge of edges || []) {
        if (
          node?.type == "form" ||
          node?.type == "askAQuestion" ||
          node?.type == "submitForm" ||
          node?.type == "sendMessage" ||
          node?.type == "template"
        ) {
          // isNotLinked = false;
          continue;
        }
        // else if (node?.type == "template" && node?.id != edge?.target) {
        //   isNotLinked = true;
        //   continue;
        // }
        else if (node?.id != edge?.source) {
          isNotLinked = true;
          continue; // Breaks both loops
        } else {
          isNotLinked = false;
          break;
        }
      }
    }

    return { isNotLinked };
  }

  // function validateNodeLinking(nodes, edges) {
  //   let isNotLinked = false;

  //   // If no edges exist and we have at least one node that must be linked, return early
  //   if (edges?.length === 0) {
  //     const requiresLinking = nodes?.some((node) =>
  //       [
  //         "form",
  //         "askAQuestion",
  //         "submitForm",
  //         "sendMessage",
  //         "template",
  //       ].includes(node?.type)
  //     );
  //     if (requiresLinking) {
  //       return { isNotLinked: true };
  //     }
  //   }

  //   // Iterate through nodes and check if they are linked to an edge
  //   outerLoop: for (const node of nodes || []) {
  //     // Only check for nodes that require linking
  //     if (
  //       [
  //         "form",
  //         "askAQuestion",
  //         "submitForm",
  //         "sendMessage",
  //         "template",
  //       ].includes(node?.type)
  //     ) {
  //       let isLinked = false;

  //       for (const edge of edges || []) {
  //         if (node?.id === edge?.source) {
  //           isLinked = true; // Node is linked
  //           break; // No need to check further for this node
  //         }
  //       }

  //       if (!isLinked) {
  //         isNotLinked = true;
  //         break outerLoop; // Exit both loops if any required node is not linked
  //       }
  //     }
  //   }

  //   return { isNotLinked };
  // }

  // function validateNodeLinking(nodes, edges) {
  //   if (!nodes || !edges) return { isNotLinked: true };

  //   // Filter out nodes that are either 'askAQuestion' or 'form'
  //   const validNodes = nodes.filter(
  //     (node) => node.type !== "form" && node.type !== "askAQuestion"
  //   );

  //   // Edge case: No valid nodes to check
  //   if (validNodes.length === 0) return { isNotLinked: false };

  //   // Create an adjacency list
  //   const adjacencyList = new Map();
  //   for (const node of validNodes) {
  //     adjacencyList.set(node.id, []);
  //   }

  //   for (const edge of edges) {
  //     if (adjacencyList.has(edge.source) || adjacencyList.has(edge.target)) {
  //       adjacencyList.get(edge.source).push(edge.target);
  //       adjacencyList.get(edge.target).push(edge.source);
  //     }
  //   }

  //   // DFS to check connectivity
  //   const visited = new Set();
  //   function dfs(nodeId) {
  //     visited.add(nodeId);
  //     for (const neighbor of adjacencyList.get(nodeId) || []) {
  //       if (!visited.has(neighbor)) {
  //         dfs(neighbor);
  //       }
  //     }
  //   }

  //   // Start DFS from the first valid node
  //   dfs(validNodes[0].id);

  //   // Check if all valid nodes were visited
  //   const isNotLinked = visited.size !== validNodes.length;

  //   return { isNotLinked };
  // }.

  const validateEdgeConnections = (nodes, edges) => {
    for (let edge of edges) {
      const sourceNode = nodes.find((node) => node.id === edge.source);
      const targetNode = nodes.find((node) => node.id === edge.target);

      // Check if the source is an answer and the target is a template
      if (sourceNode?.type === "answer" && targetNode?.type === "template") {
        return {
          valid: false,
          message: `Ask a question node cannot connect to template node`,
        };
      }
    }

    return { valid: true };
  };

  const handleSaveFlow = async () => {
    try {
      const {
        hasDuplicateTitle,
        hasMissingTitle,
        startNodeCount,
        endNodeCount,
        isEmptyNode,
        isChildNodeNotExists,
      } = validateNodes(nodes);
      const { isNotLinked } = validateNodeLinking(nodes, edges);

      console.log("isNotLinked", isNotLinked);

      const parentIds = [];

      nodes?.forEach(
        (node) => node?.parentId && parentIds.push(node?.parentId)
      );

      const edgesTargets = [];

      edges?.forEach((edge) => edgesTargets.push(edge.target));

      const missingParents = parentIds.filter(
        (parentId) => !edgesTargets.includes(parentId)
      );

      // Get unique missing parent IDs
      const uniqueMissingParents = [...new Set(missingParents)];

      console.log(
        "ParentIDs:: ",
        parentIds,
        "::",
        "EdgesTarget",
        edgesTargets,
        "::",
        "missingParents",
        missingParents
      );
      // Show only **one** toast based on the first failing condition
      if (nodes?.length < 1 || !nodes) {
        toast.error("Please add atleast one node");
        return;
      }

      if (isChildNodeNotExists) {
        toast.error("Please save the node first!");
        return;
      }

      if (isEmptyNode) {
        toast.error("Nodes should not be empty");
        return;
      }

      if (hasMissingTitle) {
        toast.error("All Screen titles are required!");
        return;
      }
      if (hasDuplicateTitle) {
        toast.error("All Screen titles should be unique!");
        return;
      }
      // if (startNodeCount < 1) {
      //   toast.error(" One screen should be set as start node.");
      //   return;
      // }
      // if (startNodeCount > 1) {
      //   toast.error("There would be only one start node.");
      //   return;
      // }

      // if (endNodeCount < 1) {
      //   toast.error("Atleast one screen should be set as end node.");
      //   return;
      // }
      if (isNotLinked) {
        toast.error("All nodes should be connected.");
        return;
      }

      if (uniqueMissingParents.length > 1) {
        toast.error(
          "There would be only start node.Please connect all other node with each other"
        );
        return;
      }
      const edgeValidation = validateEdgeConnections(nodes, edges);
      if (!edgeValidation.valid) {
        toast.error(edgeValidation.message);
        return;
      }
      setLoader(true);

      let startNodeName = "";

      for (let node of nodes || []) {
        // Check if node.id is NOT in any edge.target
        const isNotTarget = !edges.some((edge) => edge?.target === node.id);

        if (isNotTarget) {
          startNodeName = node?.data?.screenTitle;
          break; // Stop at the first unmatched node
        }
      }
      console.log("startNodeName", startNodeName);
      let newNodes = [...nodes];
      let newEdges = edges.filter((edge) =>
        newNodes.some((node) => edge.source === node.id)
      );

      const payload = {
        Nodes: newNodes,
        Edges: newEdges,
        startNodeName,
      };

      // const payload = {
      //   Nodes: [...newNodes],
      //   Edges: [...newEdges],
      //   startNodeName,
      // };

      const data = {
        payload,
        flowId: id,
      };

      const resp = await updateFlow(data);
      // success case
      if (resp?.data?.error?.length <= 0) {
        setLoader(false);
        toast.success("Flow Saved Successfully");
        dispatch(clearGlobalNodes());
        setNodes([]);
        setEdges([]);
        // await getFlowData();
        navigate("/flowListing");
      } else {
        setLoader(false);

        toast.error(resp?.data?.error[0]?.message);
        // await getFlowData();
      }
    } catch (error) {
      setLoader(false);

      console.log("error: ", error);
    }
  };

  const handlePublishFlow = async () => {
    try {
      setPublishing(true);
      const resp = await publishFlow(id);
      if (resp?.success) {
        toast.success("Flow Published successfully!");
        dispatch(clearGlobalNodes());
        setNodes([]);
        setEdges([]);
        // await getFlowData();
        navigate("/flowListing");
      } else {
        // toast.error("Something went wrong");
        toast.error(resp?.error);
      }
      setPublishing(false);
    } catch (error) {
      toast.error(error?.error);
      setPublishing(false);
    }
  };

  return (
    <div className="flow-canvas" onDrop={onDrop} onDragOver={onDragOver}>
      <div className="top-bar ps-4">
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => {
            dispatch(clearGlobalNodes());
            setNodes([]);
            setEdges([]);
            navigate("/flowListing");
          }}
        >
          <div>
            <svg
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 10L1 5.5L6 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <p className="workflow-name">{flowName}</p>
        </div>
        <div className="w-1/2 flex justify-end">
          <button
            className="workflow-save btn-main-default btn"
            onClick={handleSaveFlow}
          >
            {" "}
            {loader ? (
              <div style={{ textAlign: "center" }}>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  // style={{ color: "white" }}
                  className="workflow-spinner"
                />
              </div>
            ) : (
              " Save Changes"
            )}
          </button>
          <button
            className="publish btn-main btn"
            onClick={() => {
              setIsOpenPublish(true);
            }}
          >
            {" "}
            {false ? (
              <div style={{ textAlign: "center" }}>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  // style={{ color: "white" }}
                  className="workflow-spinner"
                />
              </div>
            ) : (
              "Publish Flow"
            )}
          </button>
        </div>
      </div>

      <Modal
        show={isDeleteNode.isDelete}
        onHide={() =>
          dispatch(setIsDeleteNode({ isDelete: false, nodeId: "" }))
        }
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete this node. This process cannot be reversed
            and connections with this node will be lost.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {/* {!loading ? ( */}
          <Button
            variant="danger-light"
            onClick={() => {
              handleDeleteNode(isDeleteNode.nodeId);
              dispatch(
                setIsDeleteNode({
                  isDelete: false,
                  nodeId: "",
                })
              );
            }}
          >
            Yes, Delete it
          </Button>
          {/* ) : (
            <Button variant="danger-light" style={{ width: "160px" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red" }}
              />
            </Button> */}
          {/* )} */}

          <Button
            variant="danger"
            onClick={() =>
              dispatch(setIsDeleteNode({ isDelete: false, nodeId: "" }))
            }
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpenPublish}
        onHide={() => setIsOpenPublish(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm Publishing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            Are you sure you want to publish this flow? Published flows can't be
            edited.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            onClick={() => setIsOpenPublish(false)}
            className="btn-main-default btn"
          >
            Cancel
          </Button>
          {!publishing ? (
            <Button className="btn-main btn" onClick={handlePublishFlow}>
              Publish
            </Button>
          ) : (
            <Button className="btn-main btn" style={{ width: "118.22px" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "#165E5B" }}
              />
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ReactFlow
        ref={flowRef}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView // Enable auto-fit on mount
        // fitViewOptions={{ padding: 0.1 }} // Add some padding around nodes
        // minZoom={0.1} // Allow deeper zoom-out
        // attributionPosition="top-right"
        edgeTypes={{
          ...edgeTypes,
          animated: true,
          type: "smoothstep",
          style: {
            stroke: "#333",
            strokeWidth: 2,
            fill: "none",
            dasharray: "5, 5",
          },
        }}
        // zoomOnScroll={false}
        onMove={handleMove} // onMove to capture pan position
      >
        <MiniMap />
        <Controls />
        <Background variant="lines" gap={65} color="#E3E8EF" />
      </ReactFlow>
    </div>
  );
};

export default FlowCanvas;
