import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}broadcast/`;

export const sendBroadcast = async (data) => {
  const response = await axios.post(`${baseUrl}send`, data);
  return response.data;
};

export const updateBroadcast = async (data) => {
  const response = await axios.post(`${baseUrl}edit`, data);
  return response.data;
};

export const getAllBroadcasts = async (
  page = 1,
  limit = 20,
  state = "",
  startDate,
  endDate,
  type = null,
  searchKey = ""
) => {
  const response = await axios.get(
    `${baseUrl}all?page=${page}&limit=${limit}&state=${state}&type=${type}&start_date=${startDate}&end_date=${endDate}&searchKey=${searchKey}`
  );
  return response.data;
};

export const getSingleBroadcasts = async (
  id,
  group = "",
  searchKey,
  page,
  limit
) => {
  const response = await axios.get(
    `${baseUrl}allDetail?broadcastId=${id}&group=${group}&searchKey=${searchKey}&page=${page}&limit=${limit}`
  );
  return response.data;
};

export const getFilteredBroadcast = async (
  id,
  filter,
  searchKey,
  groups = ""
) => {
  const response = await axios.get(
    `${baseUrl}/single?broadcastId=${id}&filter=${filter}&searchKey=${searchKey}&group=${groups}`
  );
  return response.data;
};

export const deleteBroadcast = async (data) => {
  const response = await axios.post(`${baseUrl}delete`, data);
  return response.data;
};
