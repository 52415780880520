import axios from "axios";
import config from "../../config";

const baseUrlTemplate = `${config.backend}template/`;
const baseUrlMedia = `${config.backend}media/`;

export const getAllTemplates = async (page) => {
  const response = await axios.get(
    `${baseUrlTemplate}all?page=${1}&limit=${1000}`
  );
  return response.data;
};

export const getLocTemplateCoordinates = async (companyId, templateId) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(
    `${baseUrlTemplate}getSingle/${companyId}/${templateId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};

export const deleteTemplate = async (data) => {
  console.log(data);
  const response = await axios.post(`${baseUrlTemplate}delete`, data);
  return response.data;
};

export const createTemplate = async (data) => {
  console.log(data);
  const response = await axios.post(`${baseUrlTemplate}create`, data);
  return response.data;
};

export const updateTemplate = async (data) => {
  console.log(data);
  const response = await axios.post(`${baseUrlTemplate}edit`, data);
  return response.data;
};

export const sendTemplate = async (data) => {
  const response = await axios.post(`${baseUrlTemplate}send`, data);
  return response.data;
};

export const getMediaFile = async (id) => {
  const response = await axios.get(`${baseUrlMedia}${id}`);
  return response.data;
};
