import React, { useState, useEffect } from "react";
import { Box, Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { Button, Modal, Spinner } from "react-bootstrap";
import BlockedUsersMobileListing from "../components/chat/BlockedUserMobileListing";
import BlockedUsersHeader from "../components/chat/BlockedUsersHeader";
import { blockChat, getAllBlockedUsers } from "../services/Inbox";
import { toast } from "react-toastify";
import TableSkeleton from "../components/skeletons/TableLoader";
import EmptyBlockList from "../components/chat/EmptyBlockList";
import { setCreateConversation } from "../reduxSlice/chats";

const BlockedUsers = () => {
  const { allConversations: storedConversations } = useSelector(
    (state) => state.ChatsReducer
  );
  const dispatch = useDispatch();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [allBlockedContacts, setAllBlockContacts] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [unblocking, setUnblocking] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState({
    totalPages: allBlockedContacts?.totalRecords
      ? allBlockedContacts?.totalRecords
      : 1,
    currPage: 1,
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const getBlockedUsers = async (page) => {
    try {
      setLoading(true);
      const response = await getAllBlockedUsers(page, 20);
      if (response?.success) {
        setLoading(false);
        const transformedData = handleDataForTable(response?.data);
        setAllBlockContacts({
          records: transformedData,
          totalRecords: response?.totalRecords,
        });
        setPaginationData({
          currPage: page,
          totalPages: response?.totalPages,
        });
        setCurrentPage(page);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getBlockedUsers(currentPage);
  }, []);

  const getRandomColor = (index) => {
    // Calculate hue value based on the index to get distinct colors
    const hue = (index * 50) % 360; // You can adjust the multiplier (50) to change the color range

    // Set constant saturation and lightness values
    const saturation = 50;
    const lightness = 50;

    // Convert HSL to RGB values
    const hslToRgb = (h, s, l) => {
      h /= 360;
      s /= 100;
      l /= 100;
      let r, g, b;

      if (s === 0) {
        r = g = b = l; // Achromatic
      } else {
        const hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }

      const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    return hslToRgb(hue, saturation, lightness);
  };

  const handleDataForTable = (data) => {
    console.log("data", data);
    // return []
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      const color = getRandomColor(i);
      finalData.push({
        key: data[i]._id,
        name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className="random-clr"
              style={{ background: color, marginRight: "10px" }}
            >
              {data[i]?.contactDetails?.contactName?.substring(0, 1)}
            </div>
            <span style={{ fontWeight: "600" }}>
              {data[i]?.contactDetails?.contactName}
            </span>
          </div>
        ),
        phone: "+" + data[i]?.contactDetails?.number,
        action: (
          <button
            className="unblock-btn "
            onClick={() => handleOpenDeletePopup(data[i])}
          >
            Unblock
          </button>
        ),
      });
    }

    return finalData;
  };
  const handleOpenDeletePopup = (data) => {
    setOpenDeletePopup(true);
    setActiveUser(data);
  };
  const updateBlockStatusOfChat = (chatId) => {
    let allConvs = [...storedConversations];
    const isFound = allConvs.findIndex((item) => item?._id == chatId);
    if (isFound > -1) {
      const blockStatus = allConvs[isFound].block == true ? false : true;
      allConvs[isFound] = { ...allConvs[isFound], block: blockStatus };
      console.log("allConvs[isFound]", allConvs[isFound]);
      dispatch(setCreateConversation(allConvs));
    }
  };

  const handleUnblock = async (id) => {
    try {
      setUnblocking(true);
      const block = "0";
      const response = await blockChat({ chatId: activeUser?._id, block });
      if (response?.success) {
        setUnblocking(false);
        updateBlockStatusOfChat(activeUser?._id);
        toast.success(response?.data);
        setOpenDeletePopup(false);
        getBlockedUsers(currentPage);
      }
    } catch (error) {
      toast.error(error.error);
      setUnblocking(false);
    }
  };

  const columns = [
    {
      title: <span className="ms-5">Name</span>,
      dataIndex: "name",
      width: "42%",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      width: "42%",
      // align: "center",
    },

    {
      title: <span style={{ marginLeft: "15px" }}>Action</span>,
      dataIndex: "action",
      width: "16%",

      // align: "center",
    },
  ];

  const handlePaginationChange = (event, value) => {
    console.log("valiue", value);
    getBlockedUsers(value);
    setCurrentPage(value); // Fetch new data for the selected page
    setPaginationData({
      currPage: value,
      totalPages: paginationData.totalPages,
    });
  };

  return (
    <section className="main inner-main">
      <BlockedUsersHeader screenWidth={screenWidth} />

      {allBlockedContacts?.records?.length <= 0 && !loading ? (
        <EmptyBlockList />
      ) : loading ? (
        <TableSkeleton />
      ) : (
        <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100">
          {screenWidth >= 1023 ? (
            <Box
              className="flex justify-start align-items-start w-100 mt-[0px] rounded-md container all-tags mb-28 testing"
              sx={{
                padding: 0,
                height: "80%",
                marginBottom: "100px",
              }}
            >
              <Table
                columns={columns}
                dataSource={allBlockedContacts?.records} // Use full contacts data, not sliced
                pagination={
                  false // Disable changing pageSize (optional)
                }
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                }}
                //   size="middle"
              />
            </Box>
          ) : (
            <section className=" pt-0 d-lg-none w-100">
              <BlockedUsersMobileListing
                handleOpenDeletePopup={handleOpenDeletePopup}
                data={allBlockedContacts?.records}
              />
            </section>
          )}
        </Box>
      )}
      <Box
        className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
        sx={{
          height: "70px",
          position: "fixed",
          bottom: "0",
          boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",

          marginTop: "100px",
        }}
      >
        {allBlockedContacts?.records?.length > 0 && (
          <Pagination
            count={paginationData.totalPages} // Total number of pages
            variant="outlined"
            shape="rounded"
            siblingCount={2}
            page={paginationData.currPage} // Current page
            onChange={handlePaginationChange} // Page change handler
          />
        )}
      </Box>
      <Modal
        show={openDeletePopup}
        onHide={() => setOpenDeletePopup(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Unblock?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to Unblock
            <span className="u-name" style={{ fontWeight: "600" }}>
              {` “${
                activeUser?.contactDetails != undefined
                  ? activeUser?.contactDetails?.contactName
                  : activeUser?.name?.props?.children[1]?.props?.children
              }”`}
            </span>{" "}
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {unblocking ? (
            <div
              style={{
                display: "flex",
                minWidth: "114.94px",
                maxWidth: "114.94px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button variant="danger-light" onClick={() => handleUnblock()}>
              Yes, Unblock
            </Button>
          )}

          <Button variant="danger" onClick={() => setOpenDeletePopup(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default BlockedUsers;
