import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBroadCastsData: [],
  totalRecords: 0,
  pickerLoader: false,
  defaultData: [],
  broadcastDetail: null,
  detailLoader: false,
  filteredBroadcast: null,
  isListingOpen: true,
  editLoading: false,
  groups: [],
  broadcastName: "",
  filterLoader: false,
  currentPage: 1,
};

export const GroupsSlices = createSlice({
  name: "Broadcasts",
  initialState,
  reducers: {
    // set all broadcasts
    setAllBroadCastsData: (state, action) => {
      console.log("action.payload type", action.payload);
      state.allBroadCastsData = Object.values(action.payload.data);
      state.totalRecords = action.payload.totalRecords;
      state.defaultData = action.payload.defaultData;
    },
    setBroadcastDetail: (state, action) => {
      state.broadcastDetail = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilteredBroadcast: (state, action) => {
      state.filteredBroadcast = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
    setFilterLoader: (state, action) => {
      state.filterLoader = action.payload;
    },
    setBroadcastName: (state, action) => {
      state.broadcastName = action.payload;
    },
    setIsListingOpen: (state, action) => {
      state.isListingOpen = action.payload;
    },
    setPickerLoader: (state, action) => {
      state.pickerLoader = action.payload;
    },
    setDetailLoader: (state, action) => {
      state.detailLoader = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    // empty all broadcasts
    removeAllBroadCastsData: (state, action) => {
      state.allBroadCastsData = [];
      state.totalRecords = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllBroadCastsData,
  setBroadcastDetail,
  removeAllBroadCastsData,
  setPickerLoader,
  setDetailLoader,
  setFilteredBroadcast,
  setIsListingOpen,
  setEditLoading,
  setGroups,
  setBroadcastName,
  setFilterLoader,
  setCurrentPage,
} = GroupsSlices.actions;

export default GroupsSlices.reducer;
