import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "../broadcast/emptyBroadcast.css";

import WidgetListingIcon from "../../images/widget-listing-icon.svg";

import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const EmptyBlockList = () => {
  return (
    <>
      <section className=" templates-body-empty" style={{ width: "100%" }}>
        <Container className="mt-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="tb-empty-content text-center groups">
                <div className="w-100 flex justify-center">
                  <svg
                    width="55"
                    height="50"
                    viewBox="0 0 55 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.9334 10.487C29.9334 12.4669 29.3326 14.4023 28.2071 16.0486C27.0816 17.6948 25.4818 18.9778 23.6101 19.7355C21.7383 20.4932 19.6788 20.6914 17.6918 20.3052C15.7048 19.9189 13.8796 18.9655 12.447 17.5655C11.0145 16.1655 10.0389 14.3818 9.64367 12.44C9.24843 10.4981 9.45128 8.48537 10.2266 6.6562C11.0019 4.82702 12.3148 3.2636 13.9993 2.16363C15.6838 1.06367 17.6642 0.476563 19.6901 0.476562C21.0353 0.476563 22.3673 0.735491 23.6101 1.23856C24.8528 1.74164 25.982 2.479 26.9332 3.40856C27.8844 4.33812 28.6389 5.44167 29.1537 6.6562C29.6685 7.87072 29.9334 9.17244 29.9334 10.487ZM54.7571 35.902C54.7571 38.6433 53.9253 41.3231 52.3669 43.6024C50.8085 45.8817 48.5935 47.6582 46.002 48.7072C43.4104 49.7563 40.5588 50.0308 37.8077 49.496C35.0565 48.9612 32.5294 47.6411 30.5459 45.7027C28.5625 43.7643 27.2117 41.2947 26.6645 38.606C26.1172 35.9174 26.3981 33.1306 27.4715 30.598C28.545 28.0653 30.3628 25.9007 32.6951 24.3777C35.0274 22.8547 37.7695 22.0418 40.5745 22.0418C44.336 22.0418 47.9434 23.5021 50.6031 26.1014C53.2629 28.7007 54.7571 32.2261 54.7571 35.902ZM31.7826 42.3311L47.1531 27.3099C45.0379 25.7677 42.4253 25.0214 39.7931 25.2075C37.161 25.3935 34.6855 26.4996 32.8195 28.3232C30.9534 30.1468 29.8216 32.5661 29.6312 35.1384C29.4408 37.7107 30.2045 40.264 31.7826 42.3311ZM51.6079 35.902C51.5962 33.5778 50.8093 31.3207 49.3665 29.473L33.996 44.5106C35.6291 45.7188 37.5753 46.4566 39.6149 46.6408C41.6545 46.8249 43.7061 46.4479 45.5381 45.5525C47.37 44.6571 48.9092 43.2788 49.9818 41.5735C51.0544 39.8682 51.6176 37.9037 51.6079 35.902ZM23.2428 35.902C23.243 33.278 23.8685 30.6901 25.0696 28.3433C26.2708 25.9964 28.0147 23.9552 30.1632 22.3813C30.0231 22.1732 29.8942 21.9432 29.7541 21.7406C26.9879 24.1606 23.4056 25.4986 19.6929 25.4986C15.9803 25.4986 9.89321 22.7251 7.12698 20.3052C3.89934 24.8942 1.52344 32.9997 1.52344 40.5239H23.9096C23.4687 39.0209 23.2424 37.4656 23.2372 35.902H23.2428Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="tb-page-title">
                  You don’t have any blocked contact
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EmptyBlockList;
