import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./store/user-context";
import axios from "axios";
import { handleApiError } from "./services/apiErrorHandler";
import { store } from "./store";
import { Provider } from "react-redux";
import { refreshUserToken } from "./services/User";

// const refreshTokenCall = async () => {
//   try {
//     let response;
//     // if (token) {
//     response = await refreshUserToken(localStorage.getItem("refreshToken"));
//     // }
//     const { accessToken, refreshToken, status } = response;
//     console.log("response.data", response);
//     // Store the new tokens in local storage
//     if (
//       status == 401 ||
//       (response?.error && response?.error == "jwt expired") ||
//       !response
//     ) {
//       isRefreshing = true;
//       localStorage.removeItem("token");
//       window.location.href = "/login";
//       return;
//     }
//     if (accessToken) {
//       localStorage.setItem("token", accessToken);
//       localStorage.setItem("refreshToken", refreshToken);
//     }
//   } catch (error) {
//     console.error("Error refreshing token:", error);
//     // Handle error, such as logging out the user or showing a message
//   }
// };

axios.interceptors.request.use((request) => {
  const token = localStorage.getItem("token");
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    handleApiError(error); // Handle the error globally
    return Promise.reject(error); // Reject the promise to propagate the error
  }
);
console.log("Envirnoment", process.env.NODE_ENV);
if (process.env.NODE_ENV !== "development") console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserContextProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </UserContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
