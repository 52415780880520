import axios from "axios";
import config from "../../config";

export const createFlow = async (data) => {
  const response = await axios.post(`${config.backend}flows/create`, data);
  return response.data;
};
export const updateFlow = async (data) => {
  const response = await axios.post(`${config.backend}flows/update`, data);
  return response.data;
};
export const getWorkflowById = async (flowId) => {
  const response = await axios.get(`${config.backend}flows/getById/${flowId}`);
  return response.data;
};
export const getAllWorkflows = async () => {
  const response = await axios.get(`${config.backend}flows/getAll`);
  return response.data;
};

export const getPreDefinedFlows = async () => {
  const response = await axios.get(`${config.backend}flows/getPreAll`);
  return response.data;
};
export const deleteFlow = async (id) => {
  const response = await axios.delete(`${config.backend}flows/delete/${id}`);
  return response.data;
};

export const sendFlow = async (data) => {
  const response = await axios.post(`${config.backend}flows/send`, data);
  return response.data;
};

export const publishFlow = async (flowId) => {
  const response = await axios.post(
    `${config.backend}flows/publish?flowId=${flowId}`
  );
  return response.data;
};
export const preCloneFlow = async (data) => {
  const response = await axios.post(`${config.backend}flows/preClone`, data);
  return response.data;
};

export const myCloneFlow = async (data) => {
  const response = await axios.post(`${config.backend}flows/clone`, data);
  return response.data;
};
