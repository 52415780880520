import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Username from "../userName/username";

// import UsertableStatus from "./user-status";
// import Useraction from "./useraction";

// import "../manageUser/css/usermob.css";

const GroupMobile = ({ groups }) => {
  console.log("groups", groups);
  return (
    <>
      <Container className="">
        <Row>
          <Col xs={12}>
            <div className="template-listing-collapse">
              <div className="tlc-header">Group Name</div>
              <div className="tlc-body">
                <Accordion defaultActiveKey="0">
                  {groups?.map((group, idx) => {
                    return (
                      <Accordion.Item eventKey={idx}>
                        <Accordion.Header>
                          <ul className="nav align-items-center">
                            <li className="nav-item">
                              <div className="c-name">
                                {group?.groupName || "-"}
                              </div>
                            </li>
                          </ul>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Created By</div>
                            <div className="phone_number">
                              {group?.createdBy || "-"}
                            </div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Created At</div>
                            <div className="cb-date">{group?.createdAt}</div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Members</div>
                            <div className="cb-date">{group?.members}</div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            {/* <Actionbutton /> */}
                            {group?.Actions}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default GroupMobile;
