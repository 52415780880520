import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "react-bootstrap/Button";

import Typography from "@mui/material/Typography";
import "../flows.css";
import useDeviceWidthDetector from "../../customHooks/DeviceWidthDetector";

export default function MediaCard({
  icon,
  heading,
  description,
  handleClick,
  isStart,
  setAddShow,
  flow,
  setIsClone,
  setIsEdit,
}) {
  const windowWidth = useDeviceWidthDetector();

  return (
    <Card
      sx={{
        maxWidth: windowWidth >= 1024 ? 270 : "100%",
        minWidth: windowWidth >= 1024 ? 270 : "100%",
      }}
      className="predefine-card"
    >
      <div className={`media ${!isStart ? "other-card" : "first-card"}`}>
        <img src={icon} />
      </div>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="text-[45px]"
        >
          {heading}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      <div className="d-grid gap-2 d-md-flex w-full">
        <Button
          className="btn-main  w-full"
          onClick={() => {
            if (isStart) {
              setAddShow(true);
              setIsClone(false);
              setIsEdit(false);
            } else {
              handleClick(flow);
            }
          }}
        >
          Use this
        </Button>
      </div>
    </Card>
  );
}
