// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
// const firebaseConfig = {
//   apiKey: "AIzaSyBGpptp2dYTKmaVN1u-Bsn2FcCx3SSICIg",
//   authDomain: "utterapp-7434d.firebaseapp.com",
//   projectId: "utterapp-7434d",
//   storageBucket: "utterapp-7434d.appspot.com",
//   messagingSenderId: "949543907401",
//   appId: "1:949543907401:web:dbc18e0e46ff1660820f98",
//   measurementId: "G-8WQFMKEMKH",
// };

// const vapidKey =
//   "BCu1nebL7zJE4BcYzyYQkBz71OIUOkZULB_OgSjmFly9ygPVto3K_68RTkt5rtWHbzbb86C9luoYionH9mdWxT4";

// const app = initializeApp(firebaseConfig);
// export const messaging = getMessaging(app);

// export const requestPermission = async () => {
//   return Notification.requestPermission()
//     .then((permission) => {
//       if (permission === "granted") {
//         return getToken(messaging, vapidKey);
//       } else {
//         throw new Error("Notification not granted");
//       }
//     })
//     .catch((error) => {
//       console.log("FCM Token:", error);
//       throw new Error(error);
//     });
// };

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyBGpptp2dYTKmaVN1u-Bsn2FcCx3SSICIg",
  authDomain: "utterapp-7434d.firebaseapp.com",
  projectId: "utterapp-7434d",
  storageBucket: "utterapp-7434d.appspot.com",
  messagingSenderId: "949543907401",
  appId: "1:949543907401:web:dbc18e0e46ff1660820f98",
  measurementId: "G-8WQFMKEMKH",
};

const vapidKey =
  "BCu1nebL7zJE4BcYzyYQkBz71OIUOkZULB_OgSjmFly9ygPVto3K_68RTkt5rtWHbzbb86C9luoYionH9mdWxT4";

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermission = async () => {
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, vapidKey);
      } else {
        throw new Error("Notification not granted");
      }
    })
    .catch((error) => {
      console.log("FCM Token:", error);
      throw new Error(error);
    });
};
