import React, { useState } from "react";
import { getBezierPath, useStore, BaseEdge } from "@xyflow/react";

export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY },
  offset
) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX} ${
    centerY + offset
  } ${targetX} ${targetY}`;
};

export default function CustomEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  setEdges,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const isBiDirectionEdge = useStore((s) => {
    const edgeExists = s.edges.some(
      (e) =>
        (e.source === target && e.target === source) ||
        (e.target === source && e.source === target)
    );

    return edgeExists;
  });

  const edgePathParams = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  };

  let path = "";

  if (isBiDirectionEdge) {
    path = getSpecialPath(edgePathParams, sourceX < targetX ? 25 : -25);
  } else {
    [path] = getBezierPath(edgePathParams);
  }

  // Edge Deletion Handler
  const handleDeleteEdge = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  // Calculate position for delete icon
  const midX = (sourceX + targetX) / 2;
  const midY = (sourceY + targetY) / 2;

  return (
    <>
      <BaseEdge
        path={path}
        markerEnd={markerEnd}
        style={{ stroke: "#333", strokeWidth: 2 }}
      />
      {/* <g transform={`translate(${midX - 10}, ${midY - 10})`}>
        <circle cx={10} cy={10} r={10} fill="red" onClick={handleDeleteEdge} />
        <text
          x={10}
          y={14}
          textAnchor="middle"
          fill="white"
          fontSize="12px"
          fontWeight="bold"
          style={{ cursor: "pointer" }}
          onClick={handleDeleteEdge}
        >
          X
        </text>
      </g>

      <foreignObject x={midX - 10} y={midY - 10} width={20} height={20}>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "red",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={handleDeleteEdge}
        >
          ✖️
        </div>
      </foreignObject> */}
      {/* <g
        width={30}
        height={30}
        transform={`translate(${midX - 9}, ${midY - 9})`}
      >
        <div
          style={{
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            userSelect: "none",
            backgroundColor: "white",
            borderRadius: "5px",
          }}
          onClick={handleDeleteEdge}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleDeleteEdge}
          >
            <path
              d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
              fill="#F02D2D"
            />
          </svg>
        </div>
      </g> */}

      <g
        transform={`translate(${midX}, ${midY})`}
        onClick={handleDeleteEdge}
        style={{ cursor: "pointer" }}
      >
        <rect
          x="-15"
          y="-15"
          width="30"
          height="30"
          rx="5"
          fill="white"
          pointerEvents="visible"
        />
        <svg
          x="-9"
          y="-9"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          pointerEvents="none"
        >
          <path
            d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
            fill="#F02D2D"
          />
        </svg>
      </g>
    </>
  );
}
{
  /* <svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  onClick={handleDeleteEdge}
>
  <path
    d="M6.69801 13.1596V6.99182C6.69801 6.65105 6.95545 6.37505 7.2733 6.37505C7.59116 6.37505 7.84859 6.65105 7.84859 6.99182V13.1596C7.84859 13.5004 7.59116 13.7764 7.2733 13.7764C6.95545 13.7764 6.69801 13.5004 6.69801 13.1596ZM10.725 13.7764C10.8775 13.7764 11.0242 13.7116 11.132 13.596C11.2399 13.4803 11.3003 13.323 11.3003 13.1596V6.99183C11.3003 6.65105 11.0429 6.37505 10.725 6.37505C10.4072 6.37505 10.1497 6.65105 10.1497 6.99183V13.1596C10.1497 13.323 10.2101 13.4803 10.318 13.596C10.4259 13.7116 10.5726 13.7764 10.725 13.7764ZM15.9026 4.04116C15.9026 4.20461 15.8422 4.36189 15.7344 4.47753C15.6265 4.59317 15.4798 4.65793 15.3274 4.65793H14.1768V14.8181C14.1904 15.8558 13.4181 16.7092 12.4509 16.727H5.54744C4.58024 16.7093 3.80791 15.8558 3.82157 14.8181V4.65793H2.67099C2.35314 4.65793 2.0957 4.38193 2.0957 4.04116C2.0957 3.70038 2.35314 3.42438 2.67099 3.42438H5.67336L5.92218 2.35891C6.11419 1.53551 6.80453 0.957275 7.597 0.957275H10.4022C11.1947 0.957275 11.8843 1.53474 12.0763 2.35891L12.3251 3.42438H15.3275C15.4799 3.42438 15.6266 3.48914 15.7345 3.60479C15.8424 3.72043 15.9026 3.87772 15.9026 4.04116ZM6.85983 3.42439H11.1385L10.9602 2.65882C10.8969 2.38359 10.666 2.19084 10.4014 2.19084H7.59694C7.33231 2.19084 7.1022 2.38358 7.03819 2.65805L6.85983 3.42439ZM13.0262 4.65794H4.97214V14.8181C4.95848 15.1743 5.2152 15.4765 5.54743 15.4934H12.4509C12.7831 15.4765 13.0399 15.1743 13.0262 14.8181V4.65794Z"
    fill="#F02D2D"
  />
</svg>; */
}
