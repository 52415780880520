import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Select from "react-select";
import NoData from "../images/icons/no-data.svg";

import PageHeader from "../components/page-header/pageHeader";
import Broadcastcard from "../components/broadcast/broadcast-card";
import BroadcastDetailTable from "../components/broadcast/broadcast-detailsTable";

import Recipent_icon from "../images/broadcast/recipent-icon.svg";
import Send_icon from "../images/broadcast/send-icon.svg";
import Delivered_icon from "../images/broadcast/delivered-icon.svg";
import Read_icon from "../images/broadcast/read-icon.svg";
import Failed_icon from "../images/broadcast/failed-icon.svg";

import BroadcastDetailsMob from "../components/broadcast/broadcast-details-mob";
import Pending_icon from "../images/pending.svg";
import { Box, Pagination } from "@mui/material";
import {
  getFilteredBroadcast,
  getSingleBroadcasts,
} from "../services/Broadcast";
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector";
import Broadcast_ic from "../images/broadcast/broadCast-icon.svg";
import {
  setBroadcastDetail,
  setDetailLoader,
  setFilteredBroadcast,
  setGroups,
  setBroadcastName,
  setFilterLoader,
} from "../reduxSlice/broadcast";
import EmptyBroadcast from "./emptybroadcast";

const BroadcastDetails = () => {
  const { id } = useParams();
  const width = useDeviceWidthChecker();

  const dispatch = useDispatch();
  const {
    broadcastDetail,
    detailLoader,
    filteredBroadcast,
    groups,
    broadcastName,
    filterLoader,
  } = useSelector((state) => state.BroadCastsReducer);
  const [broadcastType, setBroadcastType] = useState("");
  const [search, setSearch] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [paginationData, setPaginationData] = useState({
    totalPages: broadcastDetail ? broadcastDetail?.messages?.length : 1,
    currPage: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  console.log("broadcastType", broadcastType);
  const [count, setCount] = useState({
    total: 0,
    completed: 0,
    delivered: 0,
    read: 0,
    failed: 0,
    pending: 0,
  });

  const options = [
    { value: "all", label: "All" },
    { value: "sent", label: "Sent" },
    { value: "delivered", label: "Delivered" },
    { value: "read", label: "Read" },
    { value: "failed", label: "Failed" },
    { value: "pending", label: "Pending" },
  ];

  useEffect(() => {
    const fetchSingleBroadcastData = async (
      id,
      group,
      searchKey,
      page = 1,
      limit = 20
    ) => {
      try {
        dispatch(setDetailLoader(true));
        const response = await getSingleBroadcasts(
          id,
          group,
          searchKey,
          page,
          limit
        );

        if (response && response.success) {
          getCountOfMessages(response?.data);
        }

        console.log("response single broadcast", response);
      } catch (error) {
        console.log("error", error);
      } finally {
        dispatch(setDetailLoader(false));
      }
    };
    fetchSingleBroadcastData(id, "", "", 1, 10000);
  }, []);

  const fetchSingleBroadcastData = async (
    id,
    group,
    searchKey,
    page = 1,
    limit = 20
  ) => {
    try {
      dispatch(setDetailLoader(true));
      const response = await getSingleBroadcasts(
        id,
        group?.value,
        searchKey,
        page,
        limit
      );
      let groups = [{ label: "All", value: "all" }];
      if (response && response.success) {
        dispatch(setBroadcastDetail(response.data));
        dispatch(setBroadcastName(response.data[0]?.broadcastName));
        if (response.data[0]?.groupsName?.length <= 0) {
          response.data[0]?.groups?.map((group) => {
            groups.push({ label: group?.groupName, value: group?.groupName });
          });
        } else {
          response.data[0]?.groupsName?.map((group) => {
            groups.push({ label: group, value: group });
          });
        }

        dispatch(setGroups(groups));
        setPaginationData({
          currPage: page,
          totalPages: Math.ceil(response?.totalRecords / limit),
        });

        // Set the current page
        setCurrentPage(page);
      }

      console.log("response single broadcast", response);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(setDetailLoader(false));
    }
  };

  const fetchFilteredBroadcast = async (id, broadcastType, search, groups) => {
    try {
      dispatch(setFilterLoader(true));
      const response = await getFilteredBroadcast(
        id,
        broadcastType?.value,
        search,
        groups.value
      );
      if (response && response.success) {
        dispatch(setBroadcastDetail(response.data));
        console.log("response group", response.data);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(setFilterLoader(false));
    }
  };

  useEffect(() => {
    if (!broadcastType || broadcastType?.value == "all") {
      fetchSingleBroadcastData(id, selectedGroup, search, currentPage, 20);
      setCurrentPage(currentPage);
    }
  }, [search, selectedGroup, broadcastType]);
  useEffect(() => {
    if (
      broadcastType &&
      broadcastType?.value &&
      broadcastType?.value != "all"
    ) {
      fetchFilteredBroadcast(id, broadcastType, search, selectedGroup);
    }
  }, [broadcastType, search, selectedGroup]);

  const getCountOfMessages = (data) => {
    const newCount = {
      total: 0,
      completed: 0,
      delivered: 0,
      read: 0,
      failed: 0,
      pending: 0,
    };
    console.log("data of broadcast", data);

    if (data) {
      data[0]?.messages?.forEach(({ message }) => {
        newCount.total++;
        if (message?.read) {
          newCount.read++;
          newCount.delivered++;
          newCount.completed++;
        } else if (message?.delivered) {
          newCount.delivered++;
          newCount.completed++;
        } else if (message?.sent) newCount.completed++;
        else if (message?.failed) newCount.failed++;
        else newCount.pending++;
      });

      console.log("newCount====", newCount);

      setCount(newCount);
    }
  };

  const handleSelectChange = (selected) => {
    console.log("Selected filter:", selected);
    setBroadcastType(selected);
  };
  const handleGroupSelect = (selected) => {
    setSelectedGroup(selected);
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
    fetchSingleBroadcastData(id, "", "", value, 20);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 100, // Ensures menu appears above other elements
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "180px", // Apply max height here
      overflowY: "auto",
    }),
  };
  return (
    <>
      <PageHeader
        Pagename={broadcastName && broadcastName}
        Backpage={"Broadcast"}
        Currentpage={broadcastName && broadcastName}
        Linkpage={"/broadcast"}
        detailLoader={detailLoader}
      />

      <section className="main inner-main broadcast-sec pt-3 pt-xl-4">
        <Container>
          <Row className="">
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Recipients "}
                number={count.total}
                imageicon={Recipent_icon}
                iconClass={"recipent"}
                detailLoader={detailLoader}
              />
            </Col>
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Pending "}
                number={count.pending}
                imageicon={Pending_icon}
                iconClass={"pending"}
                detailLoader={detailLoader}
              />
            </Col>
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Sent "}
                number={count.completed}
                imageicon={Send_icon}
                iconClass={"sent"}
                detailLoader={detailLoader}
              />
            </Col>
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Delivered "}
                number={count.delivered}
                imageicon={Delivered_icon}
                iconClass={"delivered"}
                detailLoader={detailLoader}
              />
            </Col>
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Read "}
                number={count.read}
                imageicon={Read_icon}
                iconClass={"read"}
                detailLoader={detailLoader}
              />
            </Col>
            <Col md={4} xs={6}>
              <Broadcastcard
                title={"Failed "}
                number={count.failed}
                imageicon={Failed_icon}
                iconClass={"failed"}
                detailLoader={detailLoader}
              />
            </Col>
          </Row>
        </Container>

        <Container className="details-search py-3 pb-lg-2 pt-lg-4">
          <Row className="align-items-center ">
            <Col md={5} lg={5} xxl={5}>
              <div className="broadcast-heading mb-3 mb-md-0">
                Broadcast Detail
              </div>
            </Col>
            <Col md={7} lg={7} xxl={7} className="">
              <Row
                className="mx-md-0 flex justify-end"
                style={{ padding: width >= 768 ? "0" : "0 " }}
              >
                <Col
                  md={4}
                  lg={4}
                  xl={4}
                  sm={12}
                  xs={12}
                  className="ps-md-0 pe-md-1  mb-md-0"
                  style={{ marginBottom: width < 768 && "7px " }}
                >
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      style={{ height: "40px" }}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={4}
                  lg={4}
                  xl={4}
                  sm={12}
                  xs={12}
                  className="pe-md-1 ps-md-1"
                >
                  <Form.Group>
                    <Select
                      className="c-select"
                      options={options}
                      styles={customStyles}
                      placeholder={"Status"}
                      classNamePrefix="select"
                      value={broadcastType}
                      onChange={handleSelectChange}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={4}
                  lg={4}
                  xl={4}
                  sm={12}
                  xs={12}
                  className="pe-md-0 ps-md-1 "
                  style={{ marginTop: width < 768 && "7px " }}
                >
                  <Form.Group>
                    <Select
                      className="c-select"
                      options={groups}
                      styles={customStyles}
                      placeholder={"Group Name"}
                      classNamePrefix="select"
                      value={selectedGroup}
                      onChange={handleGroupSelect}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {broadcastDetail &&
        (broadcastDetail?.length <= 0 ||
          broadcastDetail[0]?.messages?.length <= 0) &&
        !detailLoader ? (
          <section className="main inner-main broadcast-sec">
            <section className="contact-group-table  d-lg-block">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div
                      className="tb-empty-content text-center "
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          width: "100%",
                          height: "300px",
                          background: "white",
                        }}
                      >
                        <img src={Broadcast_ic} alt="icon" />
                        <p
                          style={{
                            fontWeight: 500,
                            fontSize: "20px",
                            marginTop: "10px",
                            fontFamily: "Segoe UI",
                            color: "#000000",
                          }}
                        >
                          {"No data Found"}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
        ) : (
          <>
            {" "}
            <Container className="d-none d-md-block">
              <Row>
                <Col xs={12}>
                  <BroadcastDetailTable
                    broadcastDetail={broadcastDetail}
                    detailLoader={detailLoader}
                    filterLoader={filterLoader}
                  />
                </Col>
              </Row>
            </Container>
            {/* <Container className="d-md-none"> */}
            <Row className="d-md-none">
              <Col xs={12} className="mx-0 ">
                <BroadcastDetailsMob
                  broadcastDetail={broadcastDetail}
                  detailLoader={detailLoader}
                  filterLoader={filterLoader}
                />
              </Col>
            </Row>
            {/* </Container> */}
          </>
        )}

        <div style={{ marginTop: "100px" }}>
          {/* <TemplateListingFooter /> */}
          <section className="listingfooter">
            <Container>
              <Row>
                <Col xs={12}>
                  <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags">
                    {!search &&
                      (!broadcastType || broadcastType.value == "all") &&
                      (!selectedGroup || selectedGroup.value == "all") &&
                      broadcastDetail?.length > 0 && (
                        <Pagination
                          count={paginationData.totalPages} // Calculate total pages
                          variant="outlined"
                          shape="rounded"
                          siblingCount={1}
                          page={paginationData.currPage}
                          onChange={handlePaginationChange}
                        />
                      )}
                  </Box>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </section>
    </>
  );
};

export default BroadcastDetails;
