import React from "react";

const TableHeadItem = ({ item, isBroadcastTable, idx }) => {
  console.log("keyyy", idx);
  return (
    <th
      title={item}
      style={{
        textAlign: isBroadcastTable && (idx == "2" || idx == "3") && "center",
      }}
    >
      {item}
    </th>
  );
};

export default TableHeadItem;
