import axios from "axios";
import config from "../../config";

const baseUrl = `${config.backend}group/`;

export const getAllGroups = async (page = 1, limit = 10) => {
  const response = await axios.get(`${baseUrl}all?page=${page}&limit=${limit}`);
  // const response = await axios.get(`${baseUrl}all`)
  return response.data;
};
export const searchGroup = async (page = 1, limit = 10, value = "") => {
  const response = await axios.get(
    `${baseUrl}getAllBySearch?page=${page}&limit=${limit}&searchKey=${value}`
  );
  return response.data;
};

export const createGroup = async (data) => {
  const response = await axios.post(`${baseUrl}add`, data);
  return response.data;
};

export const editGroup = async (data) => {
  const response = await axios.post(`${baseUrl}edit`, data);
  return response.data;
};

export const removeContactFromGroup = async (data) => {
  const response = await axios.post(`${baseUrl}remove`, data);
  return response.data;
};

export const deleteGroup = async (groupId) => {
  const response = await axios.delete(`${baseUrl}${groupId}`);
  return response.data;
};

export const editGroup1 = async (data) => {
  const response = await axios.post(`${baseUrl}editgroup`, data);
  return response.data;
};
