import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "../company-listing/companylisting.css";
import Closeic from "../../images/icons/close-ic.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconButton } from "@mui/material";
import { Spinner } from "react-bootstrap";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const WidgetActions = ({
  widgetData,
  submitDelete,
  name,
  getSelectedWidgetData,
  isSimpleUser,
}) => {
  const navigate = useNavigate();
  console.log("data got: ", widgetData);
  const [dtshow, setDtShow] = useState(false);
  const [editshow, setEditShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const markAsCopied = () => {
    setIsCopied(true);
    if (!isCopied) toast.success("Integration code copied successfully!");
    setTimeout(() => setIsCopied(false), 10000);
  };

  const scriptUrl = window.location.origin + "/script.js";

  const scriptTag = `
            <script
                type="text/javascript"
                src=${scriptUrl}
                widget-id="${widgetData?._id}"
                id="chat-widget-script"
            >
            </script>
        `;

  const handleDelete = async () => {
    setDeleting(true);
    // if(data.isapproved){
    //     submitEdit(data)
    // }
    await submitDelete(widgetData._id);
    setDtShow(false);
    setDeleting(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const formCheck = document.getElementById("custom-switch");
      if (formCheck && !formCheck.contains(event.target)) {
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="d-flex align-items-center flex-row justify-center">
        <>
          <CustomTooltip title={"Copy Integration Code"}>
            <button className="ded-btn">
              <CopyToClipboard text={scriptTag} onCopy={() => markAsCopied()}>
                <ul className="nav align-items-cetner justify-content-center gap-1">
                  <li className="nav-ietm">
                    {isCopied ? (
                      <IconButton sx={{ padding: "0px", marginBottom: "5px" }}>
                        <CheckCircleIcon
                          sx={{ fontSize: "18px", color: "#000000" }}
                        />
                      </IconButton>
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.75 13.5C6.3375 13.5 5.98437 13.3531 5.69062 13.0594C5.39687 12.7656 5.25 12.4125 5.25 12V3C5.25 2.5875 5.39687 2.23438 5.69062 1.94063C5.98437 1.64688 6.3375 1.5 6.75 1.5H13.5C13.9125 1.5 14.2656 1.64688 14.5594 1.94063C14.8531 2.23438 15 2.5875 15 3V12C15 12.4125 14.8531 12.7656 14.5594 13.0594C14.2656 13.3531 13.9125 13.5 13.5 13.5H6.75ZM6.75 12H13.5V3H6.75V12ZM3.75 16.5C3.3375 16.5 2.98437 16.3531 2.69062 16.0594C2.39687 15.7656 2.25 15.4125 2.25 15V4.5H3.75V15H12V16.5H3.75Z"
                          fill="black"
                          fill-opacity="0.6"
                        />
                      </svg>
                    )}
                  </li>
                </ul>
              </CopyToClipboard>
            </button>
          </CustomTooltip>
          {isSimpleUser ? (
            <Tooltip title={"You can't perform this action"} arrow>
              <button className="ded-btn" style={{ cursor: "no-drop" }}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </button>
            </Tooltip>
          ) : (
            <button
              className="ded-btn"
              onClick={() => {
                getSelectedWidgetData(widgetData);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </button>
          )}
          {isSimpleUser ? (
            <Tooltip title={"You can't perform this action"} arrow>
              <button className="ded-btn" style={{ cursor: "no-drop" }}>
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </button>{" "}
            </Tooltip>
          ) : (
            <button className="ded-btn" onClick={() => setDtShow(true)}>
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </button>
          )}
        </>
      </div>

      <Modal
        show={dtshow}
        onHide={() => setDtShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete
            <span className="u-name">
              <b>{` “${name}”`}</b>
            </span>{" "}
            from your list of active widgets. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {deleting ? (
            <div
              style={{
                display: "flex",
                minWidth: "114.94px",
                maxWidth: "114.94px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button variant="danger-light" onClick={() => handleDelete()}>
              Yes, Delete it
            </Button>
          )}

          <Button variant="danger" onClick={() => setDtShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WidgetActions;
