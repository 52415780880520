// eventBus.js
const eventBus = new EventTarget();

export const triggerEvent = (eventName, detail) => {
  eventBus.dispatchEvent(new CustomEvent(eventName, { detail }));
};

export const addEventListener = (eventName, callback) => {
  eventBus.addEventListener(eventName, callback);
};

export const removeEventListener = (eventName, callback) => {
  eventBus.removeEventListener(eventName, callback);
};
