import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

const PageHeader = ({
  Pagename,
  Backpage,
  Currentpage,
  Linkpage,
  detailLoader,
}) => {
  const width = useDeviceWidthChecker();

  const pageHeaderstyle = {
    boxShadow: "0px 3px 5px 0px #00000014",
    backgroundColor: "#fff",
    padding: "25px 0px",
  };

  const pageHeadingstyle = {
    fontWeight: "700",
    fontSize: "22px",
    color: "#000",
    lineHeight: "30px",
  };

  const breadcrumbLinkstyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4B4A4A",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: width >= 768 ? "end" : "start",
  };

  const breadcrumbSpanstyle = {
    fontWeight: "600",
    color: "#4B4A4A",
    textDecoration: "none",
    display: "flex",
  };

  const linkstyle = {
    textDecoration: "none",
    marginRight: "5px",
  };

  const svg_iconstyle = {
    position: "relative",
    top: "4px",
  };

  return (
    <>
      <section style={pageHeaderstyle} className="page-header">
        <Container>
          <Row className="align-items-center">
            {width >= 768 && (
              <Col
                md={6}
                style={pageHeadingstyle}
                className="page-heading d-none d-md-block"
              >
                <div
                  className="flex align-items-center gap-2"
                  style={{ fontWeight: "600" }}
                >
                  {detailLoader ? (
                    <Skeleton width={80} height={12} />
                  ) : (
                    <span style={{ wordBreak: "break-word" }}>{Pagename}</span>
                  )}
                </div>
              </Col>
            )}

            <Col
              style={breadcrumbLinkstyle}
              className="bc-links col-md-6 text-md-end"
            >
              <Link style={linkstyle} to={Linkpage}>
                <span style={breadcrumbSpanstyle}>
                  <svg
                    style={svg_iconstyle}
                    className="me-2"
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10L1 5.5L6 1"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {Backpage}
                </span>
              </Link>{" "}
              /
              {detailLoader ? (
                <Skeleton width={80} height={12} />
              ) : (
                <span style={{ marginLeft: "2px", wordBreak: "break-word" }}>
                  {Currentpage}
                </span>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PageHeader;
