import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Box, Typography, Tooltip, Zoom } from "@mui/material";
import CallToAction from "../template-buttons/CallToAction";
import QuickReply from "../template-buttons/QuickReply";
import addIcon from "../../images/icons/add-icon.svg";
import useDeviceWidthDetector from "../customHooks/DeviceWidthDetector";
import InfoIcon from "../../images/icons/infoIcon.svg";

export const ButtonTemplate = ({
  tempData,
  handleButton,
  buttonType,
  quickReplyData,
  handleQuickReply,
  handleCallToAction,
  deleteQuickReply,
  numQuickReplyButtons,
  numcallToActionButtons,
  AddQuickReplyButton,
  AddCallToActionButton,
  callToActionData,
  deleteCallToAction,
  toggleAction,
  selectedOptions,
}) => {
  const [btnShow, setBtnShow] = useState(false);
  const buttonOptions = [
    { value: "None", label: "None" },
    { value: "calltoaction", label: "Call To Action" },
    { value: "quickreply", label: "Quick Reply" },
  ];
  const [showAction, setShowAction] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [showInteraction, setShowInteraction] = useState(false);

  const width = useDeviceWidthDetector();
  return (
    <>
      <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100 mt-[18px]">
        <Form.Group className="w-100">
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
          >
            Customer Interaction Buttons
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={
                <>
                  <p>
                    <strong style={{ fontWeight: "600" }}>
                      Call-to-Action:{" "}
                    </strong>
                    Allows your customer to click on a phone number to call and
                    visit a website by clicking on link.
                  </p>
                  <p>
                    <strong style={{ fontWeight: "600" }}>
                      {" "}
                      Quick Reply:{" "}
                    </strong>
                    Allows your customer to return a response text message e.g.
                    please rate our support.
                    <br />
                    1 For good
                    <br />
                    2 For average
                    <br />3 For poor{" "}
                  </p>
                </>
              }
              placement={"top"}
              sx={{ cursor: "pointer" }}
              open={showInteraction}
              onMouseEnter={() => setShowInteraction(true)}
              onMouseLeave={() => setShowInteraction(false)}
            >
              <img
                src={InfoIcon}
                style={{ cursor: "pointer" }}
                className="mt-1"
                width={16}
                height={16}
              />
            </Tooltip>
          </h4>
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] "
          >
            (Optional) Add quick reply or call-to-action buttons to your
            message.
          </h4>
          {/* <Select className="c-select" value={tempData.button} onChange={handleButton} options={buttonOptions} /> */}
          <div className="button-select mt-[16px]">
            <div
              className="form-check form-check-inline"
              onClick={() => handleButton({ value: "None", label: "None" })}
            >
              <input
                className="form-check-input create-template-btn"
                type="radio"
                id="inline-radio-11"
                value="None"
                checked={tempData.button?.value === "None"}
              />
              <label
                className="form-label"
                htmlFor="inline-radio-11"
                style={{
                  color:
                    tempData.button?.value === "None" ? "#165E5B" : "#4B4A4A",
                  fontSize: "14px",
                  fontFamily: "Segoe UI",
                  fontWeight: 500,
                }}
              >
                None
              </label>
            </div>
            <div
              className="form-check form-check-inline"
              onClick={() =>
                handleButton({ value: "calltoaction", label: "Call To Action" })
              }
            >
              <input
                className="form-check-input create-template-btn"
                type="radio"
                id="inline-radio-22"
                value="Call To Action"
                checked={tempData.button?.value === "calltoaction"}
              />
              <label
                className="form-label "
                htmlFor="inline-radio-22"
                style={{
                  color:
                    tempData.button?.value === "calltoaction"
                      ? "#165E5B"
                      : "#4B4A4A",
                  fontSize: "14px",
                  fontFamily: "Segoe UI",
                  fontWeight: 500,
                }}
              >
                Call To Action
              </label>
            </div>
            <div
              className="form-check form-check-inline"
              onClick={() =>
                handleButton({ value: "quickreply", label: "Quick Reply" })
              }
            >
              <input
                className="form-check-input create-template-btn"
                type="radio"
                id="inline-radio-33"
                value="Quick Reply"
                checked={tempData.button?.value === "quickreply"}
              />
              <label
                className="form-label "
                htmlFor="inline-radio-33"
                style={{
                  color:
                    tempData.button?.value === "quickreply"
                      ? "#165E5B"
                      : "#4B4A4A",
                  fontSize: "14px",
                  fontFamily: "Segoe UI",
                  fontWeight: 500,
                }}
              >
                Quick Reply
              </label>
            </div>
          </div>
        </Form.Group>

        {buttonType === "quickreply" && (
          <Row style={{ width: width > 768 ? "40%" : "100%" }}>
            <Col sm={12}>
              {/* <label className="form-label">Quick Reply</label> */}
              <div className="button-details">
                <h4
                  style={{
                    fontFamily: "SEGOE UI",
                    marginTop: "24px",
                    marginBottom: "12px",
                  }}
                  className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
                >
                  Quick Reply
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      <p>
                        <strong style={{ fontWeight: "600" }}>
                          {" "}
                          Quick Reply:{" "}
                        </strong>
                        Allows your customer to return a response text message
                        e.g. please rate our support.
                        <br />
                        1 For good
                        <br />
                        2 For average
                        <br />3 For poor{" "}
                      </p>
                    }
                    placement={"top"}
                    sx={{ cursor: "pointer" }}
                    open={showReply}
                    onMouseEnter={() => setShowReply(true)}
                    onMouseLeave={() => setShowReply(false)}
                  >
                    <img
                      src={InfoIcon}
                      style={{ cursor: "pointer" }}
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                  </Tooltip>
                </h4>
                {quickReplyData.map((item) => (
                  <div style={{ display: "flex", marginBottom: "14px" }}>
                    <QuickReply
                      style={{ width: "auto" }}
                      handleQuickReply={handleQuickReply}
                      data={item.data}
                      dataKey={item.key}
                      deleteQuickReply={deleteQuickReply}
                      key={item.key}
                      numQuickReplyButtons={numQuickReplyButtons}
                    />
                  </div>
                ))}
              </div>
              {numQuickReplyButtons < 3 && (
                <button
                  className="add-btn flex justify-end items-center"
                  style={{ width: "95%" }}
                  onClick={AddQuickReplyButton}
                >
                  <img src={addIcon} style={{ marginRight: "5px" }} />
                  <h4
                    style={{ fontFamily: "SEGOE UI" }}
                    className="text-[14px] font-medium leading-[22px] text-[#000000]"
                  >
                    Add more
                  </h4>
                </button>
              )}
            </Col>
          </Row>
        )}

        {buttonType === "calltoaction" && (
          <Row style={{ width: "100%", padding: "0px" }}>
            <Col sm={12} style={{ paddingRight: "0px" }}>
              {/* <label className="form-label">Call To Action</label> */}
              <div className="button-details call-to-action-details">
                <h4
                  style={{ fontFamily: "SEGOE UI", marginTop: "24px" }}
                  className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
                >
                  Call To Action
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      <p>
                        <strong style={{ fontWeight: "600" }}>
                          Call-to-Action:{" "}
                        </strong>
                        Allows your customer to click on a phone number to call
                        and visit a website by clicking on link.
                      </p>
                    }
                    placement={"top"}
                    sx={{ cursor: "pointer" }}
                    open={showAction}
                    onMouseEnter={() => setShowAction(true)}
                    onMouseLeave={() => setShowAction(false)}
                  >
                    <img
                      src={InfoIcon}
                      style={{ cursor: "pointer" }}
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                  </Tooltip>
                </h4>
                {callToActionData.map((item) => (
                  <CallToAction
                    style={{
                      width: "auto",
                      marginBottom: "10px",
                      marginTop: "24px",
                    }}
                    numcallToActionButtons={numcallToActionButtons}
                    handleCallToAction={handleCallToAction}
                    data={item}
                    dataKey={item.key}
                    deleteCallToAction={deleteCallToAction}
                    toggleAction={toggleAction}
                    key={item.key}
                    selectedOptions={selectedOptions}
                  />
                ))}

                {numcallToActionButtons < 2 && (
                  <button
                    className="add-btn flex justify-center items-center mt-[20px]"
                    style={{ width: "100%" }}
                    onClick={AddCallToActionButton}
                  >
                    <img src={addIcon} style={{ marginRight: "5px" }} />
                    <h4
                      style={{ fontFamily: "SEGOE UI" }}
                      className="text-[14px] font-medium leading-[22px] text-[#000000]"
                    >
                      Add more
                    </h4>
                  </button>
                )}
              </div>
            </Col>
          </Row>
        )}
      </Box>
    </>
  );
};
