import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newMessage: null,
  unReadChats: [],
  messageStatus: null,
  messageFailed: null,
  broadcastUpdate: false,
  currentUser: null,
  notificationDetail: null,
  unReadCount: 0,
};

export const MessageNotificationSlice = createSlice({
  name: "MessageNotification",
  initialState,
  reducers: {
    setNewMessage: (state, action) => {
      let allChats = state.unReadChats;
      let isFound = allChats?.find(
        (item) => item.contact == action.payload.contact
      );
      if (!isFound) {
        if (
          state.currentUser == null ||
          state.currentUser != action.payload.contact
        ) {
          allChats.push(action.payload);
        }
      }
      state.unReadChats = allChats;
      state.newMessage = action.payload;
    },
    updateUnreadChats: (state, action) => {
      state.unReadChats = action.payload.users;
      state.currentUser = action.payload.contact;
    },
    setUnreadCount: (state, action) => {
      state.unReadCount = action.payload;
    },
    setNotificationDetail: (state, action) => {
      state.notificationDetail = action.payload;
    },
    clearNotificationDetail: (state) => {
      state.notificationDetail = null;
    },
    removeCurrentChat: (state, action) => {
      state.currentUser = null;
    },
    setMessageStatus: (state, action) => {
      state.messageStatus = action.payload;
    },
    setMessageFailed: (state, action) => {
      state.messageFailed = action.payload;
    },
    setBroadcastUpdate: (state, action) => {
      state.broadcastUpdate = !state.broadcastUpdate;
    },
    emptyHeaderChatsCount: (state, action) => {
      state.unReadChats = [];
      state.newMessage = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNewMessage,
  setMessageStatus,
  setMessageFailed,
  setBroadcastUpdate,
  emptyHeaderChatsCount,
  updateUnreadChats,
  removeCurrentChat,
  setNotificationDetail,
  clearNotificationDetail,
  setUnreadCount,
} = MessageNotificationSlice.actions;

export default MessageNotificationSlice.reducer;
