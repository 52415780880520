import { useState } from "react";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import {
  Box,
  IconButton,
  Typography,
  Button as MuiButton,
  Tooltip,
  Zoom,
} from "@mui/material";
import InfoIcon from "../../images/icons/infoIcon.svg";

export const BodyTemplate = ({
  handleChange,
  formData,
  handlebodyVarCount,
  bodySample,
  bodyVarCount,
  handleBodySample,
}) => {
  const [showBody, setShowBody] = useState(false);
  return (
    <>
      <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100 mt-[18px]">
        <Form.Group className="form-group fg-body w-100 p-0">
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2 "
          >
            Message Text
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title="The primary content of your message, with placeholders for variables that will be replaced with specific data when the message is sent

                "
              placement={"top"}
              sx={{ cursor: "pointer" }}
              open={showBody}
              onMouseEnter={() => setShowBody(true)}
              onMouseLeave={() => setShowBody(false)}
            >
              <img
                src={InfoIcon}
                style={{ cursor: "pointer" }}
                className="mt-1"
                width={16}
                height={16}
              />
            </Tooltip>
          </h4>
          <h4
            style={{ fontFamily: "SEGOE UI" }}
            className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] mb-[12px]"
          >
            Enter the text for your message in the language you’ve selected.
          </h4>
          <div style={{ position: "relative" }}>
            <textarea
              style={{ backgroundColor: "#F3F7F7", resize: "none" }}
              className="form-control w-100 m-0 min-h-[200px]"
              placeholder="Body text"
              rows="6"
              value={formData.body}
              onChange={handleChange}
              name="body"
            ></textarea>
          </div>
          <Form.Control.Feedback
            style={{
              marginBottom: "0px",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
            }}
            type="invalid"
          >
            <p style={{ fontSize: "12px", textAlign: "right" }}>
              {Array.from(formData?.body).length > 1024
                ? "Not more than 1024 character allowed"
                : ""}
            </p>
            <span
              style={{
                color: "black",
                right: formData?.body.length > 750 ? "30px" : "",
              }}
            >
              {Array.from(formData?.body).length}/1024
            </span>
          </Form.Control.Feedback>

          {/* <Form.Control.Feedback style={{ marginTop: "-16px", width: "100%", justifyContent: "space-between", display: "flex" }} type="invalid">
                        <p style={{ fontSize: "12px" }}>{(formData.body.length > 1024) ? "Not more than 1024 character allowed" : ""}</p>
                        <div><span ><button className="var-button" onClick={handlebodyVarCount} >Add Variable</button></span>
                        </div>
                    </Form.Control.Feedback> */}
        </Form.Group>

        {/* {bodyVarCount > 0 && <Accordion className="accordian" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Example for Body variables</Accordion.Header>
                        <Accordion.Body>
                            {bodySample.map((bodyVar, index) => (
                                <Form.Group className="form-group" key={index}>
                                    <div className="label-bottom" style={{ marginBottom: '5px' }}>
                                        Add example for body variable {index + 1}.
                                    </div>
                                    <Form.Control
                                        placeholder="Example Text"
                                        onChange={event => handleBodySample(event, index)}
                                        value={bodyVar}
                                    />
                                </Form.Group>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>} */}
      </Box>
    </>
  );
};
