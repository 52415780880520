import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

import "./form.css";
import { useState } from "react";

const Reset_form = ({
  heading,
  checkUser,
  userExists,
  emailSent,
  submited,
  existSetter,
}) => {
  const [mail, setMail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [error, setError] = useState({
    userExistance: false,
    invalidEmail: false,
    emptyEmail: false,
  });
  console.log(submited);

  const handleonFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
    }
  };

  function handleChange(e) {
    existSetter(null);
    setMail(e.target.value);
    if (!/^\S+@\S+\.\S+$/.test(e.target.value)) {
      setValidEmail(false);
    } else setValidEmail(true);
  }

  function submit(e) {
    e?.preventDefault();

    if (userExists === false) {
      setError({ userExistance: true });
    } else if (!validEmail && mail.length > 0) {
      setError({ invalidEmail: true });
    } else if (mail.length === 0 && isFocused) {
      setError({ emptyEmail: true });
    }
    if (mail !== "") {
      checkUser(mail);
    }
  }

  return (
    <div className="form-sec">
      <Form>
        <div className="form-heading text-center">{heading}</div>
        <Form.Group className="form-group" style={{ marginBottom: "10px" }}>
          <Form.Label>Email Address*</Form.Label>
          <Form.Control
            type="email"
            onFocus={handleonFocus}
            placeholder="example@mwanmobile.com"
            onChange={handleChange}
            required={true}
          />
        </Form.Group>
        {error.userExistance && (
          <Form.Group
            className="text-center last-pra"
            style={{ marginBottom: "2px", color: "red" }}
          >
            User Does not exist
          </Form.Group>
        )}
        {error.invalidEmail && (
          <Form.Group
            className="text-center last-pra"
            style={{ marginBottom: "2px", color: "red" }}
          >
            Please enter a valid email
          </Form.Group>
        )}
        {error.emptyEmail && (
          <Form.Group
            className="text-center last-pra"
            style={{ marginBottom: "2px", color: "red" }}
          >
            Email cannot be empty
          </Form.Group>
        )}
        {userExists === true && emailSent === true && (
          <Form.Group
            className="text-center last-pra"
            style={{ marginBottom: "5px", color: "green" }}
          >
            An email has been sent to you with a link to reset your password ✔
          </Form.Group>
        )}
        <Form.Group className="form-group">
          <div className="btn-sec text-sm-end">
            <Button
              className="btn-main"
              onClick={submit}
              style={
                submited
                  ? { backgroundColor: "#165E5B", border: "none" }
                  : !validEmail
                  ? { backgroundColor: "#165E5B", border: "none" }
                  : {}
              }
              //   disabled={submited || !validEmail}
            >
              {submited ? <Spinner size="sm" /> : "Reset Password"}
            </Button>
          </div>
        </Form.Group>
        <Form.Group className="text-center last-pra">
          <Link to="/login">Back to login</Link>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Reset_form;
