import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getAllTemplates } from "../../services/WhatsappAPI";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Template_ic from "../../images/icons/template-ic.svg";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";
import Chat_banner from "../../images/chat-img.png";
// import Reciver from "../../images/reciver.svg";
import { Accordion, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AuthTemplate } from "../template-preview/AutheTemplate";
import { UrlPhoneNumber } from "../template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../template-preview/QuickReplyPreview";
import { toast } from "react-toastify";

const SelectTemplateModal = ({
  id,
  show,
  handleCloseTemplate,
  setShow,
  chatInfo,
  selectOnly = false,
  setVariable,
  setSelectedTemplate,
}) => {
  const [templates, setTemplates] = useState([]);
  const [templateIndex, setTemplateIndex] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [changeTitle, setChangeTitle] = useState(false);
  const [templateshow, setTemplateShow] = useState(false);
  const [templatetoShow, setTemplatetoShow] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [bodyVarCount, setbodyVarCount] = useState(0);
  const [headerVarCount, setHeaderVarCount] = useState(0);
  const [headerSample, setHeaderSample] = useState("");
  const [bodySample, setBodySample] = useState([]);
  const [templateData, setTemplateData] = useState({
    event: null,
    template: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleChangeTitle = () => setChangeTitle(!changeTitle);

  const [activeId, setActiveId] = useState(null);

  const handleRadioChange = (id) => {
    setActiveId(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getAllTemplates();
        if (!resp.success) throw new Error(resp.error);
        setTemplates(resp.data);
      } catch (error) {
        console.log("Failed to fetch templates!");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!templateshow) {
      setBodySample([]);
      setbodyVarCount(0);
      setHeaderVarCount(0);
      setHeaderSample("");
    }
  }, [templateshow]);

  const handleTemplateClick = (e, template) => {
    console.log("template selecteddd", template);
    console.log("event", e.target.closest(".action-b"));
    console.log("template data", templateData);

    //if (e.target.closest(".action-b")) {
    console.log("template selecteddd", template);
    setSelectedTemplate(template);
    let hLen = 0;
    let bLen = 0;
    if (
      template.components[0].type === "HEADER" &&
      template?.category == "AUTHENTICATION"
    ) {
      if (template.components[0].format == "text") {
        if (template.components[0].text.includes("{{1}}")) {
          hLen = 1;
          setHeaderVarCount(1);
        }
        bLen = (template.components[1].text.match(/{{[1-9]}}/g) || []).length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        setBodySample(newBodySample);
      }
    } else {
      if (template?.category == "AUTHENTICATION") {
        bLen = (template.components[0].text.match(/{{[1-9]}}/g) || []).length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        console.log("new body samplelelele", newBodySample);
        setBodySample(newBodySample);
      }
    }
    console.log("headerVarCount", bLen, "::", hLen);
    if ((bLen > 0 || hLen > 0) && template?.category == "AUTHENTICATION") {
      setTemplatetoShow(template);
      setTemplateShow(true);
    } else {
      setShow(false);
      setTemplateShow(false);
      setSelectedTemplate(template);
      setVariable([]);
    }

    console.log("bodyVarCount", bodyVarCount, ":::", bodySample);
    //setShow(false)
    //}
  };

  const handleHeaderSample = (e) => {
    if (templatetoShow?.category == "AUTHENTICATION") {
      setHeaderSample(e.target.value);
    }
  };

  const handleBodySample = (event, index) => {
    if (templatetoShow?.category == "AUTHENTICATION") {
      const updatedBodyVars = [...bodySample];
      updatedBodyVars[index] = event.target.value;
      setBodySample(updatedBodyVars);
    }
  };

  const selectTemplate = () => {
    const variables = [];
    const parameters1 = bodySample.map((text) => ({ type: "text", text }));
    if (headerSample !== "" && templatetoShow?.category == "AUTHENTICATION") {
      variables.push({
        type: "header",
        parameters: { type: "text", text: headerSample },
      });
    }
    if (bodySample.length > 0 && templatetoShow?.category == "AUTHENTICATION") {
      //const parameters1 = bodySample.map(text => ({ type: 'text', text }));
      variables.push({
        type: "body",
        parameters: parameters1,
      });
    }
    console.log(variables);
    if (templatetoShow?.category == "AUTHENTICATION") {
      setVariable(variables);
    }

    let isAnyVarEmpty = false;
    for (let i = 0; i != parameters1?.length; i++) {
      if (parameters1[i]?.text == "") {
        isAnyVarEmpty = true;
        break;
      }
    }

    if (bodySample.length > 0 && isAnyVarEmpty) {
      toast.error("Variable Field is required");
      return false;
    }

    setTemplateShow(false);
    setShow(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to Select Template
    </Tooltip>
  );

  console.log("bodyVarCount ====", bodyVarCount);

  console.log("templates", templates);

  const templateList = templates
    .filter(
      (template) =>
        template.status === "APPROVED" &&
        template.name.toLowerCase().includes(searchTerm)
    )
    .map((template, index) => {
      console.log("template", template);
      const radioId = `radio-${template?.id}`;
      return (
        <div
          className="single-template"
          onClick={(e) => {
            e.stopPropagation();
            //setTemplateShow(true);
            setTemplatetoShow(template);
            setTemplateData({ event: e, template: template });
            handleRadioChange(radioId);
          }}
        >
          <div className="select_template">
            <Form.Group
              className={`form-group mb-3 ${
                activeId === `radio-${index}` ? "active" : ""
              }`}
            >
              <div className="form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                <label htmlFor="radio-1">
                  <div className="template-label">
                    <div className="template-name">{template.name}</div>
                    <div className="tempalte-leng">
                      {" "}
                      Language: {template.language}
                    </div>
                  </div>
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  // id={radioId}
                  name="select-template"
                  checked={activeId === radioId}
                  // onChange={() => handleRadioChange(radioId)}
                  // // onClick={() =>
                  // //   handleTemplateClick(
                  // //     templateData.event,
                  // //     templateData.template
                  // //   )
                  // // }
                />
              </div>
            </Form.Group>
          </div>
        </div>
      );
    });

  const loadingList = Array.from({ length: 5 }).map((_, index) => (
    <div className="single-template1">
      <div className="placeholder-glow">
        <span
          className="col-12 placeholder"
          style={{ height: "70px", borderRadius: "5px" }}
        ></span>
      </div>
    </div>
  ));

  console.log("templatetoShow", templatetoShow);

  return (
    <>
      <Modal
        className="whatsapp-modal select_tempalte-modal"
        centered
        show={show}
        onHide={() => handleCloseTemplate()}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="mb-0 w-100">Select a Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search Template"
                  className="search-template"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            className="modal-body-inner"
            style={{
              maxHeight: "370px",
              height: "100%",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {loading ? (
              loadingList
            ) : templateList.length > 0 ? (
              <Row>
                <Col md={7}>{templateList}</Col>
                <Col md={5}>
                  <div className="message-preview-sec">
                    <div className="message-prreview-inner">
                      <div className="preview-sec preview-sec pt-0">
                        <div className="preview-body">
                          <div className="preview-template-inbox">
                            {templatetoShow?.category == "AUTHENTICATION" ? (
                              <AuthTemplate
                                components={templatetoShow?.components}
                                isInbox={true}
                              />
                            ) : (
                              <UrlPhoneNumber
                                components={templatetoShow?.components}
                                isInbox={true}
                                template={templatetoShow}
                                id={templatetoShow?._id}
                              />
                            )}
                          </div>
                          <QuickReplyPreview
                            components={templatetoShow?.components}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                No Templates Found<br></br>
                <br></br>Create a new template by clicking{" "}
                <Link to="/createTemplate" style={{ color: "#165E5B" }}>
                  Create New Template
                </Link>
              </p>
            )}
          </div>
          {templateshow && (
            <Modal
              show={templateshow}
              onHide={() => {
                setTemplateShow(false);
              }}
              className="whatsapp-modal modal-contact preview-template my-modal-wrapper modal-dialog modal-dialog-centered"
              centered
            >
              <Modal.Header className="p-0" closeButton></Modal.Header>
              <Modal.Body>
                <div className="preview-sec preview-sec pt-0">
                  <div className="preview-body">
                    <div className="preview-template-inbox">
                      {templatetoShow.category == "AUTHENTICATION" ? (
                        <AuthTemplate
                          components={templatetoShow.components}
                          isInbox={true}
                        />
                      ) : (
                        <UrlPhoneNumber
                          components={templatetoShow.components}
                          isInbox={true}
                          template={templatetoShow}
                          id={templatetoShow?._id}
                        />
                      )}
                    </div>
                  </div>
                  <QuickReplyPreview components={templatetoShow.components} />
                </div>
              </Modal.Body>
              {headerVarCount > 0 && (
                <Accordion style={{ marginBottom: "5px" }} defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Header variable</Accordion.Header>
                    <Accordion.Body className="accordian-body">
                      <Form.Group className="form-group">
                        <div
                          className="label-bottom"
                          style={{ marginBottom: "5px" }}
                        >
                          Set header variable.
                        </div>
                        <Form.Control
                          placeholder="Example Text"
                          onChange={handleHeaderSample}
                          value={headerSample}
                        />
                      </Form.Group>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {bodyVarCount > 0 && (
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Body variables</Accordion.Header>
                    <Accordion.Body>
                      {bodySample.map((bodyVar, index) => (
                        <Form.Group
                          className="form-group"
                          key={index}
                          style={{ marginBottom: "10px" }}
                        >
                          <div
                            className="label-bottom"
                            style={{ marginBottom: "5px" }}
                          >
                            Set body variable {index + 1}
                          </div>
                          <Form.Control
                            placeholder="Variable Text"
                            onChange={(event) => handleBodySample(event, index)}
                          />
                        </Form.Group>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {(bodyVarCount > 0 || headerVarCount > 0) && (
                <Button
                  onClick={selectTemplate}
                  className="template-select-btn"
                >
                  Select
                </Button>
              )}
            </Modal>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="main"
            onClick={() => {
              handleTemplateClick(templateData.event, templateData.template);
              // setSelectedTemplate(templateData.template);
              // handleCloseTemplate();
            }}
          >
            Insert Template
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectTemplateModal;
