import React from "react";
import "./form.css";

const SideSection = () => {
  return (
    <div className="login-details">
      <div className="login-title-main">Want to become an Utterer!</div>
      <div className="login-description">
        Let us guide you on your onboarding journey to boost your business via
        WhatsApp
      </div>

      <div className="loginDetails-list">
        <div className="loginList-item">
          <div className="list-icon">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11.5L8.5 14.5L16.528 6.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="Ldlist-text">Explain you Utter</div>
        </div>

        <div className="loginList-item">
          <div className="list-icon">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11.5L8.5 14.5L16.528 6.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="Ldlist-text">
            Understand and address your challenges
          </div>
        </div>

        <div className="loginList-item">
          <div className="list-icon">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11.5L8.5 14.5L16.528 6.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="Ldlist-text">Suggest the best solution</div>
        </div>

        <div className="loginList-item">
          <div className="list-icon">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11.5L8.5 14.5L16.528 6.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="Ldlist-text">Detailed tour of the product</div>
        </div>

        <div className="loginList-item">
          <div className="list-icon">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 11.5L8.5 14.5L16.528 6.5"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="Ldlist-text">Pricing overview and next steps</div>
        </div>
      </div>

      <a
        href="https://utter-product-page.dev.mwancloud.com/demo/"
        className="btn utter-btn ripple-button whiteBtn w-100"
        target="_blank"
        rel="noopener noreferrer"
      >
        Sign-up for a Demo
      </a>
    </div>
  );
};

export default SideSection;
