import React, { useState, useRef, useEffect, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Grouptable from "../components/group-table/g-table";
import Search from "../components/search/search";
import Modaltable from "../components/modal-table/mTable";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import Actionbutton from "../components/tableAction/tableactionBtn1";
import {
  getAllGroups,
  createGroup,
  deleteGroup,
  editGroup1,
  searchGroup,
} from "../services/Group/index";
import { getAllContacts } from "../services/Contacts";
import moment from "moment";
import { Table } from "antd";
import "./../components/group-table/group-contact.css";
import Allcontacts from "../components/group-table/all-contacts";
import { toast } from "react-toastify";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import TableSkelton from "../components/skeletons/TableLoader";
import { setGroupsData } from "../reduxSlice/groups";
import { Box, Pagination } from "@mui/material";
import { Spinner } from "react-bootstrap";
import ContactMobile from "../components/contact-table/ContactMobile";
import GroupMobile from "../components/contact-table/GroupMobile";

const Groups = ({ setCsvShow, setIsGroupImport }) => {
  const { allGroups, totalRecords } = useSelector(
    (state) => state.GroupsReducer
  );
  const dispatch = useDispatch();
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    currPage: 1,
  });

  const width = WindowWidthDetector();
  const [cgshow, setCgShow] = useState(false);
  const [cgtshow, setCgtShow] = useState(false);
  const [newgroupshow, setNewgroupShow] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsOrgData, setGroupsOrgData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const inputRef = useRef(null);
  const [selectedGroupId, setSelectedGrpId] = useState(null);
  const [allContacts, setAllContacts] = useState([]);
  const [isContactsFetching, setIsContactsFetching] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showError1, setShowError1] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [updateScreen, setUpdateScreen] = useState(false);
  const [groupContacts, setGroupContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(""); // Filter by search text
  const [isCreating, setIsCreating] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  // setting data of groups
  const setAllGroupsData = (groupsData, records = totalRecords) => {
    console.log("data of groups got :", groupsData);
    setGroups(handleDataForTable(groupsData));
    setGroupsOrgData(handleDataForTable(groupsData));

    dispatch(setGroupsData({ data: groupsData, totalRecords: records }));
  };

  const fetchData = async (page = 1, pageSize = 20, search = "") => {
    setLoading(true);
    try {
      let groupsData = [];
      const resp = await searchGroup(page, pageSize, search);

      if (resp.success) {
        const { data, totalRecords } = resp;

        groupsData = [...data]; // Append new data

        setAllGroupsData(groupsData, totalRecords);
        setPaginationData({
          currPage: page,
          totalPages: Math.ceil(totalRecords / pageSize),
        });
        setCurrentPage(page);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createNewGroup = async () => {
    const groupData = {
      groupName: groupName,
      contacts: selectedContacts,
    };
    console.log(groupData);
    setIsCreating(true);
    try {
      const resp = await createGroup(groupData);
      if (resp.success) {
        console.log("Group Created successfully");

        // Update the groups data
        const updatedGroups = [resp.data, ...allGroups];
        // setAllGroupsData(updatedGroups);

        // Update total records count and pagination
        const newTotalRecords = updatedGroups.length; // Assuming all groups are loaded
        const newTotalPages = Math.ceil(newTotalRecords / 10); // Assuming 10 items per page

        fetchData(
          paginationData.currPage > paginationData.totalPages &&
            paginationData.totalPages > 0
            ? paginationData.currPage - 1
            : paginationData.currPage,
          20,
          searchText
        );

        // Trigger re-render or other state updates if necessary
        setUpdateScreen((prevUpdateScreen) => !prevUpdateScreen);
        setNewgroupShow(false);
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    } finally {
      setIsCreating(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      // Delete the group
      const isDeleted = await deleteGroup(id);
      if (isDeleted?.success) {
        fetchData(currentPage, 20, searchText);
        console.log("Group Deleted Successfully!");
        toast.success("Group Deleted Successfully!");
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const editGroup = async (id, newName, newContacts) => {
    console.log(id, newName, newContacts);
    const resp = await editGroup1({
      id: id,
      newName,
      // newContacts,
    });
    if (resp?.success) {
      fetchData(currentPage, 20, searchText);
      let updatedRecords = [...allGroups];
      let isFound = updatedRecords.findIndex(
        (item) => item?._id == selectedGroupId
      );
      updatedRecords[isFound] = {
        ...updatedRecords[isFound],
        groupName: newName,
        contacts: newContacts,
      };
      console.log("updatedRecords", updatedRecords);

      setAllGroupsData([...updatedRecords]);
      toast.success("Group updated Successfully!");
      console.log("Group updated Successfully!");
    } else {
      toast.error(resp.error);
      console.log(resp.error);
    }
  };

  useEffect(() => {
    fetchData(currentPage, 20, searchText);
    setCurrentPage(currentPage);
  }, [currentPage]);

  const columns = [
    {
      title: <span className="ms-3">No#</span>,
      dataIndex: "No",
      width: "16.67%",
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
      width: "16.67%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "16.67%",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      width: "16.67%",
    },
    {
      title: "Members",
      dataIndex: "members",
      width: "16.67%",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      width: "16.67%",
    },
  ];
  const handleGroupContacts = (contacts) => {
    console.log("contacts in func", contacts);
    setGroupContacts(contacts);
  };

  // making data for showing rows in table
  const handleDataForTable = (data, contacts = allContacts) => {
    console.log("allContacts in function", contacts);
    let finalData = [];

    for (let i = 0; i != data.length; i++) {
      finalData.push({
        key: i,
        No: <span className="ms-3">{i + 1}</span>,
        groupName: data[i]?.groupName,
        createdAt: moment(data[i]?.createdAt).format("DD MMM, YY"),
        createdBy: data[i]?.user?.firstname + " " + data[i]?.user?.lastname,

        members: data[i]?.contacts?.length,
        Actions: (
          <Actionbutton
            onClick={() => setSelectedGrpId(data[i]?._id)}
            handleDelete={handleDelete}
            group={data[i]}
            Contactlist={contacts}
            handleSelected={handleGroupContacts}
            selectedContacts={groupContacts}
            editGroup={editGroup}
            setCsvShow={setCsvShow}
            setIsGroupImport={setIsGroupImport}
          />
        ),
      });
    }

    return finalData;
  };

  // search any record

  const handlePaginationChange = (event, value) => {
    fetchData(value, 20, searchText);
    setCurrentPage(value); // Fetch new data for the selected page
  };

  console.log("totalPages", paginationData?.totalPages);

  return (
    <>
      {loading ? (
        <TableSkelton />
      ) : screenWidth >= 1023 ? (
        <Box
          className="flex justify-start align-items-start w-100 mt-3 rounded-md container all-tags mb-8 testing"
          style={{
            padding: 0,
            height: "80%",
            marginBottom: "100px",
          }}
        >
          <Table
            columns={columns}
            dataSource={groups}
            pagination={
              false // Disable changing pageSize (optional)
            }
            style={{ width: "100%", background: "#FFFFFF" }}
            size="middle"
          />
        </Box>
      ) : (
        <>
          <section className=" pt-3 d-lg-none w-100">
            <GroupMobile groups={groups} />
          </section>
        </>
      )}
      <Box
        className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
        sx={{
          height: "10%",
          position: "fixed",
          bottom: "0",
          boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        {groups?.length > 0 && (
          <Pagination
            count={paginationData.totalPages} // Total number of pages
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            page={paginationData.currPage} // Current page
            onChange={handlePaginationChange} // Page change handler
          />
        )}
      </Box>
    </>
  );
};

export default Groups;
