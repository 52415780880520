import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";
import { Marker, GoogleMap, useLoadScript } from "@react-google-maps/api";
import { Box, Pagination } from "@mui/material";
import useDeviceWidthDetector from "../components/customHooks/DeviceWidthDetector.jsx";
import pdfIcon from "../images/icons/pdf-icon.svg";
import NoData from "./template-listing-empty.js";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "../pdf-worker.js";
import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import WindowWidthDetector from "../components/customHooks/DeviceWidthDetector";
import Template_ic from "../images/icons/template-ic.svg";
import {
  getAllTemplates,
  deleteTemplate,
  getLocTemplateCoordinates,
} from "../services/WhatsappAPI/index";
import { UrlPhoneNumber } from "../components/template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../components/template-preview/QuickReplyPreview";
import { AuthTemplate } from "../components/template-preview/AutheTemplate";
import { toast } from "react-toastify";
import TemplateListingHeader from "../components/template-listing/templateListing-header";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Listingtable from "../components/template-listing/listing-table";
import TemplateListingMob from "../components/template-listing/template-listing-mob";
import Reciver from "../images/icons/reciver.svg";
import Linkforward from "../images/icons/link-forward.svg";
import Eventimg from "../images/event-img.jpg";
import Cloasebtnic from "../images/close-icon.svg";
import activeTempIcon from "../images/icons/active-template.svg";
import TableSkeleton from "../components/skeletons/TableLoader.jsx";

const GOOGLE_API_KEY = "AIzaSyASVOh0zf-dgKxPOr4e796luwQzCT150NY";
const libraries = ["places"];

const TemplateListing = () => {
  const [show, setShow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFirstTimeLoaded, setIsFirsTimeLoaded] = useState(false);
  const [templateComponentsData, setTemplateComponentData] = useState(null);

  const getTemplCoods = async (templateId) => {
    const companyId = localStorage.getItem("companyId");
    const coordinates = await getLocTemplateCoordinates(companyId, templateId);
    console.log(
      "coordinates ",
      coordinates,
      ":::",
      coordinates?.data?.components[0]?.parameters[0]?.location
    );
    setCoordinates({
      lat: coordinates?.data?.components[0]?.parameters[0]?.location?.latitude,
      lng: coordinates?.data?.components[0]?.parameters[0]?.location?.longitude,
    });
  };

  const handleViewTemplateClick = (templateData) => {
    setShow(true);
    console.log("data got : ", templateData);
    setTemplateComponentData(templateData);

    const headerComponent = templateData.components.find(
      (c) => c.type === "HEADER"
    );
    const bodyComponent = templateData.components.find(
      (c) => c.type === "BODY"
    );
    const footerComponent = templateData.components.find(
      (c) => c.type === "FOOTER"
    );
    const buttonsComponent = templateData.components.find(
      (c) => c.type === "BUTTONS"
    );
    console.log("buttonsComponent", buttonsComponent);
    setTemplateData({
      name: templateData.name,
      language: templateData.language,
      header: headerComponent,
      body: bodyComponent ? bodyComponent.text : "",
      footer: footerComponent,
      buttons: buttonsComponent
        ? buttonsComponent.buttons.map((btn) => ({
            type: btn.type,
            text: btn.text,
          }))
        : [],
    });
    setCoordinates({ lat: null, lng: null });
    if (headerComponent?.format == "LOCATION") {
      getTemplCoods(templateData?.id);
    }
  };
  const windowWidth = useDeviceWidthDetector();
  const width = WindowWidthDetector();
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [selTempData, setSelTempData] = useState(null);
  const [dtshow, setDtShow] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [startIndexTable, setStartIndexTable] = useState(0);
  const [templateshow, setTemplateShow] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesOrg, setTemplatesOrg] = useState([]);
  const [showDeleteModal, setShowDeletModal] = useState(false);
  const [templatetoShow, setTemplatetoShow] = useState(undefined);
  const [templatetoDelete, setTemplatetoDelete] = useState(undefined);
  const [selCategory, setSelCategory] = useState(null);
  const [selStatus, setSelStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedTemplates, setSelectedTemplates] = useState({
    type: "",
    templates: [],
  });
  const [templateData, setTemplateData] = useState({
    name: "",
    language: "",
    header: "",
    body: "",
    footer: "",
    buttons: [],
  });
  const [reload, setReload] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selIndex, setSelIndex] = useState(null);
  const templatesPerPage = 5;
  const totalPages = Math.ceil(
    selectedTemplates.templates.length / templatesPerPage
  );
  const [pdfViewWidth, setPdfViewWidth] = useState(300);
  const [numPages, setNumPages] = useState();
  const [searchedText, setSearchedText] = useState("");
  const getPreviewHeight = () => {
    const previewSection = document.getElementsByClassName("heloooo");
    console.log("off set width : ", previewSection[0]?.clientWidth);
    setPdfViewWidth(previewSection[0]?.clientWidth);
  };
  // getting message preview width for pdf view
  useEffect(() => {
    getPreviewHeight();
  }, [windowWidth]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  if (currentPage > totalPages && totalPages !== 0) setCurrentPage(totalPages);
  const startIndex = (currentPage - 1) * templatesPerPage;
  const endIndex = startIndex + templatesPerPage;
  const templatesToDisplay = selectedTemplates.templates.slice(
    startIndex,
    endIndex
  );
  console.log("rendering");
  const fetchAllTemplates = async () => {
    setLoading(true);
    try {
      const resp = await getAllTemplates();
      if (!resp.success) throw new Error(resp.error);
      console.log("response of all templates : ", resp.data);
      setTemplates(resp.data);
      setTemplatesOrg(resp.data);
      setIsFirsTimeLoaded(true);
      setSelectedTemplates({ type: "all", templates: resp.data });
    } catch (error) {
      console.log("Failed to fetch templates!");
    }
    setIsLoaded(true);
    setLoading(false);
  };
  useEffect(() => {
    fetchAllTemplates();
  }, []);

  useEffect(() => {
    if (templatetoShow) {
      const headerComponent = templatetoShow.components.find(
        (c) => c.type === "HEADER"
      );
      const bodyComponent = templatetoShow.components.find(
        (c) => c.type === "BODY"
      );
      const footerComponent = templatetoShow.components.find(
        (c) => c.type === "FOOTER"
      );
      const buttonsComponent = templatetoShow.components.find(
        (c) => c.type === "BUTTONS"
      );
      console.log("footerComponent", templatetoShow);
      setTemplateData({
        name: templatetoShow.name,
        language: templatetoShow.language,
        header: headerComponent ? headerComponent.text : "",
        body: bodyComponent ? bodyComponent.text : "",
        footer: footerComponent ? footerComponent.text : "",
        buttons: buttonsComponent
          ? buttonsComponent.buttons.map((btn) => ({
              type: btn.type,
              text: btn.text,
            }))
          : [],
      });
    }
  }, [templatetoShow]);

  const templateList = templatesToDisplay.map((template) => {
    return (
      <div className="single-template">
        <div className="content-info">
          <div className="template-name">{template.name}</div>
          <div className="template-info">
            <div className="templage-date-time">
              Language: {template.language}
            </div>
            <div className="templage-date-time mt-1">
              Approval status: {template.status}
            </div>
          </div>
        </div>
        <div className="action">
          <ul className="nav responsive-actions">
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  {
                    setTemplateShow(true);
                    setTemplatetoShow(template);
                  }
                }}
              >
                <svg
                  width="16"
                  height="10"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99984 1.33333C9.22566 1.32926 10.4278 1.67119 11.4679 2.31982C12.5081 2.96845 13.3441 3.89744 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C2.65558 3.89744 3.49159 2.96845 4.53176 2.31982C5.57192 1.67119 6.77401 1.32926 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.44186 3.33333 8.86579 3.50893 9.17835 3.82149C9.49091 4.13405 9.6665 4.55797 9.6665 5C9.6665 5.44203 9.49091 5.86595 9.17835 6.17851C8.86579 6.49107 8.44186 6.66667 7.99984 6.66667C7.55781 6.66667 7.13389 6.49107 6.82133 6.17851C6.50877 5.86595 6.33317 5.44203 6.33317 5C6.33317 4.55797 6.50877 4.13405 6.82133 3.82149C7.13389 3.50893 7.55781 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
                    fill="black"
                    fillOpacity="0.6"
                  />
                </svg>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  if (template?.status == "PENDING") {
                    toast.error("You can not edit pending templates");
                  } else {
                    navigate("/createTemplate", {
                      state: { isEdit: true, template: template },
                    });
                  }
                }}
              >
                <i class="bi bi-pencil"></i>
              </button>
            </li>
            <li className="nav-item">
              <button
                className="action-b"
                onClick={() => {
                  {
                    setDtShow(true);
                    setTemplatetoDelete(template);
                  }
                }}
              >
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                    fill="black"
                    fillOpacity={"0.6"}
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  });

  const loadingList = Array.from({ length: 5 }).map((_, index) => (
    <div className="single-template1">
      <div className="placeholder-glow">
        <span
          className="col-12 placeholder"
          style={{ height: "70px", borderRadius: "5px" }}
        ></span>
      </div>
    </div>
  ));

  const deleteTemp = async (name) => {
    setIsDeleting(true);
    const res = await deleteTemplate({ name: name });
    if (res.success) {
      //setReload(prevReload => !prevReload)
      toast.success("Template deleted successfully");
      setShowDeletModal(false);
      fetchAllTemplates();
      setShow(false);
    }
    setIsDeleting(false);
  };

  const handleTemplateSelection = (e) => {
    let list;
    if (e == "all") {
      setSelectedTemplates({ type: e, templates: templates });
    }
    if (e == "approved") {
      list = templates.filter((temp) => temp.status === "APPROVED");
      setSelectedTemplates({ type: e, templates: list });
    }
    if (e == "pending") {
      list = templates.filter((temp) => temp.status === "PENDING");
      setSelectedTemplates({ type: e, templates: list });
    }
    if (e == "rejected") {
      list = templates.filter((temp) => temp.status === "REJECTED");
      setSelectedTemplates({ type: e, templates: list });
    }
  };

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  if (!isGoogleLoaded) {
    return (
      <Box className="bg-[#F3F7F7] flex flex-column justify-start align-items-start pb-6">
        <TemplateListingHeader />
        <div
          style={{
            display: "flex",
            width: "80%",
            marginLeft: "10%",
            marginTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="user-personal-info" style={{ width: "100%" }}>
            <div className="info-row">
              <div className="placeholder-glow" style={{ maxWidth: "100%" }}>
                <span
                  className="col-12 placeholder mb-2 py-2"
                  style={{ height: "70vh" }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  }

  const containerStyleForPreview = {
    width: "100%",
    height: "150px",
  };

  const options = {
    mapTypeControl: false, // Disable Map and Satellite options
    streetViewControl: false, // Disable Pegman (Street View)
    zoomControl: false, // Disable Zoom in/out controls
    fullscreenControl: false, // Disable Fullscreen control
  };

  const getSearchedTemplates = (value) => {
    let finalTemplates = templatesOrg;
    let matchedRecords = [];
    if (value?.length > 0) {
      matchedRecords = finalTemplates?.filter((item) =>
        item?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      console.log("matchedRecords without", matchedRecords);
      if (selStatus) {
        matchedRecords = matchedRecords?.filter((item) =>
          item?.status?.toLowerCase()?.includes(selStatus?.toLowerCase())
        );
      }
      if (selCategory) {
        matchedRecords = matchedRecords?.filter((item) =>
          item?.category?.toLowerCase()?.includes(selCategory?.toLowerCase())
        );
      }
      setTemplates(matchedRecords);
    } else {
      if (selCategory && selStatus) {
        matchedRecords = finalTemplates?.filter(
          (item) =>
            item?.category
              ?.toLowerCase()
              ?.includes(selCategory?.toLowerCase()) &&
            item?.status?.toLowerCase()?.includes(selStatus?.toLowerCase())
        );
      } else if (selStatus || selCategory) {
        if (selStatus) {
          matchedRecords = finalTemplates?.filter((item) =>
            item?.status?.toLowerCase()?.includes(selStatus?.toLowerCase())
          );
        }
        if (selCategory) {
          matchedRecords = finalTemplates?.filter((item) =>
            item?.category?.toLowerCase()?.includes(selCategory?.toLowerCase())
          );
        }
      } else {
        matchedRecords = templatesOrg;
      }
      setTemplates(matchedRecords);
    }
    setSearchedText(value);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  const getCategorizedTemplates = (value) => {
    setSelCategory(value == "all" ? null : value);
    let matchedRecords = [];
    let finalText = value == "all" ? "" : value;
    let finalTemplates = templatesOrg;
    if (searchedText?.length > 0) {
      finalTemplates = templatesOrg?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchedText?.toLowerCase())
      );
    }
    matchedRecords = finalTemplates?.filter((item) =>
      item?.category?.toLowerCase()?.includes(finalText?.toLowerCase())
    );
    if (selStatus) {
      matchedRecords = matchedRecords?.filter((item) =>
        item?.status?.toLowerCase()?.includes(selStatus?.toLowerCase())
      );
    }
    setTemplates(matchedRecords);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  const getStatusWiseTemplates = (value) => {
    setSelStatus(value == "all" ? null : value);
    let matchedRecords = [];
    let finalText = value == "all" ? "" : value;
    let finalTemplates = templatesOrg;
    if (searchedText?.length > 0) {
      finalTemplates = templatesOrg?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchedText?.toLowerCase())
      );
    }
    matchedRecords = finalTemplates?.filter((item) =>
      item?.status?.toLowerCase()?.includes(finalText?.toLowerCase())
    );
    if (selCategory) {
      matchedRecords = matchedRecords?.filter((item) =>
        item?.category?.toLowerCase()?.includes(selCategory?.toLowerCase())
      );
    }
    setTemplates(matchedRecords);
    setCurrPage(1);
    setStartIndexTable(0);
  };

  const editTemplate = (template) => {
    if (template?.status == "PENDING") {
      toast.error("You can not edit pending templates");
      return;
    } else {
      navigate("/createTemplate", {
        state: { isEdit: true, template: template },
      });
    }
  };

  // open image in new tab on click
  const openImageInNewTab = (image) => {
    window.open(image, "_blank")?.focus();
  };
  const handlePaginationChange = (event, value) => {
    console.log("value got :", value, (value - 1) * 10);
    setShow(false);
    setCurrPage(value);
    if (value == 1) {
      setStartIndexTable(0);
    } else {
      setStartIndexTable((value - 1) * 10);
    }
  };

  console.log("templateDataa", templateData);
  return (
    <>
      <TemplateListingHeader
        getSearchedTemplates={(value) => getSearchedTemplates(value)}
        templatesOrg={templatesOrg}
        getCategorizedTemplates={(value) => getCategorizedTemplates(value)}
        getStatusWiseTemplates={(value) => getStatusWiseTemplates(value)}
      />
      {loading ? (
        <TableSkeleton marginTop="30px" />
      ) : templates?.length == 0 &&
        selCategory == null &&
        selStatus == null &&
        searchedText == "" ? (
        <NoData />
      ) : templates?.length > 0 ? (
        <>
          <section className="main inner-main template-listing py-3 d-none d-lg-block">
            <Container>
              <Row>
                <Col xs={12} lg={show ? 8 : 12}>
                  <Listingtable
                    templateData={templateData}
                    activeTempIcon={activeTempIcon}
                    selIndex={selIndex}
                    setSelIndex={setSelIndex}
                    editTemplate={(value) => editTemplate(value)}
                    showDeleteModal={showDeleteModal}
                    isDeleting={isDeleting}
                    setShowDeletModal={setShowDeletModal}
                    onClickDelete={(name) => deleteTemp(name)}
                    templates={templates?.slice(
                      startIndexTable,
                      startIndexTable + 20
                    )}
                    onChildClick={handleViewTemplateClick}
                  />
                </Col>
                {show && (
                  <Col xs={12} lg={4} style={{ marginBottom: "50px" }}>
                    <div className="message-preview-sec">
                      <Box
                        className={`flex justify-center items-center w-100  `}
                        sx={{ paddingBottom: "16px" }}
                      >
                        <h4
                          style={{ fontFamily: "SEGOE UI" }}
                          className="text-[16px] font-medium leading-[22px] text-[#000000] mob-heading text-center py-1"
                        >
                          Message preview on WhatsApp
                        </h4>
                      </Box>
                      <div className="">
                        <div className="preview-sec preview-sec pt-0">
                          <div className="preview-body">
                            <div className="preview-template-inbox">
                              {templateComponentsData?.category ==
                              "AUTHENTICATION" ? (
                                <AuthTemplate
                                  components={
                                    templateComponentsData?.components
                                  }
                                  isInbox={true}
                                />
                              ) : (
                                <UrlPhoneNumber
                                  components={
                                    templateComponentsData?.components
                                  }
                                  isInbox={true}
                                  template={templateComponentsData}
                                  id={templateComponentsData?._id}
                                />
                              )}
                            </div>
                            <QuickReplyPreview
                              components={templateComponentsData?.components}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="preview-sec preview-sec-new"
                      style={{ marginBottom: "60px" }}
                    >
                      <div className="prev-sec-header pb-2">
                        <button
                          className="close-preview"
                          onClick={() => {
                            setShow(false);
                            setTemplateData({
                              name: "",
                              language: "",
                              header: "",
                              body: "",
                              footer: "",
                              buttons: [],
                            });
                          }}
                        >
                          <img src={Cloasebtnic} alt="close-btn" />
                        </button>
                      </div>
                      <div className="preview-body">
                        <div className="preview-screen">
                          {templateData?.header &&
                            templateData?.header?.format == "IMAGE" && (
                              <div className="event-img">
                                <img
                                  src={
                                    templateData?.header?.example
                                      ?.header_handle[0]
                                  }
                                  alt="event-img"
                                  className="w-100 mb-2"
                                  style={{
                                    objectFit: "contain",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    openImageInNewTab(
                                      templateData?.header?.example
                                        ?.header_handle[0]
                                    )
                                  }
                                />
                              </div>
                            )}
                          {templateData?.header &&
                            templateData?.header?.format == "VIDEO" && (
                              <div className="event-img">
                                <ReactPlayer
                                  controls
                                  url={
                                    templateData?.header?.example
                                      ?.header_handle[0]
                                  }
                                  width="100%"
                                  height={"150px"}
                                />
                              </div>
                            )}
                          {templateData?.header &&
                            templateData?.header?.format == "DOCUMENT" && (
                              <div className="event-img">
                                <div
                                  style={{
                                    display: "flex",
                                    background: "#BCF1D5",
                                    padding: "10px",
                                    borderRadius: "2px",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      maxHeight: "140px",
                                      overflow: "hidden",
                                      width: "100%",
                                    }}
                                    className="heloooo"
                                  >
                                 
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: "100%",
                                      gap: "10px",
                                    }}
                                    onClick={() =>
                                      window
                                        .open(
                                          templateData?.header?.example
                                            ?.header_handle[0],
                                          "_blank"
                                        )
                                        ?.focus()
                                    }
                                  >
                                    <img
                                      src={pdfIcon}
                                      style={{ width: "32px", height: "38px" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontWeight: 500,
                                          fontSize:
                                            windowWidth > 1024
                                              ? "14px"
                                              : "12px",
                                          fontFamily: "Segoe UI",
                                          color: "#000000",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Preview File
                                      </p>
                                       </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {templateData?.header &&
                            templateData?.header?.format == "LOCATION" && (
                              <div className="event-img">
                                {coordinates?.lat == null ||
                                coordinates?.lng == null ? (
                                  <p>Loading ....</p>
                                ) : (
                                  <GoogleMap
                                    center={coordinates}
                                    zoom={15}
                                    mapContainerStyle={containerStyleForPreview}
                                    options={options}
                                  >
                                    <Marker position={coordinates} />
                                  </GoogleMap>
                                )}
                              </div>
                            )}
                          {templateData?.header &&
                            templateData?.header?.format == "TEXT" && (
                              <div className="heading">
                                {templateData?.header?.text}
                              </div>
                            )}
                          <div className="content">
                            {templateData?.body
                              ?.split("\n")
                              .map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </div>
                          <div className="heading">
                            {templateData?.footer?.text}
                          </div>
                        </div>
                       
                            <QuickReplyPreview
                              components={templateComponentsData?.components}
                            />

                            {templateData?.buttons[0]?.type == "PHONE_NUMBER" ||
                            templateData?.buttons[1]?.type == "PHONE_NUMBER" ||
                            templateData?.buttons[0]?.type == "URL" ||
                            templateData?.buttons[1]?.type == "URL" ? (
                              <Row
                                className="event-time border-top link-footer"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  rowGap: "10px",
                                }}
                              >
                                {templateData?.buttons[0]?.type ==
                                  "PHONE_NUMBER" ||
                                templateData?.buttons[1]?.type ==
                                  "PHONE_NUMBER" ? (
                                  <div
                                    className="link-footer px-0"
                                    style={{ padding: 0 }}
                                  >
                                    <div>
                                      {
                                        <a href="#">
                                          <img src={Reciver} alt="icon" />
                                          {templateData?.buttons[1]?.type ==
                                          "PHONE_NUMBER"
                                            ? templateData?.buttons[1]?.text
                                            : templateData?.buttons[0]?.text}
                                        </a>
                                      }
                                    </div>
                                  </div>
                                ) : null}
                                {templateData?.buttons[1]?.type == "URL" ||
                                templateData?.buttons[0]?.type == "URL" ? (
                                  <div
                                    className="link-footer px-0"
                                    style={{ padding: 0 }}
                                  >
                                    <div>
                                      {
                                        <a href="#">
                                          <img src={Linkforward} alt="icon" />
                                          {templateData?.buttons[1]?.type ==
                                          "URL"
                                            ? templateData?.buttons[1]?.text
                                            : templateData?.buttons[0]?.text}
                                        </a>
                                      }
                                    </div>
                                  </div>
                                ) : null}
                              </Row>
                            ) : null}
                          </>
                        }
                      </div>
                    </div> */}
                  </Col>
                )}
              </Row>
            </Container>
          </section>

          <section className="main inner-main template-listing py-3 d-lg-none">
            <TemplateListingMob
              templateData={templateData}
              activeTempIcon={activeTempIcon}
              selIndex={selIndex}
              setSelIndex={setSelIndex}
              editTemplate={(value) => editTemplate(value)}
              showDeleteModal={showDeleteModal}
              isDeleting={isDeleting}
              setShowDeletModal={setShowDeletModal}
              onClickDelete={(name) => deleteTemp(name)}
              templates={templates?.slice(
                startIndexTable,
                startIndexTable + 20
              )}
              onChildClick={handleViewTemplateClick}
            />
          </section>

          {/* <TemplateListingFooter
            hideView={() => setShow(false)}
            setCurrPage={setCurrPage}
            templates={templates}
            currPage={currPage}
            setStartIndexTable={setStartIndexTable}
          /> */}
          <section className="listingfooter">
            <Container>
              <Row>
                <Col xs={12}>
                  <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags">
                    {templates?.length > 0 ? (
                      <Pagination
                        count={Math.ceil(templates?.length / 20)}
                        variant="outlined"
                        shape="rounded"
                        siblingCount={0}
                        page={currPage}
                        onChange={handlePaginationChange}
                      />
                    ) : null}
                  </Box>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      ) : (
        <NoData isEmpty={true} />
      )}
    </>
  );
};

export default TemplateListing;
