import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCompUsers: [],
  totalRecords: 0,
  isDeleted: false,
};

export const UsersSlices = createSlice({
  name: "Users",
  initialState,
  reducers: {
    // set all allCompUsers
    setAllCompUsersData: (state, action) => {
      state.allCompUsers = Object.values(action.payload.data);
      state.totalRecords = action.payload.totalRecords;
    },
    // empty all allCompUsers
    removeAllCompUsersData: (state, action) => {
      state.allCompUsers = [];
      state.totalRecords = 0;
    },
    setIsDeleted: (state, action) => {
      state.isDeleted = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllCompUsersData, removeAllCompUsersData, setIsDeleted } =
  UsersSlices.actions;

export default UsersSlices.reducer;
