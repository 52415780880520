import React, { useState, useEffect } from "react";
import Checkbox from "./checkbox";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Hajira from "../../images/hajira.png";
import ContactGroup from "./contactGroup";
import { generateColorHash } from "../../utils/utils";

const AssignContact = (props) => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState(props?.allCompanyUsers || []);
  const badgeStyel = {
    color: "#000",
    fontWeight: "600",
    lineHeight: "16px",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "5px 6px",
    display: "inline-block",
    margin: "3px 3px",
    whiteSpace: "nowrap",
  };

  const getSelectedTagsValues = (tags) => {
    if (!tags) {
      return [];
    }
    return tags.map((tag) => ({
      label: tag.title,
      value: tag._id,
    }));
  };

  useEffect(() => {
    // Extract IDs from selectedAgent and set initial state
    const selectedIds =
      (props?.selectedAgent &&
        props?.selectedAgent.length > 0 &&
        props?.selectedAgent?.map((agent) => agent._id)) ||
      [];
    setIsCheck(selectedIds);
  }, [props?.selectedAgent]);
  console.log("allCompanyUsers", props.allCompanyUsers);
  useEffect(() => {
    let sampleUsers = [];

    for (let i = 0; i != props?.allCompanyUsers?.length; i++) {
      if (props.allCompanyUsers[i]?.isapproved) {
        sampleUsers.push({
          id: props.allCompanyUsers[i]?._id,
          name: (
            <ContactGroup
              Image={Hajira}
              name={`${props.allCompanyUsers[i]?.firstname} ${props.allCompanyUsers[i]?.lastname}`}
              email={props.allCompanyUsers[i]?.email}
              Usertag={
                <>
                  {getSelectedTagsValues(props.allCompanyUsers[i]?.tags)?.map(
                    (item) => (
                      <span
                        key={item.value}
                        style={{
                          ...badgeStyel,
                          backgroundColor: generateColorHash(
                            item.value.substring(0, 10)
                          ),
                        }}
                      >
                        {item.label}
                      </span>
                    )
                  )}
                </>
              }
            />
          ),
        });
      }
    }
    setList(sampleUsers);
  }, [props?.allCompanyUsers]);

  useEffect(() => {
    // Check if all checkboxes are selected
    const isAllSelected =
      list.length > 0 && list.every((item) => isCheck.includes(item.id));
    setIsCheckAll(isAllSelected);
  }, [isCheck, list]);

  const handleSelectAll = () => {
    if (isCheckAll) {
      setIsCheck([]);
    } else {
      setIsCheck(list.map((li) => li.id));
    }
    setIsCheckAll(!isCheckAll);
    props?.markNewTag(isCheckAll ? [] : list.map((li) => li.id));
  };

  const handleClick = (userId) => {
    console.log("clicked", userId);
    const updatedList = isCheck.includes(userId)
      ? isCheck.filter((id) => id !== userId)
      : [...isCheck, userId];

    setIsCheck(updatedList);

    props?.markNewTag(updatedList);
  };
  console.log("isCheck", isCheck);

  // useEffect(() => {
  //   const isAllCheck = isCheck.every((ev) =>
  //     props?.allCompanyUsers?.includes(ev?.id)
  //   );
  //   if (isAllCheck) {
  //     setIsCheckAll(true);
  //     return;
  //   }
  //   setIsCheckAll(false);
  // }, []);

  const catalog = list.map((item) => {
    console.log("item.id", item);
    return (
      <div
        className="row mx-0 user-row"
        key={item.id}
        // onClick={() => handleClick(item.id)}
      >
        <div className="user-name user-name-details">
          <Form.Label htmlFor={item.id}>{item.name}</Form.Label>
          <input
            type="checkbox"
            id={item.id}
            checked={isCheck.includes(item.id)}
            onChange={(e) => {
              e.stopPropagation(); // Prevents the row's onClick from being triggered
              handleClick(item.id);
            }}
            className="form-checkbox-input"
          />
        </div>
      </div>
    );
  });

  return (
    <>
      <Row>
        <div className="user-name">
          <label htmlFor="selectAll">
            <ul className="nav align-items-center">
              <li className="nav-item">
                <div className="u-name">
                  Select All <span>({isCheck?.length})</span>
                </div>
              </li>
            </ul>
          </label>
          <input
            type="checkbox"
            name="selectAll"
            id="selectAll"
            onChange={handleSelectAll}
            checked={isCheckAll}
            className="form-checkbox-input"
          />
        </div>
      </Row>
      {catalog}
    </>
  );
};

export default AssignContact;
