import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allGroups: [],
    totalRecords: 0
};

export const GroupsSlices = createSlice({
    name: "Groups",
    initialState,
    reducers: {
        // set all groups
        setGroupsData: (state, action) => {
            state.allGroups = Object.values(action.payload.data);
            state.totalRecords = action.payload.totalRecords;
        },
        // empty all groups
        removeAllGroupsData: (state, action) => {
            state.allGroups = [];
            state.totalRecords = 0;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    setGroupsData,
    removeAllGroupsData,
} = GroupsSlices.actions;

export default GroupsSlices.reducer;
