import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";

import Broadcast_ic from "../../images/broadcast/broadCast-icon.svg";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import "./broadcast-header.css";

const BroadcastHeader = ({
  Contact_title,
  Contact_search,
  setSearchBroadcast,
  searchBroadcast,
}) => {
  const width = useDeviceWidthChecker();

  return (
    <>
      <section className="template-header py-3">
        <Container>
          <Row className="align-items-center">
            <Col xs={6} lg={4}>
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="template-heading">
                    <img
                      width="33"
                      className="me-2"
                      src={Broadcast_ic}
                      alt="icon"
                    />
                    <span style={{ fontWeight: "600", fontSize: "18px" }}>
                      {Contact_title}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={6} lg={8}>
              <Row className="align-items-center justify-content-end">
                <Col
                  md={12}
                  lg={8}
                  xl={9}
                  className="d-none d-lg-block group-search "
                >
                  {Contact_search}
                </Col>
                <Col md={12} lg={4} xl={3} className="text-end">
                  <Link
                    to="/newBroadcast"
                    className="btn btn-main text-decoration-none btn-main-mob w-100"
                    style={{ fontSize: width > 1023 && width < 1440 && "13px" }}
                  >
                    <i className="bi bi-plus"></i> <span>New Broadcast</span>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="template-header-mob d-lg-none pt-3">
        <Container>
          <Row>
            <Col xs={12} className="mb-2">
              <Form.Group className="form-group">
                <Form.Control
                  type="text"
                  placeholder="Search...."
                  onChange={(e) => setSearchBroadcast(e.target.value)}
                  value={searchBroadcast}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BroadcastHeader;
