import React from "react";
import { useNavigate } from "react-router-dom";

const TableRow = ({
  style,
  data,
  clickRow,
  rowid,
  isCsvTable,
  isBroadcastTable,
  isListingOpen,
}) => {
  const navigate = useNavigate();
  return (
    <tr
      style={style}
      className={`${isListingOpen && "broadcast-table-row"}`}
      onClick={() => {
        if (isBroadcastTable && isListingOpen) {
          navigate(`/broadcastDetails/${rowid}`);
        } else clickRow(rowid);
      }}
    >
      {data?.map((item, index) => {
        return (
          <td
            style={{ textAlign: isBroadcastTable && index == "3" && "center" }}
          >
            {item}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
