import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Row, Col } from "react-bootstrap";

const FullPageSkeleton = () => {
  const cardStyle = {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div className="container mt-5">
      <Row>
        {/* Left Column (Form Area) */}
        <Col md={8} sm={12} xs={12}>
          {/* Broadcast Name */}
          <div style={cardStyle}>
            <Skeleton width="40%" height={20} style={{ marginBottom: "8px" }} />
            <Skeleton width="100%" height={40} />
            <Skeleton width="100%" height={40} />
          </div>

          {/* Select Message Template */}
          <div style={cardStyle}>
            <Skeleton width="40%" height={20} style={{ marginBottom: "8px" }} />
            <Skeleton width="100%" height={40} />
          </div>

          {/* Schedule Broadcast */}
          <div style={cardStyle}>
            <Skeleton width="40%" height={20} style={{ marginBottom: "8px" }} />
            <div className="d-flex justify-content-between">
              <Skeleton width="45%" height={40} />
              <Skeleton width="45%" height={40} />
            </div>
          </div>

          {/* Schedule Broadcast Button */}
          <div style={cardStyle}>
            <Skeleton width="100%" height={50} />
          </div>
        </Col>

        {/* Right Column (Preview Area) */}
        <Col md={4} sm={12} xs={12}>
          <div style={cardStyle}>
            <Skeleton width="80%" height={20} style={{ marginBottom: "8px" }} />
            <Skeleton variant="rectangular" width="100%" height={300} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FullPageSkeleton;
