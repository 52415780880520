import React from "react";
import Skeletonn from "react-loading-skeleton";
import Skeleton from "@mui/material/Skeleton";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import "react-loading-skeleton/dist/skeleton.css";
import "../group-table/group-contact.css";

const BroadcastCardSkeleton = ({ width, marginTop }) => {
  const screenWidth = useDeviceWidthChecker();

  const renderSkeleton = () => {
    return (
      <div
        style={{
          width: "100%",
          maxWidth: "450px",
          minHeight: "110.92px",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "0 20px",
        }}
        className="flex justify-between mb-2"
      >
        <div className="flex align-items-center gap-3">
          <div className="flex flex-col gap-2">
            <Skeleton
              width={screenWidth >= 1024 ? 121 : screenWidth >= 768 ? 80 : 40}
              height={12}
            />
            <Skeleton
              width={screenWidth >= 1024 ? 112 : screenWidth >= 768 ? 70 : 30}
              height={12}
            />
          </div>
        </div>
        <Skeleton
          width={screenWidth >= 768 ? 55 : 35}
          height={screenWidth >= 768 ? 90 : 70}
          //   className="mt-3"
          style={{
            borderRadius: "50%",
            marginTop: screenWidth >= 768 ? "0" : "15px",
          }}
        />
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "0",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: marginTop || "0px",
            width: width || "100%",
          }}
        >
          {renderSkeleton()}
        </div>
      </div>
    </>
  );
};

export default BroadcastCardSkeleton;
