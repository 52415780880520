import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
// import React, { useState } from 'react';

import "../components/broadcast/emptyBroadcast.css";
import Broadcast_ic from "../images/broadcast/broadCast-icon.svg";

import BroadcastHeader from "../components/broadcast/broadcast-header";
import Search from "../components/search/search";

import { Link } from "react-router-dom";

import Select from "react-select";

const EmptyBroadcast = ({ detail }) => {
  return (
    <>
      <section className=" templates-body-empty" style={{ width: "100%" }}>
        <Container className="mt-0">
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="tb-empty-content text-center groups">
                <div className="w-100 flex justify-center">
                  <img class="groups-icons" src={Broadcast_ic} alt="icon" />
                </div>
                <div className="tb-page-title">You don’t have broadcasts</div>
                <p className="mt-2">
                  You can create a new broadcast by clicking the button below
                </p>
                {!detail && (
                  <Link to={`/newBroadcast`} className="btn btn-main mt-4">
                    <i className="bi bi-plus"></i> New Broadcast
                  </Link>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EmptyBroadcast;
