import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonM from "@mui/material/Button";

import "../../company-listing/companylisting.css";
import { Spinner } from "react-bootstrap";
import { Tooltip, Zoom } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";

const ActionButtons = ({
  data,
  submitDelete,
  submitEdit,
  isDeleting,
  handleEdit,
  handleClone,
  setIsCloneMyFlows,
}) => {
  const anchorRef = React.useRef(null);

  console.log("data got: ", data);
  const [dtshow, setDtShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editInfoHover, setEditInfoHover] = useState(false);
  const [editNodesHover, setEditNodesHover] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  function handleChange(e) {
    e.stopPropagation();
    submitEdit(data);
    setPopupOpen(false);
  }
  function handleEditChange(e) {
    e.stopPropagation();
    handleEdit(data);
    setPopupOpen(false);
  }

  function handleCloneChange(e) {
    e.stopPropagation();
    setIsCloneMyFlows(true);
    handleClone(data);
    setPopupOpen(false);
  }

  const handleDelete = async (e) => {
    e.stopPropagation();
    setLoading(true);
    await submitDelete(data.flowId);
    if (!isDeleting) {
      setDtShow(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const formCheck = document.getElementById("custom-switch");
      if (formCheck && !formCheck.contains(event.target)) {
        setEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorRef.current && !anchorRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setPopupOpen(false);
  };
  const handleToggle = (e) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  // In all menu item click handlers, add:
  const handleMenuAction = (callback) => (e) => {
    e.stopPropagation();
    setMenuOpen(false);
    callback?.(e);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(popupOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && popupOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = popupOpen;
  }, [popupOpen]);

  return (
    <>
      <div className="d-flex align-items-center flex-row flow-action">
        <Stack direction="row" spacing={2} style={{ maxWidth: "50px" }}>
          <div style={{ maxWidth: "50px" }}>
            <ButtonM
              style={{
                backgroundColor: "transparent",

                maxWidth: "50px",
                borderRadius: "5px",
                border: "1px solid #0000001A",
              }}
              ref={anchorRef}
              id="composition-button"
              aria-controls={popupOpen ? "composition-menu" : undefined}
              aria-expanded={popupOpen ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 17.5C10.0187 17.5 9.60692 17.3288 9.2645 16.9864C8.9215 16.6434 8.75 16.2312 8.75 15.75C8.75 15.2688 8.9215 14.8566 9.2645 14.5136C9.60692 14.1712 10.0187 14 10.5 14C10.9813 14 11.3934 14.1712 11.7364 14.5136C12.0788 14.8566 12.25 15.2688 12.25 15.75C12.25 16.2312 12.0788 16.6434 11.7364 16.9864C11.3934 17.3288 10.9813 17.5 10.5 17.5ZM10.5 12.25C10.0187 12.25 9.60692 12.0785 9.2645 11.7355C8.9215 11.3931 8.75 10.9812 8.75 10.5C8.75 10.0188 8.9215 9.60663 9.2645 9.26363C9.60692 8.92121 10.0187 8.75 10.5 8.75C10.9813 8.75 11.3934 8.92121 11.7364 9.26363C12.0788 9.60663 12.25 10.0188 12.25 10.5C12.25 10.9812 12.0788 11.3931 11.7364 11.7355C11.3934 12.0785 10.9813 12.25 10.5 12.25ZM10.5 7C10.0187 7 9.60692 6.8285 9.2645 6.4855C8.9215 6.14308 8.75 5.73125 8.75 5.25C8.75 4.76875 8.9215 4.35692 9.2645 4.0145C9.60692 3.6715 10.0187 3.5 10.5 3.5C10.9813 3.5 11.3934 3.6715 11.7364 4.0145C12.0788 4.35692 12.25 4.76875 12.25 5.25C12.25 5.73125 12.0788 6.14308 11.7364 6.4855C11.3934 6.8285 10.9813 7 10.5 7Z"
                  fill="black"
                  fill-opacity="0.6"
                />
              </svg>
            </ButtonM>
            <Popper
              open={menuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="top-start"
              transition
              disablePortal
              style={{ zIndex: "999", width: "159px" }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={menuOpen}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        // onKeyDown={handleListKeyDown}
                      >
                        {data?.status == "published" ? (
                          <>
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              title={
                                <p>
                                  Flow is published.You can't Edit flow nodes
                                  and its connections
                                </p>
                              }
                              placement={"top"}
                              sx={{ cursor: "not-allowed" }}
                              className="mb-1"
                            >
                              <MenuItem
                                style={{
                                  cursor: "not-allowed",
                                  fontWeight: "500 !important",
                                  fontSize: "14px",
                                }}
                              >
                                Open Flow
                              </MenuItem>
                            </Tooltip>
                            <MenuItem
                              style={{
                                fontWeight: "500 !important",
                                fontSize: "15px",
                              }}
                              onClick={handleCloneChange}
                            >
                              Clone Flow
                            </MenuItem>
                            <Tooltip
                              arrow
                              TransitionComponent={Zoom}
                              title={
                                <p>
                                  Flow is published. You can't edit its
                                  information.
                                </p>
                              }
                              placement={"top"}
                              sx={{ cursor: "not-allowed" }}
                              className="mb-1"
                            >
                              {data?.status !== "DEPRECATED" && (
                                <MenuItem
                                  style={{
                                    cursor: "not-allowed",
                                    fontWeight: "500 !important",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => {}}
                                >
                                  Edit
                                </MenuItem>
                              )}
                            </Tooltip>
                            {data?.status !== "DEPRECATED" && (
                              <MenuItem
                                style={{
                                  fontWeight: "500 !important",
                                  fontSize: "15px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();

                                  setDtShow(true);
                                  setPopupOpen(false);
                                }}
                              >
                                {data?.status == "PUBLISHED"
                                  ? " Deprecate "
                                  : "Delete"}
                              </MenuItem>
                            )}
                          </>
                        ) : (
                          !editing && (
                            <>
                              <MenuItem
                                style={{
                                  fontWeight: "500 !important",
                                  fontSize: "15px",
                                }}
                                onClick={(e) => {
                                  handleChange(e);
                                }}
                              >
                                Open Flow
                              </MenuItem>
                              <MenuItem
                                style={{
                                  fontWeight: "500 !important",
                                  fontSize: "15px",
                                }}
                                onClick={handleCloneChange}
                              >
                                Clone Flow
                              </MenuItem>
                              {data?.status !== "DEPRECATED" && (
                                <MenuItem
                                  style={{
                                    fontWeight: "500 !important",
                                    fontSize: "15px",
                                  }}
                                  onClick={(e) => {
                                    handleEditChange(e);
                                  }}
                                >
                                  Edit
                                </MenuItem>
                              )}

                              {data?.status !== "DEPRECATED" && (
                                <MenuItem
                                  style={{
                                    fontWeight: "500 !important",
                                    fontSize: "15px",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    setDtShow(true);
                                    setPopupOpen(false);
                                  }}
                                >
                                  {data?.status == "PUBLISHED"
                                    ? " Deprecate "
                                    : "Delete"}
                                </MenuItem>
                              )}
                            </>
                          )
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      </div>

      <Modal
        show={dtshow}
        onHide={() => setDtShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete
            <span className="u-name">
              <b>{` “${data.name}”`}</b>
            </span>{" "}
            from your list of flows. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {!loading ? (
            <Button variant="danger-light" onClick={(e) => handleDelete(e)}>
              {data?.status == "PUBLISHED"
                ? "Yes, Deprecate it "
                : " Yes, Delete it"}
            </Button>
          ) : (
            <Button variant="danger-light" style={{ width: "160px" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red" }}
              />
            </Button>
          )}

          <Button
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              setDtShow(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionButtons;
