import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { registerCompany } from "../../services/Company/index";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./form.css";

const Signup_form = ({ heading }) => {
  const [inputValues, setInputValues] = useState({
    companyName: "",
    firstname: "",
    lastname: "",
    email: "",
    // phoneNumberId: '',
    // whatsappBusinessId: '',
    password: "",
    confirmPassword: "",
  });
  const [btnClicked, setBtnClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const clearInputValues = () => {
    setInputValues({
      companyName: "",
      firstname: "",
      lastname: "",
      email: "",
      // phoneNumberId: '',
      // whatsappBusinessId: '',
      password: "",
      confirmPassword: "",
    });
  };
  useEffect(() => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
  }, []);
  const [createdUser, setCreatedUser] = useState(false);

  const [inputErrors, setInputErrors] = useState({
    companyName: { hasError: false, errorMessage: "" },
    firstname: { hasError: false, errorMessage: "" },
    lastname: { hasError: false, errorMessage: "" },
    email: { hasError: false, errorMessage: "" },
    // phoneNumberId: { hasError: false, errorMessage: '' },
    // whatsappBusinessId: { hasError: false, errorMessage: '' },
    password: { hasError: false, errorMessage: "" },
    confirmPassword: { hasError: false, errorMessage: "" },
  });

  const validateInput = (name, value) => {
    // perform validation and return error message if there's an error, otherwise return null
    if (
      name === "companyName" &&
      value !== "" &&
      !/^[a-zA-Z0-9 ]+$/.test(value)
    ) {
      return "Company name should only contain letters, numbers, and spaces.";
    }
    if (name === "companyName" && value == "") {
      return "Company name is required";
    }

    if (name === "firstname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "First name should only contain letters and spaces.";
    }
    if (name === "firstname" && value == "") {
      return "First name is required";
    }

    if (name === "lastname" && value !== "" && !/^[a-zA-Z ]+$/.test(value)) {
      return "Last name should only contain letters and spaces.";
    }
    if (name === "lastname" && value == "") {
      return "Last name is required";
    }
    if (name === "email") {
      if (value.trim() === "") {
        return "Email is required.";
      } else if (!/^\S+@\S+\.\S+$/.test(value)) {
        return "Email is invalid.";
      }
    }

    if (name === "phoneNumberId" && value.trim() === "") {
      return "Phone number is required.";
    }

    if (name === "phoneNumberId" && value.trim().length !== 15) {
      return "Phone number should have 15 digits.";
    }

    if (name === "whatsappBusinessId" && value.trim() === "") {
      return "Phone number is required.";
    }

    if (name === "whatsappBusinessId" && value.trim().length !== 15) {
      return "whatsappBusinessId should have 15 digits.";
    }

    if (name === "password" && value.trim() === "") {
      return "Password is required.";
    }

    if (
      name === "password" &&
      (value.length < 8 ||
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&\-_.,<>[\]{}()+=:"'/\\#^`~])[A-Za-z\d@$!%*?&\-_.,<>[\]{}()+=:"'/\\#^`~]{8,}$/.test(
          value
        ))
    ) {
      return "Password should contains at least 8 characters, one letter, one number and one special character.";
    }

    if (name === "confirmPassword" && value.trim() === "") {
      return "Confirm Password is required.";
    }

    if (
      name === "confirmPassword" &&
      inputValues.password != "" &&
      value !== inputValues.password
    ) {
      return "Confirm Password does not match Password.";
    }

    // add more validation checks for other fields here

    return null;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // validate input and set error state
    const errorMessage = validateInput(name, value);
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: {
        hasError: Boolean(errorMessage),
        errorMessage: errorMessage || "",
      },
    }));

    // update input value
    setInputValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(btnClicked);
    if (btnClicked) return;
    setBtnClicked(true);

    const errors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateInput(name, inputValues[name]);
      if (error) {
        errors[name] = { hasError: true, errorMessage: error };
      } else {
        errors[name] = { hasError: false, errorMessage: "" };
      }
    });
    setInputErrors(errors);

    let hasError = false;
    Object.keys(errors).forEach((name) => {
      if (errors[name].hasError) {
        hasError = true;
      }
    });

    if (hasError) {
      // There is at least one error
      setBtnClicked(false);
      return;
    }

    try {
      const resp = await registerCompany(inputValues);
      if (resp.success) {
        clearInputValues();
        console.log("Company registered successfully");
        setCreatedUser(true);
        console.log(resp);
        setTimeout(() => {
          navigate("/facebook-signup", {
            state: {
              companyId: resp.companyId,
              message: "Connect your Facebook Business Account",
            },
          });
        }, 1500);
      } else throw new Error(resp.error);
    } catch (error) {
      console.log(error.message);
      if (error.message === "A user with this email already exists") {
        setInputErrors((prevState) => ({
          ...prevState,
          email: {
            ...prevState.email,
            hasError: true,
            errorMessage: error.message,
          },
        }));
      } else if (
        error.message ===
        "A company with this WhatsApp Business Account Id already exists"
      ) {
        toast.error(error.message);
      } else if (error.message === "A company with this name already exists") {
        setInputErrors((prevState) => ({
          ...prevState,
          companyName: {
            ...prevState.companyName,
            hasError: true,
            errorMessage: error.message,
          },
        }));
      } else if (error.message.includes("E11000 duplicate key error")) {
        toast.error(error.message);
      } else {
        toast.error(
          "A company with this WhatsApp Phone number Id already exists"
        );
      }
    }
    setBtnClicked(false);
  };

  return (
    <div className="form-sec">
      <Form onSubmit={handleSubmit}>
        <div className="form-heading text-center">{heading}</div>
        <Form.Group className="form-group">
          <Form.Label>Company Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Company Name"
            name="companyName"
            value={inputValues.companyName}
            onChange={handleInputChange}
            className={inputErrors.companyName.hasError ? "is-invalid" : ""}
          />
          <Form.Control.Feedback type="invalid">
            {inputErrors.companyName.hasError &&
              inputErrors.companyName.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>First Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter First Name"
            name="firstname"
            value={inputValues.firstname}
            onChange={handleInputChange}
            className={inputErrors.firstname.hasError ? "is-invalid" : ""}
          />
          <Form.Control.Feedback type="invalid">
            {inputErrors.firstname.hasError &&
              inputErrors.firstname.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>Last Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Last Name"
            name="lastname"
            value={inputValues.lastname}
            onChange={handleInputChange}
            className={inputErrors.lastname.hasError ? "is-invalid" : ""}
          />

          <Form.Control.Feedback type="invalid">
            {inputErrors.lastname.hasError && inputErrors.lastname.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            name="email"
            value={inputValues.email}
            onChange={handleInputChange}
            className={inputErrors.email.hasError ? "is-invalid" : ""}
          />

          <Form.Control.Feedback type="invalid">
            {inputErrors.email.hasError && inputErrors.email.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>

        {/* <Form.Group className="form-group">
                    <Form.Label>
                        Phone Number ID*
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-right">You can find it in your Facebook developer account &gt; app dashboard &gt; whatsapp dev console </Tooltip>}
                        >
                            <span className="info-button">i</span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Phone Number ID(15 digits)"
                        name="phoneNumberId"
                        value={inputValues.phoneNumberId}
                        onChange={handleInputChange}
                        className={inputErrors.phoneNumberId.hasError ? "is-invalid" : ""}
                    />

                    <Form.Control.Feedback type="invalid">
                        {inputErrors.phoneNumberId.hasError && inputErrors.phoneNumberId.errorMessage}
                    </Form.Control.Feedback>
                </Form.Group> */}

        {/* <Form.Group className="form-group">
                    <Form.Label>
                        Whatsapp Business Account ID*
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip id="tooltip-right">You can find it in your Facebook developer account's dashboard</Tooltip>}
                        >
                            <span className="info-button">i</span>
                        </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter Whatsapp Business Account ID(15 digits)"
                        name="whatsappBusinessId"
                        value={inputValues.whatsappBusinessId}
                        onChange={handleInputChange}
                        className={inputErrors.whatsappBusinessId.hasError ? "is-invalid" : ""}
                    />

                    <Form.Control.Feedback type="invalid">
                        {inputErrors.whatsappBusinessId.hasError && inputErrors.whatsappBusinessId.errorMessage}
                    </Form.Control.Feedback>
                </Form.Group> */}

        <Form.Group className="form-group">
          <Form.Label>Password*</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            name="password"
            value={inputValues.password}
            onChange={handleInputChange}
            className={inputErrors.password.hasError ? "is-invalid" : ""}
          />

          <Form.Control.Feedback type="invalid">
            {inputErrors.password.hasError && inputErrors.password.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Confirm Password*</Form.Label>
          <Form.Control
            type="password"
            placeholder="Submit Password"
            name="confirmPassword"
            value={inputValues.confirmPassword}
            onChange={handleInputChange}
            className={inputErrors.confirmPassword.hasError ? "is-invalid" : ""}
          />

          <Form.Control.Feedback type="invalid">
            {inputErrors.confirmPassword.hasError &&
              inputErrors.confirmPassword.errorMessage}
          </Form.Control.Feedback>
        </Form.Group>

        {createdUser && (
          <Form.Group
            className="text-center last-pra"
            style={{ marginBottom: "5px", color: "green" }}
          >
            Company registered successfully ✔
          </Form.Group>
        )}
        <Form.Group className="form-group">
          <Button
            className="btn btn-main"
            type="submit"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {btnClicked ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "10px" }}
              />
            ) : null}
            Sign up
          </Button>
        </Form.Group>
        {/* <Form.Group className="form-group">
                    <Button className="signup-fb" onClick={() => {
                        navigate('/facebook-signup', { state: { message: 'Connect your Facebook Business Account' } })
                    }}>
                        Sign up with Facebook
                    </Button>
                </Form.Group> */}
        <Form.Group className="text-center last-pra">
          Already have an account? <Link to="/login">Sign in</Link>
        </Form.Group>
      </Form>
    </div>
  );
};

export default Signup_form;
