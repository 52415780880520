import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import emptyUser_ic from "../../images/icons/user-icon-header.svg";
import "../template-listing/css/templateListing-header.css";

const MangageUserHeader = (props) => {
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };
  return (
    <>
      <section className="template-header py-3">
        <Container>
          <Row className="align-items-center">
            <Col xs={6} lg={3}>
              <div className="template-heading">
                <img src={emptyUser_ic} alt="icon" /> Manage Users
              </div>
            </Col>
            <Col md={6} className="d-none d-lg-block">
              <Row>
                <Col md={6} className="px-md-1">
                  <Form.Group className="form-group">
                    <Form.Control
                      placeholder="Search by name or email"
                      disabled={props?.templatesOrg?.length == 0}
                      onChange={(e) =>
                        props?.getSearchedTemplates(e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="px-md-1">
                  <Form.Group>
                    <Select
                      styles={colourStyles}
                      className="c-select"
                      options={props?.status}
                      onChange={(val) => {
                        console.log("val", val);
                        props?.getStatusWiseTemplates(
                          val?.value === true
                            ? "1"
                            : val?.value === false
                            ? "0"
                            : "all"
                        );
                      }}
                      placeholder={"Filter Status"}
                      classNamePrefix="select"
                    />
                  </Form.Group>
                </Col>
                <Col md={3} className="px-md-1">
                  <Form.Group>
                    <Select
                      styles={colourStyles}
                      className="c-select"
                      options={props?.tags}
                      onChange={(val) => props?.getTagsWiseTemplates(val)}
                      placeholder={"Filter Tag"}
                      ref={props?.selectRef}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={6} lg={3} className="text-end">
              <div
                onClick={props?.handleClickAddNew}
                className="btn btn-main text-decoration-none btn-main-mob"
              >
                <i className="bi bi-plus"></i> <span>Add User</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="template-header-mob d-lg-none py-3">
        <Container>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group className="form-group">
                <Form.Control
                  placeholder="Search by name or email"
                  disabled={props?.templatesOrg?.length == 0}
                  onChange={(e) => props?.getSearchedTemplates(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={6} className="pe-1">
              <Form.Group>
                <Select
                  styles={colourStyles}
                  className="c-select"
                  options={props?.status}
                  onChange={(val) => props?.getStatusWiseTemplates(val?.value)}
                  placeholder={"Filter Status"}
                  classNamePrefix="select"
                />
              </Form.Group>
            </Col>
            <Col xs={6} className="ps-1">
              <Form.Group>
                <Select
                  styles={colourStyles}
                  className="c-select"
                  options={props?.tags}
                  onChange={(val) => props?.getTagsWiseTemplates(val?.label)}
                  placeholder={"Filter Tag"}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MangageUserHeader;
