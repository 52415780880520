import React from "react";
import Wtable from "../table/table";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Broadcastprogressbar from './b-progressbar';
//import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
// import { buildStyles } from 'react-circular-progressbar';
import "../table/table.css";

import BroadcastTablestatus from "./broadcast-status";
import Broadcastname from "./broadcast-name";
import BroadcastType from "./broadcast-type";
import DeliveryStatus from "./delivery-status";
import BroadcastAction from "./broadcast-action";
import TableSkeleton from "../skeletons/TableLoader";
import { Dropdown } from "react-bootstrap";
import BroadcastDropdown from "./broadcast-dropdown";
import { useSelector } from "react-redux";

const Broadcasttable = ({
  allBroadCastsData,
  // isListingOpen,
  pickerLoader,
  fetchAllBroadcastData,
  currentPage,
  startingDate,
  endingDate,
  broadcastType,
  searchBroadcast,
}) => {
  const { isListingOpen } = useSelector((state) => state.BroadCastsReducer);

  const theadData = [
    "Broadcast Name",
    "Created At",
    isListingOpen && "Message Delivery",
    "Audience",
    "Broadcast type",
    "Action",
  ];
  const countStatus = (broadcast) => {
    const count = {
      total: broadcast?.messages?.length,
      completed: 0,
      failed: 0,
      pending: 0,
    };
    broadcast?.messages?.forEach(({ message }) => {
      if (message?.failed) count.failed++;
      else if (message?.sent) count.completed++;
      else count.pending++;
    });

    return count;
  };
  const formatPercentage = (percentage) => {
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
  };

  function formatDate(isoDateString, includeTime = false) {
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }

  const tbodyData = allBroadCastsData?.map((data) => {
    const count = countStatus(data);
    const successValue = formatPercentage(
      (count.completed * 100) / count.total
    );
    const pendingValue = formatPercentage((count.pending * 100) / count.total);
    const failedValue = formatPercentage((count.failed * 100) / count.total);

    return {
      id: data?._id,
      items: [
        <Broadcastname
          EventName={data?.broadcastName}
          CreatedName={data?.madeBy?.firstname + " " + data?.madeBy?.lastname}
          isUpdated={data?.isUpdated}
        />,
        formatDate(data?.createdAt, false),
        isListingOpen && (
          <DeliveryStatus
            CompletedValue={successValue}
            PendingValue={pendingValue}
            FailedValue={failedValue}
          />
        ),
        data?.messages?.length,

        <BroadcastType
          className={"scheduled"}
          Typename={
            data?.state == 4
              ? "Cancelled"
              : data?.state == 0 || data?.isSchedule
              ? "Scheduled"
              : data?.state == 2 && data.isSchedule
              ? "Scheduled"
              : "Instant"
          }
          TypeDateTime={formatDate(data?.scheduleDate, true)}
        />,

        isListingOpen ? (
          <BroadcastAction Link_add={`/broadcastDetails/${data?._id}`} />
        ) : (
          <BroadcastDropdown
            data={data}
            fetchAllBroadcastData={fetchAllBroadcastData}
            currentPage={currentPage}
            startingDate={startingDate}
            endingDate={endingDate}
            broadcastType={broadcastType}
            searchBroadcast={searchBroadcast}
            isListingOpen={isListingOpen}
          />
        ),
      ],
    };
  });
  if (pickerLoader) {
    return <TableSkeleton width="100%" />;
  }
  return (
    <>
      <div className="cg-custom-table">
        <Wtable
          theadData={theadData}
          tbodyData={tbodyData}
          isBroadcastTable={true}
          isListingOpen={isListingOpen}
        />
      </div>
    </>
  );
};

export default Broadcasttable;
