import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { DatePicker, TimePicker } from "antd";
import Select from "react-select";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useDeviceWidthChecker from "../components/customHooks/DeviceWidthDetector";

import BroadcastHeader from "../components/broadcast/broadcast-header";
import Broadcasttable from "../components/broadcast/broadcast-table";
import TemplateListingFooter from "../components/template-listing/templateListing-footer";
import Search from "../components/search/search";
import BroadcastlistingMob from "../components/broadcast/Broadcast-listingMob";
import { useDispatch, useSelector } from "react-redux";
import { Box, Pagination } from "@mui/material";
import { getAllBroadcasts } from "../services/Broadcast";
import {
  setAllBroadCastsData,
  setIsListingOpen,
  setPickerLoader,
  setCurrentPage,
} from "../reduxSlice/broadcast";
import EmptyBroadcast from "./emptybroadcast";
import NoData from "../images/icons/no-data.svg";
import Broadcast_ic from "../images/broadcast/broadCast-icon.svg";

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const rangePresets = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  { label: "All", value: [] },
];

const BroadcastListing = () => {
  const width = useDeviceWidthChecker();

  const {
    allBroadCastsData,
    totalRecords,
    pickerLoader,
    defaultData,
    isListingOpen,
    currentPage,
  } = useSelector((state) => state.BroadCastsReducer);
  const dispatch = useDispatch();
  console.log("allBroadCastsData", allBroadCastsData, ":", totalRecords);
  const [rangePickerValue, setRangePickerValue] = useState(null);
  const [dateFilter, setDateFilter] = useState({ startDate: "", endDate: "" });
  const [isFirstFetch, setIsFirstFetch] = useState(false);
  const [allDataDate, setAllDataDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);
  const [broadcastType, setBroadcastType] = useState("");
  const [allBroadcastData, setAllBroadcastData] = useState([]);
  // const [isListingOpens, setIsListingOpens] = useState(true);
  const [searchBroadcast, setSearchBroadcast] = useState("");
  const [paginationData, setPaginationData] = useState({
    // totalPages: totalRecords ? totalRecords : 1,
    totalPages: 0,
    currPage: 1,
  });
  const [isDataFetched, setIsDataFetched] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const options = [
    { value: "allBroadcasts", label: "All Broadcasts" },
    { value: "instant", label: "Instant" },
    { value: "scheduled", label: "Scheduled" },
  ];

  useEffect(() => {
    // Reset RangePicker value whenever isListingOpen changes
    setRangePickerValue(null);
    setStartingDate("");
    setEndingDate("");
  }, [isListingOpen]);

  const handleSelectChange = (selected) => {
    setBroadcastType(selected);
  };

  const fetchAllBroadcastData = async (
    page = 1,
    pageSize = 20,
    state = "",
    startingDate,
    endingDate,
    type = "",
    searchKey = ""
  ) => {
    try {
      dispatch(setPickerLoader(true));

      const resp = await getAllBroadcasts(
        page,
        pageSize,
        state ? "" : "schedule",
        startingDate,
        endingDate,
        type?.value == "instant" ? 0 : type?.value == "scheduled" ? 1 : "",
        searchKey
      );
      if (resp.success) {
        setDefaultStartDate(resp?.broadcastStartDate);
        setDefaultEndDate(resp?.data[0]?.messages[0]?.message?.timestamp);
        const formatedStartDate = dayjs(resp?.broadcastStartDate).startOf(
          "day"
        );
        const formatedEndDate = dayjs(
          resp?.data[0]?.messages[0]?.message?.timestamp
        );
        setAllDataDate({
          startDate: formatedStartDate,
          endDate: formatedEndDate,
        });

        rangePresets[4].value = [
          dayjs(formatedStartDate, "YYYY-MM-DD"),
          dayjs(formatedEndDate, "YYYY-MM-DD"),
        ];
        setDefaultStartDate(formatedStartDate);
        setDefaultEndDate(formatedEndDate);
        const { data, totalRecords } = resp;

        console.log("datass", data, totalRecords, page);
        console.log("totalPage", Math.ceil(totalRecords / pageSize));
        // Append new data

        // Set broadcast data and total records
        // if (
        //   searchKey == "" &&
        //   startingDate == "" &&
        //   endingDate == "" &&
        //   !isFirstFetch
        // ) {
        //   dispatch(
        //     setAllBroadCastsData({
        //       data: data,
        //       totalRecords: totalRecords,
        //       defaultData: data,
        //     })
        //   );
        // } else {
        dispatch(
          setAllBroadCastsData({ data: data, totalRecords: totalRecords })
        );
        setIsDataFetched(true);

        // }

        // Set pagination data
        setPaginationData({
          currPage: page,
          totalPages: Math.ceil(totalRecords / pageSize),
        });

        // Set the current page
        dispatch(setCurrentPage(page));
      } else {
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log("Failed to fetch Data!", error);
    } finally {
      dispatch(setPickerLoader(false));
    }
  };

  useEffect(() => {
    fetchAllBroadcastData(
      currentPage,
      20,
      isListingOpen,
      startingDate,
      endingDate,
      broadcastType,
      searchBroadcast
    );
    dispatch(setCurrentPage(currentPage));
  }, [isListingOpen, broadcastType, searchBroadcast]);

  const onChangeDate = async (date, dateArray) => {
    setRangePickerValue(date);
    console.log(
      "dateArray",
      dateArray,
      dateArray[0] == "" && dateArray[1] == "",
      rangePresets[4]
    );
    if (!dateArray[0] && !dateArray[1]) {
      setAllDataDate({
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      });
      setDefaultStartDate(defaultStartDate);
      setDefaultEndDate(defaultEndDate);
      await fetchAllBroadcastData(
        1,
        20,
        isListingOpen,
        defaultStartDate,
        defaultEndDate,
        broadcastType,
        searchBroadcast
      );
    }
    const startDate = new Date(date[0]?.$d);
    const endDate = new Date(date[1]?.$d);
    const isoStartDate = startDate.toISOString();
    const isoEndDate = endDate.toISOString();
    endDate.setHours(23, 59, 59, 999);
    console.log("helloabove1", startDate);

    setStartingDate(startDate);
    setEndingDate(endDate);
    await fetchAllBroadcastData(
      1,
      20,
      isListingOpen,
      startDate,
      endDate,
      broadcastType,
      searchBroadcast
    );
  };

  const handlePaginationChange = (event, value) => {
    dispatch(setCurrentPage(value));

    fetchAllBroadcastData(
      value,
      20,
      isListingOpen,
      startingDate,
      endingDate,
      broadcastType,
      searchBroadcast
    );
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2, // Ensures menu appears above other elements
    }),
  };

  // const handleBroadcastUpdate = async (data) => {
  //   console.log("broadCast socket data", data);
  // };

  console.log("CurrenyPage", currentPage);

  // Handle the broadcast update socket event
  useEffect(() => {
    const handleBroadcastUpdate = (event) => {
      console.log("eventtt socket", event);
      // if (!isDataFetched && allBroadCastsData?.length <= 0) {
      //   return; // Ignore socket updates if data is not yet fetched
      // }
      const socketData = event.data; // Access data from 'detail'

      if (Array.isArray(socketData) && socketData?.length <= 0) {
        return;
      }
      const existingIndex = allBroadCastsData.findIndex(
        (item) => item._id === socketData._id
      );
      if (existingIndex !== -1) {
        // Replace the existing item
        const updatedList = [...allBroadCastsData];
        updatedList[existingIndex] = {
          ...updatedList[existingIndex],
          ...socketData,
        };

        dispatch(setAllBroadCastsData({ data: updatedList }));
      } else {
        // Prepend the new item
        const updatedList = [socketData, ...allBroadCastsData];
        dispatch(setAllBroadCastsData({ data: updatedList }));
      }
    };

    // Add the event listener
    document.addEventListener("broadcastUpdate", (data) => {
      console.log("broadcastUpdate data new", data);
      handleBroadcastUpdate(data?.detail);
    });

    // Cleanup the listener
    return () => {
      // document.removeEventListener("broadcastUpdate", () =>
      //   handleBroadcastUpdate(null)
      // );
    };
  }, [allBroadCastsData]);

  return (
    <>
      <BroadcastHeader
        Contact_title={"Broadcast"}
        Contact_search={
          <Form.Group className="input-search">
            <Form.Control
              type="text"
              placeholder="Search...."
              onChange={(e) => setSearchBroadcast(e.target.value)}
              value={searchBroadcast}
            />
          </Form.Group>
        }
        setSearchBroadcast={setSearchBroadcast}
        searchBroadcast={searchBroadcast}
      />

      <>
        <section className="broadcast-top pt-lg-3 mt-lg-0">
          <div className="container">
            <Row>
              <Col lg={5} xl={4} className="order-last order-md-first">
                <Row className="mx-lg-0">
                  <Col xs={6} className="px-lg-1">
                    <Button
                      variant={`main w-100 ${isListingOpen && "active"} `}
                      onClick={() => {
                        dispatch(setIsListingOpen(true));
                        setIsFirstFetch(true);
                        setStartingDate("");
                        setEndingDate("");
                      }}
                    >
                      Broadcast list
                    </Button>
                  </Col>
                  <Col xs={6} className="px-lg-1">
                    <Button
                      variant={`main w-100 ${!isListingOpen && "active"} `}
                      onClick={() => {
                        dispatch(setIsListingOpen(false));
                        setIsFirstFetch(true);
                        dispatch(setCurrentPage(1));

                        setBroadcastType("");
                        setStartingDate("");
                        setEndingDate("");
                      }}
                    >
                      Scheduled
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={7}
                xl={8}
                className="order-first order-md-last mb-3 mb-md-0"
              >
                <ul className="nav align-items-center gap-2 justify-content-lg-end mt-0 mt-md-3 mt-lg-0">
                  {isListingOpen && (
                    <li class="nav-item ">
                      <Form.Group>
                        <Select
                          className="c-select basic-multi-select"
                          options={options}
                          styles={customStyles}
                          placeholder={"All Broadcasts"}
                          classNamePrefix="select"
                          value={broadcastType}
                          onChange={handleSelectChange}
                          components={{
                            DropdownIndicator: () => (
                              <svg
                                width="10"
                                height="5"
                                viewBox="0 0 10 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 0L5 5L10 0H0Z" fill="#494949" />
                              </svg>
                            ),
                          }}
                        />
                      </Form.Group>
                    </li>
                  )}

                  <li class="nav-item ">
                    <Form.Group>
                      <RangePicker
                        dropdownClassName="createDateRangePicker"
                        className="dateRange custom-range-picker"
                        presets={rangePresets}
                        defaultValue={[defaultStartDate, defaultEndDate]}
                        value={rangePickerValue}
                        format={"YYYY-MM-DD"}
                        onChange={onChangeDate}
                        placeholder={[
                          "Start date(created)",
                          "End date(created)",
                        ]}
                      />
                    </Form.Group>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </section>
        {allBroadCastsData?.length <= 0 && !pickerLoader ? (
          <EmptyBroadcast />
        ) : (
          // : allBroadCastsData?.length <= 0 && !pickerLoader ? (
          //   <section className="main inner-main broadcast-sec">
          //     <section className="contact-group-table  d-lg-block">
          //       <Container>
          //         <Row>
          //           <Col xs={12}>
          //             <div
          //               className="tb-empty-content text-center "
          //               style={{
          //                 display: "flex",
          //                 justifyContent: "center",
          //                 alignItems: "center",
          //                 flexDirection: "column",
          //                 marginTop: "15px",
          //               }}
          //             >
          //               <div
          //                 style={{
          //                   display: "flex",
          //                   justifyContent: "center",
          //                   alignItems: "center",
          //                   flexDirection: "column",
          //                   width: "100%",
          //                   height: "300px",
          //                   background: "white",
          //                 }}
          //               >
          //                 <img src={Broadcast_ic} alt="icon" />
          //                 <p
          //                   style={{
          //                     fontWeight: 500,
          //                     fontSize: "20px",
          //                     marginTop: "10px",
          //                     fontFamily: "Segoe UI",
          //                     color: "#000000",
          //                   }}
          //                 >
          //                   {"Broadcast not Found"}
          //                 </p>
          //               </div>
          //             </div>
          //           </Col>
          //         </Row>
          //       </Container>
          //     </section>
          //   </section>
          // )
          <section className="main inner-main broadcast-sec">
            <section className="contact-group-table d-none d-lg-block">
              <Container>
                <Row>
                  <Col xs={12}>
                    <Broadcasttable
                      allBroadCastsData={allBroadCastsData}
                      isListingOpen={isListingOpen}
                      pickerLoader={pickerLoader}
                      fetchAllBroadcastData={fetchAllBroadcastData}
                      currentPage={currentPage}
                      startingDate={startingDate}
                      endingDate={endingDate}
                      broadcastType={broadcastType}
                      searchBroadcast={searchBroadcast}
                    />
                  </Col>
                </Row>
              </Container>
            </section>

            <section className="contact-group-table d-lg-none">
              <BroadcastlistingMob
                allBroadCastsData={allBroadCastsData}
                isListingOpen={isListingOpen}
                pickerLoader={pickerLoader}
                fetchAllBroadcastData={fetchAllBroadcastData}
                currentPage={currentPage}
                startingDate={startingDate}
                endingDate={endingDate}
                broadcastType={broadcastType}
                searchBroadcast={searchBroadcast}
              />
            </section>
            <div style={{ marginTop: "50px" }}>
              <section className="listingfooter">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags">
                        {!pickerLoader && (
                          <Pagination
                            count={paginationData.totalPages} // Calculate total pages
                            variant="outlined"
                            shape="rounded"
                            siblingCount={1}
                            page={paginationData.currPage}
                            onChange={handlePaginationChange}
                          />
                        )}
                      </Box>
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          </section>
        )}
      </>
    </>
  );
};

export default BroadcastListing;
