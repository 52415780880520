import React, { useState, useEffect, useRef } from "react";
import { Handle, NodeResizer, Position, useReactFlow } from "@xyflow/react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import "./styles.css";
import CustomHandle from "./CustomHandle";
import {
  setGLobalNodes,
  triggerAddChildToNodes,
  triggerDeleteNodeFromNodes,
  triggerupdateNodeData,
  setIsDeleteNode,
} from "../../../../reduxSlice/workflow";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const AskAQuestion = ({ id, data }) => {
  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);

  const { isData, nodes } = useSelector((state) => state.WorkflowReducer);
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([{ id: 1, value: "" }]);
  const [isSaved, setIsSaved] = useState(isData);
  const [parentHeight, setParentHeight] = useState("auto"); // Dynamic parent height
  const [popupOpen, setPopupOpen] = useState(false);
  const [screenTitle, setScreenTitle] = useState("");
  const [startNode, setStartNode] = useState(false);
  const [questionError, setQUestionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState({
    isError: false,
    questionError: "",
    answerError: "",
  });

  const parentHeightRef = useRef(null); // Reference to the parent container
  console.log("data?.nodes", data?.nodes);
  // useEffect(() => {
  //   const newQuestion = nodes?.filter((node) => node?.type == "askAQuestion");

  //   const newAnswers = nodes?.filter((node) => node?.type == "answer");
  //   setQuestion(newQuestion[0]?.data?.question);
  //   if (Array.isArray(newAnswers[0]?.data)) {
  //     // If it's an array, set the answers as the array
  //     setAnswers(newAnswers[0]?.data);
  //   } else if (newAnswers[0]?.data) {
  //     // If it's a simple object, wrap it in an array
  //     setAnswers([newAnswers[0]?.data]);
  //   }
  //   setScreenTitle(newQuestion[0]?.data?.screenTitle);
  //   setStartNode(newQuestion[0]?.data?.isStartNode);
  // }, []);

  useEffect(() => {
    // Safely access nodes array
    const currentNodes = nodes || [];

    // Find question node (use optional chaining)
    const questionNode = currentNodes.find(
      (node) => node?.type === "askAQuestion" && node?.id == id
    );
    if (questionNode) {
      setQuestion(questionNode.data?.question || "");
      setScreenTitle(questionNode.data?.screenTitle || "");
      setStartNode(questionNode.data?.isStartNode || false);
    }

    // Process answer nodes
    const answerNodes = currentNodes.filter(
      (node) => node?.type === "answer" && node?.parentId == id
    );
    if (answerNodes?.length) {
      // Remove undefined entries

      const newAnswers = answerNodes.map((node) => node?.data).filter(Boolean);
      setAnswers((prev) => {
        // Only update if answers have changed
        return JSON.stringify(prev) === JSON.stringify(newAnswers)
          ? prev
          : newAnswers;
      });

      const childHeight = 39; // Height of each child input
      const gap = 20; // Gap between children
      const textareaHeight = 191; // Height of the textarea
      const headerHeight = 0; // Height of the header
      const padding = 20; // Padding for the container

      const totalHeight =
        headerHeight +
        textareaHeight +
        newAnswers.length * (childHeight + gap) -
        gap + // Subtract the last gap
        padding * 2;

      // Update the parent div's height dynamically
      if (parentHeightRef.current) {
        parentHeightRef.current.style.height = `${totalHeight}px`;
      }
    }
  }, []); // Add nodes as dependency
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setPopupOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(popupOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && popupOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = popupOpen;
  }, [popupOpen]);

  // Add new answer field
  const handleAddField = () => {
    // if (answers.length < 4) {
    let uuid = crypto.randomUUID();
    setAnswers([...answers, { id: uuid, value: "" }]);
    // }
  };

  const handleDeleteOptionField = (optionId) => {
    const updatedFields = [...answers];
    if (updatedFields?.length > 1) {
      const remainingAnswers = updatedFields?.filter(
        (answer) => answer?.id != optionId
      );
      setAnswers(remainingAnswers);
    }
  };

  // Handle input change for answers
  const handleInputChange = (id, value) => {
    if (value.length > 30) {
      // toast.error("Character limit exceeded! Maximum 30 characters allowed.");
      // alert();
      setAnswerError((prevErrors) => ({
        ...prevErrors,
        [id]: value.length > 30 ? "Only 30 characters are allowed" : "",
      }));
      return;
    }
    setAnswerError("");

    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.id === id ? { ...answer, value } : answer
      )
    );
  };

  const handleQuestionChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length >= 80) {
      // toast.error("Character limit exceeded! Maximum 30 characters allowed.");
      setQUestionError("Only 80 characters are allowed!");
      // alert();
      return;
    }
    setQUestionError("");

    setQuestion(newValue);
    if (data && data.updateNodeData) {
      data.updateNodeData(id, { question: newValue });
    } else {
      const updatedData = { id, question: newValue };
      dispatch(triggerupdateNodeData(updatedData));
    }
  };

  const handleCancelClick = () => {
    setAnswers([{ id: 1, value: "" }]);
    setQuestion("");
    setParentHeight("auto");
  };
  const getUniqueId = () => Math.random().toString(36).substr(2, 9);
  const handleSaveClick = () => {
    if (!question) {
      toast.error("Question is required!");
      return;
    }
    const haveAllAnswersValue = answers?.every((answer) => answer.value);
    if (!haveAllAnswersValue) {
      toast.error("All answers are required!");
      return;
    }

    const childHeight = 39; // Height of each child input
    const gap = 20; // Gap between children
    const textareaHeight = 191; // Height of the textarea
    const headerHeight = 0; // Height of the header
    const padding = 20; // Padding for the container

    const totalHeight =
      headerHeight +
      textareaHeight +
      answers.length * (childHeight + gap) -
      gap + // Subtract the last gap
      padding * 2;

    // Update the parent div's height dynamically
    if (parentHeightRef.current) {
      parentHeightRef.current.style.height = `${totalHeight}px`;
    }

    if (data && data.updateNodeData) {
      data.updateNodeData(id, { answers });
    } else {
      const updatedData = { id, answers };

      dispatch(triggerupdateNodeData(updatedData));
    }

    const children = [];
    let currentY = textareaHeight + gap; // Initial vertical position for the first child
    answers.forEach((answer) => {
      const child = {
        id: getUniqueId(),
        type: "answer",
        position: {
          x: 10,
          y: currentY,
        },
        data: { id: answer.id, value: answer.value },
        parentId: id,
        extent: "parent",
        draggable: false,
        style: {
          minWidth: "249px",
          maxHeight: `${childHeight}px`,
          padding: "0",
          background: "transparent",
        },
      };

      children.push(child);
      currentY += childHeight + gap;
    });

    if (data && data.addChildToNodes) {
      data.addChildToNodes(children);
    } else {
      dispatch(triggerAddChildToNodes(children));
    }
    setIsSaved(true);
  };

  const handleEditClick = () => {
    setOpenEditModal(false);
    setPopupOpen(false);
    if (parentHeightRef.current) {
      parentHeightRef.current.style.height = `auto`;
    }
    if (data && data.deleteNode) {
      data?.deleteNode(id);
    } else {
      dispatch(triggerDeleteNodeFromNodes(id));
    }

    setIsSaved(false);
  };

  const handleDeleteClick = () => {
    dispatch(setIsDeleteNode({ isDelete: true, nodeId: id }));
    setPopupOpen(false);
  };

  const handleScreenTitle = (e) => {
    const value = e.target.value;
    setScreenTitle(value);
    if (data && data.updateNodeData) {
      data.updateNodeData(id, { screenTitle: value });
    } else {
      const updatedData = { id, screenTitle: value };

      dispatch(triggerupdateNodeData(updatedData));
    }
  };

  const handleStartClick = () => {
    setPopupOpen(false);
    setStartNode(!startNode);
    if (data && data.updateNodeData) {
      data.updateNodeData(id, {
        isStartNode: !startNode,
        isEndNode: false,
      });
    } else {
      const updatedData = {
        id,
        isStartNode: !startNode,
        isEndNode: false,
      };

      dispatch(triggerupdateNodeData(updatedData));
    }
  };
  // const handleEndClick = () => {
  //   setPopupOpen(false);
  //   if (data && data.updateNodeData) {
  //     data.updateNodeData(id, { startNode: false, endNode: true });
  //   } else {
  //     const updatedData = { id, startNode: false, endNode: true };

  //     dispatch(triggerupdateNodeData(updatedData));
  //   }
  // };

  console.log("answers", answers);
  console.log("question", question);

  return (
    <>
      <div
        className="ask-a-question"
        // style={{ height: parentHeight }} // Apply dynamic height
        ref={parentHeightRef}
      >
        <div className="header">
          {/* <h3>Ask a Question</h3> */}
          <input
            type="text"
            name=""
            id=""
            className="screen-name"
            placeholder="Add screen name..."
            onChange={handleScreenTitle}
            value={screenTitle}
          />
          {/* {popupOpen && (
            <div className="popup">
              <p onClick={handleEditClick}>Edit</p>
              <p
                // onClick={!startNode && handleStartClick}
                onClick={handleStartClick}

                // style={{
                //   cursor: startNode ? "not-allowed" : "pointer",
                //   background: startNode && "#7D7D7D33",
                //   color: startNode ? "#a8a5a5" : "black",
                // }}
                // disabled={startNode && true}
              >
                {startNode ? "Remove start node" : "Set start Node"}
              </p>
              {/* <p onClick={handleEndClick}>Set end Node</p> */}
          {/* </div>
          )} */}

          <Stack direction="row" spacing={2} style={{ maxWidth: "50px" }}>
            <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={popupOpen ? "composition-menu" : undefined}
                aria-expanded={popupOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => setPopupOpen(!popupOpen)}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-3 cursor-pointer"
                >
                  <path
                    d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                    fill="white"
                  />
                </svg>
              </Button>
              <Popper
                open={popupOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-start"
                transition
                disablePortal
                style={{ zIndex: "999" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={popupOpen}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          // onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={() => setOpenEditModal(true)}>
                            Edit
                          </MenuItem>

                          <hr style={{ color: "#7D7D7D33" }} />
                          <MenuItem onClick={handleDeleteClick}>
                            Delete
                          </MenuItem>
                          <hr style={{ color: "#7D7D7D33" }} />

                          {/* <MenuItem onClick={handleStartClick}>
                            {" "}
                            {startNode ? "Remove start node" : "Set start Node"}
                          </MenuItem> */}
                          {/* <hr style={{ color: "#7D7D7D33" }} /> */}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </div>
        {isSaved ? (
          <div className="body-content">
            <textarea
              className="question-field mt-4"
              placeholder="Type a question"
              onChange={handleQuestionChange}
              value={question}
              disabled={true}
            ></textarea>
          </div>
        ) : (
          <div className="body-content">
            <label className="label">Question</label>
            <textarea
              className="question-field"
              placeholder="Type a question"
              onChange={handleQuestionChange}
              value={question}
            ></textarea>
            <p
              style={{ color: "#DC3545", fontSize: "14px", fontWeight: "400" }}
            >
              {questionError && questionError}
            </p>

            {answers?.map((answer, idx) => (
              <>
                <label className="label">
                  {/* {idx === 0
                    ? "1st Option"
                    : idx === 1
                    ? "2nd Option"
                    : idx === 2
                    ? "3rd Option"
                    : "4th Option"} */}
                  Option {idx + 1}
                </label>
                <div key={answer.id} className="answer-field-container">
                  <input
                    type="text"
                    className="answer_field"
                    placeholder={`Option ${idx + 1}`}
                    value={answer.value}
                    onChange={(e) =>
                      handleInputChange(answer.id, e.target.value)
                    }
                  />

                  {idx == answers?.length - 1 ? (
                    <button className="btn" onClick={handleAddField}>
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: "23px", height: "23px" }}
                      >
                        <path
                          d="M10.5415 12.4584H5.74984C5.47831 12.4584 5.25071 12.3666 5.06702 12.1829C4.88334 11.9992 4.7915 11.7716 4.7915 11.5001C4.7915 11.2286 4.88334 11.0009 5.06702 10.8173C5.25071 10.6336 5.47831 10.5417 5.74984 10.5417H10.5415V5.75008C10.5415 5.47855 10.6333 5.25095 10.817 5.06727C11.0007 4.88359 11.2283 4.79175 11.4998 4.79175C11.7714 4.79175 11.999 4.88359 12.1827 5.06727C12.3663 5.25095 12.4582 5.47855 12.4582 5.75008V10.5417H17.2498C17.5214 10.5417 17.749 10.6336 17.9327 10.8173C18.1163 11.0009 18.2082 11.2286 18.2082 11.5001C18.2082 11.7716 18.1163 11.9992 17.9327 12.1829C17.749 12.3666 17.5214 12.4584 17.2498 12.4584H12.4582V17.2501C12.4582 17.5216 12.3663 17.7492 12.1827 17.9329C11.999 18.1166 11.7714 18.2084 11.4998 18.2084C11.2283 18.2084 11.0007 18.1166 10.817 17.9329C10.6333 17.7492 10.5415 17.5216 10.5415 17.2501V12.4584Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  ) : (
                    <button
                      className="btn"
                      onClick={() => handleDeleteOptionField(answer?.id)}
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.125 18.375C5.64375 18.375 5.23177 18.2036 4.88906 17.8609C4.54635 17.5182 4.375 17.1062 4.375 16.625V5.25C4.12708 5.25 3.91927 5.16615 3.75156 4.99844C3.58385 4.83073 3.5 4.62292 3.5 4.375C3.5 4.12708 3.58385 3.91927 3.75156 3.75156C3.91927 3.58385 4.12708 3.5 4.375 3.5H7.875C7.875 3.25208 7.95885 3.04427 8.12656 2.87656C8.29427 2.70885 8.50208 2.625 8.75 2.625H12.25C12.4979 2.625 12.7057 2.70885 12.8734 2.87656C13.0411 3.04427 13.125 3.25208 13.125 3.5H16.625C16.8729 3.5 17.0807 3.58385 17.2484 3.75156C17.4161 3.91927 17.5 4.12708 17.5 4.375C17.5 4.62292 17.4161 4.83073 17.2484 4.99844C17.0807 5.16615 16.8729 5.25 16.625 5.25V16.625C16.625 17.1062 16.4536 17.5182 16.1109 17.8609C15.7682 18.2036 15.3562 18.375 14.875 18.375H6.125ZM14.875 5.25H6.125V16.625H14.875V5.25ZM8.75 14.875C8.99792 14.875 9.20573 14.7911 9.37344 14.6234C9.54115 14.4557 9.625 14.2479 9.625 14V7.875C9.625 7.62708 9.54115 7.41927 9.37344 7.25156C9.20573 7.08385 8.99792 7 8.75 7C8.50208 7 8.29427 7.08385 8.12656 7.25156C7.95885 7.41927 7.875 7.62708 7.875 7.875V14C7.875 14.2479 7.95885 14.4557 8.12656 14.6234C8.29427 14.7911 8.50208 14.875 8.75 14.875ZM12.25 14.875C12.4979 14.875 12.7057 14.7911 12.8734 14.6234C13.0411 14.4557 13.125 14.2479 13.125 14V7.875C13.125 7.62708 13.0411 7.41927 12.8734 7.25156C12.7057 7.08385 12.4979 7 12.25 7C12.0021 7 11.7943 7.08385 11.6266 7.25156C11.4589 7.41927 11.375 7.62708 11.375 7.875V14C11.375 14.2479 11.4589 14.4557 11.6266 14.6234C11.7943 14.7911 12.0021 14.875 12.25 14.875Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                {answerError[answer.id] && (
                  <p
                    style={{
                      color: "#DC3545",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {answerError[answer.id]}
                  </p>
                )}
              </>
            ))}
            <div className="save-btn-container">
              <button className="btn-cancel" onClick={handleCancelClick}>
                Clear
              </button>
              <button className="btn-save" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>

      <CustomHandle type="target" position={Position.Left} />
      <Modal
        show={openEditModal}
        onHide={() => setOpenEditModal(false)}
        className="whatsapp-modal confirm-modal flow-confirm-modal"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to edit this node, connections with this node will be
            removed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          {/* {!loading ? ( */}
          <Button
            className="btn-main btn"
            onClick={() => {
              handleEditClick();
            }}
          >
            Yes, Edit
          </Button>
          {/* ) : (
            <Button variant="danger-light" style={{ width: "160px" }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red" }}
              />
            </Button> */}
          {/* )} */}

          <Button
            className="btn-main-default btn"
            onClick={() => setOpenEditModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <CustomHandle type="source" position={Position.Right} /> */}
    </>
  );
};

export default AskAQuestion;
