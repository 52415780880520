import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import WidgetHeader from "../components/widgets/WidgetHeader";
import { deleteWidget, getAllWidgets } from "../services/widget";
import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import WidgetActions from "../components/widgets/WidgetActions";
import { toast } from "react-toastify";
import TableSkeleton from "../components/skeletons/TableLoader";
import EmptyWidget from "../components/widgets/emptyWidget";
import WidgetMobileListing from "../components/widgets/WidgetMobileListing";

const WidgetListing = () => {
  const navigate = useNavigate();

  const [allWidgets, setAllWidgets] = useState([]);
  const [isGetWidgets, setIsGetWidgets] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isSuperAdmin = localStorage.getItem("superAdmin");
  const isAdmin = localStorage.getItem("companyAdmin");
  const isSimpleUser = isSuperAdmin == "false" && isAdmin == "false";
  console.log(
    "isSimpleUser",
    isSuperAdmin == "false",
    isAdmin == "false",
    ":",
    isSimpleUser
  );

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const getWidgets = async () => {
    setIsGetWidgets(true);
    const resp = await getAllWidgets();
    console.log("resp of all widgets ", resp);
    if (resp?.success) {
      const transformedData = handleDataForTable(resp?.data);
      setAllWidgets(transformedData);
    }
    setIsGetWidgets(false);
  };

  useEffect(() => {
    getWidgets();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await deleteWidget(id);
      console.log("responsedlt", response);
      if (response?.success) {
        toast.success("Widget deleted successfully!");
        getWidgets();
      } else toast.error(response?.message);
    } catch (error) {
      console.log("error", error);
      toast.error(error?.message);
    }
  };
  const getSelectedWidgetData = (widget) => {
    console.log("typeee", widget);
    if (isSimpleUser) {
      return null;
    }

    // let data = null;

    // if (type != "") {
    //   data = allWidgets?.find((item) => item?.type == type);
    // }

    const widgetData = {
      chatButton: {
        whatsAppNo: widget?.button?.phone_number,
        bgColor: widget?.button?.color,
        text: widget?.button?.btn_text,
        borderRadius: widget?.button?.radius,
        marginLeft: widget?.button?.marginBottom?.left || 20,
        marginRight: widget?.button?.margin?.right || 20,
        marginBottom: widget?.button?.margin?.bottom || 20,
        widgetName: widget?.button?.widgetName,
      },
      widgetDetails: {
        headerBgColor: widget?.widget?.color,
        title: widget?.widget?.name,
        desc: widget?.widget?.desc,
        CTAs: widget?.widget?.CTA,
      },
      id: widget?._id,
      type: widget?.type,
      position: widget?.position,
    };

    console.log("going to pass", widgetData);

    if (widget) {
      setTimeout(
        () =>
          navigate("/edit-creation", {
            state: { widgetData: widgetData, type: widget?.type },
          }),
        500
      );
    } else {
      navigate("/widget-creation", { state: { type: "utter-wa-widget" } });
      //navigate("/widget-creation")
    }
  };
  function formatDate(isoDateString, includeTime = false) {
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }
  const handleDataForTable = (data) => {
    // return []
    let finalData = [];
    for (let i = 0; i != data.length; i++) {
      finalData.push({
        key: data[i]._id,
        widgetName: (
          <span style={{ fontWeight: "600", marginLeft: "20px" }}>
            {data[i]?.button?.widgetName}
          </span>
        ),
        createdAt: formatDate(data[i]?.createdAt, false),
        action: (
          <WidgetActions
            widgetData={data[i]}
            submitDelete={handleDelete}
            fetchAllWidgets={getAllWidgets}
            name={data[i]?.widget?.name}
            getSelectedWidgetData={getSelectedWidgetData}
            isSimpleUser={isSimpleUser}
          />
        ),
      });
    }

    return finalData;
  };

  const columns = [
    {
      title: <span style={{ marginLeft: "20px" }}>Widget Name</span>,
      dataIndex: "widgetName",
      width: "33.33%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "33.33%",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "33.33%",
      align: "center",
    },
  ];

  return (
    <section className="main inner-main">
      <WidgetHeader screenWidth={screenWidth} isSimpleUser={isSimpleUser} />

      {allWidgets?.length <= 0 && !isGetWidgets ? (
        <EmptyWidget isSimpleUser={isSimpleUser} />
      ) : isGetWidgets ? (
        <TableSkeleton />
      ) : (
        <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100">
          {screenWidth >= 1023 ? (
            <Box
              className="flex justify-start align-items-start w-100 mt-[0px] rounded-md container all-tags mb-8 testing"
              sx={{
                padding: 0,
                height: "80%",
                marginBottom: "100px",
              }}
            >
              <Table
                columns={columns}
                dataSource={allWidgets} // Use full contacts data, not sliced
                pagination={
                  false // Disable changing pageSize (optional)
                }
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                }}
                //   size="middle"
              />
            </Box>
          ) : (
            <section className=" pt-0 d-lg-none w-100">
              {/* <ContactMobile contacts={contacts} /> */}
              <WidgetMobileListing data={allWidgets} />
            </section>
          )}
        </Box>
      )}
    </section>
  );
};

export default WidgetListing;
