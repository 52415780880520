import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Resetform from "../components/wahtsapp_form/form-reset";

import "../components/wahtsapp_form/form.css";
import { useState } from "react";
import { checkIfUser, handleForgotPassword } from "../services/User";

const Login = () => {
  const [userExists, setUserExists] = useState(null);
  const [emailSent, setEmailSent] = useState(null);
  const [submited, setSubmitted] = useState(false);

  const existSetter = (input) => {
    setUserExists(input);
  };

  const checkUser = async (email) => {
    setSubmitted(true);
    setEmailSent(null);
    setUserExists(null);
    const res = await checkIfUser(email);
    setUserExists(res.userFound);
    if (res.userFound) {
      const res1 = await handleForgotPassword(email);
      if (res1.emailSent) {
        setEmailSent(true);
      }
    }
    setSubmitted(false);
  };

  console.log(userExists);

  return (
    // <section className = "wahtsapp_form">
    //      <Container>
    //         <Row>
    //             <Col sm = {12}>
    //                 <Resetform heading = {'Reset Password'} checkUser={checkUser} userExists={userExists} emailSent={emailSent} submited={submited} existSetter={existSetter}/>
    //             </Col>
    //         </Row>
    //     </Container>
    // </section>
    <Resetform
      heading={"Reset Password"}
      checkUser={checkUser}
      userExists={userExists}
      emailSent={emailSent}
      submited={submited}
      existSetter={existSetter}
    />
  );
};

export default Login;
