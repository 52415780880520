import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Select from "react-select";
import moment from "moment";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import { Marker, GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
// import { debounce } from 'lodash';
import "./inbox.css";

import Userlist from "./userlist";
import { store } from "../../store";
import {
  singleMessageDTO,
  templateDTO,
  singleChatDTO,
  chatsReduxDTO,
} from "./dtos";
import {
  getAllCompanyUsers,
  readChat,
  readNotification,
  unreadNotificationCount,
} from "../../services/User";
import config from "../../config.js";
import {
  setConversationsData,
  removeAllConversationsData,
  setChatsData,
  removeAllChatsData,
  addNewMessageToChat,
  updateUnReadCountOfAnyConversation,
  setCurrConversationsData,
  removeCurrConversationsData,
  setCreateConversation,
  removeConversation,
  setConversationWithAgents,
  setAllTags,
  setCursor1,
  setTagOptions,
} from "../../reduxSlice/chats";
import { UrlPhoneNumber } from "../template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../template-preview/QuickReplyPreview";
import { AuthTemplate } from "../template-preview/AutheTemplate";
import {
  isDateTimeGreaterThanCurrent,
  generateColorHash,
  sortAndGroupMessages,
  getFileTypeFromUrl,
  getFileSize,
  isGreaterThanOrEqualToOneMinute,
  addHoursToCurrentDate,
} from "../../utils/utils";
import { getUserDetails } from "../../services/User";
import SelectTemplateModal from "./SelectTemplateModal";
import {
  getInbox,
  createChat,
  markMessageAsRead,
  getSingleChat,
  sendChatMessage,
  deleteChat,
  assignTagToChat,
  assignChatToUser,
  blockChat,
  muteChat,
  deleteSingleMsg,
  editSingleMsg,
} from "../../services/Inbox";
import {
  getAllContacts,
  addMultipleContacts,
  addContact,
  searchContact,
} from "../../services/Contacts";
import { sendTemplate } from "../../services/WhatsappAPI";
import {
  setMessageFailed,
  setMessageStatus,
  setUnreadCount,
} from "../../reduxSlice/messageNotificationSlice";
import {
  getAllNotesOfUser,
  addNewNoteForUser,
  updateNoteOfAUser,
  deleteNoteOfAUser,
} from "../../services/notes";

import crossIcon from "../../images/icons/cross-icon.png";
import Closeic from "../../images/icons/close-ic.svg";
import docViewIcon from "../../images/icons/doc-view-icon.svg";
import selVideoIcon from "../../images/icons/selected-video.svg";
import BackArrow from "../../images/back-arrow.svg";

import ContactIcon from "../../images/contact-icon.svg";
import UploadIcon from "../../images/upload-icon.svg";
import InvalidIcon from "../../images/invalid-contact.svg";

import Send_icon from "../../images/icons/send_ic.svg";
import Hajira_img from "../../images/hajira.png";
import dummyImage from "../../images/hajira.png";
import Muzamil_img from "../../images/muzumil.png";
import Rabbia_img from "../../images/rabbia.png";
import Chat_img from "../../images/chat-img.png";
import AgentIcon from "../../images/agent-user-icon.svg";
import DeleteIcon from "../../images/delete-icon.svg";
import AudioIcon from "../../images/audio-icon.svg";
import Broadcast_ic from "../../images/broadcast/broadCast-icon.svg";

import Groupcontact from "../group-table/groupcontact";
import AssignContact from "./Assign-contact";
import ChatAccordion from "./chat-accordion";
import UserBasicInfo from "./basic-info";
import pdfIcon from "../../images/icons/pdf-icon.svg";
import phoneIcon from "../../images/icons/phone-icon.png";
import UserTags from "./usertags";
import UserNotes from "./usernotes";
import { toast } from "react-toastify";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Mic_icon from "../../images/mic.svg";
import Doc_file from "../../images/doc-file.svg";
import Pdf_file from "../../images/pdf-file.svg";
import Ppt_file from "../../images/ppt-file.svg";
import Xls_file from "../../images/xls-file.svg";
import Text_file from "../../images/text-file.svg";
import TemplateIcon from "../../images/template-preview-icon.svg";
import ChatNoFound from "../../images/chat-no-found-icon.svg";
import { countryCodeList } from "../../utils/countryCodeList";
import Papa from "papaparse";
import Modaltable from "./ContactTable.jsx";
import { getAllGroups } from "../../services/Group";
import MicRecorder from "mic-recorder-to-mp3";
import { ReactMic } from "react-mic";
import ReactAudioPlayer from "react-audio-player";
import {
  setAllInboxContacts,
  setContactsData,
} from "../../reduxSlice/contacts";
import SelectFlowModal from "./SelectFlowModal";
import { sendFlow } from "../../services/workflow";

const baseUrl = `${config.backend}tags`;

interface Contact {
  Name: string;
  Number: string;
  id: string;
}

const GOOGLE_API_KEY = "AIzaSyASVOh0zf-dgKxPOr4e796luwQzCT150NY";
const libraries = ["places"];

const Inboxchat = () => {
  const {
    allConversations: storedConversations,
    allChatsData,
    currentConversation,
    allTags,
    cursor1,
    tagOptions,
  } = useSelector((state: chatsReduxDTO) => state.ChatsReducer);
  const { messageStatus, messageFailed } = useSelector(
    (state) => state.MessageNotification
  );
  const { newMessage, unReadChats, notificationDetail } = useSelector(
    (state) => state.MessageNotification
  );

  const { allInboxContacts } = useSelector((state) => state.ContactsReducer);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  console.log("location", location);
  const dispatch = useDispatch();
  console.log("unReadChats", unReadChats);
  const [selChatData, setSelChatData] = useState({
    isGetMsgs: false,
    _id: "",
    messages: [],
    contact: null,
    tagsAssigned: [],
    chatAssignee: null,
    notes: [],
    generalInfo: {
      phoneNo: "",
      conversationStatus: "",
      remaining: "",
    },
    block: false,
  });
  const { id } = useParams();
  const [newChatId, setNewChatId] = useState("");
  const [block, setBlock] = useState(false);
  const [isFetchMsg, setIsFetchMsg] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currChatMsgsLength, setCurrMsgsLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isGetAllConvers, setIsGetAllConvers] = useState(false);
  const [isGetSingleChat, setIsGetSingleChat] = useState(false);
  const [dtshow, setDtShow] = useState(false);
  const [blockShow, setBlockShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedMenuId, setOpenedMenuId] = useState(null);
  const open = Boolean(anchorEl);
  const [inputValue, setInputValue] = useState("");
  const [isPhotoClicked, setIsPhotoClicked] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [sending, setSending] = useState(false);
  const [camshow, setCamshow] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [isUpdateNote, setIsNoteUpdate] = useState(false);
  const [isAddNewNote, setIsAddNewNote] = useState(false);
  const [isCamPerGranted, setIsCamPerGranted] = useState(false);
  const [isShowMsgDelPopup, setIsShowDelMsgPopup] = useState(false);
  const [isMsgDelForEveryOne, setIsMsgDelForEveryOne] = useState(false);
  const [isShowEditMsgPopup, setIsShowEditMsgPopup] = useState(false);
  const [isShowRepliedSection, setIsShowRepliedSection] = useState(false);
  const [isMsgEditForEveryOne, setIsMsgEditForEveryOne] = useState(false);
  const [selMsgForOpr, setSelMsgForOpr] = useState(false);
  const [editMsgText, setEditMsgText] = useState("");
  const [isShowCamPermisPopup, setIsShowCamPermisPopup] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isNoteAdding, setIsNoteAdding] = useState(false);
  const [isNoteEditing, setIsNoteEditing] = useState(false);
  const [isShowDeleteNote, setIsShowDeleteNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isDeleteingNote, setIsDeletingNote] = useState(false);
  const [isTagFetching, setIsTagsFetching] = useState(false);
  const [allCompanyUsers, setAllCompanyUsers] = useState([]);
  const [selTagType, setSelTagType] = useState({ label: "", value: "" });
  const [isCompanyUsersFetch, setIsCompanyUsersFetch] = useState(false);
  const [isNewTagAssigning, setIsNewTagAssigning] = useState(false);
  const [isChatAssigning, setIsChatAssigning] = useState(false);
  const [newUserToBeAssigned, setNewUserToBeAssigned] = useState([]);
  // const [allTags, setAllTags] = useState([])
  // const [tagOptions, setTagOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showMessage, setMessage] = useState();
  const [cgtshow, setCgtShow] = useState(false);
  const [currUserData, setCurrUserData] = useState(null);
  const [variable, setVariable] = useState([]);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [activeTab, setActiveTab] = useState("tab1"); // State to manage active tab
  const [atsshow, setAtsShow] = useState(false);
  const [contactSelected, setContactSelected] = useState<Contact | null>(null);
  const [isContactSelected, setIsContactSelected] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);

  const [contacts, setContacts] = useState([]);
  const [isShowTempModal, setIsShowTempModal] = useState(false);
  const [isShowFlowModal, setIsShowFlowModal] = useState(false);
  const [ctshow, setCtShow] = useState(false);
  const [muteshow, setMuteShow] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState<
    string | undefined
  >(undefined);
  const [muteTime, setMuteTime] = useState(null);
  const [allContacts, setAllContacts] = useState([]);
  const [mainInputSearch, setMainInputSearch] = useState("");
  const [isMainInputSearchTriggered, setIsMainInputSearchTriggered] =
    useState(false);
  const webcamRef = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const chatRef = useRef(null);
  const userId = localStorage.getItem("userId");
  console.log("userId ; ", userId);
  const companyId = localStorage.getItem("companyId");
  const [selectedContact, setSelectedContact] = useState(null);
  const [updateContacts, setUpdateContacts] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [docshow, setDocShow] = useState(false);
  const [imageshow, setImageShow] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAssignedToMe, setIsShowAssignedToMe] = useState(false);
  const ImageInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0); // Store the audio duration
  const audioRef = useRef(null);
  const [inputErrors, setInputErrors] = useState({
    contactName: { hasError: false, errorMessage: "" },
    number: { hasError: false, errorMessage: "" },
  });
  const [imageLink, setImageLink] = useState("");
  const [imgShow, setImgShow] = useState(false);

  const [isNotesFetching, setIsNotesFetching] = useState(false);
  const [fileName, setFileName] = useState("");
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [error, setError] = useState("");
  const [cvsshow, setCvsShow] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [contactsGroup, setContactsGroup] = useState([]);
  const inputRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [groups, setGroups] = useState([]);
  const [hasResponseArrived, setHasResponseArrived] = useState(false);
  const [chatTag, setChatTag] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  console.log("newCHatID", newChatId);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [inValidContacs, setAllInValidContacts] = useState([]);
  const [isInvalidRecords, setIsInvalidRecords] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [fileData, setFileData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [invalidRecordsLength, setInvalidRecordsLength] = useState(0);
  const numericRegex = /^[0-9]+$/;
  // const nameRegex = /^[A-Za-z0-9]+(?:[-' ][A-Za-z0-9]+)*$/;
  const nameRegex = /^(?!\s*$).+/;
  const [isAdding, setIsAdding] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [contactsLoading, setContactsLoading] = useState(false);

  const listRef = useRef(null);

  const scrollContainerRef = useRef(null); // Ref for the scrollable container

  const [usersLoading, setUsersLoading] = useState(false);
  // const [cursor1,setCursor1] = useState("")
  const [searchLoading, setSearchLoading] = useState(false);
  const [contactPage, setContactPage] = useState(1);
  const [hasMoreContacts, setHasMoreContacts] = useState(true);
  const [isContactsFetching, setIsContactsFetching] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  console.log("itemsssss", cursor1);

  console.log("cursor1", cursor1);
  console.log("selectedFlow", selectedFlow);
  // Utility function to merge multiple refs
  const mergeRefs =
    (...refs) =>
    (element) => {
      refs.forEach((ref) => {
        if (typeof ref === "function") {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      });
    };

  useEffect(() => {
    if (!isInvalidRecords) {
      setIsAdding(false);
      setFileData(null);
      setFileName("");
    }
  }, [isInvalidRecords]);

  useEffect(() => {
    if (!cvsshow) {
      setContactsGroup([]);
    }
  }, [cvsshow]);

  const setAllContactsData = (contactsData, records = totalRecords) => {
    console.log("data of contacts got :", contactsData);

    // Set original data (raw contacts)

    // Transform the data for the table and update contacts
    // const transformedData = handleDataForTable(contactsData);
    setContacts(transformedData);

    // Dispatch the contacts data along with total records to Redux store
    dispatch(setContactsData({ data: contactsData, totalRecords: records }));
  };
  useEffect(() => {
    if (!cvsshow) setFileUploaded(false);
  }, [cvsshow]);

  const handleCloseCsv = () => {
    setContactsGroup([]);
    setSelectedContacts([]);
    setDuplicateNumbers([]);
    setCtShow(false);
  };

  const downloadSampleCSV = () => {
    // Define CSV content with headers "name" and "number"
    const csvContent = "name,number\n" + "Contact name,971661111111\n"; // You can add more rows here if needed

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "sample.csv"); // The downloaded file will be named 'sample.csv'

    // Trigger the download by clicking the link programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM by removing the link element
    document.body.removeChild(link);
  };

  const handleAddContacts = async (e) => {
    e?.preventDefault();

    if (!fileData) {
      setCsvError(true);
      return;
    }

    setIsAdding(true);
    let contacts = [],
      inValidContacts = [];

    // Clear previous states
    setContactsGroup([]);
    setIsInvalidRecords(false);
    setAllInValidContacts([]);

    // Parse the CSV file
    Papa.parse(fileData, {
      header: false,
      skipEmptyLines: true,
      complete: function (results) {
        if (
          !results.data[0][0] ||
          !results.data[0][1] ||
          results.data[0]?.length < 2 ||
          results.data[0][0] !== "name" ||
          results.data[0][1] !== "number"
        ) {
          toast.error("The headers are incorrect or missing");
          setIsAdding(false);
          return;
        }

        results.data.slice(1).forEach((item) => {
          let name = item[0];
          let number = item[1];
          if (!name?.trim() && !number?.trim()) {
            return; // Skip empty rows
          }
          let element = {
            contactName: name,
            number,
            invalid: false,
          };

          // Validate name and number
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName)
          ) {
            element.invalid = true;
          }

          // Country code validation
          const countryNumberDetail = countryCodeList.filter((code) => {
            return number.startsWith(code.phone);
          });

          if (
            countryNumberDetail.length === 0 ||
            number.length !==
              countryNumberDetail[0].phone.length +
                countryNumberDetail[0].phoneLength
          ) {
            element.invalid = true;
          }

          // Separate valid and invalid contacts
          if (element.invalid) {
            inValidContacts.push(element);
          } else {
            contacts.push(element);
          }
        });
        setAllInValidContacts(inValidContacts);
        setIsInvalidRecords(inValidContacts.length > 0);
        setInvalidRecordsLength(inValidContacts.length);

        if (inValidContacts.length > 0) {
          setCvsShow(false);
          return;
        }

        setContactsGroup(contacts); // This will trigger the useEffect below
      },
    });

    setIsAdding(false);
  };

  // Add useEffect to trigger API call when contactsGroup is updated
  useEffect(() => {
    const addContacts = async () => {
      if (contactsGroup.length > 0) {
        setIsAdding(true);

        const allNonEmptyRecords = contactsGroup.filter(
          (item) => item.contactName.trim() !== "" && item.number !== 0
        );

        try {
          const resp = await addMultipleContacts({
            contacts: allNonEmptyRecords,
            groups: selectedGroups,
          });

          if (resp.success) {
            setFileData(null);
            setFileName("");
            setIsInvalidRecords(false);
            setCvsShow(false);
            handleClose();
            setContacts((prevContact) => [
              ...resp.data.inserted,
              ...prevContact,
            ]);
            fetchAllContacts();
            setUpdateContacts((prev) => !prev);
            toast.success(
              `${
                resp.data.inserted.length + resp.data.updateContacts.length
              } new contacts added & ${
                resp.data.duplicateNumbers.length
              } duplicate contacts found`
            );
          } else {
            throw new Error(resp.error);
          }
        } catch (error) {
          toast.error(
            "Something went wrong. Please fix contacts in the CSV file."
          );
          console.log(error.message);
        } finally {
          setIsAdding(false);
        }
      }
    };

    addContacts();
  }, [contactsGroup]);
  const changeHandler = (event) => {
    const file = event.target.files[0];
    console.log("renderrrrrr", event.target.files[0]);
    console.log(
      "invalidRecordsss",
      invalidRecordsLength,
      "::",
      isInvalidRecords
    );

    if (file) {
      setCsvError(false);
    }
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      toast.error("Please upload a CSV file.");
      return;
    }
    setFileData(file);
    setFileName(file.name);
  };

  const handleTryAgain = () => {
    setCvsShow(true);
    setIsInvalidRecords(false);
    setFileName("");
    setFileData(null);
    setIsAdding(false);
    setAllInValidContacts([]);
    setInvalidRecordsLength(0);
  };

  // Function to handle dropdown toggle
  const handleToggle = (itemId) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === itemId ? null : itemId
    );
  };

  useEffect(() => {
    const handleNotification = async () => {
      if (
        state &&
        state.notification &&
        state.notification.webpush &&
        state.notification.webpush.data &&
        state.notification.webpush.data.chatId
      ) {
        const isUserExists = finalConversations?.filter(
          (conver) => conver?._id == state.notification.webpush.data.chatId
        );
        console.log("isUserExists", isUserExists);
        if (isUserExists?.length > 0) {
          const internalId = state.notification.webpush.data.chatId;
          if (internalId) {
            console.log("coming in if", internalId);
            setNewChatId(internalId);
            getSingleConversData(internalId);
            setTimeout(() => {
              const element = document.getElementById(internalId);
              console.log("element", element);
              if (element && scrollContainerRef.current) {
                scrollContainerRef.current.scrollTo({
                  top: element.offsetTop - scrollContainerRef.current.offsetTop,
                  behavior: "smooth",
                });
              }
            }, 100);
          }

          // else {
          //     console.log("coming in else",id)

          //     setNewChatId(id);
          //     getSingleConversData(id);
          // }
        } else {
          let chatIdFound = false;
          let cursor = cursor1; // Initial cursor value
          let response;
          while (cursor && cursor !== "end" && !chatIdFound) {
            try {
              setUsersLoading(true); // Set loading state
              response = await getInbox(cursor); // Call your API
              dispatch(setConversationsData(response?.data));

              // Check if response has the chatId
              if (
                response?.data?.some(
                  (item) => item._id === state.notification.webpush.data.chatId
                )
              ) {
                chatIdFound = true;
                break; // Exit the loop if chatId is found
              }

              // If cursor exists in response, update it for the next API call
              if (response?.cursor != "end") {
                cursor = response.cursor;
              } else {
                cursor = "end"; // Stop if there's no cursor for the next page
              }
            } catch (error) {
              console.error("Error fetching inbox data:", error);
              cursor = "end"; // End the loop on error
            } finally {
              setUsersLoading(false); // Stop loading state
            }
          }
          if (chatIdFound) {
            const internalId = state.notification.webpush.data.chatId;
            setNewChatId(internalId);
            await getSingleConversData(internalId);

            setTimeout(() => {
              const element = document.getElementById(internalId);
              console.log("element", element);
              if (element && scrollContainerRef.current) {
                scrollContainerRef.current.scrollTo({
                  top: element.offsetTop - scrollContainerRef.current.offsetTop,
                  behavior: "smooth",
                });
                // element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            }, 1000);
            // setTimeout(() => {
            //   const element = document.getElementById(internalId);
            //   console.log("element", element);
            //   if (element && scrollContainerRef.current) {
            //     scrollContainerRef.current.scrollTo({
            //       top: element.offsetTop - scrollContainerRef.current.offsetTop,
            //       behavior: "smooth",
            //     });
            //     // element.scrollIntoView({ behavior: "smooth", block: "start" });
            //   }
            // }, 100);

            //     const debouncedHandleScroll = debounce( scrollToChat, 100);

            //    debouncedHandleScroll(internalId)
          }
        }
        // else{
        //     setNewChatId(finalConversations[0]?._id);
        //     getSingleConversData(finalConversations[0]?._id);
        // }

        // Clear the state after it's been used
        navigate(location.pathname, { replace: true, state: null });
      } else if (id) {
        console.log("coming", id);
        await readChat({ inboxId: id });

        const unReadNotification = await unreadNotificationCount();
        dispatch(setUnreadCount(unReadNotification?.unreadMessage));
        if (!isGetSingleChat) {
          setNewChatId(id);
          await getSingleConversData(id);
        }
      }
    };
    // Call the async function inside useEffect
    handleNotification();
  }, [
    state,
    navigate,
    location.pathname,
    newChatId,
    cursor1,
    storedConversations,
  ]);
  console.log("idddddd", newChatId);

  const containerStyleForPreview = {
    width: "100%",
    height: "150px",
  };

  const options = {
    mapTypeControl: false, // Disable Map and Satellite options
    streetViewControl: false, // Disable Pegman (Street View)
    zoomControl: false, // Disable Zoom in/out controls
    fullscreenControl: false, // Disable Fullscreen control
  };

  const { isLoaded: isGoogleLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
    let isFound =
      allTags &&
      allTags?.length > 0 &&
      allTags.find(
        (item) =>
          item?._id ==
          storedConversations.find((item) => item?._id == selChatData?._id)?.tag
      );
    if (isFound) {
      setChatTag(isFound?.title);
    }
  }, [selChatData, storedConversations]);

  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const checkMicrophonePermission = async () => {
    try {
      // Check for microphone permission
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true; // Permission granted
    } catch (err) {
      console.error("Microphone permission denied", err);
      return false; // Permission denied
    }
  };

  //   const handleFileChange = (event) => {
  //       const file = event.target.files[0];
  //       if (file) {
  //           if (file.size > 16 * 1024 * 1024) { // 16 MB in bytes
  //               setError('File size should be up to 16 MB');
  //               setFileName('');
  //           } else {
  //               setError('');
  //               setFileName(file.name);
  //           }
  //       }
  //   };

  const fetchGroupsData = async () => {
    try {
      // setLoading(true)
      const resp = await getAllGroups(1, 1000);
      const groupData = [];
      if (resp.success) {
        resp.data.map((data) => {
          groupData.push({ value: data._id, label: data.groupName });
        });
      }
      // if (resp.error == "Invalid token" || resp.error == "jwt malformed") {
      //     localStorage.removeItem('token')
      //     localStorage.removeItem('userId')
      //     navigate('/')
      // }
      setGroups(groupData);
    } catch (error) {
      console.log("Failed to fetch Groups!");
    }
  };

  useEffect(() => {
    if (!ctshow) {
      setNewContactData({
        contactName: "",
        number: "",
        canMessage: true,
        canBroadcast: true,
      });
      setInputErrors({
        contactName: { hasError: false, errorMessage: "" },
        number: { hasError: false, errorMessage: "" },
      });
    }
  }, [ctshow]);

  // update status of message if read
  const handleMsgReadStatusChange = (messageStatus) => {
    console.log("msg update cougth", messageStatus);
    if (!messageStatus) return;
    const data = messageStatus;
    if (data) {
      // if (data?.contactId && data?.contactId === chatInfo?.contact?._id) {
      console.log("data got ", data);
      if (data?.contactId) {
        const allData = store.getState();
        const { allConversations, allChatsData, currentConversation } =
          allData?.ChatsReducer;

        let allChats = [...allChatsData];
        let matchedChat = allChats.findIndex(
          (item) => item?.contact?._id == data?.contactId
        );
        console.log("matchedChat ", matchedChat);

        if (matchedChat > -1) {
          let allMsgs = [...allChats[matchedChat]?.messages];
          let matchedMsg = allMsgs.findIndex(
            (item) => item?._id == data.messageId
          );

          let fStatus = "";
          if (matchedMsg > -1) {
            if (data.status == "delivered") {
              allMsgs[matchedMsg] = {
                ...allMsgs[matchedMsg],
                delivered: data.timestamp,
              };
              fStatus = "delivered";
            }
            if (data.status == "sent") {
              allMsgs[matchedMsg] = {
                ...allMsgs[matchedMsg],
                sent: data.timestamp,
              };
              fStatus = "sent";
            }
            if (data.status == "read") {
              allMsgs[matchedMsg] = {
                ...allMsgs[matchedMsg],
                read: data.timestamp,
              };
              fStatus = "read";
            }

            // if same chat
            const sortedMessages = sortAndGroupMessages(allMsgs);
            if (data?.contactId === currentConversation?.contact?._id) {
              console.log("same chat matched", sortedMessages);
              setSelChatData((prev) => ({ ...prev, messages: sortedMessages }));
              dispatch(
                setCurrConversationsData({
                  isGetMsgs: false,
                  _id: currentConversation?._id,
                  messages: sortedMessages,
                  tagsAssigned: currentConversation?.tag,
                  chatAssignee: null,
                  notes: currentConversation?.notes,
                  contact: currentConversation?.contact,
                  generalInfo: {
                    phoneNo: currentConversation?.contact?.number,
                    conversationStatus:
                      isDateTimeGreaterThanCurrent(
                        currentConversation?.conversationExpiry
                      ) == true
                        ? "Active"
                        : "Inactive",
                  },
                  block: currentConversation?.block,
                })
              );
            }

            // updating in chat list
            let allConsv = [...allConversations];
            let isInConv = allConsv.findIndex(
              (item) => item?.contact?._id == data?.contactId
            );
            console.log(
              "matched index of chat",
              isInConv,
              "::",
              allConsv[isInConv].messages
            );
            if (isInConv > -1) {
              if (allConsv[isInConv].messages?.length > 0) {
                console.log(
                  "matched length",
                  allConsv[isInConv].messages[0]?._id,
                  ":::",
                  data?.messageId
                );
                if (allConsv[isInConv].messages[0]?._id == data?.messageId) {
                  let newObj = { ...allConsv[isInConv]?.messages };
                  newObj[0] = { ...newObj[0], [fStatus]: data.timestamp };
                  let newChat = { ...allConsv[isInConv] };
                  newChat.messages = [];
                  newChat.messages.push(newObj[0]);
                  allConsv[isInConv] = newChat;
                  console.log("final status ", allConsv);
                  let sortedData = sortConversations([...allConsv]);
                  dispatch(setConversationsData(sortedData));
                }
              }
            }

            dispatch(
              setChatsData({
                conversationId: allChats[matchedChat]?.conversationId,
                id: allChats[matchedChat]?.conversationId,
                messages: sortedMessages,
                tags: allChats[matchedChat]?.tags,
                contact: allChats[matchedChat]?.contact,
                generalInfo: {
                  phoneNo: allChats[matchedChat]?.generalInfo?.contact?.number,
                  conversationStatus:
                    isDateTimeGreaterThanCurrent(
                      allChats[matchedChat]?.generalInfo?.conversationExpiry
                    ) == true
                      ? "Active"
                      : "Inactive",
                  remaining:
                    allChats[matchedChat]?.generalInfo?.conversationExpiry,
                },
              })
            );
          }
        }
      }
      dispatch(setMessageStatus(null));
    }
  };

  // event listener msg status changed
  useEffect(() => {
    document.addEventListener("msgReadStatusChanged", (data) =>
      handleMsgReadStatusChange(data?.detail?.msgData)
    );

    return () => {
      document.removeEventListener("msgReadStatusChanged", () => {});
    };
  }, []);

  // update status of message if failed
  const handleMsgFailedStatusChange = (messageStatus) => {
    console.log("msg update cougth", messageStatus);
    if (!messageStatus) return;
    const data = messageStatus;
    if (data) {
      // if (data?.contactId && data?.contactId === chatInfo?.contact?._id) {
      console.log("data got ", data);
      if (data?.contactId) {
        const allData = store.getState();
        const { allConversations, allChatsData, currentConversation } =
          allData?.ChatsReducer;

        let allChats = [...allChatsData];
        let matchedChat = allChats.findIndex(
          (item) => item?.contact?._id == data?.contactId
        );
        console.log("matchedChat ", matchedChat);

        if (matchedChat > -1) {
          let allMsgs = [...allChats[matchedChat]?.messages];
          let matchedMsg = allMsgs.findIndex(
            (item) => item?._id == data.messageId
          );

          let fStatus = "";
          if (matchedMsg > -1) {
            allMsgs[matchedMsg] = {
              ...allMsgs[matchedMsg],
              failed: data.timestamp,
            };
            fStatus = "failed";

            // if same chat
            const sortedMessages = sortAndGroupMessages(allMsgs);
            if (data?.contactId === currentConversation?.contact?._id) {
              console.log("same chat matched");
              setSelChatData((prev) => ({ ...prev, messages: sortedMessages }));
              dispatch(
                setCurrConversationsData({
                  isGetMsgs: false,
                  _id: selChatData?._id,
                  messages: sortedMessages,
                  tagsAssigned: currentConversation?.tag,
                  chatAssignee: null,
                  notes: currentConversation?.notes,
                  contact: currentConversation?.contact,
                  generalInfo: {
                    phoneNo: currentConversation?.contact?.number,
                    conversationStatus:
                      isDateTimeGreaterThanCurrent(
                        currentConversation?.conversationExpiry
                      ) == true
                        ? "Active"
                        : "Inactive",
                  },
                  block: currentConversation?.block,
                })
              );
            }

            // updating in chat list
            let allConsv = [...allConversations];
            let isInConv = allConsv.findIndex(
              (item) => item?.contact?._id == data?.contactId
            );
            console.log(
              "matched index of chat",
              isInConv,
              "::",
              allConsv[isInConv].messages
            );
            if (isInConv > -1) {
              if (allConsv[isInConv].messages?.length > 0) {
                console.log(
                  "matched length",
                  allConsv[isInConv].messages[0]?._id,
                  ":::",
                  data?.messageId
                );
                if (allConsv[isInConv].messages[0]?._id == data?.messageId) {
                  let newObj = { ...allConsv[isInConv]?.messages };
                  newObj[0] = { ...newObj[0], [fStatus]: data.timestamp };
                  let newChat = { ...allConsv[isInConv] };
                  newChat.messages = [];
                  newChat.messages.push(newObj[0]);
                  allConsv[isInConv] = newChat;
                  console.log("final status ", allConsv);
                  let sortedData = sortConversations([...allConsv]);
                  dispatch(setConversationsData(sortedData));
                }
              }
            }

            dispatch(
              setChatsData({
                conversationId: allChats[matchedChat]?.conversationId,
                id: allChats[matchedChat]?.conversationId,
                messages: sortedMessages,
                tags: allChats[matchedChat]?.tags,
                contact: allChats[matchedChat]?.contact,
                generalInfo: {
                  phoneNo: allChats[matchedChat]?.generalInfo?.contact?.number,
                  conversationStatus:
                    isDateTimeGreaterThanCurrent(
                      allChats[matchedChat]?.generalInfo?.conversationExpiry
                    ) == true
                      ? "Active"
                      : "Inactive",
                  remaining:
                    allChats[matchedChat]?.generalInfo?.conversationExpiry,
                },
              })
            );
          }
        }
      }
      dispatch(setMessageStatus(null));
    }
  };

  // event listener msg status failed
  useEffect(() => {
    document.addEventListener("msgFailedStatusChanged", (data) =>
      handleMsgFailedStatusChange(data?.detail?.msgData)
    );

    return () => {
      document.removeEventListener("msgFailedStatusChanged", () => {});
    };
  }, []);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    console.log("uploadedFile", uploadedFile);
    if (uploadedFile) {
      setFile({
        name: uploadedFile.name,
        size: (uploadedFile.size / 1024).toFixed(2), // size in KB
        type: uploadedFile.type,
      });
      setSelectedFile(uploadedFile);
    }
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results);
        setFileUploaded(true);
        let contacts = [];
        results.data.map(({ name, number }) => {
          let element = {
            contactName: name,
            number: number,
            invalid: false,
          };
          const num = element.number.toString();
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName)
          ) {
            element.invalid = true;
          }
          const countryNumberDetail = countryCodeList.filter((code) => {
            if (num.startsWith(code.phone)) return code;
          });
          if (countryNumberDetail.length === 0) element.invalid = true;
          else {
            const totalLength =
              countryNumberDetail[0].phone.length +
              countryNumberDetail[0].phoneLength;
            if (countryNumberDetail.length > 0) {
              if (num.length !== totalLength) {
                element.invalid = true;
              }
            }
          }
          contacts.push(element);
        });

        // setContactsGroup(contacts);
      },
    });
  };

  const handleContactInputChange = (event) => {
    const { name, value } = event.target;
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: { hasError: false, errorMessage: "" },
    }));
    setNewContactData((prevValues) => ({ ...prevValues, [name]: value }));

    setSelectedContact((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleClose = () => {
    setContactsGroup([]);
    // setInserted([]);
    setSelectedContacts([]);
    setNewContactData({});
    setInputErrors({});
    // setDuplicateNumbers([]);
    // setDuplicateNames([]);
    setCtShow(false);
  };

  const isPhoneNumValid = () => {
    const num = newContactData.number;
    const name = newContactData.contactName;
    if (!nameRegex.test(name)) {
      setInputErrors((prevState) => ({
        ...prevState,
        contactName: {
          ...prevState.contactName,
          hasError: true,
          errorMessage: "Enter a valid name",
        },
      }));
      return false;
    } else if (!numericRegex.test(num)) {
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: "Enter a valid contact number",
        },
      }));
      return false;
    } else {
      const countryNumberDetail = countryCodeList.filter((code) => {
        if (num.startsWith(code.phone)) return code;
      });
      const totalLength =
        countryNumberDetail[0].phone.length +
        countryNumberDetail[0].phoneLength;
      if (countryNumberDetail.length > 0) {
        if (num.length === totalLength) {
          return true;
        }
      }
      setInputErrors((prevState) => ({
        ...prevState,
        number: {
          ...prevState.number,
          hasError: true,
          errorMessage: `Phone number length for ${countryNumberDetail[0].label} is ${totalLength} `,
        },
      }));
      return false;
    }
  };
  const handleClick = () => {
    inputRef.current.click();
  };

  //  Add contacts
  const handleAddContact = async (e) => {
    e?.preventDefault();
    console.log("true");

    if (selectedContacts.length > 0) {
      console.log("true dewd");
      try {
        for (let index = 0; index < selectedContacts.length; index++) {
          const element = selectedContacts[index];
          if (
            !numericRegex.test(element.number) ||
            !nameRegex.test(element.contactName) ||
            element.invalid
          ) {
            // toast.error(`Invalid contact ${element.contactName} | ${element.number} `)
            toast.error(
              "Please address the highlighted contacts or deselect them to proceed."
            );
            return;
          }
        }
        const resp = await addMultipleContacts({
          contacts: selectedContacts,
          groups: selectedGroups,
        });
        console.log("resp ===", resp);
        if (resp.success) {
          setCvsShow(false);
          setContacts((prevContact) => [...resp.data.inserted, ...prevContact]);
          handleClose();
          fetchAllContacts();
          setUpdateContacts((prev) => !prev);
          toast.success(
            `${
              resp.data.inserted.length + resp.data.updateContacts.length
            } new contacts added & ${
              resp.data.duplicateNumbers.length
            } duplicate contacts`
          );

          setUpdateContacts((prevUpdateContacts) => !prevUpdateContacts);
        } else throw new Error(resp.error);
      } catch (error) {
        toast.error("Somthing went wrong. Please fix contact from csv file");
        console.log(error.message);
      }
    } else
      try {
        const check = isPhoneNumValid();
        if (!check) return;
        const resp = await addContact(newContactData);
        if (resp.success) {
          console.log("Contact Added successfully");
          //setContacts(prevContact => [resp.data, ...prevContact])
          handleClose();

          toast.success("Contact Added successfully");
          fetchAllContacts();
        } else throw new Error(resp.error);
      } catch (error) {
        console.log(error.message);
        if (error.message.includes("Enter a valid contact name")) {
          setInputErrors((prevState) => ({
            ...prevState,
            contactName: {
              ...prevState.contactName,
              hasError: true,
              errorMessage: "Enter a valid contact name",
            },
          }));
        }
        if (error.message.includes("A contact with this name already exists")) {
          setInputErrors((prevState) => ({
            ...prevState,
            contactName: {
              ...prevState.contactName,
              hasError: true,
              errorMessage: "A contact with this name already exists",
            },
          }));
        }
        if (
          error.message.includes("A contact with this number already exists")
        ) {
          setInputErrors((prevState) => ({
            ...prevState,
            number: {
              ...prevState.number,
              hasError: true,
              errorMessage: "A contact with this number already exists",
            },
          }));
        }
        if (error.message.includes("Enter a valid phone number")) {
          setInputErrors((prevState) => ({
            ...prevState,
            number: {
              ...prevState.number,
              hasError: true,
              errorMessage: "Enter a valid contact number",
            },
          }));
        }
      }
  };

  // Fetch all contacts
  // useEffect(() => {
  //     const fetchAllContacts = async () => {
  //         const res = await getAllContacts()
  //         setAllContacts(res.data)
  //         console.log("Contacts all", res)
  //     }
  //     fetchAllContacts()
  // }, [])

  // storing updated messages in redux
  const updateCurrChatMsgsInRedux = (
    messages: Object[],
    chatData: singleChatDTO,
    isFetchMore: boolean = false,
    isScroll: boolean = true
  ) => {
    dispatch(
      setChatsData({
        conversationId: chatData?._id,
        id: chatData?._id,
        messages: messages,
        tags: chatData?.tag,
        contact: chatData?.contact,
        generalInfo: {
          phoneNo: chatData?.contact?.number,
          conversationStatus:
            isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) == true
              ? "Active"
              : "Inactive",
          remaining: chatData?.conversationExpiry,
        },
      })
    );
    setTimeout(() => {
      if (chatRef.current && isScroll) {
        const container = chatRef.current;
        container.scrollTop = isFetchMore ? 150 : container.scrollHeight;
      }
    }, 500);
  };

  // handle user message container scroll
  useEffect(() => {
    const handleScroll = debounce(() => {
      console.log("scrolledddddd up");
      if (chatRef.current) {
        const { scrollTop } = chatRef.current;
        if (scrollTop < 50 && hasMore) {
          fetchMessages(Number(currChatMsgsLength + 10));
          console.log("hello fetch message");

          setCurrMsgsLength((prevPage) => prevPage + 10);
        }
      }
    }, 200);
    const chatDiv = document.getElementById("chat-div");
    chatDiv && chatDiv.addEventListener("scroll", handleScroll);

    // cleanup function as component unmounts
    return () => {
      chatDiv && chatDiv.removeEventListener("scroll", handleScroll);
    };
  }, [currChatMsgsLength, hasMore, selChatData?._id]);

  // fetch messages on page no change i.e user has scrolled up
  const fetchMessages = async (length: number) => {
    console.log("hello fetch message", selChatData?._id);
    if (selChatData?._id != "") {
      setIsFetchMsg(true);
      const finalMsgsLength = length ? length : currChatMsgsLength;
      try {
        const resp = await getSingleChat(
          selChatData?._id,
          finalMsgsLength,
          null
        );

        console.log("response of single chat ", resp);
        if (resp?.success) {
          const chatData = resp?.data?.chat;
          setBlock(chatData?.block);
          const sortedMessages = sortAndGroupMessages(
            chatData?.messages?.reverse()
          );
          setSelChatData({
            isGetMsgs: false,
            _id: chatData?._id,
            messages: sortedMessages,
            tagsAssigned: chatData?.tag,
            chatAssignee: null,
            contact: chatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: chatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: chatData?.conversationExpiry,
            },
            block: chatData?.block,
          });
          console.log("inFetchMEssages", chatData?.block);
          // setting current conversation
          dispatch(
            setCurrConversationsData({
              isGetMsgs: false,
              _id: chatData?._id,
              messages: sortedMessages,
              tagsAssigned: chatData?.tag,
              chatAssignee: null,
              contact: chatData?.contact,
              notes: [],
              generalInfo: {
                phoneNo: chatData?.contact?.number,
                conversationStatus:
                  isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) ==
                  true
                    ? "Active"
                    : "Inactive",
                remaining: chatData?.conversationExpiry,
              },
              block: chatData?.block,
            })
          );
          // update data of chat in redux
          updateCurrChatMsgsInRedux(sortedMessages, chatData, true);

          if (currChatMsgsLength >= resp?.data?.totalMsgsCount) {
            setHasMore(false);
          }
        }
        setIsFetchMsg(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsFetchMsg(false);
      }
    }
  };

  // handle user message container scroll
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (chatRef.current) {
        const { scrollTop } = chatRef.current;
        console.log("scrolledddddd up", hasMore, "::", scrollTop);
        if (scrollTop < 50 && hasMore) {
          fetchMessages(Number(currChatMsgsLength + 10));
          console.log("hello fetch message");

          setCurrMsgsLength((prevPage) => prevPage + 10);
        }
      }
    }, 200);

    // const chatDiv = document.getElementById("chat-div")
    const chatDiv = chatRef?.current;
    if (chatDiv) {
      chatDiv.addEventListener("scroll", handleScroll);

      // cleanup function as component unmounts
      return () => {
        chatDiv.removeEventListener("scroll", handleScroll);
      };
    }
  }, [currChatMsgsLength, hasMore, fetchMessages, selChatData?._id]);

  // Dynamically find and scroll to a specific chat by ID
  const scrollToChat = (chatId) => {
    if (listRef.current) {
      const chatIndex = storedConversations.findIndex(
        (chat) => chat._id === chatId
      );
      console.log("chatIndex", storedConversations);
      if (chatIndex !== -1) {
        const chatHeight =
          listRef.current.scrollHeight / storedConversations.length; // Approximate height of each chat
        const scrollPosition = chatHeight * chatIndex; // Calculate scroll position
        listRef.current.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  };
  const handleScroll = async () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        // setCurrentPage((prevPage) => prevPage + 1);
        console.log("cursor1", cursor1);

        let response;
        if (cursor1 && cursor1 != "end") {
          setUsersLoading(true);
          response = await getInbox(cursor1);
        }

        console.log("scroll response", response);
        if (response?.success) {
          dispatch(setCursor1(response?.cursor));
          // setCursor1(response?.cursor)
          // let sortedData = sortConversations(response?.data, false)
          let uniqueData = [];

          dispatch(setConversationsData(response?.data));
          setUsersLoading(false);
        }
      }
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const listContainer = listRef.current;
  useEffect(() => {
    console.log("coming in scroll", listContainer);
    const debouncedHandleScroll = debounce(handleScroll, 100); // Debounce by 300ms
    if (listContainer) {
      listContainer.addEventListener("scroll", debouncedHandleScroll);
    }
    return () => {
      if (listContainer) {
        listContainer.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [listContainer, cursor1]);

  // get all conversations of user
  const getAllConversations = async () => {
    // if(storedConversations?.length > 0 && !isFetchNew){
    //     setTimeout(() => dispatch(updateUnReadCountOfAnyConversation(storedConversations[0]?._id)), 1000)
    //     setSelChatData(prev => ({...prev, _id : storedConversations[0]?._id}))
    //     // setting current conversation
    //     dispatch(setCurrConversationsData({
    //         isGetMsgs : false,
    //         _id : storedConversations[0]?._id,
    //         messages : [],
    //         tagsAssigned : storedConversations[0]?.tag,
    //         chatAssignee : null,
    //         contact : {_id : storedConversations[0]?.contact?._id},
    //         notes : [],
    //         generalInfo : {
    //             phoneNo : "",
    //             conversationStatus : "",
    //             remaining :""
    //         }
    //     }))
    //     getSingleConversData(storedConversations[0]?._id)
    //     return
    // }

    setIsGetAllConvers(true);
    const response = await getInbox();
    setHasResponseArrived(true);
    console.log("response of all conversations ", response);
    if (response?.success) {
      let scrollCursor = response?.cursor;
      dispatch(setCursor1(scrollCursor));
      // setCursor1(scrollCursor)
      let sortedData = sortConversations(response?.data, true);
      // if(isChatCreated){
      //     dispatch(setCreateConversation(sortedData))

      // }
      // else{

      dispatch(setConversationsData(sortedData));
      // }

      console.log("sortedData", sortedData);
      setTimeout(
        () => dispatch(updateUnReadCountOfAnyConversation(sortedData[0]?._id)),
        1000
      );
      setSelChatData((prev) => ({ ...prev, _id: sortedData[0]?._id }));
      console.log("getAllConversations", sortedData[0]?.block);
      // setting current conversation
      dispatch(
        setCurrConversationsData({
          isGetMsgs: false,
          _id: sortedData[0]?._id,
          messages: [],
          tagsAssigned: sortConversations[0]?.tag,
          chatAssignee: null,
          contact: { _id: sortedData[0]?.contact?._id },
          notes: [],
          generalInfo: {
            phoneNo: "",
            conversationStatus: "",
            remaining: "",
          },
          // block:sortedData[0]?.block
        })
      );
    } else {
      //toast.error("Could not get your conversations")
    }

    setIsGetAllConvers(false);
  };

  // get user details
  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    const resp = await getUserDetails(userId);
    console.log("resp of user details ", resp);
    if (resp?.success) {
      setCurrUserData({
        image: resp?.data?.image,
        userName: resp?.data?.firstname,
      });
    }
  };

  // get all tags
  const getTagsList = async () => {
    setIsTagsFetching(true);
    try {
      const { data } = await axios.get(`${baseUrl}?page=1&limit=100000`);
      if (data?.success) {
        dispatch(setAllTags(data?.allTags));

        let sampleTags = [{ label: "All", value: "all" }];
        for (let i = 0; i != data?.allTags?.length; i++) {
          sampleTags?.push({
            label: data?.allTags[i]?.title,
            value: data?.allTags[i]?._id,
          });
        }

        dispatch(setTagOptions(sampleTags));
      }
    } catch (err) {
      //toast.error("Could not get tags")
      console.log("error in fetching tags : ", err);
    }
    setIsTagsFetching(false);
  };

  // get all users of company
  const getAllUsersOfCompany = async () => {
    setIsCompanyUsersFetch(true);
    try {
      const resp = await getAllCompanyUsers(1, 1000);
      if (resp?.success) {
        console.log("all company suers", resp?.users);
        setAllCompanyUsers(resp?.users);
      }
    } catch (err) {
      //toast.error("Could not get all company users")
      console.log("error in fetching company users : ", err);
    }
    setIsCompanyUsersFetch(false);
  };

  //   Select styles

  const colorStyles = {
    menu: (styles) => ({
      ...styles,
      zIndex: 5, // Ensure it is above other elements
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused && "#E2FFF0", // Change hover background color to red
      color: "black",

      cursor: "pointer",
    }),
  };

  // fetch all contacts
  const fetchAllContacts = useCallback(
    debounce(async () => {
      if (!hasMoreContacts || isContactsFetching) return;
      setIsContactsFetching(true);
      setContactsLoading(contactPage === 1);
      try {
        console.log("comincOntacts");
        setContactsLoading(true);
        const resp = await searchContact(contactPage, 20, inputSearch, "", "");
        if (resp.success) {
          const { data, totalPages } = resp;

          const existingIds = new Set(contacts.map((c) => c._id));
          const uniqueData = data.filter((d) => !existingIds.has(d._id));

          setContacts((prev) => [...prev, ...uniqueData]);
          setAllContacts((prev) => [...prev, ...data]);
          setHasMoreContacts(contactPage < totalPages);
          setContactPage((prev) => prev + 1);
          // dispatch(setAllInboxContacts(resp.data))
        }
      } catch (error) {
        console.error("Failed to fetch contacts:", error);
      } finally {
        setContactsLoading(false);
        setIsContactsFetching(false);
      }
    }, 400),
    [
      contactPage,
      hasMoreContacts,
      isContactsFetching,
      searchContact,
      inputSearch,
      cgtshow,
      showContactsModal,
    ]
  );
  const handleContactScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      fetchAllContacts(); // Load next page when scrolled to the bottom
    }
  };

  const handleOpenContacts = async () => {
    setCgtShow(true);

    // if (cgtshow) {
    setContactPage(1);
    setContacts([]);
    setHasMoreContacts(true);
    await fetchAllContacts();
    // }

    // if(contacts.length===0  || allContacts.length===0)
    await fetchGroupsData();
  };
  useEffect(() => {
    if (cgtshow || showContactsModal) {
      setContactPage(1);
      setContacts([]);
      setHasMoreContacts(true);
      fetchAllContacts();
    }
  }, [cgtshow, inputSearch, showContactsModal]);

  // getting all conversations + tags + contacts
  useEffect(() => {
    const callApis = async () => {
      if (!state && finalConversations?.length == 0) {
        getAllConversations();
      } else if (finalConversations?.length > 0) {
        console.log("finalConversations effect", finalConversations);

        // setSelChatData(prev => ({ ...prev, _id: finalConversations[0]?._id }))
        setIsRendering(true);
        await getSingleConversData(finalConversations[0]?._id);
        setNewChatId(finalConversations[0]?._id);
        setIsRendering(false);
      }
      if (allTags?.length == 0) {
        getTagsList();
      }
      getUserData();
      await getAllUsersOfCompany();
    };
    callApis();
    // fetchAllContacts()
  }, []);

  useEffect(() => {
    const fetchSearchApi = async () => {
      console.log("coming in apiu search");
      setSearchLoading(true);
      const response = await getInbox("", mainInputSearch, 20);
      if (response?.success) {
        let scrollCursor = response?.cursor;
        dispatch(setCursor1(scrollCursor));
        //   setCursor1(scrollCursor)

        // let sortedData = sortConversations(response?.data, true)
        // if(isChatCreated){
        //     dispatch(setCreateConversation(sortedData))

        // }
        // else{

        dispatch(setCreateConversation(response?.data));
        setSearchLoading(false);
        // }
      }
    };
    if (isMainInputSearchTriggered) {
      fetchSearchApi();
      setIsMainInputSearchTriggered(false);
    }
  }, [mainInputSearch]);

  useEffect(() => {
    if (refTwo.current) {
      refTwo.current.scrollTop = 20000000; // px
    }
  }, [refTwo.current]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      // setMessage(window.innerWidth > 767);
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useMemo(() => {
    setTimeout(() => {
      if (chatRef.current) {
        const container = chatRef.current;
        container.scrollTop = container.scrollHeight;
      }
    }, 500);
  }, [selChatData?._id]);

  const emptyAllStates = () => {
    if (selectedFile) {
      setSelectedFile(null);
    }
    if (msg != "") {
      setMsg("");
    }
    setSelMsgForOpr(null);
    setIsShowRepliedSection(false);
  };

  // sort conversations based on updated time
  const sortConversations = (
    data: singleMessageDTO[] = [],
    isGetFirstChatData: boolean = false
  ) => {
    // let givenData: singleMessageDTO[] = []
    // console.log("sort function called", data)
    // console.log("storedConversations", storedConversations)
    // if (data?.length == 0) {
    //     givenData = storedConversations
    // } else {
    //     givenData = data
    // }
    // //let nonEmptyConversations = givenData.filter(item => item?.messages?.length > 0)
    // let chatsWithEmptyMsgs = givenData?.filter(item => item?.messages?.length == 0)
    // let sortedData = []
    // let nonEmptyConversations = givenData?.filter(item => item?.messages?.length > 0)
    // let tempSortedData = nonEmptyConversations.sort((a, b) => new Date(b.messages[0]?.timestamp) - new Date(a.messages[0]?.timestamp))
    // sortedData = [...chatsWithEmptyMsgs, ...tempSortedData]
    console.log("dataaaaaaaaa", data);
    if (isGetFirstChatData && windowSize > 600) {
      if (data[0]) {
        if (!id) {
          console.log("sortedDataaa", data);
          getSingleConversData(data[0]?._id);
          setNewChatId(data[0]?._id);
        }
        // getting first sorted data chat
      }
    }
    dispatch(setCreateConversation(data));
    return data;
  };

  // marking messages as read
  const handleMarkMessageAsRead = async (
    dataMessage: singleMessageDTO[],
    isChatOpen: boolean
  ) => {
    // const isCompanyAdmin = localStorage.getItem("companyAdmin")

    const selectedChat = storedConversations.filter(
      (convers) => convers?._id == selChatData?._id
    );
    console.log("selChatDataread", selChatData);
    //   if (selectedChat?.length<=0) {
    //     console.log("No selected chat found");
    //     return;
    //   }
    // Get Id of last message from customer
    let shouldMarkedMessageAsRead = false;
    for (let i = dataMessage.length - 1; i >= 0; i--) {
      if (!dataMessage[i]?.from) {
        try {
          let response;
          console.log("selChatData?.unreadMessageCount", storedConversations);
          // if(selectedChat&& selectedChat?.unreadMessageCount>0){
          // if (!isCompanyAdmin) {
          //     const assignedUser = selectedChat?.assingUsers?.find(
          //       (element) => element?._id === userId
          //     );

          //     if (assignedUser) {
          //       response = await markMessageAsRead({
          //         chatId: selChatData?._id,
          //         messageId: dataMessage[i]._id,
          //       });
          //     }
          //   } else {
          // Admins can mark the message as read without checking assignments
          const element = document.getElementById("for-mobile");
          if (
            (screenWidth < 768 && (element || isChatOpen)) ||
            screenWidth > 768
          ) {
            response = await markMessageAsRead({
              chatId: selChatData?._id,
              messageId: dataMessage[i]._id,
            });
          }

          //   }

          // }

          if (!response.success) throw new Error(response.error);
        } catch (error) {
          console.log(error.message);
        }
        break;
      }
    }
  };

  const getSingleConversForNotificationData = async (
    conversationId: string,
    conversationData
  ) => {
    console.log("conversationIdss", conversationId);
    console.log("conversationIdss1", allChatsData);

    emptyAllStates();

    // updating unread messages of conversation selected
    const allConvs = [...storedConversations];
    let matchedConversation = allConvs?.findIndex(
      (item) => item?._id == conversationId
    );
    if (matchedConversation > -1) {
      // allConvs[matchedConversation] = {
      //   ...allConvs[matchedConversation],
      //   unreadMessageCount: 0,
      // };
      // Extract the matched conversation
      const matchedConv = allConvs[matchedConversation];

      // Remove the matched conversation from its current position
      allConvs.splice(matchedConversation, 1);

      // Add it to the start of the array
      allConvs.unshift({
        ...matchedConv,
        unreadMessageCount: 0,
      });

      console.log("coming", allConvs);
      // sortConversations(allConvs)
    }
    hideDoc();
    hideImage();
    setActiveKey(null);

    let isChatFound = allChatsData?.find(
      (item: singleChatDTO) => item?.conversationId == conversationId
    );

    if (isChatFound) {
      setSelChatData({
        isGetMsgs: false,
        _id: isChatFound?.id,
        messages: isChatFound?.messages,
        tagsAssigned: isChatFound?.tag,
        chatAssignee: null,
        contact: isChatFound?.contact,
        notes: [],
        generalInfo: isChatFound?.generalInfo,
        block: isChatFound?.block,
      });
      // setting current conversation

      console.log("getSingleConversData 854", isChatFound);
      dispatch(
        setCurrConversationsData({
          isGetMsgs: false,
          _id: isChatFound?.id,
          messages: isChatFound?.messages,
          tagsAssigned: isChatFound?.tag,
          chatAssignee: null,
          contact: isChatFound?.contact,
          notes: [],
          generalInfo: isChatFound?.generalInfo,
          block: isChatFound?.block,
        })
      );
      setMessage(true);
      setCurrMsgsLength(isChatFound?.messages?.length);

      await handleMarkMessageAsRead(isChatFound?.messages);
      console.log("cominginConverstionexists", allConvs);
      if (allConvs?.length > 0) {
        dispatch(setCreateConversation(allConvs));
      }

      // getAllNotesOfChat(conversationId)
      setTimeout(() => {
        if (chatRef.current) {
          const container = chatRef.current;
          container.scrollTop = container.scrollHeight;
        }
      }, 500);
      return;
    }

    if (conversationId) {
      console.log("cominginConverstion", conversationData);

      setIsGetSingleChat(true);
      const resp = await getSingleChat(conversationId, 10, null);

      console.log("resp of single chat : ", resp);
      if (resp?.success) {
        const chatData = resp?.data?.chat;
        setBlock(chatData?.block);
        const sortedMessages = sortAndGroupMessages(chatData?.messages);
        setSelChatData({
          isGetMsgs: false,
          _id: chatData?._id,
          messages: sortedMessages,
          tagsAssigned: chatData?.tag,
          chatAssignee: null,
          contact: chatData?.contact,
          notes: [],
          generalInfo: {
            phoneNo: chatData?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) == true
                ? "Active"
                : "Inactive",
            remaining: chatData?.conversationExpiry,
          },
          block: chatData?.block,
        });
        console.log("getSingleConversData", chatData?.block);
        // setting current conversation
        dispatch(
          setCurrConversationsData({
            isGetMsgs: false,
            _id: chatData?._id,
            messages: sortedMessages,
            tagsAssigned: chatData?.tag,
            chatAssignee: null,
            contact: chatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: chatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: chatData?.conversationExpiry,
            },
            block: chatData?.block,
          })
        );
        setCurrMsgsLength(10);

        // update data of chat in redux
        updateCurrChatMsgsInRedux(sortedMessages, chatData);
        setMessage(true);

        await handleMarkMessageAsRead(chatData?.messages);
        if (conversationData?.length > 0) {
          dispatch(setCreateConversation(conversationData));
        }

        setTimeout(() => {
          if (chatRef.current) {
            const container = chatRef.current;
            container.scrollTop = container.scrollHeight;
          }
        }, 500);

        // fetching notes of selected chat
        //getAllNotesOfChat(conversationId)
      }
      setIsGetSingleChat(false);
    }
    // dispatch(setCreateConversation(allConvs))
  };

  // get single conversation data
  const getSingleConversData = async (conversationId: string) => {
    console.log("conversationIdss", conversationId);
    console.log("conversationIdss1", allChatsData);
    setIsChatOpen(true);
    emptyAllStates();

    // updating unread messages of conversation selected
    const allConvs = [...storedConversations];
    let matchedConversation = allConvs?.findIndex(
      (item) => item?._id == conversationId
    );
    if (matchedConversation > -1) {
      allConvs[matchedConversation] = {
        ...allConvs[matchedConversation],
        unreadMessageCount: 0,
      };

      console.log("coming", allConvs);
      // sortConversations(allConvs)
    }
    hideDoc();
    hideImage();
    setActiveKey(null);

    let isChatFound = allChatsData?.find(
      (item: singleChatDTO) => item?.conversationId == conversationId
    );

    if (isChatFound) {
      setSelChatData({
        isGetMsgs: false,
        _id: isChatFound?.id,
        messages: isChatFound?.messages,
        tagsAssigned: isChatFound?.tag,
        chatAssignee: null,
        contact: isChatFound?.contact,
        notes: [],
        generalInfo: isChatFound?.generalInfo,
        block: isChatFound?.block,
      });
      // setting current conversation

      console.log("getSingleConversData 854", isChatFound);
      dispatch(
        setCurrConversationsData({
          isGetMsgs: false,
          _id: isChatFound?.id,
          messages: isChatFound?.messages,
          tagsAssigned: isChatFound?.tag,
          chatAssignee: null,
          contact: isChatFound?.contact,
          notes: [],
          generalInfo: isChatFound?.generalInfo,
          block: isChatFound?.block,
        })
      );
      setMessage(true);
      setCurrMsgsLength(isChatFound?.messages?.length);
      const sortedMessages = [];
      for (let i = isChatFound?.messages.length - 1; i >= 0; i--) {
        sortedMessages.push(isChatFound?.messages[i]);
      }
      console.log(
        "sortedMessages",
        sortedMessages,
        ":::",
        "isChatFound?.messages",
        isChatFound?.messages
      );
      await handleMarkMessageAsRead(sortedMessages, true);
      console.log("cominginConverstionexists", allConvs);
      if (allConvs?.length > 0) {
        dispatch(setCreateConversation(allConvs));
      }

      // getAllNotesOfChat(conversationId)
      setTimeout(() => {
        if (chatRef.current) {
          const container = chatRef.current;
          container.scrollTop = container.scrollHeight;
        }
      }, 500);
      return;
    }

    if (conversationId) {
      setIsGetSingleChat(true);
      const resp = await getSingleChat(conversationId, 10, null);

      console.log("resp of single chat : ", resp);
      if (resp?.success) {
        const chatData = resp?.data?.chat;
        setBlock(chatData?.block);
        const sortedMessages = sortAndGroupMessages(chatData?.messages);
        setSelChatData({
          isGetMsgs: false,
          _id: chatData?._id,
          messages: sortedMessages,
          tagsAssigned: chatData?.tag,
          chatAssignee: null,
          contact: chatData?.contact,
          notes: [],
          generalInfo: {
            phoneNo: chatData?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) == true
                ? "Active"
                : "Inactive",
            remaining: chatData?.conversationExpiry,
          },
          block: chatData?.block,
        });
        console.log("getSingleConversData", chatData?.block);
        // setting current conversation
        dispatch(
          setCurrConversationsData({
            isGetMsgs: false,
            _id: chatData?._id,
            messages: sortedMessages,
            tagsAssigned: chatData?.tag,
            chatAssignee: null,
            contact: chatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: chatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: chatData?.conversationExpiry,
            },
            block: chatData?.block,
          })
        );
        setCurrMsgsLength(10);

        // update data of chat in redux
        updateCurrChatMsgsInRedux(sortedMessages, chatData);
        setMessage(true);
        const sortedMessagess = [];
        for (let i = chatData?.messages.length - 1; i >= 0; i--) {
          sortedMessagess.push(chatData?.messages[i]);
        }
        console.log(
          "sortedMessages",
          sortedMessagess,
          ":::",
          "isChatFound?.messages",
          chatData?.messages
        );
        await handleMarkMessageAsRead(sortedMessagess, true);

        // await handleMarkMessageAsRead(chatData?.messages, true);
        if (allConvs?.length > 0) {
          dispatch(setCreateConversation(allConvs));
        }

        setTimeout(() => {
          if (chatRef.current) {
            const container = chatRef.current;
            container.scrollTop = container.scrollHeight;
          }
        }, 500);

        // fetching notes of selected chat
        //getAllNotesOfChat(conversationId)
      }
      setIsGetSingleChat(false);
    }
    // dispatch(setCreateConversation(allConvs))
  };

  // getting all notes of a user
  const getAllNotesOfChat = async (conversationId) => {
    // get notes of chat
    if (conversationId && userId) {
      setIsNotesFetching(true);
      const notesData = await getAllNotesOfUser(userId, conversationId);
      console.log("notes data : ", notesData);
      if (notesData?.success) {
        setSelChatData((prev) => ({ ...prev, notes: notesData?.data }));
      }
      setIsNotesFetching(false);
    }
  };

  const handleClickOutside = (event) => {
    if (refOne.current && !refOne.current.contains(event.target)) {
      setIsActive(false);
      setInputValue("");
    }
  };

  const handleButtonClick = (id: string) => {
    if (!isGetSingleChat) {
      getSingleConversData(id);
      setNewChatId(id);
      navigate("/inbox");

      // setMessage(true);
      setIsChatOpen(true);
    }
  };

  // change tag of any chat
  const changeTagOfAnyChatInRedux = (chatId: string, tagId: string) => {
    let allChatLists = [...storedConversations];
    let isFound = allChatLists.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    if (isFound > -1) {
      allChatLists[isFound] = { ...allChatLists[isFound], tag: tagId };
      console.log("new chat list", allChatLists);
      dispatch(setConversationsData(allChatLists));
    }
  };

  // change assignee of any chat
  const changeAssigneeOfAnyChatInRedux = (
    chatId: string,
    assignedId: string[]
  ) => {
    let allChatLists = [...storedConversations];
    let isFound = allChatLists.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    console.log("previose chat list", chatId, isFound, allChatLists);
    if (isFound > -1) {
      allChatLists[isFound] = {
        ...allChatLists[isFound],
        assingUsers: assignedId,
      };
      console.log("new chat list", allChatLists);
      dispatch(setConversationsData(allChatLists));
    }
  };

  // assign chat to new user
  const assignChatToNewUser = async () => {
    if (!newUserToBeAssigned || selChatData?._id == "") {
      return;
    }
    setIsChatAssigning(true);
    console.log("newUserAssigning", newUserToBeAssigned);
    try {
      const resp = await assignChatToUser({
        userIds: newUserToBeAssigned,
        inboxId: selChatData?._id,
      });
      console.log("resp of chat assign : ", resp);
      if (resp?.success) {
        // const response = await getInbox()

        // let sortedData = sortConversations([...response.data])
        // dispatch(setConversationWithAgents(sortedData))
        //dispatch(setConversationsData(response.data))

        const allCompUsers = getMatchedTags();
        if (allCompUsers) {
          let isFound = allCompUsers.find(
            (item) => item?._id == newUserToBeAssigned
          );
          if (isFound) {
            changeAssigneeOfAnyChatInRedux(selChatData?._id, [isFound]); // changing tag in redux
          }
        }
        if (newUserToBeAssigned && newUserToBeAssigned.length <= 0) {
          toast.success("Chat unassigned successfully");
        } else {
          toast.success("Chat Assigned successfully");
        }
        handleCloseAssignTagModal();
      }
    } catch (err) {
      toast.error(err);
    }
    setIsChatAssigning(false);
  };

  // assign new tag to current chat
  const assignNewTagToChat = async (tagId) => {
    if (!tagId || selChatData?._id == "") {
      return;
    }
    setIsNewTagAssigning(true);
    try {
      const resp = await assignTagToChat({
        tagId: tagId,
        chatId: selChatData?._id,
      });
      console.log("resp of tag assign : ", resp?.success === true);
      if (resp?.success === true) {
        setSelChatData((prev) => ({ ...prev, tagsAssigned: tagId }));
        changeTagOfAnyChatInRedux(selChatData?._id, tagId); // changing tag in redux
        toast.success("Tag Assigned successfully");
      }
    } catch (err) {
      toast.error(err);
    }
    setIsNewTagAssigning(false);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId); // Update active tab based on tabId
  };

  // get use profile image
  const getLastMessage = (userData: singleMessageDTO) => {
    const messageText = userData;
    const isReceived = messageText?.from ? false : true;
    const msgStatus = renderStatusTicks(messageText);
    console.log("last message of conversation : ", messageText);
    let msgToRender = "";

    if (messageText?.template) {
      const components = messageText.template.components;
      if (components) {
        let val = "";
        const headerComp = components?.find((item) => item?.type == "HEADER");
        const bodyComp = components?.find((item) => item?.type == "BODY");
        if (bodyComp) {
          val =
            bodyComp?.text?.length > 100
              ? bodyComp?.text.slice(0, 100) + "..."
              : bodyComp?.text;
        } else {
          if (headerComp?.example) {
            if (headerComp?.format == "DOCUMENT") {
              val = "pdf-document";
            } else if (headerComp?.format == "IMAGE") {
              val = "image file";
            } else if (headerComp?.format == "LOCATION") {
              val = "location";
            } else if (headerComp?.format == "VIDEO") {
              val = "video file";
            }
          } else {
            val =
              headerComp?.text?.length > 80
                ? headerComp?.text.slice(0, 15) + "..."
                : headerComp?.text;
          }
        }
        msgToRender = val;
        //return msgToRender;
      } else {
        msgToRender = messageText.template?.header
          ? messageText.template.header.slice(
              0,
              messageText.template.header.length
            )
          : messageText.template?.body
          ? messageText.template.body.slice(
              0,
              messageText.template.body.length - 1
            )
          : "template";
      }
    } else if (messageText?.image)
      msgToRender =
        messageText?.image?.filename?.length > 100 ? (
          <i className="bi bi-image flex gap-2 align-items-center">
            {messageText?.image?.filename?.slice(0, 100) + "..."}
          </i>
        ) : (
          <i className="bi bi-image flex gap-2 align-items-center">
            {messageText?.image?.filename || "Photo"}
          </i>
        );
    // return "Photo"
    else if (messageText?.document) {
      msgToRender =
        messageText.document.filename?.length > 100 ? (
          <div
            className="gap-2"
            style={{
              display: "flex",
              justifyCOntent: "center",
              alignItems: "center",
            }}
          >
            <img src={docViewIcon} />
            {messageText.document.filename.slice(0, 100) + "..."}
          </div>
        ) : (
          <div
            className="gap-2"
            style={{
              display: "flex",
              justifyCOntent: "center",
              alignItems: "center",
            }}
          >
            <img src={docViewIcon} />
            {messageText.document.filename || "Document"}
          </div>
        );
    } else if (messageText?.audio)
      return (
        <div className="flex">
          {" "}
          <img
            src={AudioIcon}
            className="me-2"
            width={12}
            height={12}
          /> Audio{" "}
        </div>
      );
    else if (messageText?.video)
      // return "Video"
      msgToRender =
        messageText?.video?.filename?.length > 100 ? (
          <i className="bi bi-camera-video flex gap-2 align-items-center">
            &nbsp; {messageText.video.filename.slice(0, 100) + "..."}
          </i>
        ) : (
          <i className="bi bi-camera-video flex gap-2 align-items-center">
            {messageText?.video?.filename || "Video"}
          </i>
        );
    else if (messageText?.contacts) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <img src={phoneIcon} style={{ width: "10px", height: "10px" }} />
          {messageText?.contacts?.contacts &&
          messageText?.contacts?.contacts?.length > 0
            ? messageText?.contacts?.contacts[0]?.name?.formatted_name?.length >
              60
              ? messageText?.contacts?.contacts[0]?.name?.formatted_name?.substring(
                  0,
                  50
                ) + "..."
              : messageText?.contacts?.contacts[0]?.name?.formatted_name
            : messageText?.contacts?.Name?.length > 60
            ? messageText?.contacts?.Name?.substring(0, 50) + "..."
            : messageText?.contacts?.Name}
        </div>
      );
    } else if (messageText?.type == "text") {
      msgToRender =
        messageText?.text?.length > 50
          ? messageText?.text?.slice(0, 50) + "..."
          : messageText?.text;
    } else if (messageText?.interactive?.interactive?.type == "flow") {
      msgToRender =
        messageText?.interactive?.interactive?.header?.text?.length > 50
          ? messageText?.interactive?.interactive?.header?.text?.slice(0, 50) +
            "..."
          : messageText?.interactive?.interactive?.header?.text;
    } else if (messageText?.flow) {
      msgToRender =
        messageText?.flow?.flow?.data?.index !== undefined
          ? messageText?.flow?.flow?.screen // If `data` has an `index` key, return `screen`
          : Object.entries(messageText?.flow?.flow?.data || {})
              .map(
                ([key, value]) =>
                  `${key}: ${value > 50 ? value?.slice(0, 50) + "..." : value}`
              )
              .join("\n"); // Otherwise, show key-value pairs

      // messageText?.flow?.flow > 50
      //   ? messageText?.interactive?.interactive?.header?.text?.slice(0, 50) +
      //     "..."
      //   : messageText?.interactive?.interactive?.header?.text;
    } else if (messageText?.context) msgToRender = messageText?.text;
    else if (!isReceived) {
      return (
        <div
          style={{
            display: "flex",
            marginTop: "5px",
            justifyContent: "flex-start",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          {msgStatus}
          {/* {
                        messageText?.document ?
                            <img src={docViewIcon} style={{ marginRight: "-5px" }} /> :
                            messageText?.image ?
                                <div className="link-icon">
                                    <i className="bi bi-image"></i>
                                </div> : messageText?.video ? <i className="bi bi-camera-video"></i>
                                    : null
                    } */}
          {msgToRender}
        </div>
      );
    }

    console.log(
      "msgToRender",
      messageText,
      "::",
      msgToRender,
      ":::",
      messageText?.document,
      "::",
      messageText?.document?.filename
    );
    return msgToRender;
  };

  // get conversation date
  const getConversationDate = (date: string) => {
    const today = moment().startOf("day");

    if (moment(date).startOf("day").isSame(today)) {
      return moment(date).format("hh:mm a");
    }

    const differenceInSec = moment(today).diff(date, "seconds");
    if (differenceInSec < 86400) {
      return "Yesterday";
    }

    return moment(date).format("DD/MM/YY");
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadioValue(event.target.value);
  };

  // update mute/unmute of chat
  const updateMuteStatusOfChatEvent = (chatId: string) => {
    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;

    let allConvs = [...allConversations];
    const isFound = allConvs.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    console.log("isFound", isFound);
    if (isFound > -1) {
      const muteStatus = allConvs[isFound].mute == true ? false : true;
      allConvs[isFound] = { ...allConvs[isFound], mute: muteStatus };
      sortConversations(allConvs);
    }
  };

  // event listener for delete chat in list
  useEffect(() => {
    document.addEventListener("chatMuteChangedEvent", (data) => {
      console.log("got parsms ", data?.detail);
      updateMuteStatusOfChatEvent(data?.detail?.chatId);
    });

    return () => {
      document.removeEventListener("chatMuteChangedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // update mute/unmute of chat
  const updateMuteStatusOfChat = (
    chatId: string,
    isMuted: boolean,
    duration: number | null
  ) => {
    let allConvs = [...storedConversations];
    const isFound = allConvs.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    if (isFound > -1) {
      let matchedObj = { ...allConvs[isFound] };
      let allMutedUsers = matchedObj?.mutedUsers;
      if (isMuted && duration) {
        let newObj = {
          userId: userId,
          mute: true,
          muteDuration: duration,
        };
        matchedObj.mutedUsers = [...matchedObj?.mutedUsers, newObj];
      } else {
        let filteredUsers = allMutedUsers?.filter(
          (item) => item?.userId != userId
        );
        matchedObj.mutedUsers = filteredUsers;
      }
      allConvs[isFound] = matchedObj;
      sortConversations(allConvs);
    }
  };

  // check if chat is muted for current user
  const checkIfChatIsMuted = (chatId: string) => {
    let allChats = [...storedConversations];
    let isFound = allChats.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );

    if (isFound > -1) {
      let isUserFound = allChats[isFound]?.mutedUsers?.find(
        (item) => item?.userId == userId
      );
      if (
        isUserFound &&
        isUserFound?.mute &&
        isUserFound?.muteDuration >= Date.now()
      ) {
        return true;
      }
      return false;
    }
  };

  const handleMuteNotification = async (chatId) => {
    if (checkIfChatIsMuted(chatId)) {
      console.log("inside of isMuted");
      const res = await muteChat({ chatId, mute: 0 });
      if (res?.success) {
        toast.success("Chat Unmuted successfully");
        updateMuteStatusOfChat(chatId, false, null);
        setIsMuted(false);
      }
      return;
    }
    let duration = "",
      durationInMilliSec = null;
    console.log("chatId", chatId);
    if (selectedRadioValue === "8 hours") {
      duration = "8";
      durationInMilliSec = addHoursToCurrentDate(8);
    } else if (selectedRadioValue === "1 week") {
      duration = "168";
      durationInMilliSec = addHoursToCurrentDate(168);
    } else {
      duration = "";
      durationInMilliSec = addHoursToCurrentDate(100000);
    }
    setMuteShow(false);

    const res = await muteChat({ chatId, duration, mute: 1 });
    console.log("res", res);
    if (res?.success) {
      //setIsMuted(false)
      updateMuteStatusOfChat(chatId, true, durationInMilliSec);
      toast.success("Chat Muted successfully");
    }
  };

  const noteSendingObj = {
    inboxId: selChatData?._id,
    userId: localStorage.getItem("userId"),
  };

  // create new note
  const handleSaveNote = async (noteMsg: string) => {
    if (noteMsg == "") {
      toast.error("Note message can not be empty");
      return false;
    }
    setIsNoteAdding(true);
    let sendingObj = {
      ...noteSendingObj,
      text: noteMsg,
    };
    console.log("sendingObj", sendingObj);
    const response = await addNewNoteForUser(sendingObj);
    if (response) {
      console.log("new note added data : ", response);
      setIsNoteAdding(false);
      setIsAddNewNote(false);
      getAllNotesOfChat(selChatData?._id); // getting all notes of user again
    }
  };

  // update any note
  const handleUpdateNote = async (noteMsg: string) => {
    if (noteMsg == "") {
      toast.error("Note message can not be empty");
      return false;
    }
    setIsNoteEditing(true);
    let updatedNoteObj = {
      noteId: selectedNote,
      text: noteMsg,
      userId: localStorage.getItem("userId"),
    };

    console.log("updatedNoteObj", updatedNoteObj);

    const response = await updateNoteOfAUser(updatedNoteObj);
    if (response) {
      console.log("updated note got data : ", response);
      setIsNoteEditing(false);
      setIsNoteUpdate(false);
      getAllNotesOfChat(selChatData?._id); // getting all notes of user again
    }
  };

  // handle deleting note
  const handleDeleteNote = async () => {
    if (selectedNote == null) {
      toast.error("Select Note to Delete");
      return false;
    }
    setIsDeletingNote(true);
    let deleteNoteObj = {
      data: {
        noteId: selectedNote,
        userId: userId,
      },
    };

    const response = await deleteNoteOfAUser(deleteNoteObj);
    if (response) {
      setIsDeletingNote(false);
      setIsShowDeleteNote(false);
      getAllNotesOfChat(selChatData?._id); // getting all notes of user again
    }
  };

  // get tag details
  const getTagName = (id: string) => {
    console.log("matched tag : ", allTags, ":", id);
    if (allTags?.length > 0 && id) {
      let isFound = allTags?.find((item) => item?._id == id);
      return isFound?.title;
    } else {
      return null;
    }
  };

  // render template
  function renderTemplate(
    template: templateDTO,
    templateId: string = "",
    isSending
  ) {
    if (template?.components?.length > 0) {
      return (
        <div className="preview-sec-inbox preview-sec ">
          <div className="preview-body">
            <div className="preview-template-inbox">
              {template.category == "AUTHENTICATION" ? (
                <AuthTemplate
                  components={template.components}
                  isInbox={true}
                  isSending={true}
                />
              ) : (
                <UrlPhoneNumber
                  components={template.components}
                  id={template?.id}
                  isInbox={true}
                  isSending={true}
                />
              )}
            </div>
            <QuickReplyPreview
              components={template.components}
              isSending={true}
            />
          </div>
        </div>
      );
    } else {
      return (
        <>
          {template.header && (
            <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
              {isSending && template.header?.length > 100
                ? template.header?.substring(0, 100) + "..."
                : template.header}
            </div>
          )}
          <div style={{ marginBottom: "10px" }}>
            {isSending && template.body?.length > 100
              ? template.body?.substring(0, 100) + "..."
              : template.body}
          </div>
          {template.footer && (
            <div style={{ opacity: "70%", marginBottom: "10px" }}>
              {isSending && template.footer?.length > 100
                ? template.footer?.substring(0, 100) + "..."
                : template.footer}
            </div>
          )}
        </>
      );
    }
  }

  // render flow
  function renderFlow(flow: any, flowId: string = "", isSending) {
    console.log("renderFlow", flow);

    return (
      <div className="flow-message">
        <div className="content mb-3">
          {flow?.header?.text && (
            <>
              {" "}
              <p style={{ fontWeight: "600" }}>{flow?.header?.text || ""}</p>
              <br />
            </>
          )}
          {flow?.body?.text && (
            <>
              {" "}
              <p>{flow?.body?.text || ""}</p>
              <br />
            </>
          )}
          {flow?.footer?.text && (
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#4B4A4A",
              }}
            >
              {flow?.footer?.text || ""}
            </p>
          )}
        </div>
        {flow?.action?.parameters?.flow_cta && <hr />}

        <div
          className="d-flex justify-content-center align-items-center "
          style={{ maxHeight: "25px" }}
        >
          <button
            className="btn cursor-pointer"
            disabled
            style={{ border: "none", fontWeight: "600" }}
          >
            {flow?.action?.parameters?.flow_cta}
          </button>
        </div>
      </div>
    );
  }

  function renderRecivedFlow(flow: any, flowId: string = "") {
    console.log("flowTorecive", flow?.data);
    return (
      <>
        <p
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Segoe UI",
            marginTop: "11px",
          }}
        >
          {/* {flow?.data?.split("\n")?.map((line, index) => ( */}
          <span>
            {
              flow?.data?.index !== undefined
                ? flow?.screen // If `data` has an `index` key, return `screen`
                : Object.entries(flow?.data || {})
                    .map(([key, value]) => `${key}: ${value}`)
                    .join("\n") // Otherwise, show key-value pairs
            }
            <br />
          </span>
          {/* ))} */}
        </p>
      </>
    );
  }

  function renderAudio(link: string, item: any) {
    console.log("renderAUdio", item.hasOwnProperty("read"));
    return (
      <>
        <div style={{ marginBottom: "5px", marginTop: "7px" }}>
          {/* <ReactAudioPlayer
                        src={link}
                        controls
                    //  className='audio-player'
                    /> */}
          <audio controls>
            <source src={link} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </>
    );
  }

  function renderVideo(video) {
    return (
      <>
        <div
          className="video-player"
          style={{ width: "100%", padding: "20px 0 0 0" }}
        >
          <video controls style={{ maxWidth: "250px" }}>
            <source src={video.link} type="video/mp4" />
            Your browser does not support the video element.
          </video>
          {/* <ReactPlayer controls url={video.link} width="100%" height={"150px"} /> */}
          <caption style={{ display: "flex" }}>{video.caption}</caption>
        </div>
      </>
    );
  }

  function renderContacts(showContacts) {
    if (showContacts.contacts && showContacts.contacts?.length > 0) {
      console.log("showCOntacts", showContacts);
      return (
        <>
          <div style={{ width: "100%" }}>
            <div
              onClick={() =>
                (window.location.href = `tel:${showContacts.contacts[0]?.phones[0]?.phone}`)
              }
              style={{
                cursor: "pointer",
                padding: "5px",
                columnGap: "15px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img src={phoneIcon} style={{ width: "20px", height: "20px" }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {showContacts.contacts.map((cont) => (
                  <>
                    <p
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        fontWeight: 600,
                        fontFamily: "Segoe UI",
                      }}
                    >
                      {cont.name.formatted_name}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                        fontWeight: 500,
                        marginTop: "5px",
                        fontFamily: "Segoe UI",
                      }}
                    >
                      {cont.phones[0].phone}
                    </p>
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ width: "100%" }}>
          <div
            onClick={() =>
              (window.location.href = `tel:${showContacts?.Number}`)
            }
            style={{
              cursor: "pointer",
              padding: "5px",
              columnGap: "15px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img src={phoneIcon} style={{ width: "20px", height: "20px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  fontWeight: 600,
                  fontFamily: "Segoe UI",
                }}
              >
                {showContacts?.Name}
              </p>
              <p
                style={{
                  fontSize: "15px",
                  cursor: "pointer",
                  fontWeight: 500,
                  marginTop: "5px",
                  fontFamily: "Segoe UI",
                }}
              >
                {showContacts?.Number}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderImage(link: string, caption: string) {
    return (
      <>
        <div
          className="video-player"
          style={{ width: "100%", padding: "20px 0 0 0" }}
        >
          <img
            src={link || Hajira_img}
            style={{ width: "auto", height: "auto", maxHeight: "300px" }}
            onClick={() => {
              setImageLink(link);
              setImgShow(true);
            }}
          />
          <p style={{ color: "#000000", fontSize: "14px", fontWeight: 400 }}>
            {caption}
          </p>
        </div>
      </>
    );
  }

  function renderText(text: string) {
    console.log("texttt", text);
    // if(text.includes('"id"')){

    //     const parsedObject = JSON.parse(text);
    //     console.log("parsedObject",parsedObject)
    //    return (<>

    // <p style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Segoe UI" }}>

    //             Name: {parsedObject?.Name}
    //         </p>
    //         <p style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Segoe UI" }}>

    //         Phone: {parsedObject?.Number}
    //         </p>
    // </>)
    // }
    return (
      <>
        <p
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "Segoe UI",
            marginTop: "11px",
          }}
        >
          {text.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
      </>
    );
  }

  function renderLocation(location) {
    console.log("location got : ", location);
    if (isGoogleLoaded) {
      return (
        <GoogleMap
          center={{
            lat: Number(location?.latitude),
            lng: Number(location?.longitude),
          }}
          zoom={15}
          mapContainerStyle={containerStyleForPreview}
          options={options}
        >
          <Marker
            position={{
              lat: Number(location?.latitude),
              lng: Number(location?.longitude),
            }}
          />
        </GoogleMap>
      );
    } else {
      return <p>Map is loading ...</p>;
    }
  }

  function renderDocument(document: string, isSent: boolean) {
    const documentType = getFileTypeFromUrl(document?.link || document);
    console.log("document ", document, ":::", documentType);

    return (
      <>
        {/* <div onClick={() => window.open(document, '_blank')?.focus()} style={{ cursor : "pointer", backgroundColor : "rgba(188, 241, 213, 1)", padding : "5px" , columnGap: "15px", borderRadius : "10px", display : "flex", justifyContent : "flex-start", alignItems : "flex-start", }} >
                <img src={pdfIcon} style={{width : "20px", height : "20px"}} />
                <p style={{fontSize : "15px", cursor : "pointer", fontWeight : 600, fontFamily : "Segoe UI"}} onClick={() => window.open(document?.link, '_blank')?.focus()} >Preview Document</p>
            </div> */}
        <div
          className={`mesg-sec send doc_msg ${documentType}`}
          style={{
            cursor: "pointer",
            backgroundColor: !isSent ? "rgba(233, 242, 255, 1)" : "",
          }}
          onClick={() => window.open(document?.link, "_blank")?.focus()}
        >
          <ul className="nav align-item-center gap-3">
            <li className="nav-item">
              {documentType == "doc" ? (
                <img
                  className="doc-icon doc_file-icon block"
                  src={Doc_file}
                  alt="doc-file_icon"
                />
              ) : documentType == "pdf" ? (
                <img
                  className="pdf-icon doc_file-icon block"
                  src={Pdf_file}
                  alt="ppt-file_icon"
                />
              ) : documentType == "ppt" || documentType == "pptx" ? (
                <img
                  className="ppt-icon doc_file-icon block"
                  src={Ppt_file}
                  alt="pdf-file_icon"
                />
              ) : documentType == "txt" ? (
                <img
                  className="txt-icon doc_file-icon block"
                  src={Text_file}
                  alt="text-file_icon"
                />
              ) : documentType == "csv" || documentType == "xlsx" ? (
                <img
                  className="csv-icon doc_file-icon block"
                  src={Xls_file}
                  alt="Csv-file_icon"
                />
              ) : (
                <img
                  className="xls-icon doc_file-icon block"
                  src={Doc_file}
                  alt="xls-file_icon"
                />
              )}
            </li>
            <li className="nav-item">
              <div className="file-content">
                <div className="file-name">{document?.filename}</div>
                <div className="file-size">
                  {document.filesize && getFileSize(document.filesize) + "."}{" "}
                  {documentType}
                </div>
              </div>
            </li>
          </ul>
        </div>
        {document?.caption && (
          <>
            <hr className="mt-3" />{" "}
            <p
              className="mt-3"
              style={{ color: "#000000", fontSize: "14px", fontWeight: 400 }}
            >
              {document?.caption}
            </p>{" "}
          </>
        )}
      </>
    );
  }

  // render replied msg
  // function renderRepliedMsg(message: singleMessageDTO) {
  //     console.log("message replied", message)
  //     let contextType = getRepliedMsgType(message?.context)
  //     console.log("contextType", message.context)
  //     if (message) {
  //         return (
  //             <>
  //                 <div style={{ width: '100%', fontSize: '12px', padding: '5px 5px', backgroundColor: "#CEF8E1",borderLeft:"5px solid #5FD495"
  //                 // `${message.from ? '#C9E5DF' : 'rgb(22, 94, 91, 0.1)'}`
  //                  }} className={`mesg-sec replied`} onClick={() => { scrollToMessage(message.context) }}>
  //                     <div >
  //                         <p style={{ marginBottom: '5px', fontSize: '12px', fontWeight: 'bold', color: `${message.context.from ? 'green' : '#8090C4'}` }} >{message.context.from ? message.context.from?.firstname+" "+message.context.from?.lastname : selChatData?.contact.contactName}</p>
  //                         <p>
  //                             {message.context.template ? message.context.template?.components && message.context.template?.components?.length > 0 && message.context.template?.components[0]?.text ? message.context.template?.components[0]?.text + '...' : !message?.context?.template?.components && message.context.body ? message.context.body : message.context.template?.components ? message.context.template?.components[1]?.text : message.context.template?.body : message.context.image ? <img src={message.context.image.link} style={{ width: "100%", height: "150px" }} alt="chat-img" /> : message.context.document ? <a style={{ color: '#165E5B' }}>{message.context.document.filename}</a> : message.context.text?.length > 100 ? message.context.text?.slice(0, 100) + "..." : message.context.text}
  //                         </p>
  //                     </div>
  //                 </div>
  //                 {
  //                     message?.context?.audio ?
  //                         <audio controls>
  //                             <source src={message?.context?.audio?.link} type="audio/mpeg" />
  //                             Your browser does not support the audio element.
  //                         </audio>
  //                         : null
  //                 }
  //                 {
  //                     message?.context?.video ?
  //                         <video controls style={{ maxWidth: '250px' }}>
  //                             <source src={message?.context?.video?.link} type="video/mp4" />
  //                             Your browser does not support the video element.
  //                         </video>
  //                         : null
  //                 }
  //                 <p style={{padding:"10px"}}>{message?.text && message?.text?.length > 100 ? message?.text?.slice(0, 100) + "..." : message?.text}</p>
  //             </>
  //         )
  //     }
  // }

  function renderRepliedMsg(message: singleMessageDTO) {
    if (!message) return null;

    const { context } = message;
    if (!context) return null;

    const { from, template, image, document, text, audio, video, interactive } =
      context;

    const repliedName = from
      ? `${from.firstname || ""} ${from.lastname || ""}`.trim()
      : selChatData?.contact?.contactName || "Unknown Contact";
    console.log("repliedName", repliedName);
    const isFromUser = !!from;
    const contextType = getRepliedMsgType(context);

    const renderTemplateText = () => {
      if (template?.components && template?.components.length > 0) {
        const componentText =
          template.components[0]?.text || template.components[1]?.text;
        return componentText ? `${componentText}...` : null;
      }
      return template?.body || context.body || null;
    };

    const renderMessageContent = () => {
      if (image) {
        return (
          <img
            src={image.link}
            style={{ width: "100%", height: "150px" }}
            alt="chat-img"
          />
        );
      }
      if (document) {
        return <a style={{ color: "#165E5B" }}>{document.filename}</a>;
      }
      if (text?.length > 100) {
        return `${text.slice(0, 100)}...`;
      }
      return text;
    };

    const renderFlowContent = () => {
      if (interactive) {
        return interactive?.interactive?.header?.text;
      }
    };
    return (
      <>
        <div
          style={{
            width: "100%",
            fontSize: "12px",
            padding: "5px 5px",
            backgroundColor: "#CEF8E1",
            borderLeft: "5px solid #5FD495",
            marginTop: "13px",
          }}
          className="mesg-sec replied"
          onClick={() => scrollToMessage(context)}
        >
          <div
            style={{
              paddingTop: "12px",
            }}
          >
            <p
              style={{
                marginBottom: "5px",
                fontSize: "12px",
                fontWeight: "bold",
                color: isFromUser ? "green" : "#8090C4",
              }}
            >
              {repliedName}
            </p>
            <p>
              {renderFlowContent() ||
                renderTemplateText() ||
                renderMessageContent()}
            </p>
          </div>
        </div>

        {/* Render audio if exists */}
        {audio && (
          <audio controls>
            <source src={audio.link} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        )}

        {/* Render video if exists */}
        {video && (
          <video controls style={{ maxWidth: "250px" }}>
            <source src={video.link} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        )}

        {/* Render message text */}
        <p style={{ padding: "10px" }}>
          {/* {message?.text && message?.text.length > 100
                        ? `${message.text.slice(0, 100)}...`
                        : message.text} */}
          {message.text}
        </p>
      </>
    );
  }

  // open user web cam
  const openWebCam = () => {
    setCamshow(true);
    //requestCameraAccess()
  };
  const handleTakePhoto = () => {
    const photo = webcamRef?.current?.getScreenshot();
    setPhotoUrl(photo);
  };
  const handleDiscardPhoto = () => {
    setPhotoUrl(null);
  };
  const handleCloseCamModal = () => {
    setCamshow(false);
    setPhotoUrl(null);
  };
  const handleSendPhoto = () => {
    handleCloseCamModal();
    const binaryString = atob(photoUrl.split(",")[1]);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const file = new File([bytes], "photo.jpg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    console.log("Picture sent:", photoUrl);
    setSelectedFile(file);
    setIsPhotoClicked(true);
  };

  useEffect(() => {
    if (isPhotoClicked) {
      sendMessage();
      setIsPhotoClicked(false);
    }
  }, [isPhotoClicked, handleSendPhoto]);

  // upload document
  function uploadDoc() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    input.onchange = () => {
      const file = input.files[0];
      setSelectedFile(file);
    };
    input.click();
  }

  // select contact
  const handleSelectedContact = (contact: any) => {
    setContactSelected({
      Name: contact?.contactName,
      Number: contact.number,
      id: contact._id,
    });
    //setMsg(`Name: ${contact.contactName} \n Number: ${contact.number}`);
    setShowContactsModal(false);
  };
  const handleSearch = (event) => {
    setContactPage(1);
    setInputSearch(event.target.value);
    setHasMoreContacts(true);
    setIsContactsFetching(false);
    fetchAllContacts();
  };
  const handleCreateChat = async (id) => {
    try {
      const resp = await createChat({ contactId: id });
      if (resp.success) {
        console.log("New Chat Added", finalConversations);

        setIsGetAllConvers(true);
        const response = await getInbox();
        setHasResponseArrived(true);
        if (response?.success) {
          let scrollCursor = response?.cursor;
          dispatch(setCursor1(scrollCursor));

          dispatch(setCreateConversation(response?.data));

          console.log("sortedData", response?.data);
        }

        setIsGetAllConvers(false);

        setCgtShow(false);
        getSingleConversForNotificationData(resp.data, response?.data);

        setNewChatId(resp.data);
      } else throw new Error(resp.error);
      // setNewChatId("")
    } catch (error) {
      console.log(error.message);
      console.log("Failed to fetch Data!");
      // setNewChatId("")
    }
  };

  // upload image
  function uploadImage() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/* video/*";

    input.onchange = () => {
      const file = input.files[0];
      setSelectedFile(file);
    };
    input.click();
  }

  const handleCloseTemplate = () => setIsShowTempModal(false);
  const handleCloseFlow = () => setIsShowFlowModal(false);

  // handle click of input field of message field
  const handleInputFieldClick = () => {
    // setInputValue("")
    // setIsActive(true);
  };

  // handle message change for text
  const handleInputChange = (event) => {
    setMsg(event.target.value);
  };
  console.log("input value", inputSearch);
  // update chat lists and current chat messages in redux
  const updateChatDataInReduxOnSendMsg = async (
    msgData: singleMessageDTO,
    isSameChat: boolean = true,
    chatId: string = "",
    msgId: string = "",
    assignedTag: string | null = null,
    isUpdateTime: boolean = false,
    isSentMsg = false
  ) => {
    console.log(
      "updateChatDataInReduxOnSendMsg props",
      msgData,
      ":",
      isSameChat,
      ":",
      chatId,
      ":",
      msgId,
      ":",
      assignedTag,
      ":",
      isUpdateTime,
      ":",
      isSentMsg
    );
    const date = new Date(new Date().getTime() + 60 * 60 * 24 * 1000);
    const milliseconds = date.valueOf();
    const allData = store.getState();
    const { allChatsData, allConversations, currentConversation } =
      allData?.ChatsReducer;
    const allConvs = [...allConversations];
    let finalChatId = null;
    let finalMsg = msgData?.message ? msgData?.message : msgData;
    // if(chatId != ""){
    //     finalChatId = chatId
    // }else{
    let matchedId = allConvs.findIndex(
      (item) => item?.contact?._id == finalMsg?.contact
    );
    if (matchedId > -1) {
      finalChatId = allConvs[matchedId]?._id;
    }
    //}
    console.log("finalChatId : ", finalMsg, ":::", finalChatId);
    const chatID = isSameChat ? currentConversation?.contact?._id : finalChatId;
    let allChats = [...allChatsData];
    const selChatMsgs = allChats?.find(
      (item: singleMessageDTO) => item?.conversationId == finalChatId
    );
    const prevMsgs: singleMessageDTO[] = isSameChat
      ? currentConversation?.messages
      : selChatMsgs?.messages;
    const currChatId = isSameChat ? currentConversation?._id : selChatMsgs?.id;
    console.log(
      "previouse messages ",
      isSameChat ? true : false,
      "::::",
      selChatMsgs?.messages
    );

    // sorting conversations
    let matchedConversation = allConvs?.findIndex(
      (item) => item?._id == finalChatId
    );
    console.log(
      "currentConversation in main : ",
      msgData?.assingUsers,
      "::",
      allConvs[matchedConversation]?.assingUsers,
      "::",
      allConvs[matchedConversation]
    );
    if (matchedConversation > -1) {
      allConvs[matchedConversation] = {
        ...allConvs[matchedConversation],
        conversationExpiry: isUpdateTime
          ? milliseconds
          : allConvs[matchedConversation]?.conversationExpiry,
        updatedAt: new Date()?.toISOString(),
        messages: [finalMsg],
        assingUsers: msgData?.assingUsers
          ? msgData?.assingUsers
          : allConvs[matchedConversation]?.assingUsers,
        tag: assignedTag ? assignedTag : allConvs[matchedConversation].tag,
        unreadMessageCount: isSentMsg
          ? msgData?.unreadMessageCount
          : isSameChat
          ? 0
          : msgData?.unreadMessageCount,
      };
    }
    console.log("macthe conversation", allConvs[matchedConversation]);

    sortConversations(allConvs); // sorting conversations list based on messages
    let sortedMessages;
    // updating messages
    if (prevMsgs) {
      sortedMessages = sortAndGroupMessages([...prevMsgs, finalMsg]);
    } else {
      sortedMessages = sortAndGroupMessages([finalMsg]);
    }
    console.log("sortedMessages", sortedMessages);
    console.log("currChatId ; ", finalChatId);
    dispatch(
      addNewMessageToChat({
        conversationId: finalChatId,
        newMessages: sortedMessages,
        conversationExpiry: isUpdateTime
          ? milliseconds
          : allConvs[matchedConversation]?.conversationExpiry,
        tag: assignedTag ? assignedTag : allConvs[matchedConversation]?.tag,
      })
    ); // dispatching newly sent message to redux

    // if user has opened same chat
    // if (isSameChat && sortedMessages) {
    if (isSameChat && sortedMessages) {
      handleMarkMessageAsRead(sortedMessages);
      let generalInfo = {
        ...selChatData?.generalInfo,
        remaining: isUpdateTime
          ? milliseconds
          : allConvs[matchedConversation]?.conversationExpiry,
      };
      setSelChatData((prev) => ({
        ...prev,
        tagsAssigned: assignedTag
          ? assignedTag
          : allConvs[matchedConversation]?.tag,
        assingUsers: msgData?.assingUsers,
        messages: sortedMessages,
        generalInfo: generalInfo,
      }));
      console.log("currentConversation?.block", currentConversation?.block);
      dispatch(
        setCurrConversationsData({
          isGetMsgs: false,
          _id: chatId,
          messages: sortedMessages,
          tagsAssigned: currentConversation?.tag,
          chatAssignee: msgData?.assingUsers,
          assingUsers: msgData?.assingUsers,
          notes: currentConversation?.notes,
          contact: currentConversation?.contact,
          generalInfo: {
            phoneNo: currentConversation?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(
                currentConversation?.conversationExpiry
              ) == true
                ? "Active"
                : "Inactive",
            remaining: milliseconds,
          },
          block: currentConversation?.block,
        })
      );
      setTimeout(() => {
        if (chatRef.current) {
          const container = chatRef.current;
          container.scrollTop = container.scrollHeight;
        }
      }, 500);
    }
  };

  // get selected chat from list
  const updateUserLists = async (
    chatId,
    msgId,
    newMsg,
    updatedInbox = [...storedConversations],
    assignedTag = ""
  ) => {
    const element = document.getElementById("for-mobile");
    if (element) {
      setIsChatOpen(true);
    }
    const allData = store.getState();
    const { allChatsData, allConversations, currentConversation } =
      allData?.ChatsReducer;
    console.log("comming1", currentConversation?._id == chatId);

    const allConvs = [...allConversations];
    const selectedConversation = allConvs.findIndex(
      (item) => item?.contact?._id == newMsg?.message?.contact
    );

    // // console.log("received new msg", selectedConversation, "::", chatId, updatedInbox?.unreadMessageCount)
    let newMsgChat = null;
    if (selectedConversation > -1) {
      newMsgChat = allConvs?.findIndex(
        (convs) => convs?._id === updatedInbox?._id
      );
      console.log("updated conversations:1", allConvs);

      if (newMsgChat !== -1) {
        // Check if a matching conversation was found
        // Replace the found conversation with updatedInbox
        console.log("updated conversations:2", newMsgChat);

        allConvs[newMsgChat] = updatedInbox;
      }

      console.log("updated conversations:0", allConvs);
      await updateChatDataInReduxOnSendMsg(
        newMsg,
        Boolean(currentConversation?.contact?._id == newMsg?.message?.contact),
        chatId,
        msgId,
        assignedTag,
        true
      );
      if (currentConversation?._id == chatId) {
        let matchedConversation = allConvs?.findIndex(
          (item) => item?._id == chatId
        );
        if (matchedConversation > -1) {
          const element = document.getElementById("for-mobile");
          if (element) {
            allConvs[matchedConversation] = {
              ...allConvs[matchedConversation],
              unreadMessageCount: 0,
            };
          } else {
            allConvs[matchedConversation] = {
              ...allConvs[matchedConversation],
            };
          }

          console.log("coming", allConvs);
          // sortConversations(allConvs)
        }
      }
      const updatedConversation = allConvs.splice(newMsgChat, 1)[0];
      allConvs.unshift(updatedConversation);
      console.log("conversatonssss", allConvs);
    } else {
      // if not found, adding chat to chat list in redux store
      allConvs.unshift(updatedInbox);
    }
    dispatch(setCreateConversation(allConvs));
  };

  // event listener for new message
  useEffect(() => {
    document.addEventListener("newMessageReceivedEvent", (data) => {
      updateUserLists(
        data?.detail?.chatId,
        data?.detail?.msgId,
        data?.detail?.msg,
        data?.detail?.updatedInbox,
        data?.detail?.assignedTag
      );
    });

    return () => {
      document.removeEventListener("newMessageReceivedEvent", () => {
        console.log("going to call return");
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // updating sent message in chat
  const updateSentMsgInChat = (chatId, newMsg, assignedTag = "") => {
    console.log("coming in send", newMsg);
    const allData = store.getState();
    const { allChatsData, allConversations, currentConversation } =
      allData?.ChatsReducer;

    const allConvs = [...allConversations];
    const selectedConversation = allConvs.findIndex(
      (item) => item?.contact?._id == newMsg?.contact
    );
    console.log(
      "received : ",
      selectedConversation,
      Boolean(currentConversation?.contact?._id == newMsg?.contact),
      chatId,
      newMsg?.contact
    );
    if (selectedConversation > -1) {
      updateChatDataInReduxOnSendMsg(
        newMsg,
        Boolean(currentConversation?.contact?._id == newMsg?.message?.contact),
        chatId,
        assignedTag,
        true,
        true
      );
      // if (
      //     allConvs[selectedConversation]?.messages &&
      //     allConvs[selectedConversation].messages.length > 0
      // ) {
      //     allConvs[selectedConversation].messages[0].timestamp = newMsg?.timestamp;
      // }
      // console.log("allConvs[selectedConversation]",allConvs)
      // const updatedConversation = allConvs.splice(selectedConversation, 1)[0];
      // allConvs.unshift(updatedConversation);
      // dispatch(setCreateConversation(allConvs))
      // Ensure immutability for the messages array and the timestamp update
      const updatedMessages = allConvs[selectedConversation]?.messages?.map(
        (msg, index) =>
          index === 0
            ? { ...msg, timestamp: newMsg?.timestamp, text: newMsg?.text }
            : msg
      );

      // Update the selected conversation immutably
      const updatedConversation = {
        ...allConvs[selectedConversation],
        messages: updatedMessages,
      };

      // Remove the selected conversation from the list and move it to the front
      const updatedAllConvs = [
        updatedConversation,
        ...allConvs.filter((_, index) => index !== selectedConversation),
      ];

      // Dispatch the updated conversations list
      dispatch(setCreateConversation(updatedAllConvs));
    }
  };

  // event listener for new message sent from any other user
  useEffect(() => {
    console.log("coming in effect");

    document.addEventListener("newMsgSentEvent", (data) =>
      updateSentMsgInChat(
        data?.detail?.chatId,
        data?.detail?.msg,
        data?.detail?.assignedTag
      )
    );

    return () => {
      document.removeEventListener("newMsgSentEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // handle agent changed from any other user
  const handleAgentUpdateForEvent = (chatId, newAgents) => {
    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;
    console.log("allData", allData);
    const allConvs = [...allConversations];
    const selectedConversation = allConvs.findIndex(
      (item) => item?._id == chatId
    );
    console.log("received new agent assigend ", selectedConversation);
    if (selectedConversation > -1) {
      allConvs[selectedConversation] = {
        ...allConvs[selectedConversation],
        assingUsers: newAgents,
      };
      console.log(
        "new agent assigend conversation",
        allConvs[selectedConversation]
      );
      // sortConversations(allConvs) // sorting conversations list based on messages
    }
    dispatch(setConversationWithAgents(allConvs));
  };

  // event listener for agent of any chat changed
  useEffect(() => {
    document.addEventListener("chatAgentChangedEvent", (data) =>
      handleAgentUpdateForEvent(data?.detail?.chatId, data?.detail?.newAgents)
    );

    return () => {
      document.removeEventListener("chatAgentChangedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // handle tag changed from any other user
  const handleTagUpdateForEvent = (chatId, tagId) => {
    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;

    const allConvs = [...allConversations];
    const selectedConversation = allConvs.findIndex(
      (item) => item?._id == chatId
    );
    console.log("received new tag assigned ", selectedConversation);
    if (selectedConversation > -1) {
      allConvs[selectedConversation] = {
        ...allConvs[selectedConversation],
        tag: tagId,
      };
      console.log("new tag assigned conversation", allConvs);
      // sortConversations(allConvs) // sorting conversations list based on messages
      console.log("tag updated", allConvs);
      dispatch(setConversationWithAgents(allConvs));
    }
  };

  // event listener for tag changed for any chat
  useEffect(() => {
    document.addEventListener("chatTagChangedEvent", (data) =>
      handleTagUpdateForEvent(data?.detail?.chatId, data?.detail?.tagId)
    );

    return () => {
      document.removeEventListener("chatTagChangedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // handle note added for any chat from other user
  const handleNoteUpdateEvent = (chatId: string) => {
    const allData = store.getState();
    const { currentConversation } = allData?.ChatsReducer;

    console.log("chatId ", chatId, "::", currentConversation);
    if (chatId == currentConversation?._id) {
      getAllNotesOfChat(currentConversation?._id);
    }
  };

  // event listener for new note updating in current chat
  useEffect(() => {
    document.addEventListener("notesEvent", (data) => {
      console.log("updating note", data?.detail?.chatId);
      handleNoteUpdateEvent(data?.detail?.chatId);
    });

    return () => {
      document.removeEventListener("notesEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // handle new chat added from any other user
  const handleNewChatAddedEvent = (chatId: string, newChat: singleChatDTO) => {
    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;

    const allConvs = [...allConversations];
    const selectedConversation = allConvs.findIndex(
      (item) => item?._id == chatId
    );
    // console.log("received new chat added", selectedConversation)
    if (selectedConversation < 0) {
      // console.log("new chat added", allConvs[selectedConversation])
      // sortConversations([...allConvs, newChat]) // sorting conversations list based on messages

      allConvs.unshift(newChat);
      console.log("updatedData", allConvs);
    } else {
      const [objectToMove] = allConvs.splice(selectedConversation, 1);

      // Insert the object at the start of the array

      allConvs.unshift(objectToMove);
    }
    dispatch(setCreateConversation(allConvs));
  };
  console.log("storrrrrr", storedConversations);
  // event listener for new chat added in list
  useEffect(() => {
    document.addEventListener("newChatAdded", (data) => {
      console.log("newChatSocket", data);
      handleNewChatAddedEvent(data?.detail?.chatId, data?.detail?.newChat);
    });

    return () => {
      document.removeEventListener("newChatAdded", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // handle delete chat from any other user
  const handleChatDeletedEvent = (chatId: string) => {
    console.log("new chat added", chatId);

    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;
    console.log("new chat added1", allData);

    const allConvs = [...allConversations];
    const filteredList = allConvs.filter((item) => item?._id != chatId);
    // sortConversations(filteredList) // sorting conversations list based on messages
    console.log("filteredList", filteredList);
    // dispatch(removeConversation(filteredList))
  };

  // event listener for delete chat in list
  useEffect(() => {
    document.addEventListener("chatDeleted", (data) => {
      console.log("chatDeleted", data);
      handleChatDeletedEvent(data?.detail?.chatId);
    });

    return () => {
      document.removeEventListener("chatDeleted", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // remove current conversation from redux on unmount
  useEffect(() => {
    // cleanup function
    return () => {
      console.log("going to call return updated");
      setSelChatData((prev) => ({
        ...prev,
        _id: "",
        messages: [],
        contact: null,
        block: false,
      }));
      dispatch(removeCurrConversationsData(null));
    };
  }, []);

  // checking if chat has no agent assigning that chat to agent
  const assignChatToAgentFirstReplied = async (inboxId: string) => {
    return;
    const resp = await assignChatToUser({
      userIds: [userId],
      inboxId: inboxId,
    });
    console.log("resp of chat assign : ", resp);
    if (resp?.success) {
      const allCompUsers = getMatchedTags();
      if (allCompUsers) {
        let isFound = allCompUsers.find((item) => item?._id == userId);
        console.log("isFound of chat", isFound);
        if (isFound) {
          changeAssigneeOfAnyChatInRedux(inboxId, [
            {
              email: isFound?.email,
              firstname: isFound?.firstname,
              lastname: isFound?.lastname,
              _id: isFound?._id,
            },
          ]); // changing tag in redux
        }
      }
    }
  };

  // sending all messages other than template
  const sendMessage = async () => {
    console.log("send message clicked", msg, ":", selectedFile);
    // Video size validation
    if (selectedFile && selectedFile.type.startsWith("video/")) {
      const maxSizeInBytes = 15 * 1024 * 1024; // 15 MB in bytes
      if (selectedFile.size > maxSizeInBytes) {
        toast.error("Video file size should not exceed 15 MB");
        return;
      }
    }
    if (msg?.length > 4050) {
      toast.error("Message should not exceed 4096 characters");
      return;
    }
    let data = new FormData();
    console.log("selectedFile", selectedFile, "voice-message.ogg");
    if (selectedFile) {
      // data.append('media', selectedFile)
      data.append("media", selectedFile);

      data.append("hasMedia", true);
    }
    if (isShowRepliedSection) data.append("replyToId", selMsgForOpr);

    data.append("contactId", selChatData?.contact?._id);
    console.log("contactSelected", contactSelected);
    if (contactSelected) {
      const strContact =
        "Name: " +
        contactSelected?.Name +
        "\nPhone: " +
        contactSelected?.Number;
      console.log("strContact", strContact);

      // data.append('contacts', JSON.stringify(contactSelected))
      data.append("message", strContact);
    } else {
      data.append(data.get("hasMedia") ? "caption" : "message", msg);
    }

    data.append("inboxId", selChatData?._id);

    setSending(true);
    if (msg.trim().length > 0 || selectedFile || contactSelected) {
      try {
        const res = await sendChatMessage(data);
        setSending(false);

        if (res.success) {
          if (msg != "") {
            setMsg("");
          }
          if (selectedFile) {
            setSelectedFile(null);
            setRecording(false);
            setAudioBlob(null);
          }
          if (contactSelected) {
            setContactSelected(null);
          }
          // setting current conversation
          const sortedMessages = sortAndGroupMessages([
            ...selChatData?.messages,
            res.data,
          ]);
          dispatch(
            setCurrConversationsData({
              isGetMsgs: false,
              _id: selChatData?._id,
              tagsAssigned: selChatData?.tag,
              messages: sortedMessages,
              chatAssignee: null,
              contact: selChatData?.contact,
              notes: [],
              generalInfo: {
                phoneNo: selChatData?.contact?.number,
                conversationStatus:
                  isDateTimeGreaterThanCurrent(
                    selChatData?.conversationExpiry
                  ) == true
                    ? "Active"
                    : "Inactive",
                remaining: selChatData?.conversationExpiry,
              },
              block: selChatData?.block,
            })
          );
          updateChatDataInReduxOnSendMsg(res.data, true);
          hideDoc();
          hideImage();
          await handleMarkMessageAsRead([...selChatData?.messages, res?.data]);
          setSelMsgForOpr(null);
          setIsShowRepliedSection(false);

          console.log(
            "checking ",
            storedConversations.find((item) => item?._id == selChatData?._id)
              ?.assingUsers
          );
          // checking if chat has no agent assigning that chat to agent
          //if(!storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers || storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length == 0){
          // assignChatToAgentFirstReplied(selChatData?._id)
          //}
        } else {
          if (selectedFile) {
            setMsg("");
            // setReplyToIndex(null)
            // setSelectedFile(null)
            // setFile(null)
            toast.error("File not supported");
            setRecording(false);
            setAudioBlob(null);
          }
          //setContactSelected(null)
          // toast.error(res.error)
          // toast.error(res.error)

          throw new Error(res.error);
        }
        setMsg("");
      } catch (error) {
        toast.error(error.message);
        throw new Error(error);

        console.log(error);
        console.log("Failed to Send Chat Message");
      }
    }
  };
  console.log("filteredList", storedConversations);

  // sending template
  const handleSendTemplate = async () => {
    console.log("selectedTemplate", selectedTemplate, "variable", variable);
    setSending(true);
    let emptyVarArr = [
      {
        type: "header",
        parameters: [],
      },
      {
        type: "body",
        parameters: [],
      },
    ];
    const templatePayload = {
      contactId: selChatData?.contact._id,
      templateId: selectedTemplate?.id,
      inboxId: selChatData?._id,
      variables:
        selectedTemplate?.category?.toLowerCase() != "authentication"
          ? emptyVarArr
          : variable.length == 0
          ? emptyVarArr
          : variable,
    };
    if (selectedTemplate.category == "AUTHENTICATION") {
      templatePayload.variables[1] = {
        type: "button",
        sub_type: "url",
        index: "0",
        parameters: templatePayload.variables[0].parameters,
      };
    }

    try {
      console.log("template is going to be sent  ");
      const resp = await sendTemplate(templatePayload);
      setSending(false);
      console.log("resp of template send", resp.data);
      if (resp.success) {
        console.log("Template Sent Successfully");
        if (
          resp.data.template &&
          resp.data.template.category !== "AUTHENTICATION"
        ) {
          resp.data.template = selectedTemplate;
        }
        // setting current conversation
        const sortedMessages = sortAndGroupMessages([
          ...selChatData?.messages,
          resp.data,
        ]);
        dispatch(
          setCurrConversationsData({
            isGetMsgs: false,
            _id: selChatData?._id,
            messages: sortedMessages,
            chatAssignee: null,
            tagsAssigned: selChatData?.tag,
            contact: selChatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: selChatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(selChatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: selChatData?.conversationExpiry,
            },
            block: selChatData?.block,
          })
        );
        setSelectedTemplate(null);
        await handleMarkMessageAsRead([...selChatData?.messages, resp?.data]);
        // updating message in current chat and also updating chatList according to current time
        updateChatDataInReduxOnSendMsg(resp.data, true);

        // checking if chat has no agent assigning that chat to agent
        //if(!storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers || storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length == 0){
        // assignChatToAgentFirstReplied(selChatData?._id)
        //}
      } else {
        toast.error(resp.error);
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log("Error Sending Template");
    }
  };

  const handleSendFlow = async () => {
    setSending(true);

    const flowPayload = {
      contactId: selChatData?.contact._id,
      flowId: selectedFlow?.flowId,
      inboxId: selChatData?._id,
      status: selectedFlow?.status,
    };

    try {
      console.log("flow is going to be sent  ");
      const resp = await sendChatMessage(flowPayload);
      setSending(false);
      console.log("resp of flow send", resp.data);
      if (resp.success) {
        console.log("Flow Sent Successfully");
        toast.success("Flow Sent Successfully");
        setSelectedFlow(null);

        // setting current conversation
        const sortedMessages = sortAndGroupMessages([
          ...selChatData?.messages,
          resp.data,
        ]);
        dispatch(
          setCurrConversationsData({
            isGetMsgs: false,
            _id: selChatData?._id,
            messages: sortedMessages,
            chatAssignee: null,
            tagsAssigned: selChatData?.tag,
            contact: selChatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: selChatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(selChatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: selChatData?.conversationExpiry,
            },
            block: selChatData?.block,
          })
        );
        await handleMarkMessageAsRead([...selChatData?.messages, resp?.data]);
        // updating message in current chat and also updating chatList according to current time
        updateChatDataInReduxOnSendMsg(resp.data, true);

        // checking if chat has no agent assigning that chat to agent
        //if(!storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers || storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length == 0){
        // assignChatToAgentFirstReplied(selChatData?._id)
        //}
      } else {
        toast.error(resp.error);
        // setSelectedFlow(null);
        throw new Error(resp.error);
      }
    } catch (error) {
      console.log("Error Sending Flow");
      // setSelectedFlow(null);

      toast.error(error.error);
    }
  };

  function handleMsg(e) {
    e.stopPropagation();
    setMsg(e.target.value);
  }

  // sending message on hitting enter
  const onEnterHandler = (e) => {
    // Check if Shift + Enter is pressed
    if (e.key === "Enter" && e.shiftKey) {
      // Prevent the default behavior of the Enter key
      e.preventDefault();
      // Insert a newline character at the cursor position
      const { selectionStart, selectionEnd } = e.target;
      const newText =
        msg.slice(0, selectionStart) + "\n" + msg.slice(selectionEnd);

      // Update the message state with the new text
      setMsg(newText);

      // Set the cursor position after the newline
      setTimeout(() => {
        e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
      }, 0);
    } else if (e.key === "Enter") {
      // Prevent the default behavior of the Enter key to avoid newline
      e.preventDefault();
      // Call your sendMessage function
      sendMessage();
    }
  };

  useMemo(() => {
    if (msg.length === 0) {
      setSelectedTemplate(null);
    }
  }, [msg]);

  // check selected file size
  const getSelectedFileType = () => {
    const file = selectedFile;
    if (file) {
      const fileExtension = file?.name?.split(".").pop().toLowerCase();

      if (
        ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension)
      ) {
        return "image";
      } else if (["mp4", "webm", "ogg", "avi", "mov"].includes(fileExtension)) {
        return "video";
      } else if (
        ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"].includes(
          fileExtension
        )
      ) {
        return "document";
      } else {
        return null;
      }
    }
  };

  // render selected image while sending
  const RenderSelectedImageWhileSending = ({ selectedFile, type }) => {
    if (!selectedFile) {
      return null;
    }

    if (type == "image") {
      return (
        <img
          src={URL.createObjectURL(selectedFile)}
          style={{ width: "200px", height: "200px", borderRadius: "10px" }}
        />
      );
    }

    if (type == "video") {
      return (
        <ReactPlayer
          controls
          url={URL.createObjectURL(selectedFile)}
          width="200px"
          height={"180px"}
        />
      );
    }

    if (type == "document") {
      return (
        <div
          onClick={() =>
            window.open(URL.createObjectURL(selectedFile), "_blank")?.focus()
          }
          style={{
            cursor: "pointer",
            backgroundColor: "rgba(188, 241, 213, 1)",
            padding: "15px",
            columnGap: "15px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img src={pdfIcon} style={{ width: "20px", height: "20px" }} />
          <p style={{ fontSize: "14px", fontWeight: 600 }}>
            {selectedFile?.name?.length > 100
              ? selectedFile?.name?.substring(0, 100) + "..."
              : selectedFile?.name}
          </p>
        </div>
      );
    }

    return <p style={{ fontSize: "14px", fontWeight: 600 }}>Selected File</p>;
  };
  // render selected file/template showing component
  const ShowSelectedFileView = () => {
    const fileType = getSelectedFileType();

    return (
      <div style={{ width: "100%", marginBottom: "30px" }}>
        <div className="row mx-0 w-100">
          <div className="text-input" style={{ marginLeft: "0%" }}>
            <div style={{ position: "relative" }}>
              {selectedFile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: "15px",
                  }}
                >
                  <RenderSelectedImageWhileSending
                    type={fileType}
                    selectedFile={selectedFile}
                  />
                  <img
                    src={Closeic}
                    alt="close-icon"
                    style={{
                      cursor: "pointer",
                      width: "20px",
                    }}
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // render selected template view while sending
  const ShowSelectedTemplateView = () => {
    return (
      <div style={{ flex: "10" }}>
        <div className="row mx-0 w-100">
          <div className="text-input" style={{ marginLeft: "0%" }}>
            {contactSelected ? (
              <div>
                {/* // style={{ position: 'relative' }}> */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "15px",
                    backgroundColor: "white", // Add your background color here
                    padding: "5px 10px", // Optional padding to make it look better
                    borderRadius: "5px",
                    // Optional border-radius for rounded corners
                  }}
                >
                  <div>
                    Name: {contactSelected.Name}
                    <br />
                    Phone: {contactSelected.Number}
                  </div>
                  <img
                    src={Closeic}
                    alt="close-icon"
                    style={{
                      cursor: "pointer",
                      width: "20px",
                    }}
                    onClick={() => {
                      setContactSelected(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                {/* // style={{ position: 'relative' }}> */}
                {selectedTemplate && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      // gap: "15px",
                      backgroundColor: "white", // Add your background color here
                      padding: "5px 10px", // Optional padding to make it look better
                      borderRadius: "5px",
                      minHeight: "46px",

                      // Optional border-radius for rounded corners
                    }}
                  >
                    <p
                      style={{
                        wordWrap: "break-word",
                        backgroundColor: "#165E5B",
                        color: "white",
                        padding: "9px 12px",
                        borderRadius: "4px",
                        display: "flex",
                        gap: "3px",
                        maxHeight: "38px",
                        fontSize: screenWidth > 767 ? "16px" : "12px",
                      }}
                    >
                      {" "}
                      <img src={TemplateIcon} alt="tem-icon" />{" "}
                      {screenWidth > 767
                        ? selectedTemplate.name
                        : selectedTemplate?.name?.length > 10
                        ? `${selectedTemplate.name.slice(0, 10)}...`
                        : selectedTemplate.name}
                    </p>
                    <img
                      src={Closeic}
                      alt="close-icon"
                      style={{
                        cursor: "pointer",
                        width: "20px",
                      }}
                      onClick={() => {
                        setSelectedTemplate(null);
                      }}
                    />
                  </div>
                )}

                {selectedFlow && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      // gap: "15px",
                      backgroundColor: "white", // Add your background color here
                      padding: "5px 10px", // Optional padding to make it look better
                      borderRadius: "5px",
                      minHeight: "46px",

                      // Optional border-radius for rounded corners
                    }}
                  >
                    <p
                      style={{
                        wordWrap: "break-word",
                        backgroundColor: "#165E5B",
                        color: "white",
                        padding: "9px 12px",
                        borderRadius: "4px",
                        display: "flex",
                        gap: "3px",
                        maxHeight: "38px",
                        fontSize: screenWidth > 767 ? "16px" : "12px",
                      }}
                    >
                      {" "}
                      <img src={TemplateIcon} alt="tem-icon" />{" "}
                      {screenWidth > 767
                        ? selectedFlow?.name
                        : selectedFlow?.name?.length > 10
                        ? `${selectedFlow?.name?.slice(0, 10)}...`
                        : selectedFlow?.name}
                    </p>
                    <img
                      src={Closeic}
                      alt="close-icon"
                      style={{
                        cursor: "pointer",
                        width: "20px",
                      }}
                      onClick={() => {
                        setSelectedFlow(null);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // update mute/unmute of chat
  const updateBlockStatusOfChatEvent = (chatId: string) => {
    const allData = store.getState();
    const { allConversations } = allData?.ChatsReducer;

    let allConvs = [...allConversations];
    const isFound = allConvs.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    console.log("isFound", isFound);
    if (isFound > -1) {
      const blockStatus = allConvs[isFound].block == true ? false : true;
      allConvs[isFound] = { ...allConvs[isFound], block: blockStatus };
      sortConversations(allConvs);
    }
  };

  // event listener for delete chat in list
  useEffect(() => {
    document.addEventListener("chatBlockChangedEvent", (data) => {
      console.log("got parsms ", data?.detail);
      updateBlockStatusOfChatEvent(data?.detail?.chatId);
    });

    return () => {
      document.removeEventListener("chatBlockChangedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // Delete conversation
  const handleDeleteChat = async (chatId: string) => {
    console.log("chat id", chatId);
    setDtShow(false);
    const res = await deleteChat(chatId);
    // const response = await getInbox()

    // let sortedData = sortConversations([...response.data])
    // dispatch(setConversationsData(sortedData))
    if (res?.success) {
      let allConvers = [...storedConversations];
      let filteredList = allConvers.filter(
        (item: singleChatDTO) => item?._id != chatId
      );
      console.log("filtred list", filteredList);
      if (filteredList?.length > 0) {
        getSingleConversData(filteredList[0]?._id);
        setNewChatId(filteredList[0]?._id);
      }

      // let sortedData = sortConversations(filteredList)
      dispatch(removeConversation({ userList: filteredList, chatId }));
      // dispatch(removeCurrConversationsData(null))

      toast.success("Chat removed successfully");
    }
    console.log("res111", res);
  };
  // update block/unblock of chat
  const updateBlockStatusOfChat = (chatId: string) => {
    let allConvs = [...storedConversations];
    const isFound = allConvs.findIndex(
      (item: singleChatDTO) => item?._id == chatId
    );
    if (isFound > -1) {
      const blockStatus = allConvs[isFound].block == true ? false : true;
      allConvs[isFound] = { ...allConvs[isFound], block: blockStatus };
      console.log("allConvs[isFound]", allConvs[isFound]);
      sortConversations(allConvs);
    }
  };

  // Block conversation
  const handleBlockChat = async (chatId: string) => {
    setBlockShow(false);
    const block = "1";
    const res = await blockChat({ chatId, block });
    if (res?.success) {
      setIsBlocked(true);
      updateBlockStatusOfChat(chatId);
      toast.success("Chat blocked successfully");
    }
  };

  const handleUnblockClick = async (chatId: string) => {
    const block = "0";
    const res = await blockChat({ chatId, block });
    if (res?.success) {
      setIsBlocked(false);
      updateBlockStatusOfChat(chatId);
      toast.success("Chat unblocked successfully");
    }
  };

  // useEffect(() => {
  //     let resp;
  //     const getChat = async () => {
  //         resp = await getSingleChat(selChatData?._id, 10, null);
  //         setIsBlocked(resp?.data?.chat?.block)
  //         setIsMuted(resp?.data?.chat?.mute)
  //     }

  //     if (selChatData?._id != "") {
  //         getChat()
  //     }

  // }, [selChatData])

  const WatchIcon = () => {
    return <></>;
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99958 13.0008C5.23793 13.0008 2.99916 10.7621 2.99916 8.00042C2.99916 5.23876 5.23793 3 7.99958 3C10.7612 3 13 5.23876 13 8.00042C13 10.7621 10.7612 13.0008 7.99958 13.0008Z"
          stroke="black"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99994 6V8.50021H10.1876"
          stroke="black"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  // show message delivery status
  const renderStatusTicks = (message: singleMessageDTO) => {
    console.log("message", message);
    if (message?.failed)
      // return <svg viewbox="0 0 16 15" ><path d="M22.675,0.02c-0.006,0-0.014,0.001-0.02,0.001c-0.007,0-0.013-0.001-0.02-0.001C10.135,0.02,0,10.154,0,22.656   c0,12.5,10.135,22.635,22.635,22.635c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0c12.5,0,22.635-10.135,22.635-22.635   C45.311,10.154,35.176,0.02,22.675,0.02z M22.675,38.811c-0.006,0-0.014-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001   c-2.046,0-3.705-1.658-3.705-3.705c0-2.045,1.659-3.703,3.705-3.703c0.007,0,0.013,0,0.02,0c0.006,0,0.014,0,0.02,0   c2.045,0,3.706,1.658,3.706,3.703C26.381,37.152,24.723,38.811,22.675,38.811z M27.988,10.578   c-0.242,3.697-1.932,14.692-1.932,14.692c0,1.854-1.519,3.356-3.373,3.356c-0.01,0-0.02,0-0.029,0c-0.009,0-0.02,0-0.029,0   c-1.853,0-3.372-1.504-3.372-3.356c0,0-1.689-10.995-1.931-14.692C17.202,8.727,18.62,5.29,22.626,5.29   c0.01,0,0.02,0.001,0.029,0.001c0.009,0,0.019-0.001,0.029-0.001C26.689,5.29,28.109,8.727,27.988,10.578z" /></svg>
      return <>💀</>;
    else if (message?.read)
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3012_22038)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.4955 4.29651C14.8334 4.53617 14.913 5.00433 14.6733 5.34216L9.90781 12.0597C9.66965 12.3955 9.20541 12.4765 8.86759 12.2413L6.18271 10.3722C5.84277 10.1355 5.75904 9.66807 5.99571 9.32813C6.23237 8.98818 6.6998 8.90445 7.03975 9.14112L9.11452 10.5855L13.4499 4.47426C13.6896 4.13643 14.1577 4.05684 14.4955 4.29651Z"
              fill="#01B353"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4955 4.29651C10.8334 4.53617 10.913 5.00433 10.6733 5.34216L5.90781 12.0597C5.66965 12.3955 5.20541 12.4765 4.86759 12.2413L2.18271 10.3722C1.84277 10.1355 1.75904 9.66807 1.99571 9.32813C2.23237 8.98818 2.6998 8.90445 3.03975 9.14112L5.11452 10.5855L9.44989 4.47426C9.68955 4.13643 10.1577 4.05684 10.4955 4.29651Z"
              fill="#01B353"
            />
          </g>
          <defs>
            <clipPath id="clip0_3012_22038">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    else if (message?.delivered)
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_5692_25330)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.4955 4.29651C14.8334 4.53617 14.913 5.00433 14.6733 5.34216L9.90781 12.0597C9.66965 12.3955 9.20541 12.4765 8.86759 12.2413L6.18271 10.3722C5.84277 10.1355 5.75904 9.66807 5.99571 9.32813C6.23237 8.98818 6.6998 8.90445 7.03975 9.14112L9.11452 10.5855L13.4499 4.47426C13.6896 4.13643 14.1577 4.05684 14.4955 4.29651Z"
              fill="#4B4A4A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4955 4.29651C10.8334 4.53617 10.913 5.00433 10.6733 5.34216L5.90781 12.0597C5.66965 12.3955 5.20541 12.4765 4.86759 12.2413L2.18271 10.3722C1.84277 10.1355 1.75904 9.66807 1.99571 9.32813C2.23237 8.98818 2.6998 8.90445 3.03975 9.14112L5.11452 10.5855L9.44989 4.47426C9.68955 4.13643 10.1577 4.05684 10.4955 4.29651Z"
              fill="#4B4A4A"
            />
          </g>
          <defs>
            <clipPath id="clip0_5692_25330">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    else if (message?.sent)
      return (
        <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.49555 0.296506C9.83339 0.536169 9.91297 1.00433 9.67331 1.34216L4.90781 8.05974C4.66965 8.39547 4.20541 8.4765 3.86759 8.24132L1.18271 6.37217C0.842769 6.13551 0.759042 5.66807 0.995705 5.32813C1.23237 4.98818 1.6998 4.90445 2.03975 5.14112L4.11452 6.58553L8.44989 0.474264C8.68955 0.136427 9.15771 0.0568414 9.49555 0.296506Z"
            fill="#4B4A4A"
          />
        </svg>
      );
    else
      return (
        <svg viewBox="0 0 14 18" width="14" height="18" className="">
          <path
            fill="#858585"
            d="M8.906 10.132h-1.64V7.569c0-.3-.243-.545-.545-.545h-.102c-.3 0-.545.243-.545.545v3.21c0 .3.243.545.545.545h.102l.003-.001.003.001h2.178c.3 0 .545-.243.545-.545v-.102a.544.544 0 0 0-.544-.545zm0-5.732H5.094A3.598 3.598 0 0 0 1.5 7.994v3.812A3.598 3.598 0 0 0 5.094 15.4h3.812a3.598 3.598 0 0 0 3.594-3.594V7.994A3.598 3.598 0 0 0 8.906 4.4zm2.178 7.406a2.178 2.178 0 0 1-2.178 2.178H5.094a2.178 2.178 0 0 1-2.178-2.178V7.994c0-1.203.975-2.178 2.178-2.178h3.812c1.203 0 2.178.975 2.178 2.178v3.812z"
          ></path>
        </svg>
      );
  };

  // get matched tags
  const getMatchedTags = () => {
    let temp = [];
    for (let i = 0; i != allCompanyUsers?.length; i++) {
      let tagsArr = allCompanyUsers[i]?.tags?.map((item) => item?._id);
      temp.push({ ...allCompanyUsers[i], finalTags: tagsArr });
    }
    if (selTagType?.value == "" || selTagType?.value == "all") {
      return temp;
    }
    return temp?.filter((item) =>
      item?.finalTags?.includes(selTagType?.value?.toLowerCase())
    );
  };

  // handle close assign tag modal
  const handleCloseAssignTagModal = () => {
    setAtsShow(false);
    setSelTagType({ label: "", value: "" });
  };

  async function scrollToMessage(message) {
    const messageId = message._id;
    console.log("messageId===", messageId);
    const messageElement = document.getElementById(messageId);

    // if found, means that msg has rendered, so no need of fetching that msg
    if (messageElement) {
      // Save original width and height
      const originalWidth = messageElement.offsetWidth;
      const originalHeight = messageElement.offsetHeight;
      const originalFontSize = parseInt(
        window.getComputedStyle(messageElement).fontSize
      );

      // Enlarge element by 10% using CSS transition
      //messageElement.style.width = `${originalWidth * 1.1}px`;
      messageElement.style.height = `${originalHeight * 1.1}px`;
      messageElement.style.fontSize = `${originalFontSize * 1.1}px`;
      messageElement.style.transition = "all 0.2s ease";
      messageElement.style.backgroundColor = "#f0f2f0";

      messageElement.style.filter = "brightness(90%)";

      // Wait for 2 seconds before returning to original size
      setTimeout(() => {
        messageElement.style.width = `${originalWidth}px`;
        messageElement.style.height = `${originalHeight}px`;
        messageElement.style.fontSize = `${originalFontSize}px`;
        messageElement.style.transition = "all 0.2s ease";
        messageElement.style.backgroundColor = "transparent";
        messageElement.style.filter = "none";
      }, 1000);

      // Scroll to the message element
      messageElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });

      //refTwo.current.scrollIntoView({ behavior: "smooth", block: "end" })
    } else {
      const resp = await getSingleChat(
        selChatData?._id,
        currChatMsgsLength,
        messageId
      );

      console.log("resp of fetching not in in view : ", resp);
      if (resp?.success) {
        const chatData = resp?.data?.chat;
        setBlock(chatData?.block);
        const sortedMessages = sortAndGroupMessages(chatData?.messages);
        setSelChatData({
          isGetMsgs: false,
          _id: chatData?._id,
          messages: sortedMessages,
          tagsAssigned: chatData?.tag,
          chatAssignee: null,
          contact: chatData?.contact,
          notes: [],
          generalInfo: {
            phoneNo: chatData?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) == true
                ? "Active"
                : "Inactive",
            remaining: chatData?.conversationExpiry,
          },
          block: chatData?.block,
        });

        // setting current conversation
        dispatch(
          setCurrConversationsData({
            isGetMsgs: false,
            _id: chatData?._id,
            messages: sortedMessages,
            tagsAssigned: chatData?.tag,
            chatAssignee: null,
            contact: chatData?.contact,
            notes: [],
            generalInfo: {
              phoneNo: chatData?.contact?.number,
              conversationStatus:
                isDateTimeGreaterThanCurrent(chatData?.conversationExpiry) ==
                true
                  ? "Active"
                  : "Inactive",
              remaining: chatData?.conversationExpiry,
            },
            block: chatData?.block,
          })
        );
        setCurrMsgsLength(resp?.data?.chat?.messages?.length);

        // update data of chat in redux
        updateCurrChatMsgsInRedux(sortedMessages, chatData, false);
        setMessage(true);
        await handleMarkMessageAsRead(chatData?.messages);

        scrollToMessage(message);
      }
    }
  }

  // render chat tag
  const RenderTagSection = ({ chatData, assignee }) => {
    console.log("assigneee", assignee);
    const renderTagAssigned = useCallback(() => {
      // if(!assignee?.tag){
      //     return (<></>)
      // }

      let maxUsersToShow;
      if (screenWidth >= 1439) {
        maxUsersToShow = 4;
      } else if (screenWidth < 1439 && screenWidth >= 1200) {
        maxUsersToShow = 2;
      } else {
        maxUsersToShow = 1;
      }
      const assignedUsersLength = storedConversations.find(
        (item) => item?._id == selChatData?._id
      )?.assingUsers?.length;

      const showMore = assignedUsersLength > maxUsersToShow;

      return (
        <ul className="nav align-items-center mb-2 mt-4">
          <li
            className="nav-item  mr-5 "
            style={{ fontWeight: "600" }}
            onClick={() => {
              if (canUserPerformAction) {
                setAtsShow(true);
              }
            }}
          >
            Assigned to:
          </li>
          {storedConversations.find((item) => item?._id == selChatData?._id)
            ?.assingUsers?.length <= 0 && (
            <li
              className="user-name cursor-pointer"
              style={{ fontSize: "14px" }}
              onClick={() => {
                if (canUserPerformAction) {
                  setAtsShow(true);
                }
              }}
            >
              {/* {chatTag} */}
              Select Agent
            </li>
          )}
          <li
            className="nav-item"
            style={{ cursor: !canUserPerformAction ? "no-drop" : "" }}
            onClick={() => {
              if (canUserPerformAction) {
                setAtsShow(true);
              }
            }}
          >
            {!canUserPerformAction ? (
              <Tooltip title={"You cannot perform this action"} arrow>
                <div className="d-flex align-items-center gap-2">
                  {/* <RenderTagSection chatData={selChatData} assignee={storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length > 0 ? storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers[0] : storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length > 0 && storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers[0]} /> */}
                  <div
                    className="dropdown-icon-side "
                    style={{ cursor: !canUserPerformAction ? "no-drop" : "" }}
                  >
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 10 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0L5 5L10 0H0Z" fill="black" />
                    </svg>
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className="d-flex align-items-center gap-2">
                <div
                  className="dropdown-icon-side "
                  style={{ cursor: !canUserPerformAction ? "no-drop" : "" }}
                >
                  <svg
                    width="10"
                    height="5"
                    viewBox="0 0 10 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0L5 5L10 0H0Z" fill="black" />
                  </svg>
                </div>
              </div>
            )}
          </li>
          <li className="nav-item">
            <div className="user-name">
              {storedConversations.find((item) => item?._id == selChatData?._id)
                ?.assingUsers?.length > 0 ? (
                <>
                  {screenWidth >= 1439
                    ? storedConversations
                        .find((item) => item?._id == selChatData?._id)
                        ?.assingUsers.slice(0, 4)
                        .map((user, index) => {
                          const truncatedName =
                            user?.firstname.length > 8
                              ? `${user?.firstname.slice(0, 8)}...`
                              : user?.firstname;
                          return (
                            <Tooltip
                              key={index}
                              title={user?.firstname + " " + user?.lastname}
                              arrow
                            >
                              <span
                                className="flex gap-1 justify-center align-middle"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#4A4D50",
                                  borderRadius: "30px",
                                  margin: "2px",
                                  padding: "6px 15px",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <img src={AgentIcon} alt="Agent" /> */}
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5 5C4.3125 5 3.72396 4.75521 3.23438 4.26562C2.74479 3.77604 2.5 3.1875 2.5 2.5C2.5 1.8125 2.74479 1.22396 3.23438 0.734375C3.72396 0.244792 4.3125 0 5 0C5.6875 0 6.27604 0.244792 6.76562 0.734375C7.25521 1.22396 7.5 1.8125 7.5 2.5C7.5 3.1875 7.25521 3.77604 6.76562 4.26562C6.27604 4.75521 5.6875 5 5 5ZM0 10V8.25C0 7.89583 0.0911458 7.57031 0.273438 7.27344C0.455729 6.97656 0.697917 6.75 1 6.59375C1.64583 6.27083 2.30208 6.02865 2.96875 5.86719C3.63542 5.70573 4.3125 5.625 5 5.625C5.6875 5.625 6.36458 5.70573 7.03125 5.86719C7.69792 6.02865 8.35417 6.27083 9 6.59375C9.30208 6.75 9.54427 6.97656 9.72656 7.27344C9.90885 7.57031 10 7.89583 10 8.25V10H0Z"
                                    fill="#4A4D50"
                                  />
                                </svg>
                                {truncatedName}
                              </span>
                            </Tooltip>
                          );
                        })
                    : screenWidth >= 1200
                    ? storedConversations
                        .find((item) => item?._id == selChatData?._id)
                        ?.assingUsers.slice(0, 2)
                        .map((user, index) => {
                          const truncatedName =
                            user?.firstname.length > 8
                              ? `${user?.firstname.slice(0, 8)}...`
                              : user?.firstname;
                          return (
                            <Tooltip
                              key={index}
                              title={user?.firstname + " " + user?.lastname}
                              arrow
                            >
                              <span
                                key={index}
                                className="flex gap-1 justify-center align-middle"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#4A4D50",
                                  borderRadius: "30px",
                                  margin: "3px",
                                  padding: "6px 8px",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <img src={AgentIcon} alt="Agent" /> */}
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5 5C4.3125 5 3.72396 4.75521 3.23438 4.26562C2.74479 3.77604 2.5 3.1875 2.5 2.5C2.5 1.8125 2.74479 1.22396 3.23438 0.734375C3.72396 0.244792 4.3125 0 5 0C5.6875 0 6.27604 0.244792 6.76562 0.734375C7.25521 1.22396 7.5 1.8125 7.5 2.5C7.5 3.1875 7.25521 3.77604 6.76562 4.26562C6.27604 4.75521 5.6875 5 5 5ZM0 10V8.25C0 7.89583 0.0911458 7.57031 0.273438 7.27344C0.455729 6.97656 0.697917 6.75 1 6.59375C1.64583 6.27083 2.30208 6.02865 2.96875 5.86719C3.63542 5.70573 4.3125 5.625 5 5.625C5.6875 5.625 6.36458 5.70573 7.03125 5.86719C7.69792 6.02865 8.35417 6.27083 9 6.59375C9.30208 6.75 9.54427 6.97656 9.72656 7.27344C9.90885 7.57031 10 7.89583 10 8.25V10H0Z"
                                    fill="#4A4D50"
                                  />
                                </svg>
                                {truncatedName}
                              </span>
                            </Tooltip>
                          );
                        })
                    : storedConversations
                        .find((item) => item?._id == selChatData?._id)
                        ?.assingUsers.slice(0, 1)
                        .map((user, index) => {
                          const truncatedName =
                            user?.firstname.length > 8
                              ? `${user?.firstname.slice(0, 8)}...`
                              : user?.firstname;
                          return (
                            <Tooltip
                              key={index}
                              title={user?.firstname + " " + user?.lastname}
                              arrow
                            >
                              <span
                                key={index}
                                className="flex gap-1 justify-center align-middle"
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  color: "#4A4D50",
                                  borderRadius: "30px",
                                  padding: "6px 8px",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {/* <img src={AgentIcon} alt="Agent" /> */}
                                <svg
                                  width="10"
                                  height="10"
                                  viewBox="0 0 10 10"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5 5C4.3125 5 3.72396 4.75521 3.23438 4.26562C2.74479 3.77604 2.5 3.1875 2.5 2.5C2.5 1.8125 2.74479 1.22396 3.23438 0.734375C3.72396 0.244792 4.3125 0 5 0C5.6875 0 6.27604 0.244792 6.76562 0.734375C7.25521 1.22396 7.5 1.8125 7.5 2.5C7.5 3.1875 7.25521 3.77604 6.76562 4.26562C6.27604 4.75521 5.6875 5 5 5ZM0 10V8.25C0 7.89583 0.0911458 7.57031 0.273438 7.27344C0.455729 6.97656 0.697917 6.75 1 6.59375C1.64583 6.27083 2.30208 6.02865 2.96875 5.86719C3.63542 5.70573 4.3125 5.625 5 5.625C5.6875 5.625 6.36458 5.70573 7.03125 5.86719C7.69792 6.02865 8.35417 6.27083 9 6.59375C9.30208 6.75 9.54427 6.97656 9.72656 7.27344C9.90885 7.57031 10 7.89583 10 8.25V10H0Z"
                                    fill="#4A4D50"
                                  />
                                </svg>
                                {truncatedName}
                              </span>
                            </Tooltip>
                          );
                        })}
                  {showMore &&
                    (!canUserPerformAction ? (
                      <Tooltip title={"You cannot perform this action"} arrow>
                        {" "}
                        <span
                          className="flex gap-2 justify-center align-middle"
                          style={{
                            border: "1px solid #4A4D50",
                            color: "#4A4D50",
                            borderRadius: "30px",
                            margin: "5px",
                            padding: "6px 10px",
                            fontWeight: "500",
                            fontSize: "14px",
                            cursor: !canUserPerformAction ? "no-drop" : "",
                          }}
                          onClick={() => {
                            if (canUserPerformAction) {
                              setAtsShow(true);
                            }
                          }}
                        >
                          {`+${assignedUsersLength - maxUsersToShow} More`}
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        className="flex gap-2 justify-center align-middle"
                        style={{
                          border: "1px solid #4A4D50",
                          color: "#4A4D50",
                          borderRadius: "30px",
                          margin: "5px",
                          padding: "6px 10px",
                          fontWeight: "500",
                          fontSize: "14px",
                          cursor: !canUserPerformAction ? "no-drop" : "",
                        }}
                        onClick={() => {
                          if (canUserPerformAction) {
                            setAtsShow(true);
                          }
                        }}
                      >
                        {`+${assignedUsersLength - maxUsersToShow} More`}
                      </span>
                    ))}
                </>
              ) : null}
            </div>
          </li>
        </ul>
      );
    }, [chatData?.tagsAssigned, assignee]);

    const tagElement = React.useMemo(
      () => renderTagAssigned(),
      [renderTagAssigned]
    );

    return tagElement;
  };

  const showImage = () => {
    setImageShow(true);
  };

  const hideImage = () => {
    setImageShow(false);
    setFile(null);
    setImage(null);
    setSelectedFile(null);
  };

  // ask camera permission
  const requestCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setIsCamPerGranted(true);
      setIsShowCamPermisPopup(false);
      setCamshow(true);
      // Stop the video stream if it's just for permission check
      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      console.error("Error accessing camera:", error);
      setIsCamPerGranted(false);
      setIsShowCamPermisPopup(true);
    }
  };

  const handleImageUpload = () => {
    ImageInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);

      const fileType = file.type.startsWith("image/")
        ? "image"
        : file.type.startsWith("video/")
        ? "video"
        : null;
      const fileUrl = URL.createObjectURL(file);

      if (fileType) {
        setImage({ url: fileUrl, type: fileType });
      } else {
        console.error("Unsupported file type:", file.type);
      }
    }
  };

  const [newContactData, setNewContactData] = useState({
    contactName: "",
    number: "",
    canMessage: true,
    canBroadcast: true,
  });
  const showDoc = () => {
    setDocShow(true);
  };
  const hideDoc = () => {
    setDocShow(false);
    setFile(null);
    setSelectedFile(null);
  };

  // Determine the icon to display based on the file type
  const renderIcon = () => {
    if (!file) return null;
    console.log("file0", file.type);
    if (file.type.includes("pdf")) return <img src={Pdf_file} alt="PDF Icon" />;
    if (
      file.type.includes("excel") ||
      file.type.includes("sheet") ||
      file.type.includes("csv")
    )
      return <img src={Xls_file} alt="Excel Icon" />;
    if (file.type.includes("text/plain"))
      return <img src={Text_file} alt="Text File" />;
    if (file.type.includes("word"))
      return <img src={Doc_file} alt="Word Icon" />;
    if (file.type.includes("presentation") || file.type.includes("ppt"))
      return <img src={Ppt_file} alt="Ppt-icon" />;
    return <img src={Doc_file} alt="Default Icon" />;
  };
  // get replied user data
  const getRepliedUserData = (userId: string) => {
    const selectedUserData =
      allCompanyUsers?.length > 0 &&
      selChatData &&
      allCompanyUsers?.find((item) => item?._id == userId);
    console.log("selectedUserData", selectedUserData);
    return selectedUserData;
  };

  const onStop = async (recordedBlob) => {
    console.log("recordedBlob", recordedBlob);

    // Convert the Blob into a File with the correct MIME type (audio/ogg)
    const file = new File([recordedBlob.blob], "voice-message.ogg", {
      type: "audio/ogg",
    });

    console.log("audiofile", file);

    // Set the Blob URL for audio preview and store the file for sending
    setAudioBlob(recordedBlob.blobURL); // This is for preview
    setSelectedFile(file); // Save the file with the correct format (audio/ogg)
  };
  // useEffect(() => {
  //     if (audioBlob) {
  //       const url = audioBlob;
  //       audioRef.current.src = url;

  //       const handleLoadedMetadata = () => {
  //         setAudioDuration(audioRef.current.duration);
  //       };

  //       // Add event listener for loaded metadata
  //       audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

  //       // Clean up the event listener
  //       return () => {
  //         audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
  //       };
  //     }
  //   }, [audioBlob]);

  const deleteAudioHandler = () => {
    setRecording(false);
    setAudioBlob(null);
    setSelectedFile(null);
  };

  const startAudioHandler = async () => {
    const hasPermission = await checkMicrophonePermission();
    if (hasPermission) {
      setRecording(true);
    } else {
      alert("Microphone permission is required to start recording.");
    }
  };
  const stopAudioHandler = () => {
    setRecording(false);
  };
  const clearAudioHandler = () => {};

  useEffect(() => {
    if (recording) {
      const startTime = Date.now();
      const intervalId = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startTime) / 1000); // Convert to seconds
        setElapsedTime(elapsed);
      }, 1000);

      // Save the interval ID in state
      setTimer(intervalId);
    } else {
      // Clear the timer when recording stops
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setElapsedTime(0); // Reset time
    }

    // Cleanup the interval when component unmounts or state changes
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [recording]);

  // Format elapsedTime into minutes:seconds
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    // Add leading zero to seconds if less than 10
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };
  // remove deleted message from redux
  const removeDelMsgFromRedux = (chatId: string, msgId: string) => {
    const allData = store.getState();
    const { allChatsData, allConversations } = allData?.ChatsReducer;

    let allChats = [...allChatsData];
    const isFound = allChats.findIndex(
      (item: singleChatDTO) => item?.id == chatId
    );
    console.log("isFound in msg delete", isFound);
    if (isFound > -1) {
      let matchedBj = allChats[isFound];
      let newMsgsList = [...matchedBj?.messages];
      let filteredMsgs = newMsgsList?.filter(
        (item: singleMessageDTO) => item?._id != msgId
      );
      console.log("matchedBj", matchedBj, ":", filteredMsgs);
      dispatch(
        setChatsData({
          conversationId: chatId,
          id: chatId,
          messages: filteredMsgs,
          tags: matchedBj?.tags,
          contact: matchedBj?.contact,
          generalInfo: {
            phoneNo: matchedBj?.generalInfo?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(
                matchedBj?.generalInfo?.conversationExpiry
              ) == true
                ? "Active"
                : "Inactive",
            remaining: matchedBj?.generalInfo?.conversationExpiry,
          },
        })
      );

      setSelChatData((prev) => ({ ...prev, messages: filteredMsgs }));

      // checkinmg if this msg is last msg of chat to update in chatlist also
      const allConsv = [...allConversations];
      let isInConv = allConsv.findIndex(
        (item: singleChatDto) => item?._id == chatId
      );
      console.log("isInConv in delete", isInConv);
      if (isInConv > -1) {
        if (allConsv[isInConv]?.messages[0]?._id == msgId) {
          let newObj = { ...allConsv[isInConv]?.messages };
          let newChat = { ...allConsv[isInConv] };
          newChat.messages = [];
          newChat.messages.push(filteredMsgs[filteredMsgs?.length - 1]);
          allConsv[isInConv] = newChat;
          console.log("final msg in chat list delete ", allConsv);
          let sortedData = sortConversations([...allConsv]);
          dispatch(setConversationsData(sortedData));
        }
      }
    }
  };

  // delete any message
  const delMsgForEvryOne = async () => {
    if (!selMsgForOpr) {
      return;
    }
    setIsMsgDelForEveryOne(true);
    const resp = await deleteSingleMsg({ messageId: selMsgForOpr });
    console.log("delete msg resp ", resp);
    if (resp?.success) {
      setIsShowDelMsgPopup(false);
      setSelMsgForOpr(null);
      removeDelMsgFromRedux(selChatData?._id, selMsgForOpr);
      toast.success("Message deleted for everyone");
    } else {
      toast.error(resp?.error);
    }
    setIsMsgDelForEveryOne(false);
  };

  // event listener for message delete
  useEffect(() => {
    document.addEventListener("msgDeletedEvent", (data) =>
      removeDelMsgFromRedux(data?.detail?.chatId, data?.detail?.msgId)
    );

    return () => {
      document.removeEventListener("msgDeletedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // edit single message from redux
  const editMsgInRedux = (
    chatId: string,
    msgId: string,
    newMsg: singleMessageDTO
  ) => {
    const allData = store.getState();
    const { allChatsData, allConversations } = allData?.ChatsReducer;

    let allChats = [...allChatsData];
    const isFound = allChats.findIndex(
      (item: singleChatDTO) => item?.id == chatId
    );
    console.log("isFound in msg edit", isFound);
    if (isFound > -1) {
      let matchedBj = allChats[isFound];
      let newMsgsList = [...matchedBj?.messages];
      let matchedMsg = newMsgsList?.findIndex(
        (item: singleMessageDTO) => item?._id == msgId
      );
      console.log("matchedMsg  ", matchedMsg);
      if (matchedMsg > -1) {
        newMsgsList[matchedMsg] = {
          ...newMsgsList[matchedMsg],
          text: newMsg?.text,
        };
      }
      console.log("matchedBj in edit", matchedBj, ":", newMsgsList);
      dispatch(
        setChatsData({
          conversationId: chatId,
          id: chatId,
          messages: newMsgsList,
          tags: matchedBj?.tags,
          contact: matchedBj?.contact,
          generalInfo: {
            phoneNo: matchedBj?.generalInfo?.contact?.number,
            conversationStatus:
              isDateTimeGreaterThanCurrent(
                matchedBj?.generalInfo?.conversationExpiry
              ) == true
                ? "Active"
                : "Inactive",
            remaining: matchedBj?.generalInfo?.conversationExpiry,
          },
        })
      );

      setSelChatData((prev) => ({ ...prev, messages: newMsgsList }));

      // checkinmg if this msg is last msg of chat to update in chatlist also
      const allConsv = [...allConversations];
      let isInConv = allConsv.findIndex(
        (item: singleChatDto) => item?._id == chatId
      );
      console.log("isInConv", isInConv);
      if (isInConv > -1) {
        if (allConsv[isInConv]?.messages[0]?._id == msgId) {
          let newObj = { ...allConsv[isInConv]?.messages };
          newObj[0] = { ...newObj[0], text: newMsg?.text };
          let newChat = { ...allConsv[isInConv] };
          newChat.messages = [];
          newChat.messages.push(newObj[0]);
          allConsv[isInConv] = newChat;
          console.log("final msg in chat list ", allConsv);
          let sortedData = sortConversations([...allConsv]);
          dispatch(setConversationsData(sortedData));
        }
      }
    }
  };

  // edit any message any message
  const editMsgForEvryOne = async () => {
    if (!selMsgForOpr) {
      return;
    }
    setIsMsgEditForEveryOne(true);
    const resp = await editSingleMsg({
      messageId: selMsgForOpr,
      text: editMsgText,
    });
    console.log("edit msg resp ", resp);
    if (resp?.success) {
      setIsShowEditMsgPopup(false);
      setSelMsgForOpr(null);
      editMsgInRedux(selChatData?._id, selMsgForOpr, resp.data);
      toast.success("Message editted for everyone");
    } else {
      toast.error(resp?.error);
    }
    setIsMsgEditForEveryOne(false);
  };

  // event listener for message edit
  useEffect(() => {
    document.addEventListener("msgEditedEvent", (data) =>
      editMsgInRedux(
        data?.detail?.chatId,
        data?.detail?.updatedMsg?._id,
        data?.detail?.updatedMsg
      )
    );

    return () => {
      document.removeEventListener("msgEditedEvent", () => {
        setSelChatData((prev) => ({
          ...prev,
          _id: "",
          messages: [],
          contact: null,
          block: false,
        }));
        dispatch(removeCurrConversationsData(null));
      });
    };
  }, []);

  // render replied template
  const renderRepliedTemplate = (template) => {
    let text = "";
    if (template?.components?.length > 0) {
      let templateBody = template?.components?.find(
        (item) => item?.type == "BODY"
      )?.text;
      text = templateBody
        ? templateBody?.length > 100
          ? templateBody?.substring(0, 100) + "..."
          : templateBody
        : template?.components[0]?.text;
    } else {
      text = template.header
        ? template.header?.length > 100
          ? template.header?.substring(0, 100) + "..."
          : template.header
        : template.body
        ? template.body?.length > 100
          ? template.body?.substring(0, 100) + "..."
          : template.body
        : template.footer?.length > 100
        ? template.footer?.substring(0, 100) + "..."
        : template.footer;
    }

    return text;
  };
  const renderRepliedFlow = (msg) => {
    return msg?.header?.text;
  };

  const getRepliedMsgType = (msg) => {
    let selMsg = msg;
    let type = "";
    console.log("hitititititittiti", selMsg);
    if (selMsg?.template) {
      type = "template";
    } else if (selMsg?.image) {
      type = "image";
    } else if (selMsg?.video) {
      type = "video";
    } else if (selMsg?.audio) {
      type = "audio";
    } else if (selMsg?.type === "document") {
      type = "document";
    } else if (selMsg?.contacts) {
      type = "contact";
    } else if (selMsg?.interactive?.interactive?.header) {
      type = "flow";
    }
    // else if (selMsg?.context) {
    //     type = "context"
    // }
    else {
      type = "text";
    }

    return type;
  };

  // render replied message
  const renderRepliedMessage = (msg) => {
    let selMsg = msg;
    let type = getRepliedMsgType(msg);

    console.log("type of replied : ", type, ":", msg);

    let msgToRender = null;
    switch (type) {
      case "template":
        msgToRender = renderRepliedTemplate(selMsg?.template);
        break;
      case "flow":
        msgToRender = renderRepliedFlow(selMsg?.interactive?.interactive);
        break;
      case "image":
        msgToRender = (
          <img
            src={selMsg?.image?.link}
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "100px",
              objectFit: "contain",
            }}
          />
        );
        break;
      case "video":
        msgToRender = (
          <video
            controls
            style={{
              maxWidth: "250px",
              maxHeight: "150px",
              objectFit: "contain",
              margin: "20px",
            }}
          >
            <source src={selMsg?.video?.link} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        );
        break;
      case "audio":
        msgToRender = (
          <audio controls>
            <source src={selMsg?.audio?.link} type="audio/ogg" />
            Your browser does not support the audio element.
          </audio>
        );

        break;
      case "document":
        msgToRender = selMsg?.document?.filename;
        break;
      case "contact":
        msgToRender = selMsg?.document?.contact;
        break;
      case "text":
        msgToRender =
          selMsg?.text?.length > 100
            ? selMsg?.text?.slice(0, 100) + "..."
            : selMsg?.text;
        break;
      // case "context":
      //     renderRepliedMessage(msg?.context)
      //     msgToRender = selMsg?.context?.text?.length > 100 ? selMsg?.context?.text?.slice(0, 100) + "..." : selMsg?.context?.text
      //     break;
    }

    return msgToRender;
    // selMsg?.text?.length > 100 ? selMsg?.text?.slice(0,100) + "..." : selMsg?.text
  };

  const selectedUserData =
    storedConversations?.length > 0 &&
    selChatData &&
    storedConversations?.find((item) => item?._id == selChatData?._id);
  const finalConversations = isShowAssignedToMe
    ? storedConversations?.filter(
        (item: singleChatDTO) =>
          item?.assingUsers &&
          item?.assingUsers?.length > 0 &&
          item?.assingUsers?.some((user) => user?._id === userId)
      )
    : storedConversations;
  console.log("heeeeeeee", finalConversations);
  const canUserReply = storedConversations?.find(
    (item) => item?._id == selChatData?._id
  )?.block
    ? false
    : getRepliedUserData(userId)?.role === "admin" ||
      getRepliedUserData(userId)?.role === "owner" ||
      storedConversations?.find((item) => item?._id == selChatData?._id)
        ?.assingUsers?.length == 0 ||
      (storedConversations?.find((item) => item?._id == selChatData?._id)
        ?.assingUsers?.length > 0 &&
        storedConversations
          ?.find((item) => item?._id == selChatData?._id)
          ?.assingUsers?.find((it) => it?._id == userId)) ||
      storedConversations?.find((item) => item?._id == selChatData?._id)
        ?.assingUsers?.length == 0;
  // &&
  // getRepliedUserData(userId)?.tags?.find(it => it?._id == selectedUserData?.tag)

  const canUserPerformAction =
    getRepliedUserData(userId)?.role === "admin" ||
    getRepliedUserData(userId)?.role === "owner" ||
    storedConversations.find((item) => item?._id == selChatData?._id)
      ?.assingUsers?.length == 0
      ? true
      : storedConversations.find((item) => item?._id == selChatData?._id)
          ?.assingUsers?.length > 0 &&
        storedConversations
          .find((item) => item?._id == selChatData?._id)
          ?.assingUsers?.find((it) => it?._id == userId)
      ? true
      : false;

  console.log(" selChatData?.messages", selChatData?.messages);

  console.log("finalConversations", finalConversations);

  return (
    <div className="chat-section">
      {hasResponseArrived &&
      !isShowAssignedToMe &&
      finalConversations?.length <= 0 &&
      mainInputSearch == "" &&
      !searchLoading &&
      !isGetAllConvers &&
      !isGetSingleChat ? (
        <div className="no-chat-found">
          <div className="no-chat-found-header">
            <div className="heading">
              {" "}
              <img
                src={ChatNoFound}
                alt="no-found"
                width={20}
                height={20}
              />{" "}
              Inbox
            </div>
            <Button
              className="btn-main"
              onClick={() => {
                handleOpenContacts();
              }}
            >
              <i className="bi bi-plus"></i> New Chat
            </Button>
          </div>
          <div className="no-chat-found-body">
            <img src={ChatNoFound} alt="no-found" />
            <div className="body-heading">No Chats to display.</div>
            <div>You can start a new chat by tapping the button below</div>
            <Button
              className="btn-main mt-3"
              style={{ padding: "9px 50px" }}
              onClick={() => {
                handleOpenContacts();
              }}
            >
              <i className="bi bi-plus"></i> New Chat
            </Button>
          </div>
        </div>
      ) : (
        <Row className="mx-0">
          <Col md={3} xxl={3}>
            <div className="user-list h-100">
              <div className="user-list-header-mob d-md-none">
                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="chatbox-title">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.6594 1H13.3406C13.7406 1 14.1022 1.2384 14.2597 1.60608L17 8V12C17 13.1046 16.1046 14 15 14H3C1.89543 14 1 13.1046 1 12V8L3.74025 1.60608C3.89783 1.2384 4.25937 1 4.6594 1Z"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 8H5C5.55228 8 6 8.44772 6 9V10C6 10.5523 6.44772 11 7 11H11C11.5523 11 12 10.5523 12 10V9C12 8.44772 12.4477 8 13 8H17"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>Inbox</span>
                      </div>
                      <Button variant="main" onClick={() => setCgtShow(true)}>
                        <i className="bi bi-plus-lg"></i>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="user-list-header">
                <Row className="mx-0">
                  <Col xs={12} className="px-0">
                    <div className="d-flex align-items-center gap-2">
                      <Button
                        variant="main"
                        className="d-none d-md-block"
                        onClick={() => {
                          handleOpenContacts();
                        }}
                      >
                        <i className="bi bi-plus-lg"></i>
                      </Button>
                      <input
                        type="text"
                        placeholder="Search by name or number"
                        className="search"
                        onChange={(e) => {
                          setMainInputSearch(e.target.value);
                          setIsMainInputSearchTriggered(true);
                        }}
                        value={mainInputSearch}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="chat-tab py-2 bg-white">
                <Row className="mx-0">
                  <Col xs={6} className="pe-1">
                    <Button
                      variant="main"
                      className={`${!isShowAssignedToMe && "active"}`}
                      onClick={() => setIsShowAssignedToMe(false)}
                    >
                      All Conversations
                    </Button>
                  </Col>
                  <Col xs={6} className="ps-1">
                    <Button
                      variant="main"
                      className={`${isShowAssignedToMe && "active"}`}
                      onClick={() => setIsShowAssignedToMe(true)}
                    >
                      Assigned to me
                    </Button>
                  </Col>
                </Row>
              </div>
              {isGetAllConvers ? (
                <div className="active-user-list">
                  <div className="single-user">
                    <div className="user-details placeholder-glow">
                      <div className="user-image placeholder"></div>
                      <div className="user-content w-75">
                        <span className="placeholder w-100 py-3 px-5"></span>
                      </div>
                    </div>
                  </div>
                  <div className="single-user">
                    <div className="user-details placeholder-glow">
                      <div className="user-image placeholder"></div>
                      <div className="user-content w-75">
                        <span className="placeholder w-100 py-3 px-5"></span>
                      </div>
                    </div>
                  </div>
                  <div className="single-user">
                    <div className="user-details placeholder-glow">
                      <div className="user-image placeholder"></div>
                      <div className="user-content w-75">
                        <span className="placeholder w-100 py-3 px-5"></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="active-user-list"
                  ref={mergeRefs(listRef, scrollContainerRef)}
                  style={{
                    overflowY: "auto", // Enable scrolling
                    maxHeight: "calc(100vh - 160px)", // Limit the height of the container
                  }}
                >
                  {
                    // finalConversations?.length > 0 ?finalConversations.filter((conversation: singleChatDTO) => conversation.contact.contactName.toLowerCase()?.includes(mainInputSearch.toLowerCase()) || conversation.contact.number.toLowerCase()?.includes(mainInputSearch.toLowerCase())).length>0?
                    //     finalConversations.filter((conversation: singleChatDTO) => conversation.contact.contactName.toLowerCase()?.includes(mainInputSearch.toLowerCase()) || conversation.contact.number.toLowerCase()?.includes(mainInputSearch.toLowerCase()))?.map((item, index) => {
                    finalConversations?.length > 0 ? (
                      finalConversations?.map((item, index) => {
                        console.log("item", item);
                        return (
                          <Userlist
                            id={item?._id}
                            UserClass={`save-user ${
                              item?.unreadMessageCount > 0 ? "unread-chat" : ""
                            }`}
                            Image={
                              item?.contact?.profileImage
                                ? item?.contact?.profileImage
                                : dummyImage
                            }
                            handleButtonClick={(id: string) =>
                              handleButtonClick(id)
                            }
                            activeId={newChatId}
                            Name={item?.contact?.contactName}
                            Messagetitle={getLastMessage(
                              item?.flow
                                ? item?.flow
                                : item?.interactive
                                ? item?.interactive?.interactive
                                : item?.messages && item?.messages[0]
                            )}
                            ChatTag={getTagName(item?.tag)}
                            TagClass={
                              item?.tag
                                ? generateColorHash(
                                    item && item.tag && item?.tag?.slice(0, 10)
                                  )
                                : ""
                            }
                            Time={
                              item?.messages &&
                              item?.messages[0] &&
                              getConversationDate(
                                item?.messages &&
                                  item?.messages[0] &&
                                  item?.messages[0]?.timestamp
                              )
                            }
                            MessageNumber={item?.unreadMessageCount}
                            isMuted={checkIfChatIsMuted(item?._id)}
                            setNewChatId={setNewChatId}
                            setSelectedContact={setContactSelected}
                            setSelectedTemplate={setSelectedTemplate}
                          />
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>No Chat List found</p>
                      </div>
                    )
                    // :
                    // <div style={{ display: "flex", marginTop: "20px", justifyContent: "center", alignItems: "center" }} >
                    //     <p>No Chat List found</p>
                    // </div>
                  }
                  {usersLoading && (
                    <p style={{ textAlign: "center", marginTop: "5px" }}>
                      {" "}
                      <div>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ color: "#165E5B" }}
                        />
                      </div>
                    </p>
                  )}
                  {/* <div>
                                <div style={{ display: recording ? 'block' : 'none' }}>
                                    <ReactMic
                                    record={recording}
                                    className="sound-wave"
                                    onStop={onStop}
                                    strokeColor="#165e5b"
                                    backgroundColor="#ffffff"
                                    />
                                </div>
                                <div>
                                    <button onClick={startRecording} disabled={recording}>
                                    Start Recording
                                    </button>
                                    <button onClick={stopRecording} disabled={!recording}>
                                    Stop Recording
                                    </button>
                                </div>
                                {blobURL && (
                                    <audio controls src={blobURL}>
                                    Your browser does not support the <code>audio</code> element.
                                    </audio>
                                )}
                                </div> */}
                </div>
              )}
            </div>
          </Col>
          {isGetAllConvers || isGetSingleChat || isRendering ? (
            // true?
            <Col md={6} xxl={6}>
              {/* <div className="live-chat-sec h-100 " style={{display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                            <div className="placeholder-glow row msg-row mx-0  " style={{height:"15%"}}>
                                    

                                    <span className="col-12 placeholder h-100"></span>
                                </div>
                                <div className="placeholder-glow row msg-row mx-0  " style={{height:"60%"}}>
                                    

                                    <span className="col-12 placeholder h-100"></span>
                                </div>
                                <div className="placeholder-glow row msg-row mx-0  " style={{height:"15%"}}>
                                    

                                    <span className="col-12 placeholder h-100"></span>
                                </div>
                            </div> */}

              <div
                className="live-chat-sec h-100"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  overflow: "scroll",
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <div
                  ref={refTwo}
                  style={{
                    height:
                      //   width > 600
                      // ?
                      "calc(100vh - 0px)",
                    // : "calc(100vh - 150px)",
                    overflow: "scroll",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                  className="chat-message-sec"
                >
                  <div className="placeholder-glow row msg-row mx-0 h-100">
                    <span className="col-12 placeholder h-100"></span>
                  </div>
                </div>
              </div>
            </Col>
          ) : showMessage ? (
            <Col md={6} xxl={6}>
              <div className="live-chat-sec h-100">
                <div className="chat-header">
                  <div className="chat-header-sm-md d-lg-none">
                    <div className="assign-to-user">
                      <RenderTagSection
                        chatData={selChatData}
                        assignee={
                          storedConversations.find(
                            (item) => item?._id == selChatData?._id
                          )?.assingUsers?.length > 0
                            ? storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.assingUsers[0]
                            : storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.assingUsers?.length > 0 &&
                              storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.assingUsers[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="chat-header-inner d-flex align-items-center justify-content-between w-100">
                    <ul className="nav user-info">
                      <li className="nav-item d-flex d-md-none" id="for-mobile">
                        <div
                          className="back-btn me-2 mt-1"
                          onClick={async () => {
                            navigate("/inbox");
                            setMessage(false);
                            setNewChatId("");
                            await getAllConversations();
                            setIsChatOpen(false);
                          }}
                        >
                          {/* <i className="bi bi-arrow-left-circle-fill"></i> */}
                          <img src={BackArrow} />
                        </div>
                      </li>
                      <li className="nav-item d-lg-none">
                        <div className="user-image">
                          <img src={Hajira_img} alt="user-img" />
                          {/* <div className="user-status"></div> */}
                        </div>
                      </li>
                      <li className="nav-item d-lg-none">
                        <div className="user-name">
                          {selectedUserData?.contact?.contactName}
                        </div>
                      </li>
                      <li className="nav-item assign-to-dsk">
                        <div className="assign-to-user px-3">
                          <RenderTagSection
                            chatData={selChatData}
                            assignee={
                              storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.assingUsers?.length > 0
                                ? storedConversations.find(
                                    (item) => item?._id == selChatData?._id
                                  )?.assingUsers[0]
                                : storedConversations.find(
                                    (item) => item?._id == selChatData?._id
                                  )?.assingUsers?.length > 0 &&
                                  storedConversations.find(
                                    (item) => item?._id == selChatData?._id
                                  )?.assingUsers[0]
                            }
                          />
                        </div>
                      </li>
                    </ul>
                    <ul className="nav align-items-center">
                      <li
                        className="nav-item"
                        style={{
                          cursor: !canUserPerformAction ? "no-drop" : "",
                        }}
                      >
                        <div className="chat-option">
                          {!canUserPerformAction ? (
                            <Tooltip
                              title={"You cannot perform this action"}
                              arrow
                            >
                              <div className="chat-o-btn">
                                <i
                                  className="bi bi-three-dots-vertical"
                                  style={{
                                    cursor: !canUserPerformAction
                                      ? "no-drop"
                                      : "",
                                  }}
                                ></i>
                              </div>
                            </Tooltip>
                          ) : (
                            <Dropdown>
                              <Dropdown.Toggle
                                className="chat-o-btn"
                                disabled={!canUserPerformAction}
                              >
                                <i className="bi bi-three-dots-vertical"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="option-dropdown">
                                {/* {
                                                                        checkIfChatIsMuted(selChatData?._id) ?
                                                                            <Dropdown.Item onClick={() =>handleMuteNotification(selChatData?._id)}>
                                                                                Unmute notifications
                                                                            </Dropdown.Item>
                                                                            :
                                                                            <Dropdown.Item onClick={() => setMuteShow(true)}>
                                                                                Mute notifications
                                                                            </Dropdown.Item>
                                                                    } */}
                                <Dropdown.Item onClick={() => setDtShow(true)}>
                                  Delete chat
                                </Dropdown.Item>
                                {storedConversations.find(
                                  (item) => item?._id == selChatData?._id
                                )?.block ? (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUnblockClick(selChatData?._id)
                                    }
                                  >
                                    Unblock
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() => setBlockShow(true)}
                                  >
                                    Block
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="chat-header-tab d-md-none">
                    <ul className="nav align-items-center nav-fill">
                      <li className="nav-item">
                        <Button
                          variant="navTab"
                          className={`nav-link ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("tab1")}
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.2498 3.57487V9.4082H5.05817L4.08317 10.3832V3.57487H13.2498ZM14.0832 1.9082H3.24984C2.7915 1.9082 2.4165 2.2832 2.4165 2.74154V14.4082L5.74984 11.0749H14.0832C14.5415 11.0749 14.9165 10.6999 14.9165 10.2415V2.74154C14.9165 2.2832 14.5415 1.9082 14.0832 1.9082ZM18.2498 5.24154H16.5832V12.7415H5.74984V14.4082C5.74984 14.8665 6.12484 15.2415 6.58317 15.2415H15.7498L19.0832 18.5749V6.07487C19.0832 5.61654 18.7082 5.24154 18.2498 5.24154Z"
                              fill="#B6B6B6"
                            />
                          </svg>
                          Chat
                        </Button>
                      </li>
                      <li className="nav-item">
                        <Button
                          variant="navTab"
                          className={`nav-link ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("tab2")}
                        >
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.6665 14.4082H11.3332V9.4082H9.6665V14.4082ZM10.4998 7.74154C10.7359 7.74154 10.9339 7.66167 11.0936 7.50195C11.2533 7.34223 11.3332 7.14431 11.3332 6.9082C11.3332 6.67209 11.2533 6.47417 11.0936 6.31445C10.9339 6.15473 10.7359 6.07487 10.4998 6.07487C10.2637 6.07487 10.0658 6.15473 9.90609 6.31445C9.74636 6.47417 9.6665 6.67209 9.6665 6.9082C9.6665 7.14431 9.74636 7.34223 9.90609 7.50195C10.0658 7.66167 10.2637 7.74154 10.4998 7.74154ZM10.4998 18.5749C9.34706 18.5749 8.26373 18.3561 7.24984 17.9186C6.23595 17.4811 5.354 16.8874 4.604 16.1374C3.854 15.3874 3.26025 14.5054 2.82275 13.4915C2.38525 12.4776 2.1665 11.3943 2.1665 10.2415C2.1665 9.08876 2.38525 8.00542 2.82275 6.99154C3.26025 5.97765 3.854 5.0957 4.604 4.3457C5.354 3.5957 6.23595 3.00195 7.24984 2.56445C8.26373 2.12695 9.34706 1.9082 10.4998 1.9082C11.6526 1.9082 12.7359 2.12695 13.7498 2.56445C14.7637 3.00195 15.6457 3.5957 16.3957 4.3457C17.1457 5.0957 17.7394 5.97765 18.1769 6.99154C18.6144 8.00542 18.8332 9.08876 18.8332 10.2415C18.8332 11.3943 18.6144 12.4776 18.1769 13.4915C17.7394 14.5054 17.1457 15.3874 16.3957 16.1374C15.6457 16.8874 14.7637 17.4811 13.7498 17.9186C12.7359 18.3561 11.6526 18.5749 10.4998 18.5749Z"
                              fill="#B6B6B6"
                            />
                          </svg>
                          Info
                        </Button>
                      </li>
                      <li className="nav-item">
                        <Button
                          variant="navTab"
                          className={`nav-link ${
                            activeTab === "tab3" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("tab3")}
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_3376_17590)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.7694 7.2337L9.17162 14.8335C9.10934 14.8978 9.00689 14.8978 8.94461 14.8335L2.23077 8.11963C2.20086 8.08943 2.18408 8.04864 2.18408 8.00613C2.18408 7.96362 2.20086 7.92283 2.23077 7.89262L9.82854 0.294857C9.86068 0.260705 9.9089 0.244634 9.9551 0.248652L16.01 0.758919C16.1667 0.772982 16.2893 0.895527 16.3033 1.05222L16.8156 7.10713C16.8196 7.15334 16.8035 7.19954 16.7694 7.2337ZM16.5424 9.00758L17.3379 9.80111C17.4002 9.86338 17.4002 9.96584 17.3379 10.0281L9.15555 18.1964C9.09327 18.2587 8.99081 18.2587 8.92854 18.1964L8.133 17.4029L3.24729 12.5252C3.21738 12.495 3.2006 12.4542 3.2006 12.4117C3.2006 12.3692 3.21738 12.3284 3.24729 12.2982L4.04282 11.5047C4.07303 11.4748 4.11382 11.458 4.15633 11.458C4.19884 11.458 4.23963 11.4748 4.26983 11.5047L9.04305 16.2658L16.3154 9.00758C16.3456 8.97767 16.3864 8.96089 16.4289 8.96089C16.4714 8.96089 16.5122 8.97767 16.5424 9.00758ZM12.1587 6.64453C13.2336 6.64453 14.105 5.77315 14.105 4.69824C14.105 3.62334 13.2336 2.75195 12.1587 2.75195C11.0838 2.75195 10.2124 3.62334 10.2124 4.69824C10.2124 5.77315 11.0838 6.64453 12.1587 6.64453Z"
                                fill="#B6B6B6"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3376_17590">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(0.75 0.242188)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          Tags
                        </Button>
                      </li>
                      <li className="nav-item">
                        <Button
                          variant="navTab"
                          className={`nav-link ${
                            activeTab === "tab4" ? "active" : ""
                          }`}
                          onClick={() => handleTabClick("tab4")}
                        >
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.125 15.9922H13.875V14.1172H15.75V13.3672H13.875V11.4922H13.125V13.3672H11.25V14.1172H13.125V15.9922ZM13.5 17.4922C12.4625 17.4922 11.5781 17.1266 10.8469 16.3953C10.1156 15.6641 9.75 14.7797 9.75 13.7422C9.75 12.7047 10.1156 11.8203 10.8469 11.0891C11.5781 10.3578 12.4625 9.99219 13.5 9.99219C14.5375 9.99219 15.4219 10.3578 16.1531 11.0891C16.8844 11.8203 17.25 12.7047 17.25 13.7422C17.25 14.7797 16.8844 15.6641 16.1531 16.3953C15.4219 17.1266 14.5375 17.4922 13.5 17.4922ZM5.25 6.99219H12.75V5.49219H5.25V6.99219ZM8.75625 15.9922H3.75C3.3375 15.9922 2.98437 15.8453 2.69062 15.5516C2.39687 15.2578 2.25 14.9047 2.25 14.4922V3.99219C2.25 3.57969 2.39687 3.22656 2.69062 2.93281C2.98437 2.63906 3.3375 2.49219 3.75 2.49219H14.25C14.6625 2.49219 15.0156 2.63906 15.3094 2.93281C15.6031 3.22656 15.75 3.57969 15.75 3.99219V9.01719C15.3875 8.84219 15.0219 8.71094 14.6531 8.62344C14.2844 8.53594 13.9 8.49219 13.5 8.49219C13.3625 8.49219 13.2344 8.49531 13.1156 8.50156C12.9969 8.50781 12.875 8.52344 12.75 8.54844V8.49219H5.25V9.99219H9.84375C9.61875 10.2047 9.41562 10.4359 9.23437 10.6859C9.05312 10.9359 8.89375 11.2047 8.75625 11.4922H5.25V12.9922H8.30625C8.28125 13.1172 8.26562 13.2391 8.25937 13.3578C8.25312 13.4766 8.25 13.6047 8.25 13.7422C8.25 14.1547 8.2875 14.5391 8.3625 14.8953C8.4375 15.2516 8.56875 15.6172 8.75625 15.9922Z"
                              fill="#B6B6B6"
                            />
                          </svg>
                          Notes
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                {docshow ? (
                  <div className={`doc-adding ${docshow ? "show" : ""}`}>
                    <div className="doc-header">
                      <button className="close-doc" onClick={hideDoc}>
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.3926 11.6074L11.6075 23.3925"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M23.3926 23.3926L11.6075 11.6075"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="doc-body">
                      <div className="doc-img">{renderIcon()}</div>
                      <div className="doc-description">
                        {file ? (
                          <>
                            <div className="doc-name">{file.name}</div>
                            <div className="doc-size">{file.size} KB</div>
                          </>
                        ) : (
                          <div>No file uploaded</div>
                        )}
                      </div>
                    </div>
                    <div className="img-msg">
                      <input
                        type="text"
                        className="img-msg-details"
                        placeholder="Type a message here...."
                        onChange={handleMsg}
                        value={msg}
                      />
                    </div>
                    <div className="doc-list">
                      <ul className="nav align-items-center justify-content-center">
                        {!file ? (
                          <li className="nav-item">
                            <div
                              className="doc-item upload-doc"
                              onChange={handleFileChange}
                            >
                              <input type="file" />
                              <div className="doc-img-icon">
                                <svg
                                  width="31"
                                  height="31"
                                  viewBox="0 0 31 31"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.11914 15.5H22.881"
                                    stroke="#4B4A4A"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.5005 8.11914V22.881"
                                    stroke="#4B4A4A"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li className="nav-item">
                            <div className="doc-item">
                              <div className="doc-img-icon">
                                {/* <img src = {renderIcon()} alt = "doc-icon"/> */}
                                {renderIcon()}
                              </div>
                              <div
                                className="delete-btn"
                                onClick={() => setFile(null)}
                              >
                                <svg
                                  width="30"
                                  height="29"
                                  viewBox="0 0 30 29"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.75 25.375C8.0625 25.375 7.47417 25.1386 6.985 24.6657C6.495 24.192 6.25 23.6229 6.25 22.9583V7.25H5V4.83333H11.25V3.625H18.75V4.83333H25V7.25H23.75V22.9583C23.75 23.6229 23.5054 24.192 23.0163 24.6657C22.5263 25.1386 21.9375 25.375 21.25 25.375H8.75ZM21.25 7.25H8.75V22.9583H21.25V7.25ZM11.25 20.5417H13.75V9.66667H11.25V20.5417ZM16.25 20.5417H18.75V9.66667H16.25V20.5417Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                      <button
                        className="btn-send"
                        disabled={sending}
                        onClick={
                          selectedTemplate
                            ? handleSendTemplate
                            : selectedFlow
                            ? handleSendFlow
                            : sendMessage
                        }
                      >
                        {sending ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ color: "#FFFFFF" }}
                            />
                          </div>
                        ) : (
                          <img src={Send_icon} alt="icon" />
                        )}
                      </button>
                    </div>
                  </div>
                ) : imageshow ? (
                  <div className={`img-adding ${imageshow ? "show" : ""}`}>
                    <div className="img-header">
                      <button className="close-doc" onClick={hideImage}>
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.3926 11.6074L11.6075 23.3925"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M23.3926 23.3926L11.6075 11.6075"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="img-body">
                      {image && image?.type === "image" ? (
                        <img
                          src={image?.url}
                          alt="Uploaded"
                          className="added-img"
                        />
                      ) : image?.type === "video" ? (
                        <ReactPlayer
                          controls
                          url={image.url}
                          // width="400px"
                          // height="230px"
                          className="react-player"
                        />
                      ) : (
                        <p>No file selected</p>
                      )}
                    </div>
                    <div>
                      <div className="img-msg">
                        <input
                          type="text"
                          className="img-msg-details"
                          placeholder="Type a message here...."
                          onChange={handleMsg}
                          value={msg}
                        />
                      </div>
                      <div className="img-list">
                        <ul className="nav align-items-center justify-content-center">
                          {image && (
                            <li className="nav-item">
                              <div className="img-item">
                                <img
                                  src={
                                    image.type === "image"
                                      ? image?.url
                                      : image.type === "video"
                                      ? selVideoIcon
                                      : ""
                                  }
                                  alt="Thumpnil"
                                />
                                <div
                                  className="delete-btn"
                                  onClick={() => setImage(null)}
                                >
                                  <svg
                                    width="30"
                                    height="29"
                                    viewBox="0 0 30 29"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.75 25.375C8.0625 25.375 7.47417 25.1386 6.985 24.6657C6.495 24.192 6.25 23.6229 6.25 22.9583V7.25H5V4.83333H11.25V3.625H18.75V4.83333H25V7.25H23.75V22.9583C23.75 23.6229 23.5054 24.192 23.0163 24.6657C22.5263 25.1386 21.9375 25.375 21.25 25.375H8.75ZM21.25 7.25H8.75V22.9583H21.25V7.25ZM11.25 20.5417H13.75V9.66667H11.25V20.5417ZM16.25 20.5417H18.75V9.66667H16.25V20.5417Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </li>
                          )}
                          {!image && (
                            <li className="nav-item">
                              <div
                                className="img-item upload-img"
                                onClick={handleImageUpload}
                              >
                                <input
                                  type="file"
                                  accept="image/*,video/*"
                                  ref={ImageInputRef}
                                  onChange={handleImageChange}
                                />
                                <div className="doc-img-icon">
                                  <svg
                                    width="31"
                                    height="31"
                                    viewBox="0 0 31 31"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8.11914 15.5H22.881"
                                      stroke="#4B4A4A"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M15.5005 8.11914V22.881"
                                      stroke="#4B4A4A"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </li>
                          )}
                          <button
                            className="btn-send"
                            onClick={
                              selectedTemplate
                                ? handleSendTemplate
                                : selectedFlow
                                ? handleSendFlow
                                : sendMessage
                            }
                          >
                            {sending ? (
                              <div>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ color: "#FFFFFF" }}
                                />
                              </div>
                            ) : (
                              <img src={Send_icon} alt="icon" />
                            )}
                          </button>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tab-content-area-desktop d-lg-block">
                    {windowSize > 600 ? (
                      <div
                        id="chat-div"
                        ref={chatRef}
                        style={{ position: "relative" }}
                        className="chat-message-sec"
                      >
                        {isFetchMsg ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                            }}
                          >
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              style={{ color: "rgba(22, 94, 91, 1)" }}
                            />
                          </div>
                        ) : null}
                        {selChatData?.messages?.map(
                          (item: singleMessageDTO, index: number) => {
                            console.log("item", item);
                            const flowToShow = item?.interactive
                              ? renderFlow(
                                  item?.interactive?.interactive,
                                  item?._id
                                )
                              : null;
                            const flowMessage = item?.flow
                              ? renderRecivedFlow(item?.flow?.flow, item?._id)
                              : null;
                            const templateToShow = item?.template
                              ? renderTemplate(item?.template, item?._id)
                              : null;
                            const imageMsg = item?.image
                              ? renderImage(
                                  item?.image?.link,
                                  item?.image?.caption
                                )
                              : null;
                            const videoMsg = item?.video
                              ? renderVideo(item?.video)
                              : null;
                            const audioMsg = item?.audio
                              ? renderAudio(item?.audio?.link, item)
                              : null;
                            const documentMsg =
                              item?.type === "document"
                                ? renderDocument(item?.document, item?.from)
                                : null;
                            const contactMsg = item?.contacts
                              ? renderContacts(item?.contacts)
                              : null;
                            const textMsg =
                              item?.type === "text"
                                ? renderText(item?.text)
                                : null;
                            const repliedMsg = item?.context
                              ? renderRepliedMsg(item)
                              : null;
                            const msgStatus = item?.from
                              ? renderStatusTicks(item)
                              : null;
                            const locationMsg = item?.location
                              ? renderLocation(item?.location?.location)
                              : null;

                            const finalMessageToDisplay = flowToShow
                              ? flowToShow
                              : flowMessage
                              ? flowMessage
                              : templateToShow
                              ? templateToShow
                              : audioMsg
                              ? audioMsg
                              : videoMsg
                              ? videoMsg
                              : contactMsg
                              ? contactMsg
                              : imageMsg
                              ? imageMsg
                              : documentMsg
                              ? documentMsg
                              : repliedMsg
                              ? repliedMsg
                              : locationMsg
                              ? locationMsg
                              : textMsg;
                            const senderInfo =
                              index > 0 &&
                              selChatData?.messages[index - 1]?.from?._id !=
                                item?.from?._id
                                ? item?.from
                                  ? {
                                      image:
                                        getRepliedUserData(item?.from?._id)
                                          ?.image || Hajira_img,
                                      // userName: getRepliedUserData(item?.from?._id)?.firstname + " " + getRepliedUserData(item?.from?._id)?.lastname
                                      userName:
                                        item?.from?.firstname +
                                        " " +
                                        item?.from?.lastname,
                                    }
                                  : {
                                      image:
                                        selectedUserData?.contact?.image ||
                                        Hajira_img,
                                      userName:
                                        selectedUserData?.contact?.contactName,
                                    }
                                : null;

                            console.log(
                              "senderInfo : ",
                              item,
                              ":",
                              getRepliedUserData(item?.from?._id),
                              ":",
                              senderInfo
                            );

                            const dateToShow = true;
                            return (
                              <>
                                {!item?.from ? (
                                  <div
                                    className="msg-row received row mx-0"
                                    id={item?._id}
                                  >
                                    {senderInfo && senderInfo?.userName ? (
                                      <div className="user-img-c no-img">
                                        {(() => {
                                          const userName =
                                            senderInfo.userName.trim();
                                          if (userName.includes(" ")) {
                                            const nameParts =
                                              userName.split(" ");
                                            const firstNameInitial =
                                              nameParts[0]?.[0] || "";
                                            const lastNameInitial =
                                              nameParts[1]?.[0] || "";
                                            return (
                                              firstNameInitial + lastNameInitial
                                            );
                                          } else {
                                            return userName.slice(0, 2); // First two letters of the single name
                                          }
                                        })()}
                                      </div>
                                    ) : null}
                                    <div className="msg-body">
                                      {senderInfo ? (
                                        <div className="chat-user-name">
                                          {senderInfo && senderInfo?.userName}
                                        </div>
                                      ) : null}
                                      <div className="mesg-sec received msg-option">
                                        {finalMessageToDisplay}
                                        {canUserReply ? (
                                          <div className="chat-option">
                                            <Dropdown
                                              show={openDropdown === item._id}
                                              onToggle={() =>
                                                handleToggle(item._id)
                                              }
                                            >
                                              {isGreaterThanOrEqualToOneMinute(
                                                storedConversations.find(
                                                  (item) =>
                                                    item?._id ==
                                                    selChatData?._id
                                                )?.conversationExpiry
                                              ) == true && (
                                                <Dropdown.Toggle className="chat-o-btn">
                                                  {openDropdown === item._id ? (
                                                    // Icon when the dropdown is open
                                                    <svg
                                                      width="11"
                                                      height="7"
                                                      viewBox="0 0 11 7"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M1.11914 5.68955L5.50009 1.30859L9.88105 5.68955"
                                                        stroke="black"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    // Icon when the dropdown is closed
                                                    <svg
                                                      width="11"
                                                      height="7"
                                                      viewBox="0 0 11 7"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859"
                                                        stroke="black"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  )}
                                                </Dropdown.Toggle>
                                              )}

                                              <Dropdown.Menu className="option-dropdown">
                                                {/* {
                                                                                                                            item?.type === "text" ?
                                                                                                                                <>
                                                                                                                                    <Dropdown.Item onClick={() => { setIsShowEditMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                        Edit
                                                                                                                                    </Dropdown.Item>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                                        } */}
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setIsShowRepliedSection(
                                                      true
                                                    );
                                                    setSelMsgForOpr(item?._id);
                                                  }}
                                                >
                                                  Reply
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item onClick={() => { setIsShowDelMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                            Delete
                                                                                                                        </Dropdown.Item> */}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        ) : null}
                                        {dateToShow ? (
                                          <div
                                            className="time-area mt-3"
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "center",
                                              gap: "2px",
                                            }}
                                          >
                                            {msgStatus}
                                            {item?.dateToDisplay}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="msg-row send-msg msg-option"
                                    id={item?._id}
                                  >
                                    {senderInfo && senderInfo?.userName ? (
                                      <div className="user-img-c no-img">
                                        {(() => {
                                          const userName =
                                            senderInfo.userName.trim();
                                          if (userName.includes(" ")) {
                                            const nameParts =
                                              userName.split(" ");
                                            const firstNameInitial =
                                              nameParts[0]?.[0] || "";
                                            const lastNameInitial =
                                              nameParts[1]?.[0] || "";
                                            return (
                                              firstNameInitial + lastNameInitial
                                            );
                                          } else {
                                            return userName.slice(0, 2); // First two letters of the single name
                                          }
                                        })()}
                                      </div>
                                    ) : null}
                                    <div className="msg-body">
                                      {senderInfo ? (
                                        <>
                                          <div className="chat-user-name">
                                            {senderInfo?.userName?.length > 0 &&
                                              senderInfo?.userName}{" "}
                                            {item?.from?._id == userId
                                              ? "(Me)"
                                              : null}
                                          </div>
                                        </>
                                      ) : null}
                                      <div
                                        className="mesg-sec send msg-option"
                                        style={{
                                          paddingTop: item?.context && "0",
                                          paddingLeft: item?.context && "0",
                                          paddingRight: item?.context && "0",
                                        }}
                                      >
                                        {finalMessageToDisplay}
                                        {canUserReply ? (
                                          <div className="chat-option">
                                            <Dropdown
                                              show={openDropdown === item._id}
                                              onToggle={() =>
                                                handleToggle(item._id)
                                              }
                                            >
                                              {isGreaterThanOrEqualToOneMinute(
                                                storedConversations.find(
                                                  (item) =>
                                                    item?._id ==
                                                    selChatData?._id
                                                )?.conversationExpiry
                                              ) == true && (
                                                <Dropdown.Toggle className="chat-o-btn">
                                                  {openDropdown === item._id ? (
                                                    // Icon when the dropdown is open
                                                    <svg
                                                      width="11"
                                                      height="7"
                                                      viewBox="0 0 11 7"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M1.11914 5.68955L5.50009 1.30859L9.88105 5.68955"
                                                        stroke="black"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    // Icon when the dropdown is closed
                                                    <svg
                                                      width="11"
                                                      height="7"
                                                      viewBox="0 0 11 7"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859"
                                                        stroke="black"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  )}
                                                </Dropdown.Toggle>
                                              )}

                                              <Dropdown.Menu className="option-dropdown">
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    setIsShowRepliedSection(
                                                      true
                                                    );
                                                    setSelMsgForOpr(item?._id);
                                                  }}
                                                >
                                                  Reply
                                                </Dropdown.Item>
                                                {/* {
                                                                                                                            item?.type === "text" ?
                                                                                                                                <>
                                                                                                                                    <Dropdown.Item onClick={() => { setIsShowEditMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                        Edit
                                                                                                                                    </Dropdown.Item>
                                                                                                                                </>
                                                                                                                                : null
                                                                                                                        }
                                                                                                                        <Dropdown.Item onClick={() => { setIsShowDelMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                            Delete
                                                                                                                        </Dropdown.Item> */}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        ) : null}
                                        <div
                                          className="time-area"
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            gap: "15px",
                                          }}
                                        >
                                          {dateToShow ? (
                                            <div
                                              className="time-area mt-3"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                gap: "10px",
                                              }}
                                            >
                                              {msgStatus}
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  alignItems: "center",
                                                  gap: "2px",
                                                }}
                                              >
                                                <WatchIcon />
                                                {item?.dateToDisplay}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <div className="tab-content-area-mob d-lg-none position-relative">
                        {/* messages render */}
                        <div
                          className={`tab-pane fade ${
                            activeTab === "tab1" ? "show active" : ""
                          }`}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <div
                            id="chat-div"
                            ref={chatRef}
                            className="chat-message-sec"
                          >
                            {isFetchMsg ? (
                              // <div style={{display : "flex", justifyContent : "center", alignItems : "center", height : "30px"}} >
                              //     <Spinner
                              //         as="span"
                              //         animation="border"
                              //         size="sm"
                              //         role="status"
                              //         aria-hidden="true"
                              //         style={{ color: "rgba(22, 94, 91, 1)" }}
                              //     />
                              // </div>
                              <div
                                className="live-chat-sec h-100"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  ref={refTwo}
                                  style={{
                                    height:
                                      //   width > 600
                                      // ?
                                      "calc(100vh - 0px)",
                                    // : "calc(100vh - 150px)",
                                  }}
                                  className="chat-message-sec"
                                >
                                  <div className="placeholder-glow row msg-row mx-0 h-100">
                                    <span className="col-12 placeholder h-100"></span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {selChatData?.messages?.map(
                              (item: singleMessageDTO, index: number) => {
                                const flowToShow = item?.interactive
                                  ? renderFlow(
                                      item?.interactive?.interactive,
                                      item?._id
                                    )
                                  : null;
                                const templateToShow = item?.template
                                  ? renderTemplate(item?.template, item?._id)
                                  : null;
                                const imageMsg = item?.image
                                  ? renderImage(
                                      item?.image?.link,
                                      item?.image?.caption
                                    )
                                  : null;
                                const videoMsg = item?.video
                                  ? renderVideo(item?.video)
                                  : null;
                                const audioMsg = item?.audio
                                  ? renderAudio(item?.audio?.link, item)
                                  : null;
                                const documentMsg =
                                  item?.type === "document"
                                    ? renderDocument(item?.document, item?.from)
                                    : null;
                                const contactMsg = item?.contacts
                                  ? renderContacts(item?.contacts)
                                  : null;
                                const textMsg =
                                  item?.type === "text"
                                    ? renderText(item?.text)
                                    : null;
                                const repliedMsg = item?.context
                                  ? renderRepliedMsg(item)
                                  : null;
                                const msgStatus = item?.from
                                  ? renderStatusTicks(item)
                                  : null;
                                const locationMsg = item?.location
                                  ? renderLocation(item?.location?.location)
                                  : null;

                                const finalMessageToDisplay = flowToShow
                                  ? flowToShow
                                  : templateToShow
                                  ? templateToShow
                                  : audioMsg
                                  ? audioMsg
                                  : videoMsg
                                  ? videoMsg
                                  : contactMsg
                                  ? contactMsg
                                  : imageMsg
                                  ? imageMsg
                                  : documentMsg
                                  ? documentMsg
                                  : repliedMsg
                                  ? repliedMsg
                                  : locationMsg
                                  ? locationMsg
                                  : textMsg;
                                const senderInfo =
                                  index > 0 &&
                                  selChatData?.messages[index - 1]?.from?._id !=
                                    item?.from?._id
                                    ? item?.from
                                      ? {
                                          image:
                                            getRepliedUserData(item?.from?._id)
                                              ?.image || Hajira_img,
                                          // userName: getRepliedUserData(item?.from?._id)?.firstname + " " + getRepliedUserData(item?.from?._id)?.lastname
                                          userName:
                                            item?.from?.firstname +
                                            " " +
                                            item?.from?.lastname,
                                        }
                                      : {
                                          image:
                                            selectedUserData?.contact?.image ||
                                            Hajira_img,
                                          userName:
                                            selectedUserData?.contact
                                              ?.contactName,
                                        }
                                    : null;

                                const dateToShow = true;
                                return (
                                  <>
                                    {!item?.from ? (
                                      <div
                                        className="msg-row received row mx-0"
                                        id={item?._id}
                                      >
                                        {senderInfo && senderInfo?.userName ? (
                                          <div className="user-img-c no-img">
                                            {(() => {
                                              const userName =
                                                senderInfo.userName.trim();
                                              if (userName.includes(" ")) {
                                                const nameParts =
                                                  userName.split(" ");
                                                const firstNameInitial =
                                                  nameParts[0]?.[0] || "";
                                                const lastNameInitial =
                                                  nameParts[1]?.[0] || "";
                                                return (
                                                  firstNameInitial +
                                                  lastNameInitial
                                                );
                                              } else {
                                                return userName.slice(0, 2); // First two letters of the single name
                                              }
                                            })()}
                                          </div>
                                        ) : null}
                                        <div className="msg-body">
                                          {senderInfo ? (
                                            <div className="chat-user-name">
                                              {senderInfo &&
                                                senderInfo?.userName}
                                            </div>
                                          ) : null}
                                          <div className="mesg-sec received">
                                            {finalMessageToDisplay}
                                            {canUserReply ? (
                                              <div className="chat-option">
                                                <Dropdown
                                                  show={
                                                    openDropdown === item._id
                                                  }
                                                  onToggle={() =>
                                                    handleToggle(item._id)
                                                  }
                                                >
                                                  {isGreaterThanOrEqualToOneMinute(
                                                    storedConversations.find(
                                                      (item) =>
                                                        item?._id ==
                                                        selChatData?._id
                                                    )?.conversationExpiry
                                                  ) == true && (
                                                    <Dropdown.Toggle className="chat-o-btn">
                                                      {openDropdown ===
                                                      item._id ? (
                                                        // Icon when the dropdown is open
                                                        <svg
                                                          width="11"
                                                          height="7"
                                                          viewBox="0 0 11 7"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M1.11914 5.68955L5.50009 1.30859L9.88105 5.68955"
                                                            stroke="black"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        // Icon when the dropdown is closed
                                                        <svg
                                                          width="11"
                                                          height="7"
                                                          viewBox="0 0 11 7"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859"
                                                            stroke="black"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                          />
                                                        </svg>
                                                      )}
                                                    </Dropdown.Toggle>
                                                  )}

                                                  <Dropdown.Menu className="option-dropdown">
                                                    {/* {
                                                                                                                                    item?.type === "text" ?
                                                                                                                                        <>
                                                                                                                                            <Dropdown.Item onClick={() => { setIsShowEditMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                                Edit
                                                                                                                                            </Dropdown.Item>
                                                                                                                                        </>
                                                                                                                                        : null
                                                                                                                                } */}
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        setIsShowRepliedSection(
                                                          true
                                                        );
                                                        setSelMsgForOpr(
                                                          item?._id
                                                        );
                                                      }}
                                                    >
                                                      Reply
                                                    </Dropdown.Item>
                                                    {/* <Dropdown.Item onClick={() => { setIsShowDelMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                    Delete
                                                                                                                                </Dropdown.Item> */}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            ) : null}
                                            {dateToShow ? (
                                              <div
                                                className="time-area mt-0"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  alignItems: "center",
                                                  gap: "2px",
                                                }}
                                              >
                                                {msgStatus}
                                                {item?.dateToDisplay}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="msg-row send-msg"
                                        id={item?._id}
                                      >
                                        {senderInfo && senderInfo?.userName ? (
                                          <div className="user-img-c no-img">
                                            {(() => {
                                              const userName =
                                                senderInfo.userName.trim();
                                              if (userName.includes(" ")) {
                                                const nameParts =
                                                  userName.split(" ");
                                                const firstNameInitial =
                                                  nameParts[0]?.[0] || "";
                                                const lastNameInitial =
                                                  nameParts[1]?.[0] || "";
                                                return (
                                                  firstNameInitial +
                                                  lastNameInitial
                                                );
                                              } else {
                                                return userName.slice(0, 2); // First two letters of the single name
                                              }
                                            })()}
                                          </div>
                                        ) : null}
                                        <div className="msg-body">
                                          {senderInfo ? (
                                            <>
                                              <div className="chat-user-name">
                                                {senderInfo?.userName?.length >
                                                  0 &&
                                                  senderInfo?.userName}{" "}
                                                {item?.from?._id == userId
                                                  ? "(Me)"
                                                  : null}
                                              </div>
                                            </>
                                          ) : null}
                                          <div className="mesg-sec send">
                                            {finalMessageToDisplay}
                                            {canUserReply ? (
                                              <div className="chat-option">
                                                <Dropdown
                                                  show={
                                                    openDropdown === item._id
                                                  }
                                                  onToggle={() =>
                                                    handleToggle(item._id)
                                                  }
                                                >
                                                  {isGreaterThanOrEqualToOneMinute(
                                                    storedConversations.find(
                                                      (item) =>
                                                        item?._id ==
                                                        selChatData?._id
                                                    )?.conversationExpiry
                                                  ) == true && (
                                                    <Dropdown.Toggle className="chat-o-btn">
                                                      {openDropdown ===
                                                      item._id ? (
                                                        // Icon when the dropdown is open
                                                        <svg
                                                          width="11"
                                                          height="7"
                                                          viewBox="0 0 11 7"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M1.11914 5.68955L5.50009 1.30859L9.88105 5.68955"
                                                            stroke="black"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                          />
                                                        </svg>
                                                      ) : (
                                                        // Icon when the dropdown is closed
                                                        <svg
                                                          width="11"
                                                          height="7"
                                                          viewBox="0 0 11 7"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M9.88105 1.30859L5.50009 5.68955L1.11914 1.30859"
                                                            stroke="black"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                          />
                                                        </svg>
                                                      )}
                                                    </Dropdown.Toggle>
                                                  )}

                                                  <Dropdown.Menu className="option-dropdown">
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        setIsShowRepliedSection(
                                                          true
                                                        );
                                                        setSelMsgForOpr(
                                                          item?._id
                                                        );
                                                      }}
                                                    >
                                                      Reply
                                                    </Dropdown.Item>
                                                    {/* {
                                                                                                                                    item?.type === "text" ?
                                                                                                                                        <>
                                                                                                                                            <Dropdown.Item onClick={() => { setIsShowEditMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                                Edit
                                                                                                                                            </Dropdown.Item>
                                                                                                                                        </>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                                <Dropdown.Item onClick={() => { setIsShowDelMsgPopup(true); setSelMsgForOpr(item?._id) }} >
                                                                                                                                    Delete
                                                                                                                                </Dropdown.Item> */}
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            ) : null}
                                            <div
                                              className="time-area"
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                gap: "15px",
                                              }}
                                            >
                                              {dateToShow ? (
                                                <div
                                                  className="time-area mt-3"
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  {msgStatus}
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "flex-end",
                                                      alignItems: "center",
                                                      gap: "2px",
                                                    }}
                                                  >
                                                    <WatchIcon />
                                                    {item?.dateToDisplay}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "tab2" ? "show active" : ""
                          }`}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <UserBasicInfo
                            phoneNo={selChatData?.generalInfo?.phoneNo}
                            expiryTime={
                              storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.conversationExpiry
                            }
                          />
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "tab3" ? "show active" : ""
                          }`}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <UserTags
                            isTagFetching={isTagFetching}
                            allTags={allTags}
                            isNewTagAssigning={isNewTagAssigning}
                            handleTagChange={(tagId: string) =>
                              assignNewTagToChat(tagId)
                            }
                            selectedTags={
                              storedConversations.find(
                                (item) => item?._id == selChatData?._id
                              )?.tag
                            }
                            setSelChatData={setSelChatData}
                            storedConversations={storedConversations}
                            selChatData={selChatData}
                            canUserPerformAction={canUserPerformAction}
                          />
                        </div>
                        <div
                          className={`tab-pane fade ${
                            activeTab === "tab4" ? "show active" : ""
                          }`}
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          <UserNotes
                            notes={selChatData?.notes}
                            isFetching={isTagFetching}
                            userId={userId}
                            anchorEl={anchorEl}
                            openedMenuId={openedMenuId}
                            setOpenedMenuId={setOpenedMenuId}
                            setAnchorEl={setAnchorEl}
                            open={open}
                            setSelectedNote={(noteData) =>
                              setSelectedNote(noteData)
                            }
                            setIsShowDeleteNote={setIsShowDeleteNote}
                            isNoteAdding={isNoteAdding}
                            setIsNoteAdding={setIsNoteAdding}
                            isNoteEditing={isNoteEditing}
                            isUpdateNote={isUpdateNote}
                            setIsNoteUpdate={setIsNoteUpdate}
                            isAddNewNote={isAddNewNote}
                            setIsAddNewNote={setIsAddNewNote}
                            isAddNewNote={isAddNewNote}
                            isNotesFetching={isNotesFetching}
                            setIsNoteEditing={setIsNoteEditing}
                            handleSaveNote={(msg) => handleSaveNote(msg)}
                            handleUpdateNote={(msg) => handleUpdateNote(msg)}
                            canUserPerformAction={canUserPerformAction}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="write-msg-sec"
                      style={{
                        paddingBottom: "30px",
                        marginTop: "0px",
                        display:
                          windowSize > 600 || activeTab === "tab1"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Row
                        className={`reply-row ${
                          isShowRepliedSection ? "show" : ""
                        }`}
                      >
                        <Col sm={12}>
                          <div
                            className="reply-input"
                            style={{
                              width: [
                                "video",
                                "document",
                                "image",
                                "audio",
                              ]?.includes(
                                getRepliedMsgType(
                                  selChatData?.messages?.find(
                                    (item: singleChatDto) =>
                                      item?._id == selMsgForOpr
                                  )
                                )
                              )
                                ? "97%"
                                : "97%",
                              float: "right",
                            }}
                          >
                            <div className="ri-header">
                              {selChatData?.messages?.find(
                                (item: singleChatDto) =>
                                  item?._id == selMsgForOpr
                              )?.from?._id == userId
                                ? "You"
                                : selChatData?.messages?.find(
                                    (item: singleChatDto) =>
                                      item?._id == selMsgForOpr
                                  )?.from
                                ? selChatData?.messages?.find(
                                    (item: singleChatDto) =>
                                      item?._id == selMsgForOpr
                                  )?.from?.firstname +
                                  " " +
                                  selChatData?.messages?.find(
                                    (item: singleChatDto) =>
                                      item?._id == selMsgForOpr
                                  )?.from?.lastname
                                : currentConversation?.contact?.contactName}
                            </div>
                            <div className="ri-msg">
                              {renderRepliedMessage(
                                selChatData?.messages?.find(
                                  (item: singleChatDto) =>
                                    item?._id == selMsgForOpr
                                )
                              )}
                            </div>
                          </div>
                          <button
                            className="close-reply"
                            onClick={() => {
                              setIsShowRepliedSection(false);
                              setSelMsgForOpr(null);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.94004 8.00012L13.14 3.80679C13.2656 3.68125 13.3361 3.51099 13.3361 3.33346C13.3361 3.15592 13.2656 2.98566 13.14 2.86012C13.0145 2.73459 12.8442 2.66406
                                                            12.6667 2.66406C12.4892 2.66406 12.3189 2.73459 12.1934 2.86012L8.00004 7.06012L3.80671 2.86012C3.68117 2.73459 3.51091 2.66406 3.33337 2.66406C3.15584 2.66406 2.98558 2.73459 2.86004 2.86012C2.7345 2.98566 2.66398 3.15592 2.66398 3.33346C2.66398 3.51099 2.7345 3.68125 2.86004 3.80679L7.06004 8.00012L2.86004 12.1935C2.79756 12.2554 2.74796 12.3292 2.71411 12.4104C2.68027 12.4916 2.66284 12.5788 2.66284 12.6668C2.66284 12.7548 2.68027 12.8419 2.71411 12.9232C2.74796 13.0044 2.79756 13.0781 2.86004 13.1401C2.92202 13.2026 2.99575 13.2522 3.07699 13.2861C3.15823 13.3199 3.24537 13.3373 3.33337 13.3373C3.42138 13.3373 3.50852 13.3199 3.58976 13.2861C3.671 13.2522 3.74473 13.2026 3.80671 13.1401L8.00004 8.94012L12.1934 13.1401C12.2554 13.2026 12.3291 13.2522 12.4103 13.2861C12.4916 13.3199 12.5787 13.3373 12.6667 13.3373C12.7547 13.3373 12.8419 13.3199 12.9231 13.2861C13.0043 13.2522 13.0781 13.2026 13.14 13.1401C13.2025 13.0781 13.2521 13.0044 13.286 12.9232C13.3198 12.8419 13.3372 12.7548 13.3372 12.6668C13.3372 12.5788 13.3198 12.4916 13.286 12.4104C13.2521 12.3292 13.2025 12.2554 13.14 12.1935L8.94004 8.00012Z"
                                fill="black"
                              ></path>
                            </svg>
                          </button>
                        </Col>
                      </Row>
                      <div className="row mx-0">
                        {canUserReply ? (
                          <div className="px-0 bottom-bar-container">
                            <div
                              className="attached-file"
                              style={{
                                flex: "1",
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              {isGreaterThanOrEqualToOneMinute(
                                storedConversations.find(
                                  (item) => item?._id == selChatData?._id
                                )?.conversationExpiry
                              ) != false ? (
                                <Dropdown>
                                  <Dropdown.Toggle className="attached-btn">
                                    {/* <i className="bi bi-paperclip"></i> */}
                                    <i className="bi bi-plus-lg"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="attached-option">
                                    <Dropdown.Item onClick={openWebCam}>
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            <i className="bi bi-camera"></i>
                                          </div>
                                        </li>
                                        <li className="nav-item">Capture</li>
                                      </ul>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={showDoc}>
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            {/* <i className="bi bi-file-earmark"></i> */}
                                            <i className="bi bi-file-earmark-text"></i>
                                          </div>
                                        </li>
                                        <li className="nav-item">Document</li>
                                      </ul>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={async () => {
                                        setShowContactsModal(true);

                                        // if (cgtshow) {
                                        setContactPage(1);
                                        setContacts([]);
                                        setHasMoreContacts(true);
                                        await fetchAllContacts();
                                      }}
                                    >
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            <i className="bi bi-person"></i>
                                          </div>
                                        </li>
                                        <li className="nav-item">Contact</li>
                                      </ul>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => showImage()}>
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            <i className="bi bi-image"></i>
                                          </div>
                                        </li>
                                        <li className="nav-item">Gallery</li>
                                      </ul>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => setIsShowTempModal(true)}
                                    >
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            <i className="bi bi-envelope-paper"></i>
                                          </div>
                                        </li>
                                        <li className="nav-item">Template</li>
                                      </ul>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => setIsShowFlowModal(true)}
                                    >
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="link-icon">
                                            <svg
                                              width="26"
                                              height="26"
                                              viewBox="0 0 26 26"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M1.85742 16.7129L1.85742 9.28432L8.0479 9.28432L8.0479 16.7129L1.85742 16.7129Z"
                                                stroke="#4B4A4A"
                                                stroke-width="1.7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.9531 10.5234L17.9531 3.09487L24.1436 3.09487L24.1436 10.5234L17.9531 10.5234Z"
                                                stroke="#4B4A4A"
                                                stroke-width="1.7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M17.9531 22.9043L17.9531 15.4757L24.1436 15.4757L24.1436 22.9043L17.9531 22.9043Z"
                                                stroke="#4B4A4A"
                                                stroke-width="1.7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M16.7143 19.1934L13 19.1934L13 6.81241L16.7143 6.81241"
                                                stroke="#4B4A4A"
                                                stroke-width="1.7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M11.7631 13L8.04883 13"
                                                stroke="#4B4A4A"
                                                stroke-width="1.7"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </div>
                                        </li>
                                        <li className="nav-item">Flows</li>
                                      </ul>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : null}
                            </div>
                            {audioBlob && !recording && (
                              <button
                                className="bottom-mic-btn"
                                onClick={deleteAudioHandler}
                              >
                                <img src={DeleteIcon} alt="" />
                              </button>
                            )}
                            <div
                              style={{ display: recording ? "flex" : "none" }}
                            >
                              <p className="mt-3 me-2">
                                {formatTime(elapsedTime)}
                              </p>

                              <ReactMic
                                record={recording}
                                className="sound-wave"
                                onStop={onStop}
                                strokeColor="#165e5b"
                                backgroundColor="#ffffff"
                                mimeType="audio/ogg"
                              />
                            </div>
                            {audioBlob && !recording ? (
                              <audio controls style={{ width: "50%" }}>
                                <source src={audioBlob} type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio>
                            ) : // <audio  controls src={audioBlob} style={{width:"50%"}}/>
                            // <ReactAudioPlayer
                            // // ref={audioRef}
                            //     src={audioBlob}
                            //     // autoPlay
                            //     controls
                            //     className='audio-player'
                            //     // onLoadedMetadata={handleAudioLoadedMetadata}
                            // />

                            !selectedTemplate &&
                              !contactSelected &&
                              !selectedFlow ? (
                              <div
                                className="text-input"
                                style={{
                                  flex: "13",
                                  display: recording ? "none" : "block",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent:
                                      isGreaterThanOrEqualToOneMinute(
                                        storedConversations.find(
                                          (item) =>
                                            item?._id == selChatData?._id
                                        )?.conversationExpiry
                                      ) == false
                                        ? "center"
                                        : "flex-start",
                                    alignItems:
                                      isGreaterThanOrEqualToOneMinute(
                                        storedConversations.find(
                                          (item) =>
                                            item?._id == selChatData?._id
                                        )?.conversationExpiry
                                      ) == false
                                        ? "center"
                                        : "flex-start",
                                    flexDirection: "column",
                                    height: "auto",
                                    minWidth: "100%",
                                  }}
                                >
                                  {isGreaterThanOrEqualToOneMinute(
                                    storedConversations.find(
                                      (item) => item?._id == selChatData?._id
                                    )?.conversationExpiry
                                  ) == false ? (
                                    <Button
                                      className="btn btn-main me-2"
                                      onClick={() => setIsShowTempModal(true)}
                                    >
                                      Select a template to start a conversation
                                    </Button>
                                  ) : screenWidth < 767 ? (
                                    selChatData && selChatData?._id ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          maxHeight: "200px", // Set a reasonable max height for mobile devices
                                          overflowY: "auto", // Make the container scrollable
                                          touchAction: "manipulation", // Improve touch behavior
                                        }}
                                      >
                                        <textarea
                                          className="form-control"
                                          value={msg}
                                          style={{ resize: "none" }}
                                          disabled={sending}
                                          onChange={handleMsg}
                                          onKeyDown={onEnterHandler}
                                          rows={1.5}
                                        ></textarea>
                                      </div>
                                    ) : null
                                  ) : selChatData && selChatData?._id ? (
                                    <textarea
                                      className="form-control"
                                      value={msg}
                                      style={{ resize: "none" }}
                                      disabled={sending}
                                      onChange={handleMsg}
                                      onKeyDown={onEnterHandler}
                                      rows={1.5}
                                    ></textarea>
                                  ) : null}
                                </div>
                              </div>
                            ) : (
                              <ShowSelectedTemplateView />
                            )}

                            {recording ? (
                              <button
                                className="bottom-mic-btn"
                                onClick={stopAudioHandler}
                                style={{ margin: "0 5px" }}
                              >
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="16"
                                    cy="16"
                                    r="14.75"
                                    stroke="#E94B42"
                                    stroke-width="2.5"
                                  />
                                  <path
                                    d="M17.6667 22V10H21V22H17.6667ZM11 22V10H14.3333V22H11Z"
                                    fill="#E94B42"
                                  />
                                </svg>{" "}
                              </button>
                            ) : (
                              isGreaterThanOrEqualToOneMinute(
                                storedConversations.find(
                                  (item) => item?._id == selChatData?._id
                                )?.conversationExpiry
                              ) == true && (
                                <button
                                  className="bottom-mic-btn"
                                  onClick={() =>
                                    selectedFlow || selectedTemplate
                                      ? null
                                      : startAudioHandler()
                                  }
                                  style={{
                                    cursor:
                                      selectedFlow || selectedTemplate
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <img src={Mic_icon} alt="" />
                                </button>
                              )
                            )}

                            <div
                              style={{
                                flex: "1",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {
                                //isGreaterThanOrEqualToOneMinute(storedConversations.find(item => item?._id == selChatData?._id)?.conversationExpiry) != false ?
                                <button
                                  className="bottom-send-btn"
                                  disabled={recording}
                                  onClick={
                                    selectedTemplate
                                      ? handleSendTemplate
                                      : selectedFlow
                                      ? handleSendFlow
                                      : sendMessage
                                  }
                                  style={{
                                    backgroundColor: recording
                                      ? "grey"
                                      : "#165E5B",
                                  }}
                                >
                                  {sending ? (
                                    <div>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ color: "#FFFFFF" }}
                                      />
                                    </div>
                                  ) : (
                                    <img src={Send_icon} alt="icon" />
                                  )}
                                </button>
                                //: null
                              }
                            </div>
                          </div>
                        ) : !isGetSingleChat && currentConversation ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>You can not interect to this chat</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {imageshow ? (
                      <div className={`img-adding ${imageshow ? "show" : ""}`}>
                        <div className="img-header">
                          <button className="close-doc" onClick={hideImage}>
                            <svg
                              width="35"
                              height="35"
                              viewBox="0 0 35 35"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M23.3926 11.6074L11.6075 23.3925"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M23.3926 23.3926L11.6075 11.6075"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        <div className="img-body">
                          {image ? (
                            <img
                              src={image}
                              alt="Uploaded"
                              className="added-img"
                            />
                          ) : (
                            <p>No image selected</p>
                          )}
                        </div>
                        {/* <div className = "img-msg">
                                                        <input type = "text" className = "img-msg-details" placeholder = "Type a message here...."/>
                                                    </div> */}
                        <div className="img-list">
                          <ul className="nav align-items-center justify-content-center">
                            {image && (
                              <li className="nav-item">
                                <div className="img-item">
                                  <img src={image} alt="Thumpnil" />
                                  <div
                                    className="delete-btn"
                                    onClick={() => setImage(null)}
                                  >
                                    <svg
                                      width="30"
                                      height="29"
                                      viewBox="0 0 30 29"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.75 25.375C8.0625 25.375 7.47417 25.1386 6.985 24.6657C6.495 24.192 6.25 23.6229 6.25 22.9583V7.25H5V4.83333H11.25V3.625H18.75V4.83333H25V7.25H23.75V22.9583C23.75 23.6229 23.5054 24.192 23.0163 24.6657C22.5263 25.1386 21.9375 25.375 21.25 25.375H8.75ZM21.25 7.25H8.75V22.9583H21.25V7.25ZM11.25 20.5417H13.75V9.66667H11.25V20.5417ZM16.25 20.5417H18.75V9.66667H16.25V20.5417Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </li>
                            )}
                            {!selectedTemplate ? (
                              <li className="nav-item">
                                {recording ? (
                                  <StopCircleIcon
                                    onClick={handleToggleRecording}
                                  />
                                ) : (
                                  <img
                                    onClick={handleToggleRecording}
                                    src={Mic_icon}
                                  />
                                )}
                              </li>
                            ) : null}
                            <li className="nav-item">
                              <button
                                className="btn-send-message"
                                onClick={
                                  selectedTemplate
                                    ? handleSendTemplate
                                    : selectedFlow
                                    ? handleSendFlow
                                    : sendMessage
                                }
                                style={{
                                  backgroundColor: recording
                                    ? "gray"
                                    : "rgba(22, 94, 91, 1)",
                                  height: "40px",
                                }}
                              >
                                {sending ? (
                                  <div>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      style={{ color: "#FFFFFF" }}
                                    />
                                  </div>
                                ) : (
                                  <img src={Send_icon} alt="icon" />
                                )}
                              </button>
                            </li>
                            <button
                              style={{ backgroundColor: "rgba(22, 94, 91, 1)" }}
                              onClick={
                                selectedTemplate
                                  ? handleSendTemplate
                                  : selectedFlow
                                  ? handleSendFlow
                                  : sendMessage
                              }
                            >
                              {sending ? (
                                <div>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ color: "#FFFFFF" }}
                                  />
                                </div>
                              ) : (
                                <img src={Send_icon} alt="icon" />
                              )}
                            </button>
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </Col>
          ) : null}
          <Col md={3} xxl={3} className="d-none d-md-block">
            {/* <div className="user-info-data h-100 d-none">
                <div className="user-info-header">
                    <div className="user-name">Hajira Ihsan</div>
                    <div className="online-status">Available <span></span></div>
                </div>
                <div className="user-personal-info">
                    <div className="info-row">
                        <div className="title">Basic Information</div>
                        <div className="description">Phone number: 03025676625</div>
                    </div>
                    <div className="info-row">
                        <div className="title">About</div>
                        <div className="description">Company: MWAN MOBILE</div>
                        <div className="description">Designation: UX/UI Designer</div>
                    </div>
                </div>
            </div> */}
            <div className="chat-info-side h-100">
              {isGetSingleChat || isGetAllConvers ? (
                <div
                  className="user-personal-info"
                  style={{ width: "100%", padding: "12px" }}
                >
                  <div className="info-row">
                    <div
                      className="placeholder-glow"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className="col-12 placeholder mb-2 py-2"
                        style={{ height: "40px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="placeholder-glow"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className="col-12 placeholder mb-2 py-2"
                        style={{ height: "100px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="placeholder-glow"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className="col-12 placeholder mb-2 py-2"
                        style={{ height: "100px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="placeholder-glow"
                      style={{ maxWidth: "100%" }}
                    >
                      <span
                        className="col-12 placeholder mb-2 py-2"
                        style={{ height: "100px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {/* <RenderChatDetailsOnRight chatData={selChatData} /> */}
                  <ChatAccordion
                    chatData={selChatData}
                    selectedTag={
                      storedConversations.find(
                        (item) => item?._id == selChatData?._id
                      )?.tag
                    }
                    remainingTime={
                      storedConversations.find(
                        (item) => item?._id == selChatData?._id
                      )?.conversationExpiry
                    }
                    setSelChatData={setSelChatData}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    openedMenuId={openedMenuId}
                    setOpenedMenuId={setOpenedMenuId}
                    open={open}
                    setIsShowDeleteNote={() => setIsShowDeleteNote(true)}
                    userId={userId}
                    setSelectedNote={(noteData) => setSelectedNote(noteData)}
                    isNoteAdding={isNoteAdding}
                    isNotesFetching={isNotesFetching}
                    isUpdateNote={isUpdateNote}
                    setIsNoteUpdate={setIsNoteUpdate}
                    isAddNewNote={isAddNewNote}
                    setIsAddNewNote={setIsAddNewNote}
                    setIsNoteAdding={setIsNoteAdding}
                    isNoteEditing={isNoteEditing}
                    setIsNoteEditing={setIsNoteEditing}
                    handleSaveNote={(msg: string) => handleSaveNote(msg)}
                    handleUpdateNote={(msg: string) => handleUpdateNote(msg)}
                    handleTagChange={(tagId: string) =>
                      assignNewTagToChat(tagId)
                    }
                    isNewTagAssigning={isNewTagAssigning}
                    isTagFetching={isTagFetching}
                    allTags={allTags}
                    storedConversations={storedConversations}
                    selChatData={selChatData}
                    canUserPerformAction={canUserPerformAction}
                    getAllNotesOfChat={(chatId: string) =>
                      getAllNotesOfChat(chatId)
                    }
                    setActiveKey={setActiveKey}
                    activeKey={activeKey}
                  />
                </>
              )}
            </div>
          </Col>
        </Row>
      )}

      <Modal
        show={cgtshow}
        onHide={() => setCgtShow(false)}
        className="whatsapp-modal modal-g-contact modal-select-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search Contact"
                  onChange={handleSearch}
                  value={inputSearch}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mx-0">
            <div className="col-12 contact-col" onScroll={handleContactScroll}>
              {contactsLoading && contactPage === 1 ? (
                <div
                  style={{
                    display: "flex",
                    minWidth: "150px",
                    width: "100%",
                    height: "50vh",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "60%",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "rgba(22, 94, 91, 1)" }}
                  />
                </div>
              ) : contacts?.length > 0 ? (
                //  contacts.filter(contact => contact.contactName.toLowerCase()?.includes(inputSearch.toLowerCase()) || contact.number.toLowerCase()?.includes(inputSearch.toLowerCase()))
                contacts.map((contact, index) => {
                  return (
                    <Row
                      className="mb-2 contact-group"
                      onClick={() => {
                        handleCreateChat(contact._id);
                      }}
                    >
                      <Col sm={12}>
                        <div className="user-name">
                          <Groupcontact
                            Image={Hajira_img}
                            name={contact.contactName}
                            key={index}
                            index={index + 1}
                            id={contact._id}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <div
                  className="tb-empty-content text-center "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                      height: "300px",
                      background: "white",
                    }}
                  >
                    <img src={ChatNoFound} alt="icon" />
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: "20px",
                        marginTop: "10px",
                        fontFamily: "Segoe UI",
                        color: "#000000",
                      }}
                    >
                      {"No Contacts Found"}
                    </p>
                  </div>
                </div>
              )}
              {contactsLoading && contactPage > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "rgba(22, 94, 91, 1)" }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="px-0">
                    <div className="attention-msg">
                        Couldn’t find contact in the list?
                    </div>
                    <Button variant="main-default" onClick={() => {
                        setCgtShow(false)
                        setCtShow(true)
                    }}>
                        Add New Contact
                    </Button>
                </Modal.Footer> */}
      </Modal>

      {/* <Modal
                show={cvsshow}
                onHide={() => setCvsShow(false)}
                className="whatsapp-modal modal-csv"
                centered
            >
                <Modal.Header className="p-0 border-0" closeButton>
                    <Modal.Title className="w-100 text-center">
                        <div
                            className="back-btn"
                            onClick={() => {
                                setCvsShow(false);
                                setCtShow(true);
                            }}
                         >
                            <i className="bi bi-chevron-left"></i>
                        </div>
                        Import contacts from CSV File
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    {contactsGroup.length > 0 ? (
                        <p style={{ fontSize: "14px" }}>
                            Please select the contacts you want to import.
                        </p>
                    ) : (
                        <>
                            <p
                                style={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    color: "#165e5b",
                                    marginBottom: "0",
                                }}
                            >
                                Instructions:
                            </p>
                            <ul>
                                <li style={{ fontSize: "14px" }}>
                                    Select and upload a .csv file
                                </li>
                                <li style={{ fontSize: "14px" }}>
                                    The header for the column of contact names should be
                                    "name"
                                </li>
                                <li style={{ fontSize: "14px" }}>
                                    The header for the column of contact numbers should be "number" and format should be Ex:447700900123
                                </li>
                            </ul>
                        </>
                    )}
                    <Row className="mb-1">
                        <Col sm={12}>
                            <Form.Group
                                controlId="formFile"
                                className="position-relative"
                            >
                                <Form.Control
                                    className="file-up"
                                    type="file"
                                    ref={inputRef}
                                    onChange={handleFileChange}
                                    accept=".csv"
                                />
                                <div className="input-label" onClick={handleClick}>
                                    Upload
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {contactsGroup.length > 0 && (
                        <Row>
                            <Col sm={12}>
                                <div className="modal-table">
                                    <Modaltable
                                        contacts={contactsGroup}
                                        setSelectedContacts={setSelectedContacts}
                                        fileUploaded={fileUploaded}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col sm={12}>
                            <Form.Group className="contact-m-form">
                                <Form.Label>Add to Groups (Optional)</Form.Label>
                                <Select
                                    isMulti
                                    className="c-select basic-multi-select"
                                    options={groups}
                                    placeholder={"Select Group(s)"}
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                        setSelectedGroups(e);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="px-0">
                    <Button
                        className="btn-main-default"
                        onClick={() => setCvsShow(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-main"
                        onClick={handleAddContact}
                        style={{
                            background: selectedContacts.length === 0 && "#165e5b",
                        }}
                        disabled={selectedContacts.length === 0}
                    >
                        Import Contact
                    </Button>
                </Modal.Footer>
            </Modal> */}
      <Modal
        show={isInvalidRecords}
        onHide={() => setIsInvalidRecords(false)}
        className="whatsapp-modal modal-invalid"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 50px",
              }}
            >
              <img src={InvalidIcon} className="mb-2" />
              <span className="mb-2">Failed to Upload</span>
              <p className="mb-2 text-center">
                We have found{" "}
                <span style={{ color: "#e94b42" }}>{invalidRecordsLength}</span>{" "}
                invalid Contacts. Update the Contacts in the CSV file and
                re-upload.
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {inValidContacs.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={inValidContacs}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                    setInvalidRecordsLength={setInvalidRecordsLength}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default w-100" onClick={handleTryAgain}>
            Try Again
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cvsshow}
        onHide={() => setCvsShow(false)}
        className="whatsapp-modal modal-csv"
        centered
      >
        <Modal.Header
          className="p-0"
          style={{ borderBottom: "2px solid #0000001A" }}
        >
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <span style={{ fontSize: "20px" }}>Import Contacts</span>
            <Button
              className="import-cvs"
              onClick={() => {
                setCtShow(true);
                setCvsShow(false);
              }}
            >
              Add Contact
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-4 pb-0">
          <Row>
            <Col sm={12}>
              <div className="download-template">
                <p>
                  Download the “CSV Template”, fill in the data, and then upload
                  the file to the system.
                </p>
                <Button className="btn btn-main" onClick={downloadSampleCSV}>
                  <svg
                    className="me-1"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 10.5L10.5 14.732L14.5 10.541"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 3.5V14.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 17.5H16.5"
                      stroke="#165E5B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Download Sample
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <div className="Upload-cvs-file">
                  <input type="file" onChange={changeHandler} accept=".csv" />
                  <div className="file-body">
                    <i
                      class="bi bi-file-earmark-text me-2 "
                      style={{ fontSize: "20px" }}
                    ></i>
                    {fileName ? fileName : "Upload a CSV file - up to 16 MB"}
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {csvError && (
              <Row>
                <Col sm={12}>
                  <div
                    className="error-message"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "-15px",
                    }}
                  >
                    CSV file is required
                  </div>
                </Col>
              </Row>
            )}
            <Col sm={12}>
              <Form.Group className="contact-m-form">
                <Form.Label>Add to Groups (Optional)</Form.Label>
                <Select
                  isMulti
                  className="c-select basic-multi-select"
                  options={groups}
                  placeholder={"Select Group(s)"}
                  classNamePrefix="select"
                  onChange={(e) => {
                    setSelectedGroups(e);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* {contactsGroup.length > 0 && (
            <Row>
              <Col sm={12}>
                <div className="modal-table">
                  <Modaltable
                    contacts={contactsGroup}
                    setSelectedContacts={setSelectedContacts}
                    fileUploaded={fileUploaded}
                    inValidContacs={inValidContacs}
                  />
                </div>
              </Col>
            </Row>
          )} */}
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              setCvsShow(false);
              setFileData(null);
              setFileName("");
            }}
          >
            Cancel
          </Button>
          {isAdding ? (
            <div
              style={{
                display: "flex",
                minWidth: "114.94px",
                maxWidth: "114.94px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : (
            <Button className="btn-main" onClick={() => handleAddContacts()}>
              Import
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={ctshow}
        onHide={() => setCtShow(false)}
        className="whatsapp-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title className="d-flex align-items-center justify-content-between w-100">
            <span>Add Contact</span>
            <Button
              className="import-cvs"
              onClick={() => {
                setCtShow(false);
                setCvsShow(true);
              }}
            >
              Import Contacts
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="contactName"
                  value={newContactData.contactName}
                  onChange={handleContactInputChange}
                  className={
                    inputErrors?.contactName?.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors?.contactName?.hasError &&
                    inputErrors?.contactName?.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Phone No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex: 447700900123"
                  name="number"
                  value={newContactData.number}
                  onChange={handleContactInputChange}
                  className={inputErrors?.number?.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors?.number?.hasError &&
                    inputErrors?.number?.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button className="btn-main-default" onClick={() => setCtShow(false)}>
            Cancel
          </Button>
          <Button className="btn-main" onClick={handleAddContact}>
            Add Contact
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={muteshow}
        onHide={() => setMuteShow(false)}
        className="whatsapp-modal modal-contact "
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>
            Mute “{selChatData?.contact?.contactName || "Unknown"}” for ...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-3">
              <div className="radio-row d-flex align-items-center">
                <input
                  name="radio"
                  type="radio"
                  className="form-check-input mt-0"
                  id="8hours"
                  value="8 hours"
                  onChange={handleRadioChange}
                />
                <Form.Label htmlFor="8hours" className="mb-0 ms-2">
                  8 hours
                </Form.Label>
              </div>
            </Col>
            <Col xs={12} className="mb-3">
              <div className="radio-row d-flex align-items-center">
                <input
                  name="radio"
                  type="radio"
                  className="form-check-input mt-0"
                  id="1week"
                  value="1 week"
                  onChange={handleRadioChange}
                />
                <Form.Label htmlFor="1week" className="mb-0 ms-2">
                  1 Week
                </Form.Label>
              </div>
            </Col>
            <Col xs={12} className="mb-3">
              <div className="radio-row d-flex align-items-center">
                <input
                  name="radio"
                  type="radio"
                  className="form-check-input mt-0"
                  id="always"
                  value="Always"
                  onChange={handleRadioChange}
                />
                <Form.Label htmlFor="always" className="mb-0 ms-2">
                  Always
                </Form.Label>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => setMuteShow(false)}
          >
            Cancel
          </Button>
          <Button
            className="btn-main"
            onClick={() => handleMuteNotification(selChatData?._id)}
          >
            Mute Notification
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={dtshow}
        onHide={() => setDtShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to delete{" "}
            <span className="u-name">
              “ {selChatData?.contact?.contactName} “
            </span>{" "}
            from your contacts. This process cannot be reversed.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            variant="danger-light"
            onClick={() => handleDeleteChat(selChatData?._id)}
          >
            Yes, Delete it
          </Button>
          <Button variant="danger" onClick={() => setDtShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={blockShow}
        onHide={() => setBlockShow(false)}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Block?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-content">
            You are about to block{" "}
            <span className="u-name">
              “ {selChatData?.contact?.contactName} “
            </span>{" "}
            from your contacts list. Block contact will no longer send you
            message.
          </div>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            variant="danger-light"
            onClick={() => handleBlockChat(selChatData?._id)}
          >
            Yes, Block it
          </Button>
          <Button variant="danger" onClick={() => setBlockShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={atsshow}
        onHide={handleCloseAssignTagModal}
        backdrop="static"
        className="whatsapp-modal modal-g-contact modal-select-assignTo"
        centered
      >
        <Modal.Header className="pt-0 px-0" closeButton>
          <Modal.Title className="mb-3 w-100">Assign to:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {isCompanyUsersFetch ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "50vh",
              }}
            >
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                style={{ color: "rgba(22, 94, 91, 1)" }}
              />
            </div>
          ) : allCompanyUsers?.length > 0 ? (
            <>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Filter agents by choosing Tags</Form.Label>
                    <Select
                      styles={colorStyles}
                      className="c-select basic-multi-select"
                      options={tagOptions}
                      placeholder={"Choose Tag"}
                      classNamePrefix="select"
                      onChange={(value) =>
                        setSelTagType({
                          label: value?.label,
                          value: value?.value,
                        })
                      }
                      value={
                        selTagType?.value
                          ? { label: selTagType.label, value: selTagType.value }
                          : null
                      }
                      components={{
                        DropdownIndicator: () => (
                          <svg
                            width="10"
                            height="5"
                            viewBox="0 0 10 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M0 0L5 5L10 0H0Z" fill="#494949" />
                          </svg>
                        ),
                      }}

                      //  value={{ label: selTagType?.label, value: selTagType?.value }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="modal-body-inner">
                <Row>
                  <div className="col-12">
                    <AssignContact
                      // selectedAgent={storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length > 0 ? storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers  : storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers?.length > 0 && storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers && storedConversations.find(item => item?._id == selChatData?._id)?.assingUsers}
                      selectedAgent={
                        storedConversations.find(
                          (item) => item?._id === selChatData?._id
                        )?.assingUsers || []
                      }
                      allCompanyUsers={getMatchedTags()}
                      markNewTag={(userIds) => setNewUserToBeAssigned(userIds)}
                    />
                  </div>
                </Row>
              </div>
              <Row className="pt-3">
                <Col xs={12}>
                  {isChatAssigning ? (
                    <Button variant="main" className="d-block w-100">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="spinner-assign"
                        // style={{ color: "#FFFFFF", }}
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="main"
                      className="d-block w-100"
                      onClick={assignChatToNewUser}
                    >
                      Save Assignment
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <p>No Users to display</p>
          )}
        </Modal.Body>
      </Modal>
      {/*             
        <Modal
            show={atsshow}
            onHide={handleCloseAssignTagModal}
            className="whatsapp-modal modal-g-contact modal-select-assignTo"
            centered
        >
            <Modal.Header className="pt-0 px-0" closeButton>
            <Modal.Title className="mb-3 w-100">Assign to:</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
            <Row>
                <Col sm={12}>
                <Form.Group>
                    <Form.Label>Filter users by choosing Tags</Form.Label>
                    <Select
                    className="c-select basic-multi-select"
                    options={options}
                    placeholder={"Choose Tag"}
                    classNamePrefix="select"
                    />
                </Form.Group>
                </Col>
            </Row>

            <div className="modal-body-inner">
                <Row>
                <div className="col-12">
                    <AssignContact />
                </div>
                </Row>
            </div>

            <Row className="pt-3">
                <Col xs={12}>
                <Button
                    variant="main"
                    className="d-block w-100"
                    onClick={() => setAtsShow(false)}
                >
                    Save Assignment
                </Button>
                </Col>
            </Row>
            </Modal.Body>
        </Modal> */}

      <Modal
        show={isShowDeleteNote}
        onHide={() => {
          setIsShowDeleteNote(false);
          setSelectedNote(null);
        }}
        className="whatsapp-modal delete-modal"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-0">
              <h4 className="text-[20px] font-['Segoe UI'] font-thin leading-[18px] text-[#000000]">
                Do you want to delete this note?
              </h4>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn btn-danger-light"
            onClick={() => handleDeleteNote()}
          >
            {isDeleteingNote ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red", marginRight: "10px" }}
              />
            ) : null}
            Yes, Delete It
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setIsShowDeleteNote(false);
              setSelectedNote(null);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {imgShow && (
        <Modal
          show={imgShow}
          onHide={() => {
            setImgShow(false);
            setImageLink("");
          }}
          className="whatsapp-modal modal-g-image modal-image-preview"
          centered
        >
          <img src={imageLink} />
        </Modal>
      )}

      <Modal
        show={camshow}
        onHide={() => {
          setCamshow(false);
          setPhotoUrl(null);
        }}
        className="whatsapp-modal modal-g-contact modal-select-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!photoUrl ? (
            <div className="webcam-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width="100%"
              />
            </div>
          ) : (
            <div className="webcam-container">
              <img src={photoUrl} alt="Preview" />
            </div>
          )}

          {!photoUrl ? (
            <div
              style={{
                marginTop: "8px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <Button
                style={{ backgroundColor: "#165E5B" }}
                onClick={handleTakePhoto}
              >
                Take Picture
              </Button>
            </div>
          ) : (
            <div
              style={{
                marginTop: "8px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <Button
                style={{ backgroundColor: "#165E5B" }}
                onClick={handleSendPhoto}
              >
                Send Picture
              </Button>
              <Button
                style={{ backgroundColor: "#165E5B" }}
                onClick={handleDiscardPhoto}
              >
                Retake Picture
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showContactsModal}
        onHide={() => {
          setShowContactsModal(false);
          setContactSelected(null);
        }}
        className="whatsapp-modal modal-g-contact modal-select-contact inbox-contact"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search Contact"
                  value={inputSearch}
                  onChange={handleSearch}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mx-0">
            <div className="col-12 contact-col" onScroll={handleContactScroll}>
              {contactsLoading && contactPage === 1 ? (
                <div
                  style={{
                    display: "flex",
                    minWidth: "150px",
                    width: "100%",
                    height: "50vh",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "60%",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "rgba(22, 94, 91, 1)" }}
                  />
                </div>
              ) : (
                contacts?.map((contact, index) => {
                  return (
                    <Row
                      className="mb-2 contact-group"
                      onClick={() => {
                        handleSelectedContact(contact);
                      }}
                    >
                      <Col sm={12}>
                        <div className="user-name">
                          <Groupcontact
                            Image={Hajira_img}
                            name={contact.contactName}
                            handleCreateChat={handleCreateChat}
                            key={index}
                            index={index + 1}
                            id={contact._id}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })
              )}
              {contactsLoading && contactPage > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "rgba(22, 94, 91, 1)" }}
                  />
                </div>
              )}
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* delete message popup */}
      <Modal
        show={isShowMsgDelPopup}
        onHide={() => {
          setIsShowDelMsgPopup(false);
          setIsMsgDelForEveryOne(false);
        }}
        className="whatsapp-modal confirm-modal modal-contact"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Delete Message?</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="px-0">
          <Row className="w-100">
            <Col sm={12} className="text-end">
              {isMsgDelForEveryOne ? (
                <Button
                  variant="danger-light"
                  style={{ color: "#FFFFFF", backgroundColor: "#bb2d3b" }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ color: "#FFFFFF", marginRight: "5px" }}
                  />
                  Delete for everyone
                </Button>
              ) : (
                <Button variant="danger-light" onClick={delMsgForEvryOne}>
                  Delete for everyone
                </Button>
              )}
            </Col>
          </Row>
          <Row className="w-100">
            <Col sm={12} className="text-end">
              <Button
                variant="danger-light"
                onClick={() => setIsShowDelMsgPopup(false)}
              >
                Delete for me
              </Button>
            </Col>
          </Row>
          <Row className="w-100">
            <Col sm={12} className="text-end">
              <Button
                variant="danger"
                onClick={() => setIsShowDelMsgPopup(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

      {/* edit message popup */}
      <Modal
        show={isShowEditMsgPopup}
        onHide={() => {
          setIsShowEditMsgPopup(false);
          setEditMsgText("");
        }}
        className="Edit_modal modal-contact"
        centered
      >
        <Modal.Header>
          <ul className="nav align-items-center justify-content-between w-100">
            <li className="nav-item">Edit Message</li>
            <li className="nav-item">
              <div
                className="hide-editModal"
                onClick={() => {
                  setIsShowEditMsgPopup(false);
                  setEditMsgText("");
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3926 11.6074L11.6075 23.3925"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M23.3926 23.3926L11.6075 11.6075"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </li>
          </ul>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="message-edit-content">
            <div className="edit-content-area">
              <div className="edit-msg">
                <div className="edit-msg-body">
                  {
                    selChatData?.messages?.find(
                      (item: singleChatDto) => item?._id == selMsgForOpr
                    )?.text
                  }
                  {/* <div className = "read-more">
                                <button className = "rm">
                                    Read More
                                </button>
                            </div> */}
                </div>
                <div className="edit-msg-time-status">
                  <div className="chat-received">
                    {/* <img src = {Chat_seen} alt = "chat-seen-icons"/> */}
                    {renderStatusTicks(
                      selChatData?.messages?.find(
                        (item: singleChatDto) => item?._id == selMsgForOpr
                      )
                    )}
                  </div>
                  <div className="chat-time">
                    {moment(
                      selChatData?.messages?.find(
                        (item: singleChatDto) => item?._id == selMsgForOpr
                      )?.timestamp
                    ).format("DD MMM YYYY, hh:mm a")}
                  </div>
                </div>
              </div>
            </div>
            <div className="msg-area">
              <div className="input-msg w-100">
                <input
                  type="text"
                  placeholder="Type message here ..."
                  value={editMsgText}
                  onChange={(e) => setEditMsgText(e.target.value)}
                />
              </div>
              <div className="cmd-side">
                {isMsgEditForEveryOne ? (
                  <button className="btn-send">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ color: "#FFFFFF" }}
                    />
                  </button>
                ) : (
                  <button className="btn-send" onClick={editMsgForEvryOne}>
                    <svg
                      width="27"
                      height="20"
                      viewBox="0 0 27 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.07129 10.786L10.9284 14.6431L21.2501 4.35742"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {isShowTempModal && (
        <SelectTemplateModal
          id={selChatData?._id}
          show={isShowTempModal}
          setShow={setIsShowTempModal}
          handleCloseTemplate={handleCloseTemplate}
          setVariable={setVariable}
          selChatData={selChatData}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {isShowFlowModal && (
        <SelectFlowModal
          id={selChatData?._id}
          show={isShowFlowModal}
          setShow={setIsShowFlowModal}
          handleCloseFlow={handleCloseFlow}
          setVariable={setVariable}
          selChatData={selChatData}
          setSelectedFlow={setSelectedFlow}
        />
      )}
    </div>
  );
};

export default Inboxchat;
