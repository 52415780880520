import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  parents: {}, // Stores state for each parent by id
  templatePreviewHeight: 0,
  nodes: [],
  isData: false,
  triggerDeleteNode: null,
  triggerAddChild: null,
  triggerupdateNode: {},
  triggerAddSingleChild: null,
  isActive: false,
  isDeleteNode: {
    isDelete: false,
    nodeId: "",
  },
};

export const WorkflowSlice = createSlice({
  name: "Workflow",
  initialState,
  reducers: {
    setShowImage: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = value;
      state.parents[id].showVideo = false; // Ensure others are false
      state.parents[id].showDocument = false;
    },
    setShowVideo: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = false; // Ensure others are false
      state.parents[id].showVideo = value;
      state.parents[id].showDocument = false;
    },
    setShowDocument: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists
      state.parents[id].showImage = false; // Ensure others are false
      state.parents[id].showVideo = false;
      state.parents[id].showDocument = value;
    },
    setShowMessage: (state, action) => {
      const { id, value } = action.payload;
      if (!state.parents[id]) state.parents[id] = {}; // Initialize if not exists

      state.parents[id].showMessage = value;
    },
    setTemplatePreviewHeight: (state, action) => {
      state.templatePreviewHeight = action.payload;
    },
    setGLobalNodes: (state, action) => {
      action.payload.length > 0 &&
        action.payload.map((node) => state.nodes.push(node));

      // Always spread to ensure immutability
    },
    clearGlobalNodes: (state, action) => {
      state.nodes = [];
      state.isData = false;
    },
    setIsData: (state, action) => {
      state.isData = action.payload;
    },

    triggerDeleteNodeFromNodes: (state, action) => {
      state.triggerDeleteNode = action.payload; // Store requested children
    },
    triggerAddChildToNodes: (state, action) => {
      state.triggerAddChild = action.payload; // Store requested children
    },
    triggerupdateNodeData: (state, action) => {
      const { id, ...rest } = action.payload;
      state.triggerupdateNode = {
        id,
        ...rest,
      };
    },
    triggerAddSingleChildToNode: (state, action) => {
      state.triggerAddSingleChild = action.payload;
    },

    setIsDeleteNode: (state, action) => {
      state.isDeleteNode.isDelete = action.payload.isDelete;
      state.isDeleteNode.nodeId = action.payload.nodeId;
    },

    clearTrigger: (state) => {
      state.triggerDeleteNode = null; // Reset trigger after use
      state.triggerAddChild = null;
      state.triggerupdateNode = null;
      state.triggerAddSingleChild = null;
    },
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const {
  setShowImage,
  setShowVideo,
  setShowDocument,
  setShowMessage,
  setTemplatePreviewHeight,
  setGLobalNodes,
  setIsData,
  triggerDeleteNodeFromNodes,
  clearTrigger,
  triggerAddChildToNodes,
  triggerupdateNodeData,
  triggerAddSingleChildToNode,
  clearGlobalNodes,
  setIsActive,
  setIsDeleteNode,
} = WorkflowSlice.actions;

export default WorkflowSlice.reducer;
