import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getAllTemplates } from "../../services/WhatsappAPI";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Template_ic from "../../images/icons/template-ic.svg";
import Reciver from "../../images/icons/reciver.svg";
import Linkforward from "../../images/icons/link-forward.svg";
import Chat_banner from "../../images/chat-img.png";
// import Reciver from "../../images/reciver.svg";
import { Accordion, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AuthTemplate } from "../template-preview/AutheTemplate";
import { UrlPhoneNumber } from "../template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../template-preview/QuickReplyPreview";
import { toast } from "react-toastify";
import { getAllWorkflows } from "../../services/workflow";

const SelectFlowModal = ({
  id,
  show,
  handleCloseFlow,
  setShow,

  setSelectedFlow,
}) => {
  const [flows, setFlows] = useState([]);

  const [singleFlow, setSingleFlow] = useState(null);
  const [flowtoShow, setFlowtoShow] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (flows.length > 0) {
      setActiveId(`radio-${flows[0]._id}`);
    }
  }, [flows]);

  const handleRadioChange = (id) => {
    setActiveId(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getAllWorkflows();
        if (!resp.success) throw new Error(resp.error);
        console.log("flowsData", resp?.data);
        const nonEmptyFlows =
          resp?.data?.db?.filter(
            (item) => item?.payLoad && Object.keys(item.payLoad).length > 0
          ) || [];
        console.log("nonEmptyFlows", nonEmptyFlows);
        setFlows(nonEmptyFlows);
        setFlowtoShow(nonEmptyFlows[0]);
        setSingleFlow(nonEmptyFlows[0]);
      } catch (error) {
        console.log("Failed to fetch flows!");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleInsertFlow = () => {
    setSelectedFlow(singleFlow);
    setShow(false);
  };

  console.log("flowtoShow", flowtoShow);

  const handleFlowData = (flow) => {
    // const matchedFlow = flows?.meta?.data?.find(
    //   (metaFlow) => metaFlow?.id == flow?.flowId
    // );
    // if (matchedFlow) {
    setSingleFlow(flow);
    // }

    setFlowtoShow(flow);
  };

  const flowList = flows
    ?.filter((flow) => flow?.name?.toLowerCase()?.includes(searchTerm))
    ?.filter((flow) => flow?.status == "PUBLISHED")
    .map((flow, index) => {
      console.log("template", flow);
      const radioId = `radio-${flow._id}`;
      return (
        <div
          className="single-template"
          onClick={(e) => {
            e.stopPropagation();
            //setTemplateShow(true);
            // setFlowtoShow(flow);
            handleFlowData(flow);
            // setTemplateData({ event: e, flow });
            handleRadioChange(radioId);
          }}
        >
          <div className="select_template">
            <Form.Group
              className={`form-group mb-3 ${
                activeId === radioId ? "active" : ""
              }`}
            >
              <div className="form-check d-flex align-items-center justify-content-between ps-0 mb-0">
                <label htmlFor="radio-1">
                  <div className="template-label">
                    <div className="template-name">{flow?.name}</div>
                    <div className="tempalte-leng">
                      {" "}
                      CreatedAt: {flow?.createdAt?.split("T")[0]}
                    </div>
                  </div>
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="select-template"
                  checked={activeId === radioId}
                />
              </div>
            </Form.Group>
          </div>
        </div>
      );
    });

  const loadingList = Array.from({ length: 5 }).map((_, index) => (
    <div className="single-template1">
      <div className="placeholder-glow">
        <span
          className="col-12 placeholder"
          style={{ height: "70px", borderRadius: "5px" }}
        ></span>
      </div>
    </div>
  ));

  console.log("flowtoShow", flowtoShow);

  return (
    <>
      <Modal
        className="whatsapp-modal select_tempalte-modal"
        centered
        show={show}
        onHide={() => handleCloseFlow()}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="mb-0 w-100">Select a Flow</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Search Flow"
                  className="search-template"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </Form.Group>
            </Col>
          </Row>

          <div
            className="modal-body-inner"
            style={{
              maxHeight: "370px",
              height: "100%",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {loading ? (
              loadingList
            ) : flowList?.length > 0 ? (
              <Row>
                <Col md={7}>{flowList}</Col>
                <Col md={5}>
                  <div className="message-preview-sec">
                    {/* <iframe
                      src={flowtoShow}
                      width="100%"
                      height="600"
                      style={{ border: "none" }}
                      allowFullScreen
                    ></iframe> */}
                    <div className="flow-message">
                      <div className="content">
                        {flowtoShow?.flowMessage?.header?.text && (
                          <>
                            {" "}
                            <p style={{ fontWeight: "600" }}>
                              {flowtoShow?.flowMessage?.header?.text || ""}
                            </p>
                            <br />
                          </>
                        )}
                        {flowtoShow?.flowMessage?.body?.text && (
                          <>
                            {" "}
                            <p>{flowtoShow?.flowMessage?.body?.text || ""}</p>
                            <br />
                          </>
                        )}
                        {flowtoShow?.flowMessage?.footer?.text && (
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#4B4A4A",
                            }}
                          >
                            {flowtoShow?.flowMessage?.footer?.text || ""}
                          </p>
                        )}
                      </div>
                      {flowtoShow?.btnText && <hr />}

                      <div className="d-flex justify-content-center align-items-center ">
                        <button
                          className="btn cursor-pointer"
                          disabled
                          style={{ border: "none", fontWeight: "600" }}
                        >
                          {flowtoShow?.btnText}
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                No Flows Found
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="main"
            onClick={() => {
              handleInsertFlow();
            }}
          >
            Insert Flow
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectFlowModal;
