import React from "react";
import { getRandomColor } from ".././../../utils/utils";

const Username = ({ name, Useremail }) => {
  const randomColorStyle = {
    //backgroundColor: getRandomColor(),
    width: "33px",
    height: "33px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "21px",
    color: "#fff",
    textTransform: "uppercase",
  };

  // Extract first letter of name for nameletter
  const firstLetter = name ? name.charAt(0).toUpperCase() : "";

  return (
    <>
      <ul className="nav align-items-center">
        <li className="nav-item">
          <div
            style={{
              ...randomColorStyle,
              backgroundColor: getRandomColor(Useremail),
            }}
            className="random-clr"
          >
            {firstLetter}
          </div>
        </li>
        <li className="nav-item">
          <div className="c-name">{name}</div>
          <div className="user-email">{Useremail}</div>
        </li>
      </ul>
    </>
  );
};

export default Username;
