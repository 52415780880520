import { useEffect } from "react";
import axios from "axios";
import { refreshUserToken } from "../../services/User";

const useTokenRefresh = () => {
  const token = localStorage.getItem("token");
  // const refreshToken = localStorage.getItem("refreshToken");
  // console.log("refreshUserToken", refreshToken);
  useEffect(() => {
    // Function to fetch and update tokens
    const refreshTokenCall = async () => {
      try {
        let response;
        // if (token) {
        response = await refreshUserToken(localStorage.getItem("refreshToken"));
        // }
        const { accessToken, refreshToken } = response;
        console.log("response.data", response);
        // Store the new tokens in local storage
        if (accessToken) {
          localStorage.setItem("token", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        // Handle error, such as logging out the user or showing a message
      }
    };

    // Set an interval to refresh the token every 50 seconds
    const intervalId = setInterval(refreshTokenCall, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export default useTokenRefresh;
