import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Spinner } from "react-bootstrap";
import { Box, Pagination, Tooltip, Zoom } from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Modaltable from "../components/modal-table/mTable";
import { TbArrowBarToDown } from "react-icons/tb";
import { Table } from "antd";

import Select from "react-select";

import TableSkeleton from "../components/skeletons/TableLoader";
import ContactIcon from "../images/contact-icon.svg";
import InvalidIcon from "../images/invalid-contact.svg";
import AddIcon from "../images/contact-mobile-add-icon.svg";
import InfoIcon from "../images/icons/infoIcon.svg";
import startScratchIcon from "../images/icons/workflow-icons/from-scratch.svg";
import preDefinedFlowIcon from "../images/icons/workflow-icons/predefine-flow.svg";

import Wtable from "../components/table/table";
import ContactMobile from "../components/contact-table/ContactMobile";
import Groups from "./group";
import Header from "../components/whatsapp-flows/Listing/Header";
import {
  createFlow,
  deleteFlow,
  getAllWorkflows,
  getPreDefinedFlows,
  myCloneFlow,
  preCloneFlow,
  updateFlow,
} from "../services/workflow";

import Companyactionbtn from "../components/company-listing/compnay-actionbtn";
import ActionButtons from "../components/whatsapp-flows/Listing/ActionButtons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Margin, SettingsAccessibility } from "@mui/icons-material";
import "./flows.css";
import Keyword from "../components/whatsapp-flows/Listing/Keyword";
import MediaCard from "../components/whatsapp-flows/Workflow/PredefinedCard";
import { setIsActive } from "../reduxSlice/workflow";
import useDeviceWidthDetector from "../components/customHooks/DeviceWidthDetector";

const columns = [
  {
    title: "Flow Name",
    dataIndex: "name",
  },
  {
    title: "Created By",
    dataIndex: "createdby",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Status",
    dataIndex: "condition",
  },

  {
    title: "Actions",
    dataIndex: "Actions",
  },
];

const FlowListing = () => {
  const dispatch = useDispatch();
  const { isActive } = useSelector((state) => state.WorkflowReducer);
  const [isEdit, setIsEdit] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [flows, setFlows] = useState([]);
  const [allFlows, setAllFlows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dtshow, setDtShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [creatingFlow, setCreatingFLow] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [activeFlowId, setActiveFlowId] = useState("");
  const windowWidth = useDeviceWidthDetector();
  const [isClone, setIsClone] = useState(false);
  const [isCloneMyFlows, setIsCloneMyFlows] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);

  const [inputErrors, setInputErrors] = useState({
    flowName: { hasError: false, errorMessage: "" },
    keyword: { hasError: false, errorMessage: "" },
    messageHeader: { hasError: false, errorMessage: "" },

    message: { hasError: false, errorMessage: "" },
    messageFooter: { hasError: false, errorMessage: "" },

    category: { hasError: false, errorMessage: "" },
    btnText: { hasError: false, errorMessage: "" },
  });
  const [flowData, setFlowData] = useState({
    flowName: "",
    // keyword: "",
    messageHeader: "",
    message: "",
    messageFooter: "",
    btnText: "",
  });
  console.log("tags", keywords);
  // Pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Change as needed
  const [PredefinedFlows, setPredifinedFlows] = useState(null);

  const [categories, setCategories] = useState([
    {
      label: "SIGN UP",
      value: "SIGN_UP",
    },
    {
      label: "SIGN IN",
      value: "SIGN_IN",
    },
    {
      label: "APPOINTMENT BOOKING",
      value: "APPOINTMENT_BOOKING",
    },
    {
      label: "LEAD GENERATION",
      value: "LEAD_GENERATION",
    },
    {
      label: "CONTACT US",
      value: "CONTACT_US",
    },
    {
      label: "CUSTOMER SUPPORT",
      value: "CUSTOMER_SUPPORT",
    },
    {
      label: "SURVEY",
      value: "SURVEY",
    },
    {
      label: "OTHER",
      value: "OTHER",
    },
  ]);

  const navigate = useNavigate();
  useEffect(() => {
    // Function to update screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const getAllFlows = async () => {
    try {
      setLoading(true);
      const resp = await getAllWorkflows();
      if (resp?.success) {
        // setAllFlows(resp?.data);
        const allNewFlows = resp?.data?.db;
        setAllFlows(allNewFlows); // Store full dataset in allFlows
        const paginatedFlows = allNewFlows?.slice(
          (page - 1) * rowsPerPage,
          page * rowsPerPage
        );
        setAllFlowsData(paginatedFlows);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  };

  const getPredefinedFlows = async () => {
    try {
      setLoading(true);
      const resp = await getPreDefinedFlows();
      if (resp?.success) {
        setPredifinedFlows(resp?.data?.db);
        console.log("PredefinedFLows", resp?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error: ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllFlows();
    getPredefinedFlows();
  }, []);

  useEffect(() => {
    if (isClone || isCloneMyFlows) {
      setFlowData((prevValues) => ({
        ...prevValues,
        flowName: "", // Reset flowName when cloning is enabled
      }));
      setKeywords([]);
    }
  }, [isClone, isCloneMyFlows]);

  const setAllFlowsData = (flowsData) => {
    const transformedData = handleDataForTable(flowsData);
    setFlows(transformedData);
  };

  const handleDeleteFlow = async (id) => {
    try {
      setIsDeleting(true);
      const resp = await deleteFlow(id);
      if (resp.success) {
        toast.success("Flow deleted successfully");
        setIsDeleting(false);
        getAllFlows();
      } else {
        console.log("resp", resp);
        toast.error(resp?.message);
        setIsDeleting(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message);

      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (allFlows.length > 0) {
      const paginatedFlows = allFlows.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
      );
      const transformedData = handleDataForTable(paginatedFlows);
      setFlows(transformedData);
    }
  }, [page, rowsPerPage, allFlows]);

  const handleOpenFlow = (data) => {
    navigate(`/automation/${data?.flowId}`);
  };
  const handleEditFlow = async (data) => {
    try {
      setActiveFlowId(data?.flowId);
      const currentFlowData = data;

      // const newKeywords = currentFlowData?.keyword
      //   ? currentFlowData?.keyword?.split(",").map((item) => item.trim())
      //   : [];
      let editPayload = {
        flowName: currentFlowData?.name,

        // keyword: newKeywords,
        keyword: currentFlowData?.keyword,

        messageHeader: currentFlowData?.flowMessage?.header?.text,
        message: currentFlowData?.flowMessage?.body?.text,
        messageFooter: currentFlowData?.flowMessage?.footer?.text,
        btnText: currentFlowData?.btnText,
        category: {
          label: currentFlowData?.category?.split("_").join(" "),
          value: currentFlowData?.category,
        },
      };

      if (currentFlowData) {
        const { category, keyword, flowMessage, ...flowDataWithoutCategory } =
          editPayload;

        setFlowData(flowDataWithoutCategory);
        setKeywords(keyword);

        setSelectedCategory(() => category);
      }
      setIsClone(false);
      setIsCloneMyFlows(false);
      setIsEdit(true);
      setAddShow(true);
    } catch (error) {
      console.error("error:", error);
    }
  };
  const handleCloneFlow = async (data) => {
    try {
      setActiveFlowId(data?.flowId);
      const currentFlowData = data;

      // const newKeywords = currentFlowData?.keyword
      //   ? currentFlowData?.keyword?.split(",").map((item) => item.trim())
      //   : [];
      let clonePayload = {
        flowName: currentFlowData?.name,

        // keyword: newKeywords,
        keyword: currentFlowData?.keyword,

        messageHeader: currentFlowData?.flowMessage?.header?.text,
        message: currentFlowData?.flowMessage?.body?.text,
        messageFooter: currentFlowData?.flowMessage?.footer?.text,
        btnText: currentFlowData?.btnText,
        category: {
          label: currentFlowData?.category?.split("_").join(" "),
          value: currentFlowData?.category,
        },
      };

      if (currentFlowData) {
        const { category, keyword, flowMessage, ...flowDataWithoutCategory } =
          clonePayload;

        setFlowData(flowDataWithoutCategory);
        setKeywords(keyword);

        setSelectedCategory(() => category);
      }
      setIsEdit(false);
      setIsClone(true);
      setIsCloneMyFlows(false);
      setAddShow(true);
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleCloneMyFlow = async (data) => {
    try {
      setActiveFlowId(data?.flowId);
      const currentFlowData = data;

      // const newKeywords = currentFlowData?.keyword
      //   ? currentFlowData?.keyword?.split(",").map((item) => item.trim())
      //   : [];
      let clonePayload = {
        flowName: currentFlowData?.name,

        // keyword: newKeywords,
        keyword: currentFlowData?.keyword,

        messageHeader: currentFlowData?.flowMessage?.header?.text,
        message: currentFlowData?.flowMessage?.body?.text,
        messageFooter: currentFlowData?.flowMessage?.footer?.text,
        btnText: currentFlowData?.btnText,
        category: {
          label: currentFlowData?.category?.split("_").join(" "),
          value: currentFlowData?.category,
        },
      };

      if (currentFlowData) {
        const { category, keyword, flowMessage, ...flowDataWithoutCategory } =
          clonePayload;

        setFlowData(flowDataWithoutCategory);
        setKeywords(keyword);

        setSelectedCategory(() => category);
      }
      setIsEdit(false);
      setIsClone(false);
      setIsCloneMyFlows(true);
      setAddShow(true);
    } catch (error) {
      console.error("error:", error);
    }
  };

  const handleDataForTable = (dataArray) => {
    let finalData = [];
    for (let i = 0; i != dataArray.length; i++) {
      const data = dataArray[i];
      console.log("dataa", data);
      // Create a new data object with the transformed groups array
      finalData.push({
        key: data._id,
        flowId: data?.flowId,
        status: data?.status,
        name: data?.name,
        name: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                fontFamily: "Segoe UI",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
              }}
            >
              {data?.name}
            </p>
          </div>
        ),
        createdby: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {data?.user?.firstname?.toUpperCase() +
              " " +
              data?.user?.lastname?.toUpperCase() || "-"}
          </p>
        ),
        category: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            {data?.category || "-"}
          </p>
        ),
        condition: (
          <p
            style={{
              fontFamily: "Segoe UI",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "22px",
              textTransform: "capitalize",
            }}
          >
            <span
              style={{
                padding: "3px 6px",
                backgroundColor:
                  data?.status == "PUBLISHED" ? "#A3FBCC" : "#E0E9F7",
                borderRadius: "4px",
                color: data?.status == "PUBLISHED" ? "#165E5B" : "#415674",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "100%",
              }}
            >
              {" "}
              {data?.status}
            </span>
          </p>
        ),

        Actions: (
          <ActionButtons
            id={data?.flowId}
            data={data}
            submitDelete={handleDeleteFlow}
            submitEdit={handleOpenFlow}
            handleEdit={handleEditFlow}
            handleClone={handleCloneMyFlow}
            isDeleting={isDeleting}
            setDtShow={setDtShow}
            dtshow={dtshow}
            setIsCloneMyFlows={setIsCloneMyFlows}
            setPopupOpen={setPopupOpen}
            popupOpen={popupOpen}
          />
        ),
      });
    }

    return finalData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputErrors((prevState) => ({
      ...prevState,
      [name]: { hasError: false, errorMessage: "" },
    }));

    // Normal flow data update
    setFlowData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const validateInputs = () => {
    const errors = {};

    // Define custom error messages
    const errorMessages = {
      flowName: "Flow name is required!",
      keyword: "Keywords are required!",
      messageHeader: "Message header is required!",
      message: "Message body is required!",
      messageFooter: "Message footer is required!",
      btnText: "Button text is required!",
      category: "Flow Category is required!",
    };

    // Validate required fields
    for (const key in flowData) {
      if (!flowData[key]) {
        errors[key] = {
          hasError: true,
          errorMessage: errorMessages[key] || "This field is required!",
        };
      }
    }
    if (keywords?.length <= 0) {
      errors.keyword = {
        hasError: true,
        errorMessage: errorMessages.keyword,
      };
    }
    // Validate category separately
    if (selectedCategory == null) {
      errors.category = {
        hasError: true,
        errorMessage: errorMessages.category,
      };
    }

    if (Object.keys(errors).length > 0) {
      setInputErrors((prevState) => ({
        ...prevState,
        ...errors,
      }));
      return false;
    }

    return true;
  };

  const handleAddFlow = async () => {
    try {
      if (!validateInputs()) return;
      // if (!flowData.flowName) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     flowName: {
      //       ...prevState.flowName,
      //       hasError: true,
      //       errorMessage: "Flow name is required!",
      //     },
      //   }));
      //   return;
      // }
      // if (JSON.stringify(selectedCategory) === "{}") {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     category: {
      //       ...prevState.category,
      //       hasError: true,
      //       errorMessage: "Flow Category is required!",
      //     },
      //   }));
      //   return;
      // }
      // if (!flowData.keyword) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     keyword: {
      //       ...prevState.keyword,
      //       hasError: true,
      //       errorMessage: "Keyword is required!",
      //     },
      //   }));
      //   return;
      // }
      // if (!flowData.messageHeader) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     messageHeader: {
      //       ...prevState.message,
      //       hasError: true,
      //       errorMessage: "Message header is required!",
      //     },
      //   }));
      //   return;
      // }
      // if (!flowData.message) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     message: {
      //       ...prevState.message,
      //       hasError: true,
      //       errorMessage: "Message body is required!",
      //     },
      //   }));
      //   return;
      // }
      // if (!flowData.messageFooter) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     messageFooter: {
      //       ...prevState.message,
      //       hasError: true,
      //       errorMessage: "Message footer is required!",
      //     },
      //   }));
      //   return;
      // }

      // if (!flowData.btnText) {
      //   setInputErrors((prevState) => ({
      //     ...prevState,
      //     btnText: {
      //       ...prevState.message,
      //       hasError: true,
      //       errorMessage: "Button text is required!",
      //     },
      //   }));
      //   return;
      // }
      setCreatingFLow(true);
      const payload = {
        name: flowData?.flowName,
        category: selectedCategory?.value,
        keyword: keywords?.join(", "),
        flowMessage: {
          header: { text: flowData?.messageHeader },
          body: { text: flowData?.message },
          footer: { text: flowData?.messageFooter },
        },

        btnText: flowData?.btnText,
      };

      let resp;
      if (isEdit) {
        resp = await updateFlow({ ...payload, flowId: activeFlowId });
      } else if (isClone) {
        resp = await preCloneFlow({ ...payload, flowId: activeFlowId });
      } else if (isCloneMyFlows) {
        resp = await myCloneFlow({ ...payload, flowId: activeFlowId });
      } else {
        resp = await createFlow(payload);
      }

      if (resp?.success) {
        dispatch(setIsActive(false));

        setCreatingFLow(false);
        setFlowData({
          flowName: "",
          // keyword: "",
          messageHeader: "",
          message: "",
          messageFooter: "",
          btnText: "",
        });
        setKeywords([]);
        setSelectedCategory(null);
        setAddShow(false);
        isEdit
          ? toast.success("Flow updated successfully")
          : isClone || isCloneMyFlows
          ? toast.success("Flow cloned successfully!")
          : toast.success("Flow created successfully");
        await getAllFlows();
      } else {
        setCreatingFLow(false);
        // setAddShow(false);
        toast.error(resp?.error);
      }
      console.log("resp", resp);
    } catch (error) {
      console.log("error", error);
      setCreatingFLow(false);
      // setAddShow(false);
      toast.error(error?.error);
    }
  };

  const resetStates = () => {
    setAddShow(false);

    setFlowData({
      flowName: "",
      // keyword: "",
      messageHeader: "",
      message: "",
      messageFooter: "",
      btnText: "",
    });
    setSelectedCategory(null);
    setInputErrors({
      flowName: { hasError: false, errorMessage: "" },
      keyword: { hasError: false, errorMessage: "" },
      messageHeader: { hasError: false, errorMessage: "" },
      message: { hasError: false, errorMessage: "" },
      messageFooter: { hasError: false, errorMessage: "" },
      btnText: { hasError: false, errorMessage: "" },

      category: { hasError: false, errorMessage: "" },
    });
    setKeywords([]);
    setIsCloneMyFlows(false);
    setIsClone(false);
  };

  // Paginate the data
  // const paginatedFlows = handleDataForTable(flows).slice(
  //   (page - 1) * rowsPerPage,
  //   page * rowsPerPage
  // );
  // console.log("paginatedFlows", paginatedFlows);
  // styles

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2, // Ensures menu appears above other elements
      backgroundColor: "white",
    }),
  };

  console.log("flowData", flowData);
  console.log("inputErrors", inputErrors);

  return (
    <section className="main inner-main">
      <Header
        isActive={isActive}
        screenWidth={screenWidth}
        setAddShow={setAddShow}
        setIsEdit={setIsEdit}
      />

      <Box className="bg-[#ecf1f9] flex justify-start align-items-start w-100 flex-column h-100">
        {!isActive ? (
          //   screenWidth >= 1023 ? (
          loading ? (
            <TableSkeleton />
          ) : (
            <Box
              className="flex justify-start align-items-start w-100 mt-3 rounded-md container all-tags mb-8 testing "
              style={{
                padding: 0,
                height: "80%",
                marginBottom: "100px",
              }}
            >
              <Table
                columns={columns}
                dataSource={flows} // Use full contacts data, not sliced
                pagination={
                  false // Disable changing pageSize (optional)
                }
                style={{ width: "100%", background: "#FFFFFF" }}
                size="middle"
                // rowClassName={(record) =>
                //   record?.status === "published" ? "disabled-row" : ""
                // }
                onRow={(record) => {
                  console.log("recorrrd", record);
                  // if (record?.status != "published") {
                  return {
                    onClick: () => navigate(`/automation/${record.flowId}`),
                  };
                  // }
                }}
              />
            </Box>
          )
        ) : (
          //   ) : (
          //     <>
          //       <section className=" pt-3 d-lg-none w-100">
          //         <ContactMobile contacts={contacts} />
          //       </section>
          //     </>
          //   )
          <Box
            className="flex justify-start gap-3 align-items-start w-100 mt-3 rounded-md container all-tags mb-8 testing "
            style={{
              padding: 0,
              height: "80%",
              marginBottom: "100px",
              display: "flex",
              flexDirection: windowWidth >= 1024 ? "row" : "column",
            }}
          >
            {/* <MediaCard
              description="Create a new flow from scratch according to your requirements."
              heading="Start FROM SCRATCH"
              isStart={true}
              icon={startScratchIcon}
              setAddShow={setAddShow}
              setIsClone={setIsClone}
              setIsEdit={setIsEdit}
            /> */}
            {PredefinedFlows?.map((flow) => {
              return (
                <MediaCard
                  description=" Predefined flow to capture bookings"
                  heading={flow?.name}
                  isStart={false}
                  icon={preDefinedFlowIcon}
                  setAddShow={setAddShow}
                  handleClick={handleCloneFlow}
                  flow={flow}
                />
              );
            })}
          </Box>
        )}
        {!isActive && (
          <Box
            className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags "
            sx={{
              height: "70px",
              position: "fixed",
              bottom: "0",
              boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.1)",
              marginTop: "100px",
            }}
          >
            {allFlows?.length > 0 && (
              <Pagination
                count={Math.ceil(allFlows.length / rowsPerPage)}
                page={page}
                onChange={(event, value) => setPage(value)}
                variant="outlined"
                shape="rounded"
                siblingCount={2}
              />
            )}
          </Box>
        )}
      </Box>

      <Modal
        show={addShow}
        onHide={() => {
          resetStates();
        }}
        className="whatsapp-modal modal-contact flows"
        centered
      >
        <Modal.Header className="p-0" closeButton>
          <Modal.Title style={{ marginBottom: "8px" }}>
            {isEdit
              ? "Edit Flow"
              : isClone || isCloneMyFlows
              ? "Clone Flow"
              : "Add New Flow"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Flow Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter flow name"
                  name="flowName"
                  onChange={handleInputChange}
                  value={flowData?.flowName || ""}
                  className={inputErrors.flowName.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.flowName.hasError &&
                    inputErrors.flowName.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Flow Category*</Form.Label>
                <Select
                  style={{ marginBottom: "0" }}
                  styles={customStyles}
                  className={`c-select basic-multi-select c-select-new ${
                    inputErrors.category.hasError ? "is-invalid" : ""
                  } `}
                  options={categories}
                  placeholder={"Select Category"}
                  classNamePrefix="select"
                  onChange={(e) => {
                    console.log("eeee", e);
                    setSelectedCategory(e);
                    setInputErrors((prevState) => ({
                      ...prevState,
                      category: {
                        ...prevState.category,
                        hasError: false,
                        errorMessage: "",
                      },
                    }));
                  }}
                  value={selectedCategory}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.category.hasError &&
                    inputErrors.category.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label className="d-flex gap-2 align-items-center">
                  Keywords*{" "}
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      <p>
                        Add multiple keywords <br /> (pre-condition to send flow
                        automatically){" "}
                      </p>
                    }
                    placement={"top"}
                    sx={{ cursor: "pointer" }}
                    className="mb-1"
                  >
                    <img
                      src={InfoIcon}
                      style={{ cursor: "pointer" }}
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                  </Tooltip>
                </Form.Label>
                <Keyword
                  keywords={keywords}
                  setKeywords={setKeywords}
                  setInputErrors={setInputErrors}
                />

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#DC3545",
                    marginTop: "5px",
                  }}
                >
                  {inputErrors.keyword.hasError &&
                    inputErrors.keyword.errorMessage}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Message Title*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Message Body"
                  name="messageHeader"
                  onChange={handleInputChange}
                  value={flowData?.messageHeader}
                  className={
                    inputErrors.messageHeader.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.messageHeader.hasError &&
                    inputErrors.messageHeader.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label> Message Body*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter Flow start message"
                  name="message"
                  onChange={handleInputChange}
                  value={flowData?.message}
                  className={inputErrors.message.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.message.hasError &&
                    inputErrors.message.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Message Footer*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Message Footer"
                  name="messageFooter"
                  onChange={handleInputChange}
                  value={flowData?.messageFooter}
                  className={
                    inputErrors.messageFooter.hasError ? "is-invalid" : ""
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.messageFooter.hasError &&
                    inputErrors.messageFooter.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label className="d-flex gap-2 align-items-center">
                  Button text*
                  <Tooltip
                    arrow
                    TransitionComponent={Zoom}
                    title={
                      <p>
                        This is initial message's button text when user click
                        it, flow will start.
                      </p>
                    }
                    placement={"top"}
                    sx={{ cursor: "pointer" }}
                    className="mb-1"
                  >
                    <img
                      src={InfoIcon}
                      style={{ cursor: "pointer" }}
                      className="mt-1"
                      width={16}
                      height={16}
                    />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Button Text"
                  name="btnText"
                  onChange={handleInputChange}
                  value={flowData?.btnText}
                  className={inputErrors.btnText.hasError ? "is-invalid" : ""}
                />
                <Form.Control.Feedback type="invalid">
                  {inputErrors.btnText.hasError &&
                    inputErrors.btnText.errorMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn-main-default"
            onClick={() => {
              resetStates();
            }}
          >
            Cancel
          </Button>

          <Button className="btn-main" onClick={handleAddFlow}>
            {creatingFlow ? (
              <div
                style={{
                  display: "flex",
                  // minWidth: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  // style={{ color: "rgba(22, 94, 91, 1)" }}
                />
              </div>
            ) : isEdit ? (
              "Edit "
            ) : isClone || isCloneMyFlows ? (
              "Clone"
            ) : (
              "Add"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default FlowListing;
