import React, { useEffect, useState, useContext } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { authenticateUser } from "../services/User";
import Authform from "../components/wahtsapp_form/form-auth";
import UserContext from "../store/user-context";
import { useNavigate } from "react-router";

const Do2FA = () => {
  const [isSendingReq, setIsReqSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setError] = useState("");
  const { userState, setUserState } = useContext(UserContext);
  const navigate = useNavigate();
  console.log(userState);

  const setErrorMessage = (msg) => {
    setError(msg);
  };

  const checkAuth = async (code) => {
    setIsReqSending(true);
    const resp = await authenticateUser({ authCode: code });
    console.log("response of do2FA", resp, ":::", resp.userToken);
    if (resp.success) {
      //localStorage.removeItem('token')
      setSuccessMessage("User authentication successful ✔");
      localStorage.setItem("mfaCompleted", true);
      localStorage.setItem("mfaDone", true);
      localStorage.setItem("token", resp.userToken);
      localStorage.setItem("refreshToken", resp?.refreshToken);
      setUserState((prevState) => ({
        ...prevState,
        mfaDone: true,
        mfaCompleted: true,
      }));
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      setErrorMessage("Authentication failed, Please enter correct code");
    }
    setIsReqSending(false);
  };

  return (
    <>
      <section className="wahtsapp_form">
        <Container>
          <Row>
            <Col sm={12}>
              <Authform
                isSendingReq={isSendingReq}
                checkAuth={checkAuth}
                successMessage={successMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Do2FA;
