import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export const QuickReplyPreview = ({ components, isSending }) => {
  return (
    <>
      {components?.map((component, index) => {
        if (component.type === "BUTTONS") {
          if (component.buttons[0].type === "QUICK_REPLY") {
            return (
              <Row className="event-time">
                {component?.buttons?.length === 1 ? (
                  <Col xs={12} className="">
                    <div className="e-time ">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-2"
                        style={{ width: "12px", height: "12px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                        />
                      </svg>
                      {component.buttons[0].text}
                    </div>
                  </Col>
                ) : (
                  <Col xs={12} className="">
                    <div className="e-time ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-2"
                        style={{ width: "12px", height: "12px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                        />
                      </svg>
                      {component.buttons[0].text}
                    </div>
                  </Col>
                )}

                {component.buttons.length > 1 && (
                  <Col xs={12} className="">
                    <div className="e-time ">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-2"
                        style={{ width: "12px", height: "12px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                        />
                      </svg>
                      {component.buttons[1].text}
                    </div>
                  </Col>
                )}
                {component.buttons.length > 2 && (
                  <div className="event-time">
                    <Col xs={12}>
                      <div className="e-time">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-2"
                          style={{ width: "12px", height: "12px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                          />
                        </svg>
                        {component.buttons[2].text}
                      </div>
                    </Col>
                  </div>
                )}
              </Row>
            );
          }
        }

        return null;
      })}
    </>
  );
};
