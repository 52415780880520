import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Username from "../userName/username";

import "./usermob.css";
import TableSkeleton from "../skeletons/TableLoader";
import BroadcastTablestatus from "./broadcast-status";

const BroadcastDetailsMob = ({
  broadcastDetail,
  detailLoader,
  filterLoader,
}) => {
  const groupStyles = {
    backgroundColor: "#BCF1D54D",
    padding: "7px",
    borderRadius: "6px",
  };
  const groupContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  };
  const determineStatus = (message) => {
    if (!message) return { status: "Pending", deliveredDate: "" };

    const { success, read, delivered, sent, failed } = message;
    if (failed) return { status: "Failed", deliveredDate: "" };

    if (read) return { status: "Read", deliveredDate: read };
    if (delivered) return { status: "Delivered", deliveredDate: delivered };
    if (sent) return { status: "Sent", deliveredDate: sent };

    return { status: "Pending", deliveredDate: "" };
  };
  function formatDate(isoDateString, includeTime = false) {
    if (isoDateString == "") {
      return;
    }
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }
  if (detailLoader || filterLoader) {
    return (
      <Container>
        <TableSkeleton width="100%" />
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="template-listing-collapse">
              <div className="tlc-header">Name</div>
              <div className="tlc-body">
                {broadcastDetail &&
                  broadcastDetail[0]?.messages?.map((data) => {
                    const { status, deliveredDate } = determineStatus(
                      data.message
                    );
                    return (
                      <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="username-broadcastmob">
                              <Username
                                name={data?.contact?.contactName || "-"}
                              />
                            </div>
                            <div className="contact-list d-flex align-items-center justify-content-between  mt-3">
                              <div
                                className="ub-title"
                                style={{ fontWeight: "400" }}
                              >
                                {data?.contact?.number || "-"}
                              </div>
                              <div className="phone_number">
                                {
                                  <BroadcastTablestatus
                                    statusClass="status-title"
                                    statusTitle={status}
                                  />
                                }
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                              <div
                                className="ub-title"
                                style={{ fontWeight: "400" }}
                              >
                                Phone Number
                              </div>
                              <div className="phone_number">
                                {data?.contact?.number || "-"}
                              </div>
                            </div> */}

                            <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                              <div
                                className="ub-title"
                                style={{ fontWeight: "400" }}
                              >
                                Delivery At
                              </div>
                              <div className="cb-date">
                                {formatDate(deliveredDate, true)}
                              </div>
                            </div>
                            <div className="contact-list  mb-3">
                              <div
                                className="ub-title"
                                style={{ fontWeight: "400" }}
                              >
                                Member of Group
                              </div>
                              <div
                                className="phone_number mt-2"
                                style={groupContainerStyles}
                              >
                                {data?.groups?.map((group) => {
                                  return (
                                    <span style={groupStyles}>{group}</span>
                                  );
                                })}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BroadcastDetailsMob;
