import React, { useState } from "react";
import { Tooltip, Zoom } from "@mui/material";

const Useraction = (props) => {
  const role = localStorage.getItem("role");
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <>
      <div className="d-flex align-items-center flex-row">
        {props?.isOwner && role != "owner" ? (
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={"You can not edit Owner"}
            placement={"top"}
            sx={{ cursor: "pointer" }}
            open={isEdit}
            onMouseEnter={() => setIsEdit(true)}
            onMouseLeave={() => setIsEdit(false)}
          >
            <button className="ded-btn">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </button>
          </Tooltip>
        ) : (
          <button className="ded-btn" onClick={props?.handleClickEditUser}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.75 12.25H2.8L9.26875 5.78125L8.21875 4.73125L1.75 11.2V12.25ZM12.475 4.69375L9.2875 1.54375L10.3375 0.49375C10.625 0.20625 10.9783 0.0625 11.3973 0.0625C11.8158 0.0625 12.1687 0.20625 12.4562 0.49375L13.5062 1.54375C13.7937 1.83125 13.9437 2.17825 13.9562 2.58475C13.9688 2.99075 13.8313 3.3375 13.5438 3.625L12.475 4.69375ZM11.3875 5.8L3.4375 13.75H0.25V10.5625L8.2 2.6125L11.3875 5.8ZM8.74375 5.25625L8.21875 4.73125L9.26875 5.78125L8.74375 5.25625Z"
                fill="black"
                fillOpacity={"0.6"}
              />
            </svg>
          </button>
        )}
        {props?.isOwner ? (
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={"You can not delete Owner"}
            placement={"top"}
            sx={{ cursor: "pointer" }}
            open={show}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <button className="ded-btn">
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                  fill="black"
                  fillOpacity={"0.6"}
                />
              </svg>
            </button>
          </Tooltip>
        ) : (
          <button className="ded-btn" onClick={props?.handleClickDeleteUser}>
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 13.75C1.8375 13.75 1.4845 13.6033 1.191 13.3097C0.897 13.0157 0.75 12.6625 0.75 12.25V2.5H0V1H3.75V0.25H8.25V1H12V2.5H11.25V12.25C11.25 12.6625 11.1033 13.0157 10.8097 13.3097C10.5157 13.6033 10.1625 13.75 9.75 13.75H2.25ZM9.75 2.5H2.25V12.25H9.75V2.5ZM3.75 10.75H5.25V4H3.75V10.75ZM6.75 10.75H8.25V4H6.75V10.75Z"
                fill="black"
                fillOpacity={"0.6"}
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
};

export default Useraction;
