import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { Box, IconButton, Pagination } from "@mui/material";

import "./css/templateListing-footer.css";

const TemplateListingFooter = (props) => {
  const handlePaginationChange = (event, value) => {
    console.log("Pagination value:", value);
    // if (!props.isSearching && props.selectRef.current) {
    //   props.selectRef.current.clearValue();
    // }
    console.log("tagpag", props.selectedTag);
    console.log("tagpagsearch", props.searchText);
    console.log("tagpagstatus", props.selectedStatus);

    props.hideView();
    props.setCurrPage(value);
    // props.setStartIndexTable((value - 1) * 10);
    props.fetchData(
      value,
      props.searchText,
      props.selectedStatus,
      props.selectedTag
    ); // Fetch data for the selected page
  };

  console.log("totalRecords in table footer", props?.totalRecords);

  return (
    <>
      <section className="listingfooter">
        <Container>
          <Row>
            <Col xs={12}>
              <Box className="bg-[#FFFFFF] flex justify-center align-items-center w-100 all-tags">
                <Pagination
                  count={
                    props?.totalRecords
                      ? Math.ceil(props?.totalRecords / 10)
                      : 0
                  } // Calculate total pages
                  variant="outlined"
                  shape="rounded"
                  siblingCount={0}
                  page={props?.currPage}
                  onChange={handlePaginationChange}
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TemplateListingFooter;
