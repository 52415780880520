import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

import Broadcastname from "./broadcast-name";
import BroadcastTablestatus from "./broadcast-status";
import BroadcastType from "./broadcast-type";
import DeliveryStatus from "./delivery-status";
import BroadcastAction from "./broadcast-action";

import "./usermob.css";
import TableSkeleton from "../skeletons/TableLoader";
import BroadcastDropdown from "./broadcast-dropdown";
import { useSelector } from "react-redux";

const BroadcastlistingMob = ({
  allBroadCastsData,
  // isListingOpen,
  pickerLoader,
  fetchAllBroadcastData,
  currentPage,
  startingDate,
  endingDate,
  broadcastType,
  searchBroadcast,
}) => {
  const { isListingOpen } = useSelector((state) => state.BroadCastsReducer);

  const countStatus = (broadcast) => {
    const count = {
      total: broadcast?.messages?.length,
      completed: 0,
      failed: 0,
      pending: 0,
    };
    broadcast?.messages?.forEach(({ message }) => {
      if (message?.failed) count.failed++;
      else if (message?.success) count.completed++;
      else count.pending++;
    });

    return count;
  };
  const formatPercentage = (percentage) => {
    return percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(1);
  };

  function formatDate(isoDateString, includeTime = false) {
    const date = new Date(isoDateString);

    // Get the month abbreviation
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = String(date.getDate()).padStart(2, "0"); // Get the day, padded with leading zero if necessary
    const year = date.getFullYear(); // Get the full year

    let formattedDate = `${month}-${day}-${year}`;

    // If includeTime is true, append the time in the desired format
    if (includeTime) {
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHour = hours % 12 || 12; // Handle 0 as 12 for AM/PM format

      formattedDate += ` ${formattedHour}:${minutes} ${period}`;
    }

    return formattedDate;
  }

  if (pickerLoader) {
    return (
      <Container>
        <TableSkeleton width="100%" />
      </Container>
    );
  }
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className="template-listing-collapse">
              <div className="tlc-header">Broadcast Name</div>
              <div className="tlc-body">
                {allBroadCastsData?.map((data) => {
                  const count = countStatus(data);
                  const successValue = formatPercentage(
                    (count.completed * 100) / count.total
                  );
                  const pendingValue = formatPercentage(
                    (count.pending * 100) / count.total
                  );
                  const failedValue = formatPercentage(
                    (count.failed * 100) / count.total
                  );

                  return (
                    <Accordion defaultActiveKey="1">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="username-broadcastmob">
                            <Broadcastname
                              EventName={data?.broadcastName}
                              CreatedName={
                                data?.madeBy?.firstname +
                                " " +
                                data?.madeBy?.lastname
                              }
                              isUpdated={data?.isUpdated}
                            />
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Created At</div>
                            <div className="cb-date">
                              {formatDate(data?.createdAt, false)}
                            </div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Audience</div>
                            <div className="phone_number">
                              {data?.messages?.length}
                            </div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3">
                            <div className="ub-title">Broadcast Type</div>
                            <div className="phone_number">
                              <BroadcastType
                                className={"scheduled"}
                                Typename={
                                  data?.state == 0
                                    ? "Scheduled"
                                    : data?.state == 4
                                    ? "Cancelled"
                                    : data?.state == 2 && data.isSchedule
                                    ? "Scheduled"
                                    : "Instant"
                                }
                                TypeDateTime={formatDate(
                                  data?.scheduleDate,
                                  true
                                )}
                              />
                            </div>
                          </div>
                          <div className="contact-list d-flex align-items-center justify-content-between mb-3 w-100">
                            <Row className="w-100 ms-1">
                              <Col xs={12}>
                                <div
                                  className="phone_number"
                                  style={{ width: "100%" }}
                                >
                                  {isListingOpen && (
                                    <DeliveryStatus
                                      CompletedValue={successValue}
                                      PendingValue={pendingValue}
                                      FailedValue={failedValue}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="contact-list d-flex justify-content-end mb-3">
                            <Row className="w-100">
                              <Col xs={12} className="text-end">
                                {isListingOpen ? (
                                  <BroadcastAction
                                    Link_add={`/broadcastDetails/${data?._id}`}
                                  />
                                ) : (
                                  <div className="w-100 flex justify-end">
                                    <BroadcastDropdown
                                      data={data}
                                      fetchAllBroadcastData={
                                        fetchAllBroadcastData
                                      }
                                      currentPage={currentPage}
                                      startingDate={startingDate}
                                      endingDate={endingDate}
                                      broadcastType={broadcastType}
                                      searchBroadcast={searchBroadcast}
                                      isListingOpen={isListingOpen}
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BroadcastlistingMob;
