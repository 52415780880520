import Button from "react-bootstrap/Button";
import Whatsapp_logo from "../../../images/whatsapp-logo.svg";
import Whatsapp_whieLogo from "../../../images/ic_baseline-whatsapp.svg";
import Btn_chatClose from "../../../images/btn-close-chat.svg";
import Utter_logo from "../../../images/utter-logo.svg";

import "./styles.css";

const ChatUs = (props) => {
  const openchat = () => {
    props?.setIsOpen(true);
  };
  const closechat = () => {
    props?.setIsOpen(false);
  };

  const btnStyles = {
    backgroundColor: props?.widgetStyles?.chatButton?.bgColor,
    boxShadow: "0px 4px 20px 0px #00000033",
    borderRadius:
      props?.widgetStyles?.chatButton?.borderRadius != ""
        ? props?.widgetStyles?.chatButton?.borderRadius + "px"
        : "0px",
    color: "#fff",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "8px 20px",
    position: "fixed",
    bottom:
      props?.widgetStyles?.chatButton?.marginBottom != ""
        ? props?.widgetStyles?.chatButton?.marginBottom + "px"
        : "20px",
    right:
      props?.widgetStyles?.position === "right" &&
      props?.widgetStyles?.chatButton?.marginRight != ""
        ? props?.widgetStyles?.chatButton?.marginRight + "px"
        : props?.widgetStyles?.position === "right"
        ? "20px"
        : "auto",
    left:
      props?.widgetStyles?.position != "right" &&
      props?.widgetStyles?.chatButton?.marginLeft != ""
        ? props?.widgetStyles?.chatButton?.marginLeft + "px"
        : props?.widgetStyles?.position != "right"
        ? "20px"
        : "auto",
    width: "auto",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: props?.widgetStyles?.chatButton?.bgColor,
  };

  const closeBtnBottomStyle = {
    width: "54px",
    height: "54px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: props?.widgetStyles?.chatButton?.bgColor,
    position: "fixed",
    bottom:
      props?.widgetStyles?.chatButton?.marginBottom != ""
        ? props?.widgetStyles?.chatButton?.marginBottom + "px"
        : "0px",
    right:
      props?.widgetStyles?.position === "right" &&
      props?.widgetStyles?.chatButton?.marginRight != "" &&
      props?.widgetStyles?.chatButton?.marginRight != null
        ? props?.widgetStyles?.chatButton?.marginRight + "px" || 0 + "px"
        : props?.widgetStyles?.position == "right"
        ? "0px"
        : null,
    left:
      props?.widgetStyles?.position != "right" &&
      props?.widgetStyles?.chatButton?.marginLeft != "" &&
      props?.widgetStyles?.chatButton?.marginLeft != null
        ? props?.widgetStyles?.chatButton?.marginLeft + "px" || 0 + "px"
        : props?.widgetStyles?.position != "right"
        ? "0px"
        : null,
    border: "none",
    boxShadow: "0px 4px 20px 0px #00000033",
    cursor: "pointer",
  };

  const chatHeader = {
    backgroundColor: props?.widgetStyles?.widgetDetails?.headerBgColor,
    color: "#fff",
    borderRadius: "5px 5px 0px 0px",
    padding: "25px 20px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  };

  const chatHeaderTitle = {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "26px",
  };

  const closeBtnStyle = {
    border: "none",
    padding: "5px",
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const chatUsStyles = {
    right:
      props?.widgetStyles?.position === "right" &&
      (props?.widgetStyles?.chatButton?.marginRight != "" ||
        props?.widgetStyles?.chatButton?.marginRight != null)
        ? props?.widgetStyles?.chatButton?.marginRight
          ? props?.widgetStyles?.chatButton?.marginRight + "px"
          : "0px"
        : props?.widgetStyles?.position == "right"
        ? "0px"
        : null,
    left:
      props?.widgetStyles?.position != "right" &&
      (props?.widgetStyles?.chatButton?.marginLeft != "" ||
        props?.widgetStyles?.chatButton?.marginLeft != null)
        ? props?.widgetStyles?.chatButton?.marginLeft
          ? props?.widgetStyles?.chatButton?.marginLeft + "px"
          : "0px"
        : props?.widgetStyles?.position != "right"
        ? "0px"
        : null,
  };

  return (
    <>
      <section
        className={`chatUs ${props?.isOpen ? "open" : ""}`}
        style={{
          ...chatUsStyles,
          bottom:
            props?.widgetStyles?.chatButton?.marginBottom != null ||
            props?.widgetStyles?.chatButton?.marginBottom != ""
              ? Number(props?.widgetStyles?.chatButton?.marginBottom) +
                80 +
                "px"
              : "0px",
        }}
      >
        <div className="position-relative" style={chatHeader}>
          <div style={chatHeaderTitle}>
            {props?.widgetStyles?.widgetDetails?.title}
          </div>
          <div>{props?.widgetStyles?.widgetDetails?.desc}</div>
          <div className="close-chat" style={closeBtnStyle} onClick={closechat}>
            <img
              src={Btn_chatClose}
              alt="btn-close-icon"
              style={{ width: "25px", height: "25px" }}
            />
          </div>
        </div>
        <div className="chatus-body">
          {props?.widgetStyles?.widgetDetails?.CTAs?.length == 1 &&
          props?.widgetStyles?.widgetDetails?.CTAs[0]?.label == "" ? (
            <div className="chat-question">
              <div className="chat-logo">
                <img src={Whatsapp_logo} alt="whatsapp-logo" />
              </div>
              <div className="question">Sales Queries</div>
            </div>
          ) : (
            props?.widgetStyles?.widgetDetails?.CTAs?.map((item, index) => (
              <div className="chat-question">
                <div className="chat-logo">
                  <img src={Whatsapp_logo} alt="whatsapp-logo" />
                </div>
                <div className="question">{item?.text}</div>
              </div>
            ))
          )}
        </div>
        <div className="chatus-footer">
          Powered by <img src={Utter_logo} alt="logo" />
        </div>
      </section>

      {props?.isOpen && (
        <div
          className="btn-chat-close"
          style={closeBtnBottomStyle}
          onClick={closechat}
        >
          <img
            src={Btn_chatClose}
            alt="btn-close-icon"
            style={{ width: "25px", height: "25px" }}
          />
        </div>
      )}

      {!props?.isOpen && (
        <Button className="btn-chatwithUs" style={btnStyles} onClick={openchat}>
          <img src={Whatsapp_whieLogo} alt="whatsapp-ic" />{" "}
          {props?.widgetStyles?.chatButton?.text}
        </Button>
      )}
    </>
  );
};

export default ChatUs;
