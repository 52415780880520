import Scheduled_ic from "../../images/broadcast/scheduled-ic.svg";
import Instant_ic from "../../images/broadcast/instant-ic.svg";
import useDeviceWidthChecker from "../customHooks/DeviceWidthDetector";

import "./broadcast.css";

const BroadcastType = ({ Typename, TypeDateTime, className }) => {
  const width = useDeviceWidthChecker();

  return (
    <>
      <div className={`broadcast-type ${className}`}>
        <div className={`type-name ${width < 1024 && "flex justify-end"}`}>
          {Typename === "Scheduled" ? (
            <img
              className="scheduled-img me-1"
              src={Scheduled_ic}
              alt="schedule-icon"
            />
          ) : Typename === "Instant" ? (
            <svg
              width="13"
              height="14"
              className="me-1"
              viewBox="0 0 13 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8943 6.0865C11.8415 5.93444 11.7065 5.83333 11.5555 5.83333H7.19512L9.71026 0.567C9.78934 0.401333 9.74854 0.198722 9.6124 0.0836111C9.54668 0.0276111 9.46759 0 9.38887 0C9.30473 0 9.22059 0.0318889 9.15306 0.0945L6.49998 2.562L1.20862 7.48339C1.09451 7.58956 1.05334 7.76106 1.10607 7.91311C1.15879 8.06517 1.29348 8.16667 1.44443 8.16667H5.80484L3.2897 13.433C3.21062 13.5987 3.25143 13.8013 3.38756 13.9164C3.45329 13.9724 3.53237 14 3.61109 14C3.69523 14 3.77937 13.9681 3.8469 13.9055L6.49998 11.438L11.7913 6.51661C11.9058 6.41044 11.9466 6.23894 11.8943 6.0865Z"
                fill="#FFAC33"
              />
            </svg>
          ) : (
            <svg
              width="15"
              height="15"
              className="me-1"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 14.0002C3.91001 14.0002 0.999823 11.09 0.999823 7.50009C0.999823 3.91019 3.91001 1 7.49991 1C11.0898 1 14 3.91019 14 7.50009C14 11.09 11.0898 14.0002 7.49991 14.0002Z"
                fill="#FFE3D4"
                stroke="#E94B42"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.49999 8.14349L5.25416 10.3893C5.17013 10.4734 5.06319 10.5154 4.93333 10.5154C4.80346 10.5154 4.69652 10.4734 4.61249 10.3893C4.52846 10.3053 4.48645 10.1984 4.48645 10.0685C4.48645 9.93863 4.52846 9.83168 4.61249 9.74766L6.85833 7.50182L4.61249 5.25599C4.52846 5.17196 4.48645 5.06502 4.48645 4.93516C4.48645 4.8053 4.52846 4.69835 4.61249 4.61432C4.69652 4.5303 4.80346 4.48828 4.93333 4.48828C5.06319 4.48828 5.17013 4.5303 5.25416 4.61432L7.49999 6.86016L9.74583 4.61432C9.82985 4.5303 9.9368 4.48828 10.0667 4.48828C10.1965 4.48828 10.3035 4.5303 10.3875 4.61432C10.4715 4.69835 10.5135 4.8053 10.5135 4.93516C10.5135 5.06502 10.4715 5.17196 10.3875 5.25599L8.14166 7.50182L10.3875 9.74766C10.4715 9.83168 10.5135 9.93863 10.5135 10.0685C10.5135 10.1984 10.4715 10.3053 10.3875 10.3893C10.3035 10.4734 10.1965 10.5154 10.0667 10.5154C9.9368 10.5154 9.82985 10.4734 9.74583 10.3893L7.49999 8.14349Z"
                fill="#E94B42"
              />
            </svg>
          )}

          {Typename}
        </div>
        <div className="type-date_time">{TypeDateTime}</div>
      </div>
    </>
  );
};

export default BroadcastType;
