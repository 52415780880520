import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import { Position } from "@xyflow/react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import CustomHandle from "../Ask-a-question/CustomHandle";
import { getAllTemplates } from "../../../../services/WhatsappAPI";
import CustomSelect from "./CustomSelect";
import { AuthTemplate } from "../../../template-preview/AutheTemplate";
import { UrlPhoneNumber } from "../../../template-preview/UrlPhoneNumber";
import { QuickReplyPreview } from "../../../template-preview/QuickReplyPreview";
import TemplatePreview from "./TemplatePreview";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsDeleteNode,
  triggerupdateNodeData,
} from "../../../../reduxSlice/workflow";
import { toast } from "react-toastify";

const Template = ({ id, data }) => {
  const { templatePreviewHeight, nodes, isData } = useSelector(
    (state) => state.WorkflowReducer
  );
  const anchorRef = React.useRef(null);

  const dispatch = useDispatch();
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [templatetoShow, setTemplatetoShow] = useState(undefined);
  const [bodyVarCount, setbodyVarCount] = useState(0);
  const [headerVarCount, setHeaderVarCount] = useState(0);
  const [headerSample, setHeaderSample] = useState("");
  const [TemplateError, setTemplateError] = useState(false);
  const [bodySample, setBodySample] = useState([]);
  const [templateshow, setTemplateShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isSaved, setIsSaved] = useState(isData);
  const [popupOpen, setPopupOpen] = useState(false);
  const [screenTitle, setScreenTitle] = useState("");
  const [endNode, setEndNode] = useState(false);

  const componentRef = useRef(null);

  // State to store the height
  const [componentHeight, setComponentHeight] = useState(0);

  useEffect(() => {
    const fetchTemplatesData = async () => {
      try {
        const resp = await getAllTemplates();
        if (!resp.success) throw new Error(resp.error);
        const templateList = [];
        const approvedTemplates = resp?.data?.filter(
          (item) => item?.status == "APPROVED"
        );
        console.log("templatesDataaa", approvedTemplates);

        approvedTemplates?.map((data) => {
          const singleTemplate = {};
          singleTemplate.value = data?.id;
          singleTemplate.label = data?.name;
          templateList.push({ ...singleTemplate, ...data });
        });

        setTemplateList(templateList);
      } catch (error) {
        console.log("Failed to fetch templates!");
      }
    };
    fetchTemplatesData();
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setPopupOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(popupOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && popupOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = popupOpen;
  }, [popupOpen]);

  useEffect(() => {
    const newTemplate = nodes?.filter((node) => node?.type == "template");
    setTemplatetoShow(newTemplate[0]?.data?.templatetoShow);
    setScreenTitle(newTemplate[0]?.data?.screenTitle);
    setEndNode(newTemplate[0]?.data?.isEndNode);
  }, []);

  const handleSelectTemplateChange = (selectedOption) => {
    console.log("template selecteddd", selectedOption);
    setSelectedTemplate(selectedOption);
    if (selectedOption) {
      setTemplateError(false);
    }
    let hLen = 0;
    let bLen = 0;
    if (
      selectedOption.components[0].type === "HEADER" &&
      selectedOption?.category == "AUTHENTICATION"
    ) {
      console.log("coming1");
      if (selectedOption.components[0].format == "text") {
        if (selectedOption.components[0].text.includes("{{1}}")) {
          hLen = 1;
          setHeaderVarCount(1);
        }
        bLen = (selectedOption.components[1].text.match(/{{[1-9]}}/g) || [])
          .length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        setBodySample(newBodySample);
      }
    } else {
      console.log("coming2");

      if (selectedOption?.category == "AUTHENTICATION") {
        console.log("coming3");

        bLen = (selectedOption.components[0].text.match(/{{[1-9]}}/g) || [])
          .length;
        setbodyVarCount(bLen);
        const newBodySample = Array(bLen).fill("");
        console.log("new body samplelelele", newBodySample);
        setBodySample(newBodySample);
      }
    }
    console.log("headerVarCount", bLen, "::", hLen);
    if (
      (bLen > 0 || hLen > 0) &&
      selectedOption?.category == "AUTHENTICATION"
    ) {
      setTemplatetoShow(selectedOption);
      setTemplateShow(true);
    } else {
      setTemplatetoShow(selectedOption);
    }

    console.log("bodyVarCount", bodyVarCount, ":::", bodySample);
  };

  console.log("templatetoShow", data);
  const handleSaveClick = () => {
    if (data && data.updateNodeData) {
      data.updateNodeData(id, { templatetoShow });
    } else {
      const updatedData = { id, templatetoShow };

      dispatch(triggerupdateNodeData(updatedData));
    }

    // const child = {
    //   id: `${+new Date()}`,
    //   type: "templatePreview",
    //   position: {
    //     x: 20,
    //     y: 50,
    //   },
    //   data: { id: templatetoShow.id, templatetoShow: templatetoShow },
    //   parentId: id,
    //   extent: "parent",
    //   draggable: false,
    //   style: {
    //     maxWidth: "271px",
    //     minWidth: "271px",
    //     padding: "0",
    //   },
    // };
    // data.addSingleChildToNode(child);
    if (!isSaved && componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      console.log("height", height);
      setComponentHeight(height);
    }
    if (!templatetoShow) {
      toast.error("Template selection is required!");
      return;
    }
    setIsSaved(true);
  };

  // Measure the component height when `isSaved` is false
  useEffect(() => {
    if (!isSaved && componentRef.current) {
      const height = componentRef.current.getBoundingClientRect().height;
      console.log("height", height);
      setComponentHeight(height);
    }
  }, [isSaved, selectedTemplate]);
  console.log("componentHeight", componentHeight);

  const handleEditClick = () => {
    setPopupOpen(false);

    setIsSaved(false);
  };
  const handleDeleteClick = () => {
    dispatch(setIsDeleteNode({ isDelete: true, nodeId: id }));
    setPopupOpen(false);
  };
  const handleScreenTitle = (e) => {
    const value = e.target.value;
    setScreenTitle(value);
    if (data && data.updateNodeData) {
      data.updateNodeData(id, { screenTitle: value });
    } else {
      const updatedData = { id, screenTitle: value };

      dispatch(triggerupdateNodeData(updatedData));
    }
  };
  const handleEndClick = () => {
    setPopupOpen(false);
    setEndNode(!endNode);
    if (data && data.updateNodeData) {
      data.updateNodeData(id, {
        isEndNode: !endNode,
        isStartNode: false,
      });
    } else {
      const updatedData = {
        id,
        isEndNode: !endNode,
        isStartNode: false,
      };

      dispatch(triggerupdateNodeData(updatedData));
    }
  };

  const handleClearClick = () => {
    setIsSaved(false);
    setTemplatetoShow(null);
    setSelectedTemplate(null);
  };

  return (
    <>
      <div
        className="template"
        // ref={componentRef}
        // style={{ height: `${isSaved && componentHeight + 100}` + "px" }}
        // style={{ width: width + "px", height: height + "px" }}
      >
        <div className="header">
          {/* <h3>Template</h3> */}
          <input
            type="text"
            name=""
            id=""
            className="screen-name"
            placeholder="Add screen name..."
            onChange={handleScreenTitle}
            value={screenTitle}
          />
          {/* {popupOpen && (
            <div className="popup">
              <p onClick={handleEditClick}>Edit</p>
              <p onClick={handleEndClick}>
                {" "}
                {endNode ? "Remove end node" : "Set end Node"}
              </p>
            </div>
          )}
          <div onClick={() => setPopupOpen(!popupOpen)}>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="me-3 cursor-pointer"
            >
              <path
                d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                fill="white"
              />
            </svg>
          </div> */}
          <Stack direction="row" spacing={2} style={{ maxWidth: "50px" }}>
            <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={popupOpen ? "composition-menu" : undefined}
                aria-expanded={popupOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => setPopupOpen(!popupOpen)}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-3 cursor-pointer"
                >
                  <path
                    d="M13.5 9.5C12.8125 9.5 12.224 9.27969 11.7344 8.83906C11.2448 8.39844 11 7.86875 11 7.25C11 6.63125 11.2448 6.10156 11.7344 5.66094C12.224 5.22031 12.8125 5 13.5 5C14.1875 5 14.776 5.22031 15.2656 5.66094C15.7552 6.10156 16 6.63125 16 7.25C16 7.86875 15.7552 8.39844 15.2656 8.83906C14.776 9.27969 14.1875 9.5 13.5 9.5ZM13.5 16.25C12.8125 16.25 12.224 16.0297 11.7344 15.5891C11.2448 15.1484 11 14.6187 11 14C11 13.3813 11.2448 12.8516 11.7344 12.4109C12.224 11.9703 12.8125 11.75 13.5 11.75C14.1875 11.75 14.776 11.9703 15.2656 12.4109C15.7552 12.8516 16 13.3813 16 14C16 14.6187 15.7552 15.1484 15.2656 15.5891C14.776 16.0297 14.1875 16.25 13.5 16.25ZM13.5 23C12.8125 23 12.224 22.7797 11.7344 22.3391C11.2448 21.8984 11 21.3687 11 20.75C11 20.1313 11.2448 19.6016 11.7344 19.1609C12.224 18.7203 12.8125 18.5 13.5 18.5C14.1875 18.5 14.776 18.7203 15.2656 19.1609C15.7552 19.6016 16 20.1313 16 20.75C16 21.3687 15.7552 21.8984 15.2656 22.3391C14.776 22.7797 14.1875 23 13.5 23Z"
                    fill="white"
                  />
                </svg>
              </Button>
              <Popper
                open={popupOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="top-start"
                transition
                disablePortal
                style={{ zIndex: "999" }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={popupOpen}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          // onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                          <hr style={{ color: "#7D7D7D33" }} />
                          <MenuItem onClick={handleDeleteClick}>
                            Delete
                          </MenuItem>
                          {/* <hr style={{ color: "#7D7D7D33" }} />
                          <MenuItem onClick={handleEndClick}>
                            {" "}
                            {endNode ? "Remove end node" : "Set end Node"}
                          </MenuItem> */}
                          {/* <hr style={{ color: "#7D7D7D33" }} /> */}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Stack>
        </div>
        <div className="body-content">
          {!isSaved && (
            <div className="w-full template-section">
              <CustomSelect
                placeholder="Select Template"
                options={templateList}
                onChange={handleSelectTemplateChange}
                value={selectedTemplate}
              />
            </div>
          )}

          {templatetoShow && (
            <div className="template-preview" ref={componentRef}>
              <div className="message-preview-sec px-3">
                <div className="message-prreview-inner ">
                  <div className="preview-sec preview-sec pt-0 ">
                    <div className="preview-body">
                      <div className="preview-template-inbox ps-1">
                        {templatetoShow?.category == "AUTHENTICATION" ? (
                          <AuthTemplate
                            components={templatetoShow?.components}
                            isInbox={true}
                          />
                        ) : (
                          <UrlPhoneNumber
                            components={templatetoShow?.components}
                            isInbox={true}
                            template={templatetoShow}
                            id={templatetoShow?.id}
                          />
                        )}
                      </div>
                      <QuickReplyPreview
                        components={templatetoShow?.components}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isSaved && (
            <div className="save-btn-container">
              <button className="btn-cancel" onClick={handleClearClick}>
                Clear
              </button>
              <button className="btn-save" onClick={handleSaveClick}>
                Save
              </button>
            </div>
          )}
          {/* <CustomHandle type="source" position={Position.Right} /> */}

          <CustomHandle type="target" position={Position.Left} />
        </div>
      </div>
    </>
  );
};

export default Template;
