import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import AutomationIcon from "../../../images/automation-page-icon.svg";
import { setIsActive } from "../../../reduxSlice/workflow";
import { useSelector, useDispatch } from "react-redux";

const Header = ({ screenWidth, setAddShow, setIsEdit }) => {
  const { isActive } = useSelector((state) => state.WorkflowReducer);
  const dispatch = useDispatch();
  // styles
  const tabContainerStyles = {
    border: "1px solid #D9D9D9",
    borderRadius: "6px",
    padding: "2px",
    backgroundColor: "white",
    minWidth: screenWidth > 768 ? "212px" : "100%",
    width: screenWidth > 768 ? "250px" : "100%",
  };
  const tabStyles1 = {
    padding: "7.5px 11px",

    cursor: "pointer",
    fontWeight: "600",
    fontSize: "14px",
    width: screenWidth > 768 ? "146px" : "100%",
    textAlign: "center",
    fontFamily: "Segoe UI",
  };
  const tabStyles2 = {
    padding: "7.5px 11px",

    cursor: "pointer",
    fontWeight: "600",
    fontSize: "14px",
    width: screenWidth > 768 ? "100px" : "100%",

    textAlign: "center",
    fontFamily: "Segoe UI",
  };
  const activeUsersStyle = {
    borderRadius: "4px",
    backgroundColor: isActive ? "#165E5B" : "white",
    color: isActive ? "white" : "#165E5BE5",
  };

  const deletedUsersStyles = {
    borderRadius: "4px",

    backgroundColor: !isActive ? "#165E5B" : "white",
    color: !isActive ? "white" : "#165E5BE5",
  };

  return (
    <section className="main inner-main">
      {screenWidth >= 1024 ? (
        <section className="contact-group-top mb-3">
          <Container className="ps-0 pe-0">
            <Row className="flex-column-reverse flex-md-row align-items-center">
              <Col xs={6} md={6} className="mb-3 mb-md-0">
                <div className="mx-lg-0 d-flex gap-3">
                  <h2
                    className="d-flex align-items-center gap-2 m-0"
                    style={{
                      fontWeight: "600",
                      fontSize: "22px",
                      fontFamily: "Segoe UI",
                    }}
                  >
                    <img src={AutomationIcon} alt="Contact Icon" />
                    Automation
                  </h2>
                  <div style={tabContainerStyles} className="d-flex">
                    <div
                      style={{
                        ...tabStyles1,
                        ...activeUsersStyle,
                      }}
                      onClick={() => {
                        dispatch(setIsActive(true));
                      }}
                    >
                      Pre-define FLows
                    </div>
                    <div
                      style={{
                        ...tabStyles2,
                        ...deletedUsersStyles,
                      }}
                      onClick={() => {
                        dispatch(setIsActive(false));
                      }}
                    >
                      My Flows
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Button
                    className="btn-main d-flex align-items-center"
                    onClick={() => {
                      setAddShow(true);
                      setIsEdit(false);
                    }}
                  >
                    <i className="bi bi-plus"></i>
                    Create Flow
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <section className="contact-group-top">
          <Container className="">
            <h2
              className="mt-1 flex gap-2 mb-3 "
              style={{
                fontWeight: "600",
                fontSize: "22px",
                fontFamily: "Segoe UI",
              }}
            >
              <img src={AutomationIcon} width={24} height={24} />
              Automation
            </h2>
            <div style={tabContainerStyles} className="d-flex mb-3">
              <div
                style={{
                  ...tabStyles1,
                  ...activeUsersStyle,
                }}
                onClick={() => {
                  dispatch(setIsActive(true));
                }}
              >
                Pre-define FLows
              </div>
              <div
                style={{
                  ...tabStyles2,
                  ...deletedUsersStyles,
                }}
                onClick={() => {
                  dispatch(setIsActive(false));
                }}
              >
                My Flows
              </div>
            </div>

            <div className="d-grid gap-2 d-flex justify-content-start mb-3">
              <Button
                className="btn-main d-flex align-items-center"
                onClick={() => setAddShow(true)}
              >
                <i className="bi bi-plus"></i>
                Create Flow
              </Button>
            </div>
          </Container>
        </section>
      )}
    </section>
  );
};

export default Header;
