import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import "../table/table.css";
import { deleteBroadcast } from "../../services/Broadcast";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const BroadcastDropdown = ({
  data,
  fetchAllBroadcastData,
  currentPage,
  startingDate,
  endingDate,
  broadcastType,
  searchBroadcast,
  // isListingOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const { isListingOpen } = useSelector((state) => state.BroadCastsReducer);
  const navigate = useNavigate();

  const cancelHandler = async () => {
    try {
      setLoading(true);
      const resp = await deleteBroadcast({
        broadcastId: data?._id,
        isRemoved: 0,
      });
      if (resp?.success) {
        await fetchAllBroadcastData(
          currentPage,
          20,
          isListingOpen,
          startingDate,
          endingDate,
          broadcastType,
          searchBroadcast
        );
        toast.success(resp?.message);
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };
  const editHandler = async () => {
    navigate(`/editBroadcast/${data?._id}`);
  };
  const deleteHandler = async () => {
    try {
      setLoading(true);
      const resp = await deleteBroadcast({
        broadcastId: data?._id,
        isRemoved: 1,
      });
      if (resp?.success) {
        await fetchAllBroadcastData(
          currentPage,
          20,
          isListingOpen,
          startingDate,
          endingDate,
          broadcastType,
          searchBroadcast
        );
        toast.success(resp?.message);
      }
    } catch (error) {
      console.log("error:", error);
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          className="broadcast-dropdown"
          style={{ background: "none", border: "none", padding: 0 }}
        >
          <svg
            width="5"
            height="15"
            viewBox="0 0 5 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 14.5C2.01875 14.5 1.60692 14.3288 1.2645 13.9864C0.9215 13.6434 0.75 13.2312 0.75 12.75C0.75 12.2688 0.9215 11.8566 1.2645 11.5136C1.60692 11.1712 2.01875 11 2.5 11C2.98125 11 3.39338 11.1712 3.73638 11.5136C4.07879 11.8566 4.25 12.2688 4.25 12.75C4.25 13.2312 4.07879 13.6434 3.73638 13.9864C3.39338 14.3288 2.98125 14.5 2.5 14.5ZM2.5 9.25C2.01875 9.25 1.60692 9.0785 1.2645 8.7355C0.9215 8.39308 0.75 7.98125 0.75 7.5C0.75 7.01875 0.9215 6.60663 1.2645 6.26363C1.60692 5.92121 2.01875 5.75 2.5 5.75C2.98125 5.75 3.39338 5.92121 3.73638 6.26363C4.07879 6.60663 4.25 7.01875 4.25 7.5C4.25 7.98125 4.07879 8.39308 3.73638 8.7355C3.39338 9.0785 2.98125 9.25 2.5 9.25ZM2.5 4C2.01875 4 1.60692 3.8285 1.2645 3.4855C0.9215 3.14308 0.75 2.73125 0.75 2.25C0.75 1.76875 0.9215 1.35692 1.2645 1.0145C1.60692 0.6715 2.01875 0.5 2.5 0.5C2.98125 0.5 3.39338 0.6715 3.73638 1.0145C4.07879 1.35692 4.25 1.76875 4.25 2.25C4.25 2.73125 4.07879 3.14308 3.73638 3.4855C3.39338 3.8285 2.98125 4 2.5 4Z"
              fill="black"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className="option-dropdown">
          {data?.state != 4 && (
            <Dropdown.Item onClick={() => setOpenCancelPopup(true)}>
              Cancel Broadcast
            </Dropdown.Item>
          )}

          <Dropdown.Item onClick={editHandler}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => setOpenDeletePopup(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={openDeletePopup}
        onHide={() => {
          setOpenDeletePopup(false);
        }}
        className="whatsapp-modal delete-modal"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Delete?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-0">
              <Typography className="text-[20px] font-['Segoe UI'] font-thin leading-[18px] text-[#000000]">
                {`You are about to delete scheduled broadcast`}{" "}
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  “${data?.broadcastName}“
                </span>
                {`? This process cannot be reversed.`}
              </Typography>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn btn-danger-light"
            onClick={() => deleteHandler()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red", marginRight: "10px" }}
              />
            ) : null}
            Yes, Delete It
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setOpenDeletePopup(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openCancelPopup}
        onHide={() => {
          setOpenCancelPopup(false);
        }}
        className="whatsapp-modal delete-modal"
        centered
      >
        <Modal.Header className="p-0">
          <Modal.Title>Cancel Broadcast?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-0">
              <Typography className="text-[20px] font-['Segoe UI'] font-bold leading-[18px] text-[#000000]">
                {`You are about to cancel broadcast `}
                <span
                  style={{ fontWeight: "600" }}
                >{`“${data?.broadcastName}“`}</span>
                {`?`}
              </Typography>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="px-0">
          <Button
            className="btn btn-danger-light"
            onClick={() => cancelHandler()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ color: "red", marginRight: "10px" }}
              />
            ) : null}
            Yes, Cancel It
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setOpenCancelPopup(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BroadcastDropdown;
