import { Accordion, Col, Form, Row } from "react-bootstrap";
import { useRef, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Zoom,
  Button as MuiButton,
  useMediaQuery,
} from "@mui/material";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "../../images/icons/infoIcon.svg";

import imageIcon from "../../images/icons/image-small-icon.svg";
import selImageIcon from "../../images/icons/selected-image.svg";
import documentIcon from "../../images/document-small-icon.svg";
import selDocumentIcon from "../../images/icons/selected-doc.svg";
import videoIcon from "../../images/video-small-icon.svg";
import selVideoIcon from "../../images/icons/selected-video.svg";
import locationIcon from "../../images/location-small-icon.svg";
import selLocationIcon from "../../images/icons/selected-loc.svg";
import smallImageIcon from "../../images/image-icon.svg";
import smallDocIcon from "../../images/document-icon.svg";
import smallVideoIcon from "../../images/video-icon.svg";
import dropdownIcon from "../../images/icons/select-field-icon.svg";

export const HeaderTemplate = ({
  handleChange,
  formData,
  setSelAddress,
  handleHeaderVarCount,
  setFormData,
  showMapModal,
  headerVarCount,
  handleHeaderSample,
  headerSample,
  setTempImageUrl,
  setHeaderData,
  headerData,
}) => {
  useMemo(() => {
    if (headerData?.type == "none") {
      setTempImageUrl(null);
      setFormData((prev) => ({ ...prev, header: "" }));
      // setHeaderData(prev => ({ ...prev, file: null }))
    } else if (headerData?.type == "media") {
      setFormData((prev) => ({ ...prev, header: "" }));
    } else {
      setTempImageUrl(null);
      // setHeaderData(prev => ({ ...prev, file: null }))
    }
  }, [headerData?.type]);
  const [headerShow, setHeaderShow] = useState(false);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdownIcon} />
      </components.DropdownIndicator>
    );
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      color: "#000000",
      fontWeight: 500,
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": { backgroundColor: "#E2FFF0", color: "#000000" },
    }),
  };

  const isTablet = useMediaQuery("(min-width:768px)");
  console.log("isTablet", isTablet);

  const headerOptions = [
    { value: "none", label: "None" },
    { value: "text", label: "Text" },
    { value: "media", label: "Media" },
  ];

  const [selectedMediaType, setSelectedMediaType] = useState(
    headerData?.fileType || "image"
  );
  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const documentRef = useRef(null);
  const allowedImagesTypes = ["image/jpeg", "image/png", "image/jpg"];
  const allowedVideoTypes = ["video/mp4"];
  const allowedFilesTypes = ["application/pdf"];

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("file.size", file.size / 1024);
    if (!allowedImagesTypes.includes(file.type)) {
      toast.error("Please select image type of (jpeg/png/jpg)");
      return;
    }
    if ((file.size / 1024).toFixed(2) > 5120) {
      toast.error("Maximum upload size of any file is 5MB");
      return;
    }

    setHeaderData((prev) => ({ ...prev, file: file }));
    const url = URL.createObjectURL(file);
    setTempImageUrl(url);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    console.log("file.size", file.size / 1024);
    if (!allowedVideoTypes.includes(file.type)) {
      toast.error("Please select video type of (.mp4)");
      return;
    }
    if ((file.size / 1024).toFixed(2) > 10240) {
      toast.error("Maximum upload size of any file is 10MB");
      return;
    }
    console.log("name of file : ", file);
    setTempImageUrl(null);
    setHeaderData((prev) => ({ ...prev, file: file }));
  };

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    console.log("file.size", file.size / 1024);
    if (!allowedFilesTypes.includes(file.type)) {
      toast.error("Please select file type of (.pdf)");
      return;
    }
    if ((file.size / 1024).toFixed(2) > 10240) {
      toast.error("Maximum upload size of any file is 10MB");
      return;
    }
    console.log("name of file : ", file);
    setTempImageUrl(null);
    setHeaderData((prev) => ({ ...prev, file: file }));
  };

  const handleCloseBtnClick = () => {
    setTempImageUrl(null);
    setHeaderData((prev) => ({ ...prev, file: null }));
  };

  console.log("formData?.header", formData?.header);

  return (
    <>
      <Row>
        <Col sm={12}>
          <Box className="flex justify-start items-start flex-column border-[1px] border-dashed rounded-md border-[#77777780] p-3 bg-[#FFFFFF] w-100 mt-[18px] templates ">
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[16px] font-medium leading-[22px] text-[#000000] flex gap-2"
            >
              Message Type
              <Tooltip
                arrow
                TransitionComponent={Zoom}
                title={
                  <p>
                    <strong style={{ fontWeight: "600" }}> Text: </strong>A text
                    message you want to send only text content. <br />
                    <strong style={{ fontWeight: "600" }}> Media: </strong>An
                    image, Document, or a video-type message you want to send.
                    <br />
                    <strong style={{ fontWeight: "600" }}>Image: </strong>
                    is the message type that contains an Image/Photo file with
                    text. <br />
                    <strong style={{ fontWeight: "600" }}>Document: </strong>is
                    the message type include a PDF, CSV, XLS, TXT, DOC file as
                    attachment in the message along text
                    <br />
                    <strong style={{ fontWeight: "600" }}> Video: </strong> is
                    the message type to send a video file message.
                    <br />
                    <strong style={{ fontWeight: "600" }}>
                      {" "}
                      Location:{" "}
                    </strong>{" "}
                    is the message type to add a specific location from the map
                    to guide the user.
                  </p>
                }
                placement={"top"}
                sx={{ cursor: "pointer" }}
                open={headerShow}
                onMouseEnter={() => setHeaderShow(true)}
                onMouseLeave={() => setHeaderShow(false)}
              >
                <img
                  src={InfoIcon}
                  style={{ cursor: "pointer" }}
                  className="mt-1"
                  width={16}
                  height={16}
                />
              </Tooltip>
            </h4>
            <h4
              style={{ fontFamily: "SEGOE UI" }}
              className="text-[15px] font-normal leading-[18px] text-[#494949] mt-[8px] "
            >
              (Optional) Choose the message type which can include text, image,
              document, video or location
            </h4>
            <Select
              styles={colourStyles}
              className="c-select create-template create-template-header mt-[12px] min-w-[200px] "
              components={{ DropdownIndicator }}
              options={headerOptions}
              placeholder="Select a Type"
              value={headerOptions?.find(
                (item) => item?.value == headerData?.type
              )}
              onChange={(val) =>
                setHeaderData((prev) => ({ ...prev, type: val?.value }))
              }
            />

            {/* in case user has selected text as header */}
            {headerData?.type == "none" ? null : (
              <>
                {headerData?.type == "text" ? (
                  <>
                    <h4
                      style={{ fontFamily: "SEGOE UI" }}
                      className="text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]"
                    >
                      Message Title
                    </h4>
                    <Form.Group className="form-group w-full mt-[12px]">
                      <Form.Control
                        style={{
                          marginBottom: "0px",
                          backgroundColor: "#F3F7F7",
                          height: "40px",
                        }}
                        type="text"
                        isInvalid={formData?.header.length > 60}
                        value={formData?.header}
                        placeholder="Enter text"
                        name="header"
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback
                        style={{
                          marginBottom: "0px",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                          display: "flex",
                        }}
                        type="invalid"
                      >
                        <p style={{ fontSize: "12px" }}>
                          {formData?.header.length > 60
                            ? "Not more than 60 character allowed"
                            : ""}
                        </p>
                        <span style={{ color: "black" }}>
                          {Array.from(formData?.header).length}/60
                        </span>
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                ) : null}

                {headerData?.type == "media" ? (
                  <>
                    {/* in case user has selected file type */}
                    <Box
                      className="flex justify-between md:justify-start items-start flex-wrap gap-2 mt-4"
                      sx={{ width: !isTablet ? "100%" : "auto" }}
                    >
                      <Box
                        className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66]  min-h-[110px] rounded-md "
                        sx={{
                          cursor: "pointer",
                          width: isTablet ? "110px" : "48%",
                          backgroundColor:
                            headerData?.fileType == "image"
                              ? "#E5FFF1"
                              : "#FFFFFF",
                        }}
                        onClick={() => {
                          setSelectedMediaType("image");
                          headerData?.fileType != "image" &&
                            setHeaderData((prev) => ({
                              ...prev,
                              fileType: "image",
                              file: null,
                            }));
                        }}
                      >
                        <Box className="flex justify-start items-center pl-[10px] pt-[5px]">
                          <Box className="form-check form-check-inline ">
                            <input
                              className="form-check-input create-template-btn"
                              type="radio"
                              id="header-type-1"
                              checked={headerData?.fileType == "image"}
                            />
                            <label
                              className="form-label"
                              htmlFor="header-type-1"
                            ></label>
                          </Box>
                        </Box>
                        <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2">
                          <img
                            src={
                              headerData?.fileType == "image"
                                ? selImageIcon
                                : imageIcon
                            }
                            style={{ width: "44px", height: "44px" }}
                          />
                          <h4
                            style={{ fontFamily: "SEGOE UI" }}
                            className="text-[14px] font-medium leading-[22px] text-[#000000]"
                          >
                            Image
                          </h4>
                        </Box>
                      </Box>
                      <Box
                        className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66]  min-h-[110px] rounded-md "
                        sx={{
                          cursor: "pointer",
                          width: isTablet ? "110px" : "48%",
                          backgroundColor:
                            headerData?.fileType == "video"
                              ? "#E5FFF1"
                              : "#FFFFFF",
                        }}
                        onClick={() => {
                          setSelectedMediaType("video");
                          headerData?.fileType != "video" &&
                            setHeaderData((prev) => ({
                              ...prev,
                              fileType: "video",
                              file: null,
                            }));
                        }}
                      >
                        <Box className="flex justify-start items-center pl-[10px] pt-[5px]">
                          <Box className="form-check form-check-inline ">
                            <input
                              className="form-check-input create-template-btn"
                              type="radio"
                              id="header-type-1"
                              checked={headerData?.fileType == "video"}
                            />
                            <label
                              className="form-label"
                              htmlFor="header-type-1"
                            ></label>
                          </Box>
                        </Box>
                        <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2">
                          <img
                            src={
                              headerData?.fileType == "video"
                                ? selVideoIcon
                                : videoIcon
                            }
                            style={{ width: "44px", height: "44px" }}
                          />
                          <h4
                            style={{ fontFamily: "SEGOE UI" }}
                            className="text-[14px] font-medium leading-[22px] text-[#000000]"
                          >
                            Video
                          </h4>
                        </Box>
                      </Box>
                      <Box
                        className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66]  min-h-[110px] rounded-md "
                        sx={{
                          cursor: "pointer",
                          width: isTablet ? "110px" : "48%",
                          backgroundColor:
                            headerData?.fileType == "document"
                              ? "#E5FFF1"
                              : "#FFFFFF",
                        }}
                        onClick={() => {
                          setSelectedMediaType("document");
                          headerData?.fileType != "document" &&
                            setHeaderData((prev) => ({
                              ...prev,
                              fileType: "document",
                              file: null,
                            }));
                        }}
                      >
                        <Box className="flex justify-start items-center pl-[10px] pt-[5px]">
                          <Box className="form-check form-check-inline ">
                            <input
                              className="form-check-input create-template-btn"
                              type="radio"
                              id="header-type-1"
                              checked={headerData?.fileType == "document"}
                            />
                            <label
                              className="form-label"
                              htmlFor="header-type-1"
                            ></label>
                          </Box>
                        </Box>
                        <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2">
                          <img
                            src={
                              headerData?.fileType == "document"
                                ? selDocumentIcon
                                : documentIcon
                            }
                            style={{ width: "44px", height: "44px" }}
                          />
                          <h4
                            style={{ fontFamily: "SEGOE UI" }}
                            className="text-[14px] font-medium leading-[22px] text-[#000000]"
                          >
                            Document
                          </h4>
                        </Box>
                      </Box>
                      <Box
                        className="flex justify-start items-start flex-col border-[1px] border-[#165E5B66]  min-h-[110px] rounded-md "
                        sx={{
                          cursor: "pointer",
                          width: isTablet ? "110px" : "48%",
                          backgroundColor:
                            headerData?.fileType == "location"
                              ? "#E5FFF1"
                              : "#FFFFFF",
                        }}
                        onClick={() => {
                          setSelectedMediaType("location");
                          setHeaderData((prev) => ({
                            ...prev,
                            fileType: "location",
                            file: null,
                          }));
                          setTempImageUrl(null);
                        }}
                      >
                        <Box className="flex justify-start items-center pl-[10px] pt-[5px]">
                          <Box className="form-check form-check-inline ">
                            <input
                              className="form-check-input create-template-btn"
                              type="radio"
                              id="header-type-1"
                              checked={headerData?.fileType == "location"}
                            />
                            <label
                              className="form-label"
                              htmlFor="header-type-1"
                            ></label>
                          </Box>
                        </Box>
                        <Box className="flex justify-center items-center flex-col w-100 gap-2 pb-2">
                          <img
                            src={
                              headerData?.fileType == "location"
                                ? selLocationIcon
                                : locationIcon
                            }
                            style={{ width: "44px", height: "44px" }}
                          />
                          <h4
                            style={{ fontFamily: "SEGOE UI" }}
                            className="text-[14px] font-medium leading-[22px] text-[#000000]"
                          >
                            Location
                          </h4>
                        </Box>
                      </Box>
                    </Box>

                    {/* if option of image is selected for file */}
                    {headerData?.fileType == "image" ? (
                      <Box
                        className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]"
                        sx={{ cursor: "pointer" }}
                      >
                        <h4
                          style={{ fontFamily: "SEGOE UI" }}
                          className="text-[16px] font-medium leading-[22px] text-[#000000]"
                        >
                          Upload image
                        </h4>
                        <Box className="flex justify-start items-center w-[80%] md:w-[30%] gap-[10px]">
                          <Box
                            className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]"
                            onClick={() =>
                              headerData?.file == null
                                ? imageRef.current.click()
                                : null
                            }
                          >
                            <img
                              src={smallImageIcon}
                              style={{ width: "14px", height: "14px" }}
                            />
                            <h4
                              style={{ fontFamily: "SEGOE UI" }}
                              className="text-[14px] font-medium leading-[22px] text-[#4B4A4A]"
                            >
                              {headerData?.file != null
                                ? headerData?.file?.name
                                  ? headerData?.file?.name?.length > 20
                                    ? headerData?.file?.name?.substring(0, 20) +
                                      "..."
                                    : headerData?.file?.name
                                  : headerData?.file?.substring(0, 20) + "..."
                                : "Choose JPG / PNG - up to 5 MB"}
                            </h4>
                          </Box>
                          {headerData?.file != null ? (
                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={handleCloseBtnClick}
                            >
                              <CloseIcon
                                sx={{ fontSize: "20px", color: "#000000" }}
                              />
                            </IconButton>
                          ) : null}
                        </Box>
                        <input
                          ref={imageRef}
                          onChange={handleImageChange}
                          type="file"
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </Box>
                    ) : null}

                    {/* if option of video is selected for file */}
                    {headerData?.fileType == "video" ? (
                      <Box
                        className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]"
                        sx={{ cursor: "pointer" }}
                      >
                        <h4
                          style={{ fontFamily: "SEGOE UI" }}
                          className="text-[16px] font-medium leading-[22px] text-[#000000]"
                        >
                          Upload video{" "}
                        </h4>
                        <Box
                          className="flex justify-start items-center w-[80%] md:w-[30%] gap-[10px]"
                          onClick={() =>
                            headerData?.file == null
                              ? videoRef.current.click()
                              : null
                          }
                        >
                          <Box className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]">
                            <img
                              src={smallVideoIcon}
                              style={{ width: "14px", height: "14px" }}
                            />
                            <h4
                              style={{ fontFamily: "SEGOE UI" }}
                              className="text-[14px] font-medium leading-[22px] text-[#4B4A4A]"
                            >
                              {headerData?.file != null
                                ? headerData?.file?.name
                                  ? headerData?.file?.name?.substring(0, 20) +
                                    "..."
                                  : headerData?.file?.substring(0, 20) + "..."
                                : "Choose MP4 file - up to 10 MB"}
                            </h4>
                          </Box>
                          {headerData?.file != null ? (
                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={handleCloseBtnClick}
                            >
                              <CloseIcon
                                sx={{ fontSize: "20px", color: "#000000" }}
                              />
                            </IconButton>
                          ) : null}
                          <input
                            ref={videoRef}
                            onChange={handleVideoChange}
                            type="file"
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            accept="video/*"
                            style={{ display: "none" }}
                          />
                        </Box>
                      </Box>
                    ) : null}

                    {/* if option of document is selected for file */}
                    {headerData?.fileType == "document" ? (
                      <Box
                        className="flex justify-start items-start flex-col w-100 gap-[10px] mt-[30px]"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          headerData?.file == null
                            ? documentRef.current.click()
                            : null
                        }
                      >
                        <h4
                          style={{ fontFamily: "SEGOE UI" }}
                          className="text-[16px] font-medium leading-[22px] text-[#000000]"
                        >
                          Upload PDF{" "}
                        </h4>
                        <Box className="flex justify-start items-center w-[80%] md:w-[30%] gap-[10px]">
                          <Box className="flex justify-start items-center gap-2  border-[1px] border-[#BDC8D4] rounded-md p-[11px]">
                            <img
                              src={smallDocIcon}
                              style={{ width: "14px", height: "14px" }}
                            />
                            <h4
                              style={{ fontFamily: "SEGOE UI" }}
                              className="text-[14px] font-medium leading-[22px] text-[#4B4A4A]"
                            >
                              {headerData?.file != null
                                ? headerData?.file?.name
                                  ? headerData?.file?.name
                                  : headerData?.file?.substring(0, 20) + "..."
                                : "Choose a PDF file - up to 10 MB"}
                            </h4>
                          </Box>
                          {headerData?.file != null ? (
                            <IconButton
                              sx={{ padding: "0px" }}
                              onClick={handleCloseBtnClick}
                            >
                              <CloseIcon
                                sx={{ fontSize: "20px", color: "#000000" }}
                              />
                            </IconButton>
                          ) : null}
                          <input
                            ref={documentRef}
                            onChange={handleDocumentChange}
                            type="file"
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            accept="application/pdf"
                            style={{ display: "none" }}
                          />
                        </Box>
                      </Box>
                    ) : null}

                    {/* if option of location is selected for file */}
                    {headerData?.fileType == "location" ? (
                      <Box
                        className="flex justify-start items-start flex-col w-100 gap-[10px]"
                        sx={{ cursor: "pointer" }}
                      >
                        <h4
                          style={{ fontFamily: "SEGOE UI" }}
                          className="text-[16px] font-medium leading-[22px] text-[#000000] mt-[22px]"
                        >
                          Location Name
                        </h4>
                        <Form.Group className="form-group w-full ">
                          <Form.Control
                            style={{
                              marginBottom: "0px",
                              background: "#F3F7F7",
                              height: "40px",
                            }}
                            type="text"
                            placeholder="Enter Name"
                            value={headerData?.locationName}
                            onChange={(e) => {
                              setHeaderData((prev) => ({
                                ...prev,
                                locationName: e.target.value,
                              }));
                              setSelAddress(e.target.value);
                            }}
                            name="name"
                          />
                        </Form.Group>

                        <Box className="flex justify-between items-baseline gap-[30px] w-100 mt-0">
                          <Box className="flex justify-start items-start flex-col w-100 ">
                            <h4
                              style={{ fontFamily: "SEGOE UI" }}
                              className="text-[16px] font-medium leading-[22px] text-[#000000]"
                            >
                              Latitude
                            </h4>
                            <Form.Group className="form-group w-full mt-[12px]">
                              <Form.Control
                                style={{
                                  marginBottom: "0px",
                                  background: "#F3F7F7",
                                  height: "40px",
                                }}
                                type="text"
                                value={headerData?.locLatitude}
                                onChange={(e) => {
                                  setHeaderData((prev) => ({
                                    ...prev,
                                    locLatitude: e.target.value,
                                  }));
                                }}
                                placeholder="Enter Latitude"
                                name="name"
                              />
                            </Form.Group>
                          </Box>
                          <Box className="flex justify-start items-start flex-col w-100 ">
                            <h4
                              style={{ fontFamily: "SEGOE UI" }}
                              className="text-[16px] font-medium leading-[22px] text-[#000000]"
                            >
                              Longitude
                            </h4>
                            <Form.Group className="form-group w-full mt-[12px]">
                              <Form.Control
                                style={{
                                  marginBottom: "0px",
                                  background: "#F3F7F7",
                                  height: "40px",
                                }}
                                type="text"
                                value={headerData?.locLongitude}
                                placeholder="Enter Longitude"
                                onChange={(e) => {
                                  setHeaderData((prev) => ({
                                    ...prev,
                                    locLongitude: e.target.value,
                                  }));
                                }}
                                name="name"
                              />
                            </Form.Group>
                          </Box>
                        </Box>

                        <Box className="flex justify-start items-start w-100 mt-[26px]">
                          <MuiButton
                            variant="contained"
                            onClick={showMapModal}
                            disableRipple
                            sx={{
                              background: "#165E5B",
                              color: "#FFFFFF",
                              borderRadius: "5px",
                              height: "45px",
                              textTransform: "none",
                              fontWeight: 600,
                              boxShadow: "none",
                              fontSize: "16px",
                              fontFamily: "Segoe UI",
                              boxShadow: "none",
                              "&:hover": { backgroundColor: "#165E5B" },
                            }}
                          >
                            Add Location From Map
                          </MuiButton>
                        </Box>
                      </Box>
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </Box>
        </Col>
      </Row>
    </>
  );
};
