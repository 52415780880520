import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Whatsappform from "../components/wahtsapp_form/forms";

import "../components/wahtsapp_form/form.css";

const Login = () => {
  return (
    <>
      <Whatsappform heading={"Sign in to your account"} />

      {/* <section className="wahtsapp_form">
        <Container>
          <Row>
            <Col sm={12}>
              <Whatsappform heading={"Sign in to your account"} />
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Login;
